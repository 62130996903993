import gql from 'graphql-tag';
import { createMutationFunction, createSmartQueryOptionsFunction, createSmartSubscriptionOptionsFunction } from 'vue-apollo-smart-ops';
import { ApolloError } from 'apollo-client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  bigint: any;
  bpchar: any;
  communication_types: any;
  contact_role: any;
  date: any;
  evergreen_types: any;
  float8: any;
  inet: any;
  invite_status: any;
  jsonb: any;
  name: any;
  numeric: any;
  oid: any;
  point: any;
  smallint: any;
  status: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Unified view of a User, as Auth0 || DB data. */
export type CombinedUser = {
  __typename?: 'CombinedUser';
  auth0_status: Scalars['String'];
  auth0_user_id?: Maybe<Scalars['String']>;
  blocked?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  family_name?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  last_ip?: Maybe<Scalars['String']>;
  last_login?: Maybe<Scalars['DateTime']>;
  last_password_reset?: Maybe<Scalars['DateTime']>;
  logins_count?: Maybe<Scalars['Float']>;
  multifactor?: Maybe<Array<Scalars['String']>>;
  picture?: Maybe<Scalars['String']>;
  pristine?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars['String']>>;
  _eq?: Maybe<Array<Scalars['String']>>;
  _gt?: Maybe<Array<Scalars['String']>>;
  _gte?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Array<Scalars['String']>>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Array<Scalars['String']>>;
  _lte?: Maybe<Array<Scalars['String']>>;
  _neq?: Maybe<Array<Scalars['String']>>;
  _nin?: Maybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "ach_queues" */
export type Ach_Queues = {
  __typename?: 'ach_queues';
  account_number: Scalars['String'];
  amount: Scalars['numeric'];
  batch_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['Int'];
  /** An object relationship */
  customers: Customers;
  id: Scalars['bigint'];
  invalidated: Scalars['Boolean'];
  invoice_number?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['jsonb']>;
  one_time_ach: Scalars['Boolean'];
  pmt_date: Scalars['timestamptz'];
  routing_number: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Auth_Users>;
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "ach_queues" */
export type Ach_QueuesNotesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ach_queues" */
export type Ach_Queues_Aggregate = {
  __typename?: 'ach_queues_aggregate';
  aggregate?: Maybe<Ach_Queues_Aggregate_Fields>;
  nodes: Array<Ach_Queues>;
};

export type Ach_Queues_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Ach_Queues_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Ach_Queues_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Ach_Queues_Aggregate_Bool_Exp_Count>;
};

export type Ach_Queues_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ach_Queues_Select_Column_Ach_Queues_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ach_Queues_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ach_Queues_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ach_Queues_Select_Column_Ach_Queues_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ach_Queues_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ach_Queues_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Ach_Queues_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ach_Queues_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ach_queues" */
export type Ach_Queues_Aggregate_Fields = {
  __typename?: 'ach_queues_aggregate_fields';
  avg?: Maybe<Ach_Queues_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ach_Queues_Max_Fields>;
  min?: Maybe<Ach_Queues_Min_Fields>;
  stddev?: Maybe<Ach_Queues_Stddev_Fields>;
  stddev_pop?: Maybe<Ach_Queues_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ach_Queues_Stddev_Samp_Fields>;
  sum?: Maybe<Ach_Queues_Sum_Fields>;
  var_pop?: Maybe<Ach_Queues_Var_Pop_Fields>;
  var_samp?: Maybe<Ach_Queues_Var_Samp_Fields>;
  variance?: Maybe<Ach_Queues_Variance_Fields>;
};


/** aggregate fields of "ach_queues" */
export type Ach_Queues_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ach_Queues_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ach_queues" */
export type Ach_Queues_Aggregate_Order_By = {
  avg?: Maybe<Ach_Queues_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ach_Queues_Max_Order_By>;
  min?: Maybe<Ach_Queues_Min_Order_By>;
  stddev?: Maybe<Ach_Queues_Stddev_Order_By>;
  stddev_pop?: Maybe<Ach_Queues_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ach_Queues_Stddev_Samp_Order_By>;
  sum?: Maybe<Ach_Queues_Sum_Order_By>;
  var_pop?: Maybe<Ach_Queues_Var_Pop_Order_By>;
  var_samp?: Maybe<Ach_Queues_Var_Samp_Order_By>;
  variance?: Maybe<Ach_Queues_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ach_Queues_Append_Input = {
  notes?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "ach_queues" */
export type Ach_Queues_Arr_Rel_Insert_Input = {
  data: Array<Ach_Queues_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Ach_Queues_On_Conflict>;
};

/** aggregate avg on columns */
export type Ach_Queues_Avg_Fields = {
  __typename?: 'ach_queues_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ach_queues" */
export type Ach_Queues_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ach_queues". All fields are combined with a logical 'AND'. */
export type Ach_Queues_Bool_Exp = {
  _and?: Maybe<Array<Ach_Queues_Bool_Exp>>;
  _not?: Maybe<Ach_Queues_Bool_Exp>;
  _or?: Maybe<Array<Ach_Queues_Bool_Exp>>;
  account_number?: Maybe<String_Comparison_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  batch_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  customers?: Maybe<Customers_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  invalidated?: Maybe<Boolean_Comparison_Exp>;
  invoice_number?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<Jsonb_Comparison_Exp>;
  one_time_ach?: Maybe<Boolean_Comparison_Exp>;
  pmt_date?: Maybe<Timestamptz_Comparison_Exp>;
  routing_number?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Auth_Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ach_queues" */
export enum Ach_Queues_Constraint {
  /** unique or primary key constraint on columns "id" */
  AchQueuesPkey = 'ach_queues_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ach_Queues_Delete_At_Path_Input = {
  notes?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ach_Queues_Delete_Elem_Input = {
  notes?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ach_Queues_Delete_Key_Input = {
  notes?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "ach_queues" */
export type Ach_Queues_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  invoice_number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ach_queues" */
export type Ach_Queues_Insert_Input = {
  account_number?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  customers?: Maybe<Customers_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  invalidated?: Maybe<Scalars['Boolean']>;
  invoice_number?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['jsonb']>;
  one_time_ach?: Maybe<Scalars['Boolean']>;
  pmt_date?: Maybe<Scalars['timestamptz']>;
  routing_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Auth_Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Ach_Queues_Max_Fields = {
  __typename?: 'ach_queues_max_fields';
  account_number?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  invoice_number?: Maybe<Scalars['Int']>;
  pmt_date?: Maybe<Scalars['timestamptz']>;
  routing_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ach_queues" */
export type Ach_Queues_Max_Order_By = {
  account_number?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  pmt_date?: Maybe<Order_By>;
  routing_number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ach_Queues_Min_Fields = {
  __typename?: 'ach_queues_min_fields';
  account_number?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  invoice_number?: Maybe<Scalars['Int']>;
  pmt_date?: Maybe<Scalars['timestamptz']>;
  routing_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ach_queues" */
export type Ach_Queues_Min_Order_By = {
  account_number?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  pmt_date?: Maybe<Order_By>;
  routing_number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "ach_queues" */
export type Ach_Queues_Mutation_Response = {
  __typename?: 'ach_queues_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ach_Queues>;
};

/** on_conflict condition type for table "ach_queues" */
export type Ach_Queues_On_Conflict = {
  constraint: Ach_Queues_Constraint;
  update_columns?: Array<Ach_Queues_Update_Column>;
  where?: Maybe<Ach_Queues_Bool_Exp>;
};

/** Ordering options when selecting data from "ach_queues". */
export type Ach_Queues_Order_By = {
  account_number?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customers?: Maybe<Customers_Order_By>;
  id?: Maybe<Order_By>;
  invalidated?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  one_time_ach?: Maybe<Order_By>;
  pmt_date?: Maybe<Order_By>;
  routing_number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Auth_Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: ach_queues */
export type Ach_Queues_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ach_Queues_Prepend_Input = {
  notes?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "ach_queues" */
export enum Ach_Queues_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  Amount = 'amount',
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Invalidated = 'invalidated',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  Notes = 'notes',
  /** column name */
  OneTimeAch = 'one_time_ach',
  /** column name */
  PmtDate = 'pmt_date',
  /** column name */
  RoutingNumber = 'routing_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "ach_queues_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ach_queues" */
export enum Ach_Queues_Select_Column_Ach_Queues_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Invalidated = 'invalidated',
  /** column name */
  OneTimeAch = 'one_time_ach'
}

/** select "ach_queues_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ach_queues" */
export enum Ach_Queues_Select_Column_Ach_Queues_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Invalidated = 'invalidated',
  /** column name */
  OneTimeAch = 'one_time_ach'
}

/** input type for updating data in table "ach_queues" */
export type Ach_Queues_Set_Input = {
  account_number?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  invalidated?: Maybe<Scalars['Boolean']>;
  invoice_number?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['jsonb']>;
  one_time_ach?: Maybe<Scalars['Boolean']>;
  pmt_date?: Maybe<Scalars['timestamptz']>;
  routing_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ach_Queues_Stddev_Fields = {
  __typename?: 'ach_queues_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ach_queues" */
export type Ach_Queues_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ach_Queues_Stddev_Pop_Fields = {
  __typename?: 'ach_queues_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ach_queues" */
export type Ach_Queues_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ach_Queues_Stddev_Samp_Fields = {
  __typename?: 'ach_queues_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ach_queues" */
export type Ach_Queues_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "ach_queues" */
export type Ach_Queues_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ach_Queues_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ach_Queues_Stream_Cursor_Value_Input = {
  account_number?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  invalidated?: Maybe<Scalars['Boolean']>;
  invoice_number?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['jsonb']>;
  one_time_ach?: Maybe<Scalars['Boolean']>;
  pmt_date?: Maybe<Scalars['timestamptz']>;
  routing_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ach_Queues_Sum_Fields = {
  __typename?: 'ach_queues_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  invoice_number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ach_queues" */
export type Ach_Queues_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "ach_queues" */
export enum Ach_Queues_Update_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  Amount = 'amount',
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Invalidated = 'invalidated',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  Notes = 'notes',
  /** column name */
  OneTimeAch = 'one_time_ach',
  /** column name */
  PmtDate = 'pmt_date',
  /** column name */
  RoutingNumber = 'routing_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Ach_Queues_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Ach_Queues_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Ach_Queues_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Ach_Queues_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Ach_Queues_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ach_Queues_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Ach_Queues_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ach_Queues_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ach_Queues_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ach_Queues_Var_Pop_Fields = {
  __typename?: 'ach_queues_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ach_queues" */
export type Ach_Queues_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ach_Queues_Var_Samp_Fields = {
  __typename?: 'ach_queues_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ach_queues" */
export type Ach_Queues_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ach_Queues_Variance_Fields = {
  __typename?: 'ach_queues_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ach_queues" */
export type Ach_Queues_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "active_storage_blobs" */
export type Active_Storage_Blobs = {
  __typename?: 'active_storage_blobs';
  byte_size: Scalars['bigint'];
  checksum: Scalars['String'];
  content_type?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  filename: Scalars['String'];
  id: Scalars['bigint'];
  key: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  service_name: Scalars['String'];
};

/** aggregated selection of "active_storage_blobs" */
export type Active_Storage_Blobs_Aggregate = {
  __typename?: 'active_storage_blobs_aggregate';
  aggregate?: Maybe<Active_Storage_Blobs_Aggregate_Fields>;
  nodes: Array<Active_Storage_Blobs>;
};

/** aggregate fields of "active_storage_blobs" */
export type Active_Storage_Blobs_Aggregate_Fields = {
  __typename?: 'active_storage_blobs_aggregate_fields';
  avg?: Maybe<Active_Storage_Blobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Active_Storage_Blobs_Max_Fields>;
  min?: Maybe<Active_Storage_Blobs_Min_Fields>;
  stddev?: Maybe<Active_Storage_Blobs_Stddev_Fields>;
  stddev_pop?: Maybe<Active_Storage_Blobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Active_Storage_Blobs_Stddev_Samp_Fields>;
  sum?: Maybe<Active_Storage_Blobs_Sum_Fields>;
  var_pop?: Maybe<Active_Storage_Blobs_Var_Pop_Fields>;
  var_samp?: Maybe<Active_Storage_Blobs_Var_Samp_Fields>;
  variance?: Maybe<Active_Storage_Blobs_Variance_Fields>;
};


/** aggregate fields of "active_storage_blobs" */
export type Active_Storage_Blobs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Active_Storage_Blobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Active_Storage_Blobs_Avg_Fields = {
  __typename?: 'active_storage_blobs_avg_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "active_storage_blobs". All fields are combined with a logical 'AND'. */
export type Active_Storage_Blobs_Bool_Exp = {
  _and?: Maybe<Array<Active_Storage_Blobs_Bool_Exp>>;
  _not?: Maybe<Active_Storage_Blobs_Bool_Exp>;
  _or?: Maybe<Array<Active_Storage_Blobs_Bool_Exp>>;
  byte_size?: Maybe<Bigint_Comparison_Exp>;
  checksum?: Maybe<String_Comparison_Exp>;
  content_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<String_Comparison_Exp>;
  service_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "active_storage_blobs" */
export enum Active_Storage_Blobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActiveStorageBlobsPkey = 'active_storage_blobs_pkey',
  /** unique or primary key constraint on columns "key" */
  IndexActiveStorageBlobsOnKey = 'index_active_storage_blobs_on_key'
}

/** input type for incrementing numeric columns in table "active_storage_blobs" */
export type Active_Storage_Blobs_Inc_Input = {
  byte_size?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "active_storage_blobs" */
export type Active_Storage_Blobs_Insert_Input = {
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Active_Storage_Blobs_Max_Fields = {
  __typename?: 'active_storage_blobs_max_fields';
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Active_Storage_Blobs_Min_Fields = {
  __typename?: 'active_storage_blobs_min_fields';
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "active_storage_blobs" */
export type Active_Storage_Blobs_Mutation_Response = {
  __typename?: 'active_storage_blobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Active_Storage_Blobs>;
};

/** input type for inserting object relation for remote table "active_storage_blobs" */
export type Active_Storage_Blobs_Obj_Rel_Insert_Input = {
  data: Active_Storage_Blobs_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Active_Storage_Blobs_On_Conflict>;
};

/** on_conflict condition type for table "active_storage_blobs" */
export type Active_Storage_Blobs_On_Conflict = {
  constraint: Active_Storage_Blobs_Constraint;
  update_columns?: Array<Active_Storage_Blobs_Update_Column>;
  where?: Maybe<Active_Storage_Blobs_Bool_Exp>;
};

/** Ordering options when selecting data from "active_storage_blobs". */
export type Active_Storage_Blobs_Order_By = {
  byte_size?: Maybe<Order_By>;
  checksum?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  service_name?: Maybe<Order_By>;
};

/** primary key columns input for table: active_storage_blobs */
export type Active_Storage_Blobs_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "active_storage_blobs" */
export enum Active_Storage_Blobs_Select_Column {
  /** column name */
  ByteSize = 'byte_size',
  /** column name */
  Checksum = 'checksum',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ServiceName = 'service_name'
}

/** input type for updating data in table "active_storage_blobs" */
export type Active_Storage_Blobs_Set_Input = {
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Active_Storage_Blobs_Stddev_Fields = {
  __typename?: 'active_storage_blobs_stddev_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Active_Storage_Blobs_Stddev_Pop_Fields = {
  __typename?: 'active_storage_blobs_stddev_pop_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Active_Storage_Blobs_Stddev_Samp_Fields = {
  __typename?: 'active_storage_blobs_stddev_samp_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "active_storage_blobs" */
export type Active_Storage_Blobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Active_Storage_Blobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Active_Storage_Blobs_Stream_Cursor_Value_Input = {
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Active_Storage_Blobs_Sum_Fields = {
  __typename?: 'active_storage_blobs_sum_fields';
  byte_size?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "active_storage_blobs" */
export enum Active_Storage_Blobs_Update_Column {
  /** column name */
  ByteSize = 'byte_size',
  /** column name */
  Checksum = 'checksum',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ServiceName = 'service_name'
}

export type Active_Storage_Blobs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Active_Storage_Blobs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Active_Storage_Blobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Active_Storage_Blobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Active_Storage_Blobs_Var_Pop_Fields = {
  __typename?: 'active_storage_blobs_var_pop_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Active_Storage_Blobs_Var_Samp_Fields = {
  __typename?: 'active_storage_blobs_var_samp_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Active_Storage_Blobs_Variance_Fields = {
  __typename?: 'active_storage_blobs_variance_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "addresses" */
export type Addresses = {
  __typename?: 'addresses';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  backup_power_availability?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  deal_billto: Array<Deals>;
  /** An aggregate relationship */
  deal_billto_aggregate: Deals_Aggregate;
  exempt_code?: Maybe<Scalars['String']>;
  facility_id?: Maybe<Scalars['float8']>;
  formatted_address?: Maybe<Scalars['String']>;
  geometry?: Maybe<Scalars['point']>;
  google_api_res?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  interruptible_service?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  invoice_data: Array<Invoice_Data>;
  /** An aggregate relationship */
  invoice_data_aggregate: Invoice_Data_Aggregate;
  place_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tax_exempt?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "addresses" */
export type AddressesDeal_BilltoArgs = {
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


/** columns and relationships of "addresses" */
export type AddressesDeal_Billto_AggregateArgs = {
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


/** columns and relationships of "addresses" */
export type AddressesGoogle_Api_ResArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "addresses" */
export type AddressesInvoice_DataArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


/** columns and relationships of "addresses" */
export type AddressesInvoice_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};

/** aggregated selection of "addresses" */
export type Addresses_Aggregate = {
  __typename?: 'addresses_aggregate';
  aggregate?: Maybe<Addresses_Aggregate_Fields>;
  nodes: Array<Addresses>;
};

export type Addresses_Aggregate_Bool_Exp = {
  avg?: Maybe<Addresses_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Addresses_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Addresses_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Addresses_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Addresses_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Addresses_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Addresses_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Addresses_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Addresses_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Addresses_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Addresses_Aggregate_Bool_Exp_Var_Samp>;
};

export type Addresses_Aggregate_Bool_Exp_Avg = {
  arguments: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Bool_And = {
  arguments: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Corr = {
  arguments: Addresses_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Addresses_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Addresses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Addresses_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Addresses_Aggregate_Bool_Exp_Max = {
  arguments: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Min = {
  arguments: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Sum = {
  arguments: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Addresses_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Addresses_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "addresses" */
export type Addresses_Aggregate_Fields = {
  __typename?: 'addresses_aggregate_fields';
  avg?: Maybe<Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Addresses_Max_Fields>;
  min?: Maybe<Addresses_Min_Fields>;
  stddev?: Maybe<Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Addresses_Sum_Fields>;
  var_pop?: Maybe<Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Addresses_Var_Samp_Fields>;
  variance?: Maybe<Addresses_Variance_Fields>;
};


/** aggregate fields of "addresses" */
export type Addresses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Addresses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "addresses" */
export type Addresses_Aggregate_Order_By = {
  avg?: Maybe<Addresses_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Addresses_Max_Order_By>;
  min?: Maybe<Addresses_Min_Order_By>;
  stddev?: Maybe<Addresses_Stddev_Order_By>;
  stddev_pop?: Maybe<Addresses_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Addresses_Stddev_Samp_Order_By>;
  sum?: Maybe<Addresses_Sum_Order_By>;
  var_pop?: Maybe<Addresses_Var_Pop_Order_By>;
  var_samp?: Maybe<Addresses_Var_Samp_Order_By>;
  variance?: Maybe<Addresses_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Addresses_Append_Input = {
  google_api_res?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "addresses" */
export type Addresses_Arr_Rel_Insert_Input = {
  data: Array<Addresses_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Addresses_On_Conflict>;
};

/** aggregate avg on columns */
export type Addresses_Avg_Fields = {
  __typename?: 'addresses_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  facility_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "addresses" */
export type Addresses_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "addresses". All fields are combined with a logical 'AND'. */
export type Addresses_Bool_Exp = {
  _and?: Maybe<Array<Addresses_Bool_Exp>>;
  _not?: Maybe<Addresses_Bool_Exp>;
  _or?: Maybe<Array<Addresses_Bool_Exp>>;
  address1?: Maybe<String_Comparison_Exp>;
  address2?: Maybe<String_Comparison_Exp>;
  backup_power_availability?: Maybe<Boolean_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  deal_billto?: Maybe<Deals_Bool_Exp>;
  deal_billto_aggregate?: Maybe<Deals_Aggregate_Bool_Exp>;
  exempt_code?: Maybe<String_Comparison_Exp>;
  facility_id?: Maybe<Float8_Comparison_Exp>;
  formatted_address?: Maybe<String_Comparison_Exp>;
  geometry?: Maybe<Point_Comparison_Exp>;
  google_api_res?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  interruptible_service?: Maybe<Boolean_Comparison_Exp>;
  invoice_data?: Maybe<Invoice_Data_Bool_Exp>;
  invoice_data_aggregate?: Maybe<Invoice_Data_Aggregate_Bool_Exp>;
  place_id?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  tax_exempt?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<String_Comparison_Exp>;
  zip?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "addresses" */
export enum Addresses_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressesPkey = 'addresses_pkey',
  /** unique or primary key constraint on columns "place_id" */
  AddressesPlaceIdKey = 'addresses_place_id_key',
  /** unique or primary key constraint on columns "uuid" */
  AddressesUuidKey = 'addresses_uuid_key',
  /** unique or primary key constraint on columns "id" */
  IndexAddressesOnId = 'index_addresses_on_id'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Addresses_Delete_At_Path_Input = {
  google_api_res?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Addresses_Delete_Elem_Input = {
  google_api_res?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Addresses_Delete_Key_Input = {
  google_api_res?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "addresses" */
export type Addresses_Inc_Input = {
  customer_id?: Maybe<Scalars['Int']>;
  facility_id?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "addresses" */
export type Addresses_Insert_Input = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  backup_power_availability?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  deal_billto?: Maybe<Deals_Arr_Rel_Insert_Input>;
  exempt_code?: Maybe<Scalars['String']>;
  facility_id?: Maybe<Scalars['float8']>;
  formatted_address?: Maybe<Scalars['String']>;
  geometry?: Maybe<Scalars['point']>;
  google_api_res?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['Int']>;
  interruptible_service?: Maybe<Scalars['Boolean']>;
  invoice_data?: Maybe<Invoice_Data_Arr_Rel_Insert_Input>;
  place_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tax_exempt?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Addresses_Max_Fields = {
  __typename?: 'addresses_max_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  exempt_code?: Maybe<Scalars['String']>;
  facility_id?: Maybe<Scalars['float8']>;
  formatted_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  place_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "addresses" */
export type Addresses_Max_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  exempt_code?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  formatted_address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  place_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Addresses_Min_Fields = {
  __typename?: 'addresses_min_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  exempt_code?: Maybe<Scalars['String']>;
  facility_id?: Maybe<Scalars['float8']>;
  formatted_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  place_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "addresses" */
export type Addresses_Min_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  exempt_code?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  formatted_address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  place_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** response of any mutation on the table "addresses" */
export type Addresses_Mutation_Response = {
  __typename?: 'addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Addresses>;
};

/** input type for inserting object relation for remote table "addresses" */
export type Addresses_Obj_Rel_Insert_Input = {
  data: Addresses_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Addresses_On_Conflict>;
};

/** on_conflict condition type for table "addresses" */
export type Addresses_On_Conflict = {
  constraint: Addresses_Constraint;
  update_columns?: Array<Addresses_Update_Column>;
  where?: Maybe<Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "addresses". */
export type Addresses_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  backup_power_availability?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  deal_billto_aggregate?: Maybe<Deals_Aggregate_Order_By>;
  exempt_code?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  formatted_address?: Maybe<Order_By>;
  geometry?: Maybe<Order_By>;
  google_api_res?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interruptible_service?: Maybe<Order_By>;
  invoice_data_aggregate?: Maybe<Invoice_Data_Aggregate_Order_By>;
  place_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tax_exempt?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** primary key columns input for table: addresses */
export type Addresses_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Addresses_Prepend_Input = {
  google_api_res?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "addresses" */
export enum Addresses_Select_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  BackupPowerAvailability = 'backup_power_availability',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExemptCode = 'exempt_code',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FormattedAddress = 'formatted_address',
  /** column name */
  Geometry = 'geometry',
  /** column name */
  GoogleApiRes = 'google_api_res',
  /** column name */
  Id = 'id',
  /** column name */
  InterruptibleService = 'interruptible_service',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  State = 'state',
  /** column name */
  TaxExempt = 'tax_exempt',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  Zip = 'zip'
}

/** select "addresses_aggregate_bool_exp_avg_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  FacilityId = 'facility_id'
}

/** select "addresses_aggregate_bool_exp_bool_and_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  BackupPowerAvailability = 'backup_power_availability',
  /** column name */
  InterruptibleService = 'interruptible_service',
  /** column name */
  TaxExempt = 'tax_exempt'
}

/** select "addresses_aggregate_bool_exp_bool_or_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  BackupPowerAvailability = 'backup_power_availability',
  /** column name */
  InterruptibleService = 'interruptible_service',
  /** column name */
  TaxExempt = 'tax_exempt'
}

/** select "addresses_aggregate_bool_exp_corr_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  FacilityId = 'facility_id'
}

/** select "addresses_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  FacilityId = 'facility_id'
}

/** select "addresses_aggregate_bool_exp_max_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  FacilityId = 'facility_id'
}

/** select "addresses_aggregate_bool_exp_min_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  FacilityId = 'facility_id'
}

/** select "addresses_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  FacilityId = 'facility_id'
}

/** select "addresses_aggregate_bool_exp_sum_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  FacilityId = 'facility_id'
}

/** select "addresses_aggregate_bool_exp_var_samp_arguments_columns" columns of table "addresses" */
export enum Addresses_Select_Column_Addresses_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  FacilityId = 'facility_id'
}

/** input type for updating data in table "addresses" */
export type Addresses_Set_Input = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  backup_power_availability?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  exempt_code?: Maybe<Scalars['String']>;
  facility_id?: Maybe<Scalars['float8']>;
  formatted_address?: Maybe<Scalars['String']>;
  geometry?: Maybe<Scalars['point']>;
  google_api_res?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['Int']>;
  interruptible_service?: Maybe<Scalars['Boolean']>;
  place_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tax_exempt?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Addresses_Stddev_Fields = {
  __typename?: 'addresses_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  facility_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "addresses" */
export type Addresses_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Addresses_Stddev_Pop_Fields = {
  __typename?: 'addresses_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  facility_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "addresses" */
export type Addresses_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Addresses_Stddev_Samp_Fields = {
  __typename?: 'addresses_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  facility_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "addresses" */
export type Addresses_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "addresses" */
export type Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Addresses_Stream_Cursor_Value_Input = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  backup_power_availability?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  exempt_code?: Maybe<Scalars['String']>;
  facility_id?: Maybe<Scalars['float8']>;
  formatted_address?: Maybe<Scalars['String']>;
  geometry?: Maybe<Scalars['point']>;
  google_api_res?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['Int']>;
  interruptible_service?: Maybe<Scalars['Boolean']>;
  place_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tax_exempt?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Addresses_Sum_Fields = {
  __typename?: 'addresses_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  facility_id?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "addresses" */
export type Addresses_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "addresses" */
export enum Addresses_Update_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  BackupPowerAvailability = 'backup_power_availability',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExemptCode = 'exempt_code',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FormattedAddress = 'formatted_address',
  /** column name */
  Geometry = 'geometry',
  /** column name */
  GoogleApiRes = 'google_api_res',
  /** column name */
  Id = 'id',
  /** column name */
  InterruptibleService = 'interruptible_service',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  State = 'state',
  /** column name */
  TaxExempt = 'tax_exempt',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  Zip = 'zip'
}

export type Addresses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Addresses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Addresses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Addresses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Addresses_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Addresses_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Addresses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Addresses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Addresses_Var_Pop_Fields = {
  __typename?: 'addresses_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  facility_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "addresses" */
export type Addresses_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Addresses_Var_Samp_Fields = {
  __typename?: 'addresses_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  facility_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "addresses" */
export type Addresses_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Addresses_Variance_Fields = {
  __typename?: 'addresses_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  facility_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "addresses" */
export type Addresses_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  facility_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "audit.logged_actions" */
export type Audit_Logged_Actions = {
  __typename?: 'audit_logged_actions';
  action: Scalars['String'];
  action_tstamp_clk: Scalars['timestamptz'];
  action_tstamp_stm: Scalars['timestamptz'];
  action_tstamp_tx: Scalars['timestamptz'];
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id: Scalars['bigint'];
  hasura_user?: Maybe<Scalars['jsonb']>;
  relid: Scalars['oid'];
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name: Scalars['String'];
  session_user_name?: Maybe<Scalars['String']>;
  statement_only: Scalars['Boolean'];
  table_name: Scalars['String'];
  transaction_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<Auth_Users>;
  user_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "audit.logged_actions" */
export type Audit_Logged_ActionsChanged_FieldsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "audit.logged_actions" */
export type Audit_Logged_ActionsHasura_UserArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "audit.logged_actions" */
export type Audit_Logged_ActionsRow_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate = {
  __typename?: 'audit_logged_actions_aggregate';
  aggregate?: Maybe<Audit_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Audit_Logged_Actions>;
};

export type Audit_Logged_Actions_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Audit_Logged_Actions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Audit_Logged_Actions_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Audit_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Audit_Logged_Actions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Audit_Logged_Actions_Select_Column_Audit_Logged_Actions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Audit_Logged_Actions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Audit_Logged_Actions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Audit_Logged_Actions_Select_Column_Audit_Logged_Actions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Audit_Logged_Actions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Audit_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Audit_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_Fields = {
  __typename?: 'audit_logged_actions_aggregate_fields';
  avg?: Maybe<Audit_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Audit_Logged_Actions_Max_Fields>;
  min?: Maybe<Audit_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Audit_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Audit_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audit_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Audit_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Audit_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Audit_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Audit_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_Order_By = {
  avg?: Maybe<Audit_Logged_Actions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Audit_Logged_Actions_Max_Order_By>;
  min?: Maybe<Audit_Logged_Actions_Min_Order_By>;
  stddev?: Maybe<Audit_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: Maybe<Audit_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Audit_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: Maybe<Audit_Logged_Actions_Sum_Order_By>;
  var_pop?: Maybe<Audit_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: Maybe<Audit_Logged_Actions_Var_Samp_Order_By>;
  variance?: Maybe<Audit_Logged_Actions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Append_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "audit.logged_actions" */
export type Audit_Logged_Actions_Arr_Rel_Insert_Input = {
  data: Array<Audit_Logged_Actions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Audit_Logged_Actions_On_Conflict>;
};

/** aggregate avg on columns */
export type Audit_Logged_Actions_Avg_Fields = {
  __typename?: 'audit_logged_actions_avg_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Avg_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'. */
export type Audit_Logged_Actions_Bool_Exp = {
  _and?: Maybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  _not?: Maybe<Audit_Logged_Actions_Bool_Exp>;
  _or?: Maybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  action?: Maybe<String_Comparison_Exp>;
  action_tstamp_clk?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?: Maybe<Timestamptz_Comparison_Exp>;
  application_name?: Maybe<String_Comparison_Exp>;
  changed_fields?: Maybe<Jsonb_Comparison_Exp>;
  client_addr?: Maybe<Inet_Comparison_Exp>;
  client_port?: Maybe<Int_Comparison_Exp>;
  client_query?: Maybe<String_Comparison_Exp>;
  event_id?: Maybe<Bigint_Comparison_Exp>;
  hasura_user?: Maybe<Jsonb_Comparison_Exp>;
  relid?: Maybe<Oid_Comparison_Exp>;
  row_data?: Maybe<Jsonb_Comparison_Exp>;
  schema_name?: Maybe<String_Comparison_Exp>;
  session_user_name?: Maybe<String_Comparison_Exp>;
  statement_only?: Maybe<Boolean_Comparison_Exp>;
  table_name?: Maybe<String_Comparison_Exp>;
  transaction_id?: Maybe<Bigint_Comparison_Exp>;
  user?: Maybe<Auth_Users_Bool_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit.logged_actions" */
export enum Audit_Logged_Actions_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  LoggedActionsPkey = 'logged_actions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logged_Actions_Delete_At_Path_Input = {
  changed_fields?: Maybe<Array<Scalars['String']>>;
  hasura_user?: Maybe<Array<Scalars['String']>>;
  row_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logged_Actions_Delete_Elem_Input = {
  changed_fields?: Maybe<Scalars['Int']>;
  hasura_user?: Maybe<Scalars['Int']>;
  row_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logged_Actions_Delete_Key_Input = {
  changed_fields?: Maybe<Scalars['String']>;
  hasura_user?: Maybe<Scalars['String']>;
  row_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "audit.logged_actions" */
export type Audit_Logged_Actions_Inc_Input = {
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "audit.logged_actions" */
export type Audit_Logged_Actions_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user?: Maybe<Auth_Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Audit_Logged_Actions_Max_Fields = {
  __typename?: 'audit_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Max_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Audit_Logged_Actions_Min_Fields = {
  __typename?: 'audit_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Min_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "audit.logged_actions" */
export type Audit_Logged_Actions_Mutation_Response = {
  __typename?: 'audit_logged_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Logged_Actions>;
};

/** on_conflict condition type for table "audit.logged_actions" */
export type Audit_Logged_Actions_On_Conflict = {
  constraint: Audit_Logged_Actions_Constraint;
  update_columns?: Array<Audit_Logged_Actions_Update_Column>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "audit.logged_actions". */
export type Audit_Logged_Actions_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  changed_fields?: Maybe<Order_By>;
  client_addr?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  hasura_user?: Maybe<Order_By>;
  relid?: Maybe<Order_By>;
  row_data?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  statement_only?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user?: Maybe<Auth_Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: audit.logged_actions */
export type Audit_Logged_Actions_Pk_Columns_Input = {
  event_id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Prepend_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

/** select "audit_logged_actions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column_Audit_Logged_Actions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  StatementOnly = 'statement_only'
}

/** select "audit_logged_actions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column_Audit_Logged_Actions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  StatementOnly = 'statement_only'
}

/** input type for updating data in table "audit.logged_actions" */
export type Audit_Logged_Actions_Set_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Audit_Logged_Actions_Stddev_Fields = {
  __typename?: 'audit_logged_actions_stddev_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Stddev_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Audit_Logged_Actions_Stddev_Pop_Fields = {
  __typename?: 'audit_logged_actions_stddev_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Stddev_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Audit_Logged_Actions_Stddev_Samp_Fields = {
  __typename?: 'audit_logged_actions_stddev_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Stddev_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "audit_logged_actions" */
export type Audit_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Audit_Logged_Actions_Sum_Fields = {
  __typename?: 'audit_logged_actions_sum_fields';
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Sum_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

export type Audit_Logged_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Audit_Logged_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Audit_Logged_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Audit_Logged_Actions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Audit_Logged_Actions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Audit_Logged_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Audit_Logged_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Logged_Actions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audit_Logged_Actions_Var_Pop_Fields = {
  __typename?: 'audit_logged_actions_var_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Var_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Audit_Logged_Actions_Var_Samp_Fields = {
  __typename?: 'audit_logged_actions_var_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Var_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Audit_Logged_Actions_Variance_Fields = {
  __typename?: 'audit_logged_actions_variance_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Variance_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls = {
  __typename?: 'auth_rbac_role_acls';
  acl_regex: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  rbac_role: Auth_Rbac_Roles;
  rbac_role_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Aggregate = {
  __typename?: 'auth_rbac_role_acls_aggregate';
  aggregate?: Maybe<Auth_Rbac_Role_Acls_Aggregate_Fields>;
  nodes: Array<Auth_Rbac_Role_Acls>;
};

export type Auth_Rbac_Role_Acls_Aggregate_Bool_Exp = {
  count?: Maybe<Auth_Rbac_Role_Acls_Aggregate_Bool_Exp_Count>;
};

export type Auth_Rbac_Role_Acls_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Auth_Rbac_Role_Acls_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Aggregate_Fields = {
  __typename?: 'auth_rbac_role_acls_aggregate_fields';
  avg?: Maybe<Auth_Rbac_Role_Acls_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Rbac_Role_Acls_Max_Fields>;
  min?: Maybe<Auth_Rbac_Role_Acls_Min_Fields>;
  stddev?: Maybe<Auth_Rbac_Role_Acls_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Rbac_Role_Acls_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Rbac_Role_Acls_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Rbac_Role_Acls_Sum_Fields>;
  var_pop?: Maybe<Auth_Rbac_Role_Acls_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Rbac_Role_Acls_Var_Samp_Fields>;
  variance?: Maybe<Auth_Rbac_Role_Acls_Variance_Fields>;
};


/** aggregate fields of "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Rbac_Role_Acls_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Aggregate_Order_By = {
  avg?: Maybe<Auth_Rbac_Role_Acls_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Rbac_Role_Acls_Max_Order_By>;
  min?: Maybe<Auth_Rbac_Role_Acls_Min_Order_By>;
  stddev?: Maybe<Auth_Rbac_Role_Acls_Stddev_Order_By>;
  stddev_pop?: Maybe<Auth_Rbac_Role_Acls_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Auth_Rbac_Role_Acls_Stddev_Samp_Order_By>;
  sum?: Maybe<Auth_Rbac_Role_Acls_Sum_Order_By>;
  var_pop?: Maybe<Auth_Rbac_Role_Acls_Var_Pop_Order_By>;
  var_samp?: Maybe<Auth_Rbac_Role_Acls_Var_Samp_Order_By>;
  variance?: Maybe<Auth_Rbac_Role_Acls_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Arr_Rel_Insert_Input = {
  data: Array<Auth_Rbac_Role_Acls_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Auth_Rbac_Role_Acls_On_Conflict>;
};

/** aggregate avg on columns */
export type Auth_Rbac_Role_Acls_Avg_Fields = {
  __typename?: 'auth_rbac_role_acls_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Avg_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auth.rbac_role_acls". All fields are combined with a logical 'AND'. */
export type Auth_Rbac_Role_Acls_Bool_Exp = {
  _and?: Maybe<Array<Auth_Rbac_Role_Acls_Bool_Exp>>;
  _not?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
  _or?: Maybe<Array<Auth_Rbac_Role_Acls_Bool_Exp>>;
  acl_regex?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  rbac_role?: Maybe<Auth_Rbac_Roles_Bool_Exp>;
  rbac_role_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.rbac_role_acls" */
export enum Auth_Rbac_Role_Acls_Constraint {
  /** unique or primary key constraint on columns "id" */
  RbacRoleAclsPkey = 'rbac_role_acls_pkey'
}

/** input type for incrementing numeric columns in table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Insert_Input = {
  acl_regex?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rbac_role?: Maybe<Auth_Rbac_Roles_Obj_Rel_Insert_Input>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Rbac_Role_Acls_Max_Fields = {
  __typename?: 'auth_rbac_role_acls_max_fields';
  acl_regex?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Max_Order_By = {
  acl_regex?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Rbac_Role_Acls_Min_Fields = {
  __typename?: 'auth_rbac_role_acls_min_fields';
  acl_regex?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Min_Order_By = {
  acl_regex?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Mutation_Response = {
  __typename?: 'auth_rbac_role_acls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Rbac_Role_Acls>;
};

/** on_conflict condition type for table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_On_Conflict = {
  constraint: Auth_Rbac_Role_Acls_Constraint;
  update_columns?: Array<Auth_Rbac_Role_Acls_Update_Column>;
  where?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.rbac_role_acls". */
export type Auth_Rbac_Role_Acls_Order_By = {
  acl_regex?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rbac_role?: Maybe<Auth_Rbac_Roles_Order_By>;
  rbac_role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: auth.rbac_role_acls */
export type Auth_Rbac_Role_Acls_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "auth.rbac_role_acls" */
export enum Auth_Rbac_Role_Acls_Select_Column {
  /** column name */
  AclRegex = 'acl_regex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RbacRoleId = 'rbac_role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Set_Input = {
  acl_regex?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Auth_Rbac_Role_Acls_Stddev_Fields = {
  __typename?: 'auth_rbac_role_acls_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auth_Rbac_Role_Acls_Stddev_Pop_Fields = {
  __typename?: 'auth_rbac_role_acls_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auth_Rbac_Role_Acls_Stddev_Samp_Fields = {
  __typename?: 'auth_rbac_role_acls_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "auth_rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Rbac_Role_Acls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Rbac_Role_Acls_Stream_Cursor_Value_Input = {
  acl_regex?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Auth_Rbac_Role_Acls_Sum_Fields = {
  __typename?: 'auth_rbac_role_acls_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Sum_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** update columns of table "auth.rbac_role_acls" */
export enum Auth_Rbac_Role_Acls_Update_Column {
  /** column name */
  AclRegex = 'acl_regex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RbacRoleId = 'rbac_role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Rbac_Role_Acls_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Auth_Rbac_Role_Acls_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Auth_Rbac_Role_Acls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Rbac_Role_Acls_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Rbac_Role_Acls_Var_Pop_Fields = {
  __typename?: 'auth_rbac_role_acls_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auth_Rbac_Role_Acls_Var_Samp_Fields = {
  __typename?: 'auth_rbac_role_acls_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Auth_Rbac_Role_Acls_Variance_Fields = {
  __typename?: 'auth_rbac_role_acls_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "auth.rbac_role_acls" */
export type Auth_Rbac_Role_Acls_Variance_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** columns and relationships of "auth.rbac_roles" */
export type Auth_Rbac_Roles = {
  __typename?: 'auth_rbac_roles';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An array relationship */
  rbac_role_acls: Array<Auth_Rbac_Role_Acls>;
  /** An aggregate relationship */
  rbac_role_acls_aggregate: Auth_Rbac_Role_Acls_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Auth_Users>;
  /** An aggregate relationship */
  users_aggregate: Auth_Users_Aggregate;
};


/** columns and relationships of "auth.rbac_roles" */
export type Auth_Rbac_RolesRbac_Role_AclsArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Role_Acls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Role_Acls_Order_By>>;
  where?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
};


/** columns and relationships of "auth.rbac_roles" */
export type Auth_Rbac_RolesRbac_Role_Acls_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Role_Acls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Role_Acls_Order_By>>;
  where?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
};


/** columns and relationships of "auth.rbac_roles" */
export type Auth_Rbac_RolesUsersArgs = {
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


/** columns and relationships of "auth.rbac_roles" */
export type Auth_Rbac_RolesUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};

/** aggregated selection of "auth.rbac_roles" */
export type Auth_Rbac_Roles_Aggregate = {
  __typename?: 'auth_rbac_roles_aggregate';
  aggregate?: Maybe<Auth_Rbac_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Rbac_Roles>;
};

/** aggregate fields of "auth.rbac_roles" */
export type Auth_Rbac_Roles_Aggregate_Fields = {
  __typename?: 'auth_rbac_roles_aggregate_fields';
  avg?: Maybe<Auth_Rbac_Roles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Rbac_Roles_Max_Fields>;
  min?: Maybe<Auth_Rbac_Roles_Min_Fields>;
  stddev?: Maybe<Auth_Rbac_Roles_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Rbac_Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Rbac_Roles_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Rbac_Roles_Sum_Fields>;
  var_pop?: Maybe<Auth_Rbac_Roles_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Rbac_Roles_Var_Samp_Fields>;
  variance?: Maybe<Auth_Rbac_Roles_Variance_Fields>;
};


/** aggregate fields of "auth.rbac_roles" */
export type Auth_Rbac_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Rbac_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Auth_Rbac_Roles_Avg_Fields = {
  __typename?: 'auth_rbac_roles_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "auth.rbac_roles". All fields are combined with a logical 'AND'. */
export type Auth_Rbac_Roles_Bool_Exp = {
  _and?: Maybe<Array<Auth_Rbac_Roles_Bool_Exp>>;
  _not?: Maybe<Auth_Rbac_Roles_Bool_Exp>;
  _or?: Maybe<Array<Auth_Rbac_Roles_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  rbac_role_acls?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
  rbac_role_acls_aggregate?: Maybe<Auth_Rbac_Role_Acls_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  users?: Maybe<Auth_Users_Bool_Exp>;
  users_aggregate?: Maybe<Auth_Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.rbac_roles" */
export enum Auth_Rbac_Roles_Constraint {
  /** unique or primary key constraint on columns "name" */
  RbacRolesNameKey = 'rbac_roles_name_key',
  /** unique or primary key constraint on columns "id" */
  RbacRolesPkey = 'rbac_roles_pkey'
}

/** input type for incrementing numeric columns in table "auth.rbac_roles" */
export type Auth_Rbac_Roles_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "auth.rbac_roles" */
export type Auth_Rbac_Roles_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  rbac_role_acls?: Maybe<Auth_Rbac_Role_Acls_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<Auth_Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Auth_Rbac_Roles_Max_Fields = {
  __typename?: 'auth_rbac_roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Auth_Rbac_Roles_Min_Fields = {
  __typename?: 'auth_rbac_roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "auth.rbac_roles" */
export type Auth_Rbac_Roles_Mutation_Response = {
  __typename?: 'auth_rbac_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Rbac_Roles>;
};

/** input type for inserting object relation for remote table "auth.rbac_roles" */
export type Auth_Rbac_Roles_Obj_Rel_Insert_Input = {
  data: Auth_Rbac_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Auth_Rbac_Roles_On_Conflict>;
};

/** on_conflict condition type for table "auth.rbac_roles" */
export type Auth_Rbac_Roles_On_Conflict = {
  constraint: Auth_Rbac_Roles_Constraint;
  update_columns?: Array<Auth_Rbac_Roles_Update_Column>;
  where?: Maybe<Auth_Rbac_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.rbac_roles". */
export type Auth_Rbac_Roles_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  rbac_role_acls_aggregate?: Maybe<Auth_Rbac_Role_Acls_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  users_aggregate?: Maybe<Auth_Users_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.rbac_roles */
export type Auth_Rbac_Roles_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "auth.rbac_roles" */
export enum Auth_Rbac_Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.rbac_roles" */
export type Auth_Rbac_Roles_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Auth_Rbac_Roles_Stddev_Fields = {
  __typename?: 'auth_rbac_roles_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Rbac_Roles_Stddev_Pop_Fields = {
  __typename?: 'auth_rbac_roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Rbac_Roles_Stddev_Samp_Fields = {
  __typename?: 'auth_rbac_roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "auth_rbac_roles" */
export type Auth_Rbac_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Rbac_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Rbac_Roles_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Auth_Rbac_Roles_Sum_Fields = {
  __typename?: 'auth_rbac_roles_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "auth.rbac_roles" */
export enum Auth_Rbac_Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Rbac_Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Auth_Rbac_Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Auth_Rbac_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Rbac_Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Rbac_Roles_Var_Pop_Fields = {
  __typename?: 'auth_rbac_roles_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Auth_Rbac_Roles_Var_Samp_Fields = {
  __typename?: 'auth_rbac_roles_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Auth_Rbac_Roles_Variance_Fields = {
  __typename?: 'auth_rbac_roles_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "auth.users" */
export type Auth_Users = {
  __typename?: 'auth_users';
  /** An array relationship */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** An aggregate relationship */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  avatar_url?: Maybe<Scalars['String']>;
  combined_user?: Maybe<CombinedUser>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  customers_users: Array<Customers_Users>;
  /** An aggregate relationship */
  customers_users_aggregate: Customers_Users_Aggregate;
  /** An array relationship */
  data: Array<Users_Data>;
  /** An aggregate relationship */
  data_aggregate: Users_Data_Aggregate;
  email: Scalars['String'];
  id: Scalars['bigint'];
  locked: Scalars['Boolean'];
  /** An array relationship */
  logs: Array<Logs>;
  /** An aggregate relationship */
  logs_aggregate: Logs_Aggregate;
  name: Scalars['String'];
  password_hash?: Maybe<Scalars['String']>;
  password_reset?: Maybe<Scalars['String']>;
  /** An object relationship */
  rbac_role: Auth_Rbac_Roles;
  rbac_role_id: Scalars['bigint'];
  /** An array relationship */
  records: Array<Records>;
  /** An aggregate relationship */
  records_aggregate: Records_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "auth.users" */
export type Auth_UsersAudit_Logged_ActionsArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "auth.users" */
export type Auth_UsersAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "auth.users" */
export type Auth_UsersCustomers_UsersArgs = {
  distinct_on?: Maybe<Array<Customers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Users_Order_By>>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};


/** columns and relationships of "auth.users" */
export type Auth_UsersCustomers_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Users_Order_By>>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};


/** columns and relationships of "auth.users" */
export type Auth_UsersDataArgs = {
  distinct_on?: Maybe<Array<Users_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Data_Order_By>>;
  where?: Maybe<Users_Data_Bool_Exp>;
};


/** columns and relationships of "auth.users" */
export type Auth_UsersData_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Data_Order_By>>;
  where?: Maybe<Users_Data_Bool_Exp>;
};


/** columns and relationships of "auth.users" */
export type Auth_UsersLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


/** columns and relationships of "auth.users" */
export type Auth_UsersLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


/** columns and relationships of "auth.users" */
export type Auth_UsersRecordsArgs = {
  distinct_on?: Maybe<Array<Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Records_Order_By>>;
  where?: Maybe<Records_Bool_Exp>;
};


/** columns and relationships of "auth.users" */
export type Auth_UsersRecords_AggregateArgs = {
  distinct_on?: Maybe<Array<Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Records_Order_By>>;
  where?: Maybe<Records_Bool_Exp>;
};

/** aggregated selection of "auth.users" */
export type Auth_Users_Aggregate = {
  __typename?: 'auth_users_aggregate';
  aggregate?: Maybe<Auth_Users_Aggregate_Fields>;
  nodes: Array<Auth_Users>;
};

export type Auth_Users_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Auth_Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Auth_Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Auth_Users_Aggregate_Bool_Exp_Count>;
};

export type Auth_Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Auth_Users_Select_Column_Auth_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Auth_Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Auth_Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Auth_Users_Select_Column_Auth_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Auth_Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Auth_Users_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Auth_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Auth_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.users" */
export type Auth_Users_Aggregate_Fields = {
  __typename?: 'auth_users_aggregate_fields';
  avg?: Maybe<Auth_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Users_Max_Fields>;
  min?: Maybe<Auth_Users_Min_Fields>;
  stddev?: Maybe<Auth_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Users_Sum_Fields>;
  var_pop?: Maybe<Auth_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Users_Var_Samp_Fields>;
  variance?: Maybe<Auth_Users_Variance_Fields>;
};


/** aggregate fields of "auth.users" */
export type Auth_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.users" */
export type Auth_Users_Aggregate_Order_By = {
  avg?: Maybe<Auth_Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Users_Max_Order_By>;
  min?: Maybe<Auth_Users_Min_Order_By>;
  stddev?: Maybe<Auth_Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Auth_Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Auth_Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Auth_Users_Sum_Order_By>;
  var_pop?: Maybe<Auth_Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Auth_Users_Var_Samp_Order_By>;
  variance?: Maybe<Auth_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auth.users" */
export type Auth_Users_Arr_Rel_Insert_Input = {
  data: Array<Auth_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Auth_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Auth_Users_Avg_Fields = {
  __typename?: 'auth_users_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "auth.users" */
export type Auth_Users_Avg_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export type Auth_Users_Bool_Exp = {
  _and?: Maybe<Array<Auth_Users_Bool_Exp>>;
  _not?: Maybe<Auth_Users_Bool_Exp>;
  _or?: Maybe<Array<Auth_Users_Bool_Exp>>;
  audit_logged_actions?: Maybe<Audit_Logged_Actions_Bool_Exp>;
  audit_logged_actions_aggregate?: Maybe<Audit_Logged_Actions_Aggregate_Bool_Exp>;
  avatar_url?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customers_users?: Maybe<Customers_Users_Bool_Exp>;
  customers_users_aggregate?: Maybe<Customers_Users_Aggregate_Bool_Exp>;
  data?: Maybe<Users_Data_Bool_Exp>;
  data_aggregate?: Maybe<Users_Data_Aggregate_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  locked?: Maybe<Boolean_Comparison_Exp>;
  logs?: Maybe<Logs_Bool_Exp>;
  logs_aggregate?: Maybe<Logs_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  password_hash?: Maybe<String_Comparison_Exp>;
  password_reset?: Maybe<String_Comparison_Exp>;
  rbac_role?: Maybe<Auth_Rbac_Roles_Bool_Exp>;
  rbac_role_id?: Maybe<Bigint_Comparison_Exp>;
  records?: Maybe<Records_Bool_Exp>;
  records_aggregate?: Maybe<Records_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.users" */
export enum Auth_Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  IndexUsersOnEmail = 'index_users_on_email',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "auth.users" */
export type Auth_Users_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "auth.users" */
export type Auth_Users_Insert_Input = {
  audit_logged_actions?: Maybe<Audit_Logged_Actions_Arr_Rel_Insert_Input>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customers_users?: Maybe<Customers_Users_Arr_Rel_Insert_Input>;
  data?: Maybe<Users_Data_Arr_Rel_Insert_Input>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  locked?: Maybe<Scalars['Boolean']>;
  logs?: Maybe<Logs_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  password_reset?: Maybe<Scalars['String']>;
  rbac_role?: Maybe<Auth_Rbac_Roles_Obj_Rel_Insert_Input>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  records?: Maybe<Records_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Users_Max_Fields = {
  __typename?: 'auth_users_max_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  password_reset?: Maybe<Scalars['String']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.users" */
export type Auth_Users_Max_Order_By = {
  avatar_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  password_reset?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Users_Min_Fields = {
  __typename?: 'auth_users_min_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  password_reset?: Maybe<Scalars['String']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.users" */
export type Auth_Users_Min_Order_By = {
  avatar_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  password_reset?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.users" */
export type Auth_Users_Mutation_Response = {
  __typename?: 'auth_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Users>;
};

/** input type for inserting object relation for remote table "auth.users" */
export type Auth_Users_Obj_Rel_Insert_Input = {
  data: Auth_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Auth_Users_On_Conflict>;
};

/** on_conflict condition type for table "auth.users" */
export type Auth_Users_On_Conflict = {
  constraint: Auth_Users_Constraint;
  update_columns?: Array<Auth_Users_Update_Column>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.users". */
export type Auth_Users_Order_By = {
  audit_logged_actions_aggregate?: Maybe<Audit_Logged_Actions_Aggregate_Order_By>;
  avatar_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customers_users_aggregate?: Maybe<Customers_Users_Aggregate_Order_By>;
  data_aggregate?: Maybe<Users_Data_Aggregate_Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locked?: Maybe<Order_By>;
  logs_aggregate?: Maybe<Logs_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  password_reset?: Maybe<Order_By>;
  rbac_role?: Maybe<Auth_Rbac_Roles_Order_By>;
  rbac_role_id?: Maybe<Order_By>;
  records_aggregate?: Maybe<Records_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: auth.users */
export type Auth_Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "auth.users" */
export enum Auth_Users_Select_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Name = 'name',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  PasswordReset = 'password_reset',
  /** column name */
  RbacRoleId = 'rbac_role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "auth_users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
export enum Auth_Users_Select_Column_Auth_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Locked = 'locked'
}

/** select "auth_users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
export enum Auth_Users_Select_Column_Auth_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Locked = 'locked'
}

/** input type for updating data in table "auth.users" */
export type Auth_Users_Set_Input = {
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  locked?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  password_reset?: Maybe<Scalars['String']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Auth_Users_Stddev_Fields = {
  __typename?: 'auth_users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "auth.users" */
export type Auth_Users_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auth_Users_Stddev_Pop_Fields = {
  __typename?: 'auth_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "auth.users" */
export type Auth_Users_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auth_Users_Stddev_Samp_Fields = {
  __typename?: 'auth_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "auth.users" */
export type Auth_Users_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "auth_users" */
export type Auth_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Users_Stream_Cursor_Value_Input = {
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  locked?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  password_reset?: Maybe<Scalars['String']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Auth_Users_Sum_Fields = {
  __typename?: 'auth_users_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  rbac_role_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "auth.users" */
export type Auth_Users_Sum_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** update columns of table "auth.users" */
export enum Auth_Users_Update_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Name = 'name',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  PasswordReset = 'password_reset',
  /** column name */
  RbacRoleId = 'rbac_role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Auth_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Auth_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Users_Var_Pop_Fields = {
  __typename?: 'auth_users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "auth.users" */
export type Auth_Users_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auth_Users_Var_Samp_Fields = {
  __typename?: 'auth_users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "auth.users" */
export type Auth_Users_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Auth_Users_Variance_Fields = {
  __typename?: 'auth_users_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rbac_role_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "auth.users" */
export type Auth_Users_Variance_Order_By = {
  id?: Maybe<Order_By>;
  rbac_role_id?: Maybe<Order_By>;
};

/** columns and relationships of "balance_dues" */
export type Balance_Dues = {
  __typename?: 'balance_dues';
  amount_due?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamp'];
  current?: Maybe<Scalars['float8']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  due_date?: Maybe<Scalars['date']>;
  effective_date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  invoice_number?: Maybe<Scalars['Int']>;
  period_1?: Maybe<Scalars['float8']>;
  period_2?: Maybe<Scalars['float8']>;
  period_3?: Maybe<Scalars['float8']>;
  period_4?: Maybe<Scalars['float8']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "balance_dues" */
export type Balance_Dues_Aggregate = {
  __typename?: 'balance_dues_aggregate';
  aggregate?: Maybe<Balance_Dues_Aggregate_Fields>;
  nodes: Array<Balance_Dues>;
};

export type Balance_Dues_Aggregate_Bool_Exp = {
  avg?: Maybe<Balance_Dues_Aggregate_Bool_Exp_Avg>;
  corr?: Maybe<Balance_Dues_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Balance_Dues_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Balance_Dues_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Balance_Dues_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Balance_Dues_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Balance_Dues_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Balance_Dues_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Balance_Dues_Aggregate_Bool_Exp_Var_Samp>;
};

export type Balance_Dues_Aggregate_Bool_Exp_Avg = {
  arguments: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Balance_Dues_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Balance_Dues_Aggregate_Bool_Exp_Corr = {
  arguments: Balance_Dues_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Balance_Dues_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Balance_Dues_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Balance_Dues_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Balance_Dues_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Balance_Dues_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Balance_Dues_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Balance_Dues_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Balance_Dues_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Balance_Dues_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Balance_Dues_Aggregate_Bool_Exp_Max = {
  arguments: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Balance_Dues_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Balance_Dues_Aggregate_Bool_Exp_Min = {
  arguments: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Balance_Dues_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Balance_Dues_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Balance_Dues_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Balance_Dues_Aggregate_Bool_Exp_Sum = {
  arguments: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Balance_Dues_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Balance_Dues_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Balance_Dues_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "balance_dues" */
export type Balance_Dues_Aggregate_Fields = {
  __typename?: 'balance_dues_aggregate_fields';
  avg?: Maybe<Balance_Dues_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Balance_Dues_Max_Fields>;
  min?: Maybe<Balance_Dues_Min_Fields>;
  stddev?: Maybe<Balance_Dues_Stddev_Fields>;
  stddev_pop?: Maybe<Balance_Dues_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Balance_Dues_Stddev_Samp_Fields>;
  sum?: Maybe<Balance_Dues_Sum_Fields>;
  var_pop?: Maybe<Balance_Dues_Var_Pop_Fields>;
  var_samp?: Maybe<Balance_Dues_Var_Samp_Fields>;
  variance?: Maybe<Balance_Dues_Variance_Fields>;
};


/** aggregate fields of "balance_dues" */
export type Balance_Dues_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Balance_Dues_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "balance_dues" */
export type Balance_Dues_Aggregate_Order_By = {
  avg?: Maybe<Balance_Dues_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Balance_Dues_Max_Order_By>;
  min?: Maybe<Balance_Dues_Min_Order_By>;
  stddev?: Maybe<Balance_Dues_Stddev_Order_By>;
  stddev_pop?: Maybe<Balance_Dues_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Balance_Dues_Stddev_Samp_Order_By>;
  sum?: Maybe<Balance_Dues_Sum_Order_By>;
  var_pop?: Maybe<Balance_Dues_Var_Pop_Order_By>;
  var_samp?: Maybe<Balance_Dues_Var_Samp_Order_By>;
  variance?: Maybe<Balance_Dues_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "balance_dues" */
export type Balance_Dues_Arr_Rel_Insert_Input = {
  data: Array<Balance_Dues_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Balance_Dues_On_Conflict>;
};

/** aggregate avg on columns */
export type Balance_Dues_Avg_Fields = {
  __typename?: 'balance_dues_avg_fields';
  amount_due?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  period_1?: Maybe<Scalars['Float']>;
  period_2?: Maybe<Scalars['Float']>;
  period_3?: Maybe<Scalars['Float']>;
  period_4?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "balance_dues" */
export type Balance_Dues_Avg_Order_By = {
  amount_due?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "balance_dues". All fields are combined with a logical 'AND'. */
export type Balance_Dues_Bool_Exp = {
  _and?: Maybe<Array<Balance_Dues_Bool_Exp>>;
  _not?: Maybe<Balance_Dues_Bool_Exp>;
  _or?: Maybe<Array<Balance_Dues_Bool_Exp>>;
  amount_due?: Maybe<Float8_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  current?: Maybe<Float8_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  due_date?: Maybe<Date_Comparison_Exp>;
  effective_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_number?: Maybe<Int_Comparison_Exp>;
  period_1?: Maybe<Float8_Comparison_Exp>;
  period_2?: Maybe<Float8_Comparison_Exp>;
  period_3?: Maybe<Float8_Comparison_Exp>;
  period_4?: Maybe<Float8_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "balance_dues" */
export enum Balance_Dues_Constraint {
  /** unique or primary key constraint on columns "id" */
  BalanceDuesPkey = 'balance_dues_pkey'
}

/** input type for incrementing numeric columns in table "balance_dues" */
export type Balance_Dues_Inc_Input = {
  amount_due?: Maybe<Scalars['float8']>;
  current?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  period_1?: Maybe<Scalars['float8']>;
  period_2?: Maybe<Scalars['float8']>;
  period_3?: Maybe<Scalars['float8']>;
  period_4?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "balance_dues" */
export type Balance_Dues_Insert_Input = {
  amount_due?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current?: Maybe<Scalars['float8']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  due_date?: Maybe<Scalars['date']>;
  effective_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  period_1?: Maybe<Scalars['float8']>;
  period_2?: Maybe<Scalars['float8']>;
  period_3?: Maybe<Scalars['float8']>;
  period_4?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Balance_Dues_Max_Fields = {
  __typename?: 'balance_dues_max_fields';
  amount_due?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  due_date?: Maybe<Scalars['date']>;
  effective_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  period_1?: Maybe<Scalars['float8']>;
  period_2?: Maybe<Scalars['float8']>;
  period_3?: Maybe<Scalars['float8']>;
  period_4?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "balance_dues" */
export type Balance_Dues_Max_Order_By = {
  amount_due?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  effective_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Balance_Dues_Min_Fields = {
  __typename?: 'balance_dues_min_fields';
  amount_due?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  due_date?: Maybe<Scalars['date']>;
  effective_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  period_1?: Maybe<Scalars['float8']>;
  period_2?: Maybe<Scalars['float8']>;
  period_3?: Maybe<Scalars['float8']>;
  period_4?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "balance_dues" */
export type Balance_Dues_Min_Order_By = {
  amount_due?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  effective_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "balance_dues" */
export type Balance_Dues_Mutation_Response = {
  __typename?: 'balance_dues_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Balance_Dues>;
};

/** on_conflict condition type for table "balance_dues" */
export type Balance_Dues_On_Conflict = {
  constraint: Balance_Dues_Constraint;
  update_columns?: Array<Balance_Dues_Update_Column>;
  where?: Maybe<Balance_Dues_Bool_Exp>;
};

/** Ordering options when selecting data from "balance_dues". */
export type Balance_Dues_Order_By = {
  amount_due?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  effective_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: balance_dues */
export type Balance_Dues_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "balance_dues" */
export enum Balance_Dues_Select_Column {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Current = 'current',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "balance_dues_aggregate_bool_exp_avg_arguments_columns" columns of table "balance_dues" */
export enum Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  Current = 'current',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4'
}

/** select "balance_dues_aggregate_bool_exp_corr_arguments_columns" columns of table "balance_dues" */
export enum Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  Current = 'current',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4'
}

/** select "balance_dues_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "balance_dues" */
export enum Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  Current = 'current',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4'
}

/** select "balance_dues_aggregate_bool_exp_max_arguments_columns" columns of table "balance_dues" */
export enum Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  Current = 'current',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4'
}

/** select "balance_dues_aggregate_bool_exp_min_arguments_columns" columns of table "balance_dues" */
export enum Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  Current = 'current',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4'
}

/** select "balance_dues_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "balance_dues" */
export enum Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  Current = 'current',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4'
}

/** select "balance_dues_aggregate_bool_exp_sum_arguments_columns" columns of table "balance_dues" */
export enum Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  Current = 'current',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4'
}

/** select "balance_dues_aggregate_bool_exp_var_samp_arguments_columns" columns of table "balance_dues" */
export enum Balance_Dues_Select_Column_Balance_Dues_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  Current = 'current',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4'
}

/** input type for updating data in table "balance_dues" */
export type Balance_Dues_Set_Input = {
  amount_due?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  due_date?: Maybe<Scalars['date']>;
  effective_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  period_1?: Maybe<Scalars['float8']>;
  period_2?: Maybe<Scalars['float8']>;
  period_3?: Maybe<Scalars['float8']>;
  period_4?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Balance_Dues_Stddev_Fields = {
  __typename?: 'balance_dues_stddev_fields';
  amount_due?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  period_1?: Maybe<Scalars['Float']>;
  period_2?: Maybe<Scalars['Float']>;
  period_3?: Maybe<Scalars['Float']>;
  period_4?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "balance_dues" */
export type Balance_Dues_Stddev_Order_By = {
  amount_due?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Balance_Dues_Stddev_Pop_Fields = {
  __typename?: 'balance_dues_stddev_pop_fields';
  amount_due?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  period_1?: Maybe<Scalars['Float']>;
  period_2?: Maybe<Scalars['Float']>;
  period_3?: Maybe<Scalars['Float']>;
  period_4?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "balance_dues" */
export type Balance_Dues_Stddev_Pop_Order_By = {
  amount_due?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Balance_Dues_Stddev_Samp_Fields = {
  __typename?: 'balance_dues_stddev_samp_fields';
  amount_due?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  period_1?: Maybe<Scalars['Float']>;
  period_2?: Maybe<Scalars['Float']>;
  period_3?: Maybe<Scalars['Float']>;
  period_4?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "balance_dues" */
export type Balance_Dues_Stddev_Samp_Order_By = {
  amount_due?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
};

/** Streaming cursor of the table "balance_dues" */
export type Balance_Dues_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Balance_Dues_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Balance_Dues_Stream_Cursor_Value_Input = {
  amount_due?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  due_date?: Maybe<Scalars['date']>;
  effective_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  period_1?: Maybe<Scalars['float8']>;
  period_2?: Maybe<Scalars['float8']>;
  period_3?: Maybe<Scalars['float8']>;
  period_4?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Balance_Dues_Sum_Fields = {
  __typename?: 'balance_dues_sum_fields';
  amount_due?: Maybe<Scalars['float8']>;
  current?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  period_1?: Maybe<Scalars['float8']>;
  period_2?: Maybe<Scalars['float8']>;
  period_3?: Maybe<Scalars['float8']>;
  period_4?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "balance_dues" */
export type Balance_Dues_Sum_Order_By = {
  amount_due?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
};

/** update columns of table "balance_dues" */
export enum Balance_Dues_Update_Column {
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Current = 'current',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  Period_1 = 'period_1',
  /** column name */
  Period_2 = 'period_2',
  /** column name */
  Period_3 = 'period_3',
  /** column name */
  Period_4 = 'period_4',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Balance_Dues_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Balance_Dues_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Balance_Dues_Set_Input>;
  /** filter the rows which have to be updated */
  where: Balance_Dues_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Balance_Dues_Var_Pop_Fields = {
  __typename?: 'balance_dues_var_pop_fields';
  amount_due?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  period_1?: Maybe<Scalars['Float']>;
  period_2?: Maybe<Scalars['Float']>;
  period_3?: Maybe<Scalars['Float']>;
  period_4?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "balance_dues" */
export type Balance_Dues_Var_Pop_Order_By = {
  amount_due?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Balance_Dues_Var_Samp_Fields = {
  __typename?: 'balance_dues_var_samp_fields';
  amount_due?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  period_1?: Maybe<Scalars['Float']>;
  period_2?: Maybe<Scalars['Float']>;
  period_3?: Maybe<Scalars['Float']>;
  period_4?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "balance_dues" */
export type Balance_Dues_Var_Samp_Order_By = {
  amount_due?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Balance_Dues_Variance_Fields = {
  __typename?: 'balance_dues_variance_fields';
  amount_due?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  period_1?: Maybe<Scalars['Float']>;
  period_2?: Maybe<Scalars['Float']>;
  period_3?: Maybe<Scalars['Float']>;
  period_4?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "balance_dues" */
export type Balance_Dues_Variance_Order_By = {
  amount_due?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  period_1?: Maybe<Order_By>;
  period_2?: Maybe<Order_By>;
  period_3?: Maybe<Order_By>;
  period_4?: Maybe<Order_By>;
};

/** columns and relationships of "bhe_accounts" */
export type Bhe_Accounts = {
  __typename?: 'bhe_accounts';
  bhe_account_number: Scalars['String'];
  bhe_company_name: Scalars['String'];
  /** An array relationship */
  bhe_invoices: Array<Bhe_Invoices>;
  /** An aggregate relationship */
  bhe_invoices_aggregate: Bhe_Invoices_Aggregate;
  bhe_phone_number: Scalars['String'];
  bhe_raw_account?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  last_failure?: Maybe<Scalars['timestamptz']>;
  last_logs?: Maybe<Scalars['String']>;
  last_success?: Maybe<Scalars['timestamptz']>;
  should_sync: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "bhe_accounts" */
export type Bhe_AccountsBhe_InvoicesArgs = {
  distinct_on?: Maybe<Array<Bhe_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Invoices_Order_By>>;
  where?: Maybe<Bhe_Invoices_Bool_Exp>;
};


/** columns and relationships of "bhe_accounts" */
export type Bhe_AccountsBhe_Invoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Bhe_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Invoices_Order_By>>;
  where?: Maybe<Bhe_Invoices_Bool_Exp>;
};


/** columns and relationships of "bhe_accounts" */
export type Bhe_AccountsBhe_Raw_AccountArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bhe_accounts" */
export type Bhe_Accounts_Aggregate = {
  __typename?: 'bhe_accounts_aggregate';
  aggregate?: Maybe<Bhe_Accounts_Aggregate_Fields>;
  nodes: Array<Bhe_Accounts>;
};

/** aggregate fields of "bhe_accounts" */
export type Bhe_Accounts_Aggregate_Fields = {
  __typename?: 'bhe_accounts_aggregate_fields';
  avg?: Maybe<Bhe_Accounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bhe_Accounts_Max_Fields>;
  min?: Maybe<Bhe_Accounts_Min_Fields>;
  stddev?: Maybe<Bhe_Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Bhe_Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bhe_Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Bhe_Accounts_Sum_Fields>;
  var_pop?: Maybe<Bhe_Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Bhe_Accounts_Var_Samp_Fields>;
  variance?: Maybe<Bhe_Accounts_Variance_Fields>;
};


/** aggregate fields of "bhe_accounts" */
export type Bhe_Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bhe_Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Bhe_Accounts_Append_Input = {
  bhe_raw_account?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Bhe_Accounts_Avg_Fields = {
  __typename?: 'bhe_accounts_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bhe_accounts". All fields are combined with a logical 'AND'. */
export type Bhe_Accounts_Bool_Exp = {
  _and?: Maybe<Array<Bhe_Accounts_Bool_Exp>>;
  _not?: Maybe<Bhe_Accounts_Bool_Exp>;
  _or?: Maybe<Array<Bhe_Accounts_Bool_Exp>>;
  bhe_account_number?: Maybe<String_Comparison_Exp>;
  bhe_company_name?: Maybe<String_Comparison_Exp>;
  bhe_invoices?: Maybe<Bhe_Invoices_Bool_Exp>;
  bhe_invoices_aggregate?: Maybe<Bhe_Invoices_Aggregate_Bool_Exp>;
  bhe_phone_number?: Maybe<String_Comparison_Exp>;
  bhe_raw_account?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_failure?: Maybe<Timestamptz_Comparison_Exp>;
  last_logs?: Maybe<String_Comparison_Exp>;
  last_success?: Maybe<Timestamptz_Comparison_Exp>;
  should_sync?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bhe_accounts" */
export enum Bhe_Accounts_Constraint {
  /** unique or primary key constraint on columns "bhe_account_number" */
  BheAccountsBheAccountNumberKey = 'bhe_accounts_bhe_account_number_key',
  /** unique or primary key constraint on columns "id" */
  BheAccountsPkey = 'bhe_accounts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Bhe_Accounts_Delete_At_Path_Input = {
  bhe_raw_account?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Bhe_Accounts_Delete_Elem_Input = {
  bhe_raw_account?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Bhe_Accounts_Delete_Key_Input = {
  bhe_raw_account?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "bhe_accounts" */
export type Bhe_Accounts_Inc_Input = {
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "bhe_accounts" */
export type Bhe_Accounts_Insert_Input = {
  bhe_account_number?: Maybe<Scalars['String']>;
  bhe_company_name?: Maybe<Scalars['String']>;
  bhe_invoices?: Maybe<Bhe_Invoices_Arr_Rel_Insert_Input>;
  bhe_phone_number?: Maybe<Scalars['String']>;
  bhe_raw_account?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  last_failure?: Maybe<Scalars['timestamptz']>;
  last_logs?: Maybe<Scalars['String']>;
  last_success?: Maybe<Scalars['timestamptz']>;
  should_sync?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Bhe_Accounts_Max_Fields = {
  __typename?: 'bhe_accounts_max_fields';
  bhe_account_number?: Maybe<Scalars['String']>;
  bhe_company_name?: Maybe<Scalars['String']>;
  bhe_phone_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  last_failure?: Maybe<Scalars['timestamptz']>;
  last_logs?: Maybe<Scalars['String']>;
  last_success?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Bhe_Accounts_Min_Fields = {
  __typename?: 'bhe_accounts_min_fields';
  bhe_account_number?: Maybe<Scalars['String']>;
  bhe_company_name?: Maybe<Scalars['String']>;
  bhe_phone_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  last_failure?: Maybe<Scalars['timestamptz']>;
  last_logs?: Maybe<Scalars['String']>;
  last_success?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "bhe_accounts" */
export type Bhe_Accounts_Mutation_Response = {
  __typename?: 'bhe_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bhe_Accounts>;
};

/** input type for inserting object relation for remote table "bhe_accounts" */
export type Bhe_Accounts_Obj_Rel_Insert_Input = {
  data: Bhe_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Bhe_Accounts_On_Conflict>;
};

/** on_conflict condition type for table "bhe_accounts" */
export type Bhe_Accounts_On_Conflict = {
  constraint: Bhe_Accounts_Constraint;
  update_columns?: Array<Bhe_Accounts_Update_Column>;
  where?: Maybe<Bhe_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "bhe_accounts". */
export type Bhe_Accounts_Order_By = {
  bhe_account_number?: Maybe<Order_By>;
  bhe_company_name?: Maybe<Order_By>;
  bhe_invoices_aggregate?: Maybe<Bhe_Invoices_Aggregate_Order_By>;
  bhe_phone_number?: Maybe<Order_By>;
  bhe_raw_account?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_failure?: Maybe<Order_By>;
  last_logs?: Maybe<Order_By>;
  last_success?: Maybe<Order_By>;
  should_sync?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: bhe_accounts */
export type Bhe_Accounts_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Bhe_Accounts_Prepend_Input = {
  bhe_raw_account?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "bhe_accounts" */
export enum Bhe_Accounts_Select_Column {
  /** column name */
  BheAccountNumber = 'bhe_account_number',
  /** column name */
  BheCompanyName = 'bhe_company_name',
  /** column name */
  BhePhoneNumber = 'bhe_phone_number',
  /** column name */
  BheRawAccount = 'bhe_raw_account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastFailure = 'last_failure',
  /** column name */
  LastLogs = 'last_logs',
  /** column name */
  LastSuccess = 'last_success',
  /** column name */
  ShouldSync = 'should_sync',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bhe_accounts" */
export type Bhe_Accounts_Set_Input = {
  bhe_account_number?: Maybe<Scalars['String']>;
  bhe_company_name?: Maybe<Scalars['String']>;
  bhe_phone_number?: Maybe<Scalars['String']>;
  bhe_raw_account?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  last_failure?: Maybe<Scalars['timestamptz']>;
  last_logs?: Maybe<Scalars['String']>;
  last_success?: Maybe<Scalars['timestamptz']>;
  should_sync?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Bhe_Accounts_Stddev_Fields = {
  __typename?: 'bhe_accounts_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bhe_Accounts_Stddev_Pop_Fields = {
  __typename?: 'bhe_accounts_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bhe_Accounts_Stddev_Samp_Fields = {
  __typename?: 'bhe_accounts_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bhe_accounts" */
export type Bhe_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bhe_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bhe_Accounts_Stream_Cursor_Value_Input = {
  bhe_account_number?: Maybe<Scalars['String']>;
  bhe_company_name?: Maybe<Scalars['String']>;
  bhe_phone_number?: Maybe<Scalars['String']>;
  bhe_raw_account?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  last_failure?: Maybe<Scalars['timestamptz']>;
  last_logs?: Maybe<Scalars['String']>;
  last_success?: Maybe<Scalars['timestamptz']>;
  should_sync?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Bhe_Accounts_Sum_Fields = {
  __typename?: 'bhe_accounts_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "bhe_accounts" */
export enum Bhe_Accounts_Update_Column {
  /** column name */
  BheAccountNumber = 'bhe_account_number',
  /** column name */
  BheCompanyName = 'bhe_company_name',
  /** column name */
  BhePhoneNumber = 'bhe_phone_number',
  /** column name */
  BheRawAccount = 'bhe_raw_account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastFailure = 'last_failure',
  /** column name */
  LastLogs = 'last_logs',
  /** column name */
  LastSuccess = 'last_success',
  /** column name */
  ShouldSync = 'should_sync',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Bhe_Accounts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Bhe_Accounts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Bhe_Accounts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Bhe_Accounts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Bhe_Accounts_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Bhe_Accounts_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Bhe_Accounts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bhe_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bhe_Accounts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bhe_Accounts_Var_Pop_Fields = {
  __typename?: 'bhe_accounts_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bhe_Accounts_Var_Samp_Fields = {
  __typename?: 'bhe_accounts_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bhe_Accounts_Variance_Fields = {
  __typename?: 'bhe_accounts_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "bhe_invoices" */
export type Bhe_Invoices = {
  __typename?: 'bhe_invoices';
  ap_batch_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  bhe_account: Bhe_Accounts;
  bhe_account_id: Scalars['bigint'];
  bill_number: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  raw_api_data?: Maybe<Scalars['jsonb']>;
  raw_parse_data?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
  wre_invoice_number?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "bhe_invoices" */
export type Bhe_InvoicesRaw_Api_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "bhe_invoices" */
export type Bhe_InvoicesRaw_Parse_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bhe_invoices" */
export type Bhe_Invoices_Aggregate = {
  __typename?: 'bhe_invoices_aggregate';
  aggregate?: Maybe<Bhe_Invoices_Aggregate_Fields>;
  nodes: Array<Bhe_Invoices>;
};

export type Bhe_Invoices_Aggregate_Bool_Exp = {
  count?: Maybe<Bhe_Invoices_Aggregate_Bool_Exp_Count>;
};

export type Bhe_Invoices_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Bhe_Invoices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Bhe_Invoices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bhe_invoices" */
export type Bhe_Invoices_Aggregate_Fields = {
  __typename?: 'bhe_invoices_aggregate_fields';
  avg?: Maybe<Bhe_Invoices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bhe_Invoices_Max_Fields>;
  min?: Maybe<Bhe_Invoices_Min_Fields>;
  stddev?: Maybe<Bhe_Invoices_Stddev_Fields>;
  stddev_pop?: Maybe<Bhe_Invoices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bhe_Invoices_Stddev_Samp_Fields>;
  sum?: Maybe<Bhe_Invoices_Sum_Fields>;
  var_pop?: Maybe<Bhe_Invoices_Var_Pop_Fields>;
  var_samp?: Maybe<Bhe_Invoices_Var_Samp_Fields>;
  variance?: Maybe<Bhe_Invoices_Variance_Fields>;
};


/** aggregate fields of "bhe_invoices" */
export type Bhe_Invoices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bhe_Invoices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bhe_invoices" */
export type Bhe_Invoices_Aggregate_Order_By = {
  avg?: Maybe<Bhe_Invoices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Bhe_Invoices_Max_Order_By>;
  min?: Maybe<Bhe_Invoices_Min_Order_By>;
  stddev?: Maybe<Bhe_Invoices_Stddev_Order_By>;
  stddev_pop?: Maybe<Bhe_Invoices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Bhe_Invoices_Stddev_Samp_Order_By>;
  sum?: Maybe<Bhe_Invoices_Sum_Order_By>;
  var_pop?: Maybe<Bhe_Invoices_Var_Pop_Order_By>;
  var_samp?: Maybe<Bhe_Invoices_Var_Samp_Order_By>;
  variance?: Maybe<Bhe_Invoices_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Bhe_Invoices_Append_Input = {
  raw_api_data?: Maybe<Scalars['jsonb']>;
  raw_parse_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "bhe_invoices" */
export type Bhe_Invoices_Arr_Rel_Insert_Input = {
  data: Array<Bhe_Invoices_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Bhe_Invoices_On_Conflict>;
};

/** aggregate avg on columns */
export type Bhe_Invoices_Avg_Fields = {
  __typename?: 'bhe_invoices_avg_fields';
  bhe_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wre_invoice_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Avg_Order_By = {
  bhe_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bhe_invoices". All fields are combined with a logical 'AND'. */
export type Bhe_Invoices_Bool_Exp = {
  _and?: Maybe<Array<Bhe_Invoices_Bool_Exp>>;
  _not?: Maybe<Bhe_Invoices_Bool_Exp>;
  _or?: Maybe<Array<Bhe_Invoices_Bool_Exp>>;
  ap_batch_number?: Maybe<String_Comparison_Exp>;
  bhe_account?: Maybe<Bhe_Accounts_Bool_Exp>;
  bhe_account_id?: Maybe<Bigint_Comparison_Exp>;
  bill_number?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  pdf_text?: Maybe<String_Comparison_Exp>;
  pdf_url?: Maybe<String_Comparison_Exp>;
  raw_api_data?: Maybe<Jsonb_Comparison_Exp>;
  raw_parse_data?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wre_invoice_number?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bhe_invoices" */
export enum Bhe_Invoices_Constraint {
  /** unique or primary key constraint on columns "bill_number", "bhe_account_id" */
  BheInvoicesBheAccountIdBheBillIdKey = 'bhe_invoices_bhe_account_id_bhe_bill_id_key',
  /** unique or primary key constraint on columns "id" */
  BheInvoicesPkey = 'bhe_invoices_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Bhe_Invoices_Delete_At_Path_Input = {
  raw_api_data?: Maybe<Array<Scalars['String']>>;
  raw_parse_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Bhe_Invoices_Delete_Elem_Input = {
  raw_api_data?: Maybe<Scalars['Int']>;
  raw_parse_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Bhe_Invoices_Delete_Key_Input = {
  raw_api_data?: Maybe<Scalars['String']>;
  raw_parse_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "bhe_invoices" */
export type Bhe_Invoices_Inc_Input = {
  bhe_account_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  wre_invoice_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "bhe_invoices" */
export type Bhe_Invoices_Insert_Input = {
  ap_batch_number?: Maybe<Scalars['String']>;
  bhe_account?: Maybe<Bhe_Accounts_Obj_Rel_Insert_Input>;
  bhe_account_id?: Maybe<Scalars['bigint']>;
  bill_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  raw_api_data?: Maybe<Scalars['jsonb']>;
  raw_parse_data?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wre_invoice_number?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Bhe_Invoices_Max_Fields = {
  __typename?: 'bhe_invoices_max_fields';
  ap_batch_number?: Maybe<Scalars['String']>;
  bhe_account_id?: Maybe<Scalars['bigint']>;
  bill_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wre_invoice_number?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Max_Order_By = {
  ap_batch_number?: Maybe<Order_By>;
  bhe_account_id?: Maybe<Order_By>;
  bill_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_text?: Maybe<Order_By>;
  pdf_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bhe_Invoices_Min_Fields = {
  __typename?: 'bhe_invoices_min_fields';
  ap_batch_number?: Maybe<Scalars['String']>;
  bhe_account_id?: Maybe<Scalars['bigint']>;
  bill_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wre_invoice_number?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Min_Order_By = {
  ap_batch_number?: Maybe<Order_By>;
  bhe_account_id?: Maybe<Order_By>;
  bill_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_text?: Maybe<Order_By>;
  pdf_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** response of any mutation on the table "bhe_invoices" */
export type Bhe_Invoices_Mutation_Response = {
  __typename?: 'bhe_invoices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bhe_Invoices>;
};

/** on_conflict condition type for table "bhe_invoices" */
export type Bhe_Invoices_On_Conflict = {
  constraint: Bhe_Invoices_Constraint;
  update_columns?: Array<Bhe_Invoices_Update_Column>;
  where?: Maybe<Bhe_Invoices_Bool_Exp>;
};

/** Ordering options when selecting data from "bhe_invoices". */
export type Bhe_Invoices_Order_By = {
  ap_batch_number?: Maybe<Order_By>;
  bhe_account?: Maybe<Bhe_Accounts_Order_By>;
  bhe_account_id?: Maybe<Order_By>;
  bill_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_text?: Maybe<Order_By>;
  pdf_url?: Maybe<Order_By>;
  raw_api_data?: Maybe<Order_By>;
  raw_parse_data?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** primary key columns input for table: bhe_invoices */
export type Bhe_Invoices_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Bhe_Invoices_Prepend_Input = {
  raw_api_data?: Maybe<Scalars['jsonb']>;
  raw_parse_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "bhe_invoices" */
export enum Bhe_Invoices_Select_Column {
  /** column name */
  ApBatchNumber = 'ap_batch_number',
  /** column name */
  BheAccountId = 'bhe_account_id',
  /** column name */
  BillNumber = 'bill_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PdfText = 'pdf_text',
  /** column name */
  PdfUrl = 'pdf_url',
  /** column name */
  RawApiData = 'raw_api_data',
  /** column name */
  RawParseData = 'raw_parse_data',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WreInvoiceNumber = 'wre_invoice_number'
}

/** input type for updating data in table "bhe_invoices" */
export type Bhe_Invoices_Set_Input = {
  ap_batch_number?: Maybe<Scalars['String']>;
  bhe_account_id?: Maybe<Scalars['bigint']>;
  bill_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  raw_api_data?: Maybe<Scalars['jsonb']>;
  raw_parse_data?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wre_invoice_number?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bhe_Invoices_Stddev_Fields = {
  __typename?: 'bhe_invoices_stddev_fields';
  bhe_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wre_invoice_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Stddev_Order_By = {
  bhe_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bhe_Invoices_Stddev_Pop_Fields = {
  __typename?: 'bhe_invoices_stddev_pop_fields';
  bhe_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wre_invoice_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Stddev_Pop_Order_By = {
  bhe_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bhe_Invoices_Stddev_Samp_Fields = {
  __typename?: 'bhe_invoices_stddev_samp_fields';
  bhe_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wre_invoice_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Stddev_Samp_Order_By = {
  bhe_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** Streaming cursor of the table "bhe_invoices" */
export type Bhe_Invoices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bhe_Invoices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bhe_Invoices_Stream_Cursor_Value_Input = {
  ap_batch_number?: Maybe<Scalars['String']>;
  bhe_account_id?: Maybe<Scalars['bigint']>;
  bill_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  raw_api_data?: Maybe<Scalars['jsonb']>;
  raw_parse_data?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wre_invoice_number?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bhe_Invoices_Sum_Fields = {
  __typename?: 'bhe_invoices_sum_fields';
  bhe_account_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  wre_invoice_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Sum_Order_By = {
  bhe_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** update columns of table "bhe_invoices" */
export enum Bhe_Invoices_Update_Column {
  /** column name */
  ApBatchNumber = 'ap_batch_number',
  /** column name */
  BheAccountId = 'bhe_account_id',
  /** column name */
  BillNumber = 'bill_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PdfText = 'pdf_text',
  /** column name */
  PdfUrl = 'pdf_url',
  /** column name */
  RawApiData = 'raw_api_data',
  /** column name */
  RawParseData = 'raw_parse_data',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WreInvoiceNumber = 'wre_invoice_number'
}

export type Bhe_Invoices_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Bhe_Invoices_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Bhe_Invoices_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Bhe_Invoices_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Bhe_Invoices_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Bhe_Invoices_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Bhe_Invoices_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bhe_Invoices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bhe_Invoices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bhe_Invoices_Var_Pop_Fields = {
  __typename?: 'bhe_invoices_var_pop_fields';
  bhe_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wre_invoice_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Var_Pop_Order_By = {
  bhe_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bhe_Invoices_Var_Samp_Fields = {
  __typename?: 'bhe_invoices_var_samp_fields';
  bhe_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wre_invoice_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Var_Samp_Order_By = {
  bhe_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Bhe_Invoices_Variance_Fields = {
  __typename?: 'bhe_invoices_variance_fields';
  bhe_account_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wre_invoice_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bhe_invoices" */
export type Bhe_Invoices_Variance_Order_By = {
  bhe_account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wre_invoice_number?: Maybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: Maybe<Scalars['bpchar']>;
  _gt?: Maybe<Scalars['bpchar']>;
  _gte?: Maybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['bpchar']>;
  _in?: Maybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['bpchar']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['bpchar']>;
  _lt?: Maybe<Scalars['bpchar']>;
  _lte?: Maybe<Scalars['bpchar']>;
  _neq?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['bpchar']>;
  _nin?: Maybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['bpchar']>;
};

/** columns and relationships of "commissions" */
export type Commissions = {
  __typename?: 'commissions';
  comm_rate?: Maybe<Scalars['float8']>;
  comm_rate_2?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "commissions" */
export type Commissions_Aggregate = {
  __typename?: 'commissions_aggregate';
  aggregate?: Maybe<Commissions_Aggregate_Fields>;
  nodes: Array<Commissions>;
};

/** aggregate fields of "commissions" */
export type Commissions_Aggregate_Fields = {
  __typename?: 'commissions_aggregate_fields';
  avg?: Maybe<Commissions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commissions_Max_Fields>;
  min?: Maybe<Commissions_Min_Fields>;
  stddev?: Maybe<Commissions_Stddev_Fields>;
  stddev_pop?: Maybe<Commissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commissions_Stddev_Samp_Fields>;
  sum?: Maybe<Commissions_Sum_Fields>;
  var_pop?: Maybe<Commissions_Var_Pop_Fields>;
  var_samp?: Maybe<Commissions_Var_Samp_Fields>;
  variance?: Maybe<Commissions_Variance_Fields>;
};


/** aggregate fields of "commissions" */
export type Commissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Commissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commissions_Avg_Fields = {
  __typename?: 'commissions_avg_fields';
  comm_rate?: Maybe<Scalars['Float']>;
  comm_rate_2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commissions". All fields are combined with a logical 'AND'. */
export type Commissions_Bool_Exp = {
  _and?: Maybe<Array<Commissions_Bool_Exp>>;
  _not?: Maybe<Commissions_Bool_Exp>;
  _or?: Maybe<Array<Commissions_Bool_Exp>>;
  comm_rate?: Maybe<Float8_Comparison_Exp>;
  comm_rate_2?: Maybe<Float8_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "commissions" */
export enum Commissions_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommissionsPkey = 'commissions_pkey'
}

/** input type for incrementing numeric columns in table "commissions" */
export type Commissions_Inc_Input = {
  comm_rate?: Maybe<Scalars['float8']>;
  comm_rate_2?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "commissions" */
export type Commissions_Insert_Input = {
  comm_rate?: Maybe<Scalars['float8']>;
  comm_rate_2?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Commissions_Max_Fields = {
  __typename?: 'commissions_max_fields';
  comm_rate?: Maybe<Scalars['float8']>;
  comm_rate_2?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Commissions_Min_Fields = {
  __typename?: 'commissions_min_fields';
  comm_rate?: Maybe<Scalars['float8']>;
  comm_rate_2?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "commissions" */
export type Commissions_Mutation_Response = {
  __typename?: 'commissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Commissions>;
};

/** on_conflict condition type for table "commissions" */
export type Commissions_On_Conflict = {
  constraint: Commissions_Constraint;
  update_columns?: Array<Commissions_Update_Column>;
  where?: Maybe<Commissions_Bool_Exp>;
};

/** Ordering options when selecting data from "commissions". */
export type Commissions_Order_By = {
  comm_rate?: Maybe<Order_By>;
  comm_rate_2?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: commissions */
export type Commissions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "commissions" */
export enum Commissions_Select_Column {
  /** column name */
  CommRate = 'comm_rate',
  /** column name */
  CommRate_2 = 'comm_rate_2',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "commissions" */
export type Commissions_Set_Input = {
  comm_rate?: Maybe<Scalars['float8']>;
  comm_rate_2?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Commissions_Stddev_Fields = {
  __typename?: 'commissions_stddev_fields';
  comm_rate?: Maybe<Scalars['Float']>;
  comm_rate_2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commissions_Stddev_Pop_Fields = {
  __typename?: 'commissions_stddev_pop_fields';
  comm_rate?: Maybe<Scalars['Float']>;
  comm_rate_2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commissions_Stddev_Samp_Fields = {
  __typename?: 'commissions_stddev_samp_fields';
  comm_rate?: Maybe<Scalars['Float']>;
  comm_rate_2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commissions" */
export type Commissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commissions_Stream_Cursor_Value_Input = {
  comm_rate?: Maybe<Scalars['float8']>;
  comm_rate_2?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Commissions_Sum_Fields = {
  __typename?: 'commissions_sum_fields';
  comm_rate?: Maybe<Scalars['float8']>;
  comm_rate_2?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "commissions" */
export enum Commissions_Update_Column {
  /** column name */
  CommRate = 'comm_rate',
  /** column name */
  CommRate_2 = 'comm_rate_2',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Commissions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Commissions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Commissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commissions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Commissions_Var_Pop_Fields = {
  __typename?: 'commissions_var_pop_fields';
  comm_rate?: Maybe<Scalars['Float']>;
  comm_rate_2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commissions_Var_Samp_Fields = {
  __typename?: 'commissions_var_samp_fields';
  comm_rate?: Maybe<Scalars['Float']>;
  comm_rate_2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commissions_Variance_Fields = {
  __typename?: 'commissions_variance_fields';
  comm_rate?: Maybe<Scalars['Float']>;
  comm_rate_2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "communication_types". All fields are combined with logical 'AND'. */
export type Communication_Types_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars['communication_types']>>;
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars['communication_types']>>;
  _eq?: Maybe<Array<Scalars['communication_types']>>;
  _gt?: Maybe<Array<Scalars['communication_types']>>;
  _gte?: Maybe<Array<Scalars['communication_types']>>;
  _in?: Maybe<Array<Array<Scalars['communication_types']>>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Array<Scalars['communication_types']>>;
  _lte?: Maybe<Array<Scalars['communication_types']>>;
  _neq?: Maybe<Array<Scalars['communication_types']>>;
  _nin?: Maybe<Array<Array<Scalars['communication_types']>>>;
};

/** columns and relationships of "contact_assignments" */
export type Contact_Assignments = {
  __typename?: 'contact_assignments';
  /** An object relationship */
  contact_entry?: Maybe<Contact_Entries>;
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "contact_assignments" */
export type Contact_Assignments_Aggregate = {
  __typename?: 'contact_assignments_aggregate';
  aggregate?: Maybe<Contact_Assignments_Aggregate_Fields>;
  nodes: Array<Contact_Assignments>;
};

export type Contact_Assignments_Aggregate_Bool_Exp = {
  count?: Maybe<Contact_Assignments_Aggregate_Bool_Exp_Count>;
};

export type Contact_Assignments_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Contact_Assignments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Contact_Assignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contact_assignments" */
export type Contact_Assignments_Aggregate_Fields = {
  __typename?: 'contact_assignments_aggregate_fields';
  avg?: Maybe<Contact_Assignments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Assignments_Max_Fields>;
  min?: Maybe<Contact_Assignments_Min_Fields>;
  stddev?: Maybe<Contact_Assignments_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Assignments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Assignments_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Assignments_Sum_Fields>;
  var_pop?: Maybe<Contact_Assignments_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Assignments_Var_Samp_Fields>;
  variance?: Maybe<Contact_Assignments_Variance_Fields>;
};


/** aggregate fields of "contact_assignments" */
export type Contact_Assignments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Assignments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contact_assignments" */
export type Contact_Assignments_Aggregate_Order_By = {
  avg?: Maybe<Contact_Assignments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Contact_Assignments_Max_Order_By>;
  min?: Maybe<Contact_Assignments_Min_Order_By>;
  stddev?: Maybe<Contact_Assignments_Stddev_Order_By>;
  stddev_pop?: Maybe<Contact_Assignments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Contact_Assignments_Stddev_Samp_Order_By>;
  sum?: Maybe<Contact_Assignments_Sum_Order_By>;
  var_pop?: Maybe<Contact_Assignments_Var_Pop_Order_By>;
  var_samp?: Maybe<Contact_Assignments_Var_Samp_Order_By>;
  variance?: Maybe<Contact_Assignments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contact_assignments" */
export type Contact_Assignments_Arr_Rel_Insert_Input = {
  data: Array<Contact_Assignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Contact_Assignments_On_Conflict>;
};

/** aggregate avg on columns */
export type Contact_Assignments_Avg_Fields = {
  __typename?: 'contact_assignments_avg_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contact_assignments" */
export type Contact_Assignments_Avg_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact_assignments". All fields are combined with a logical 'AND'. */
export type Contact_Assignments_Bool_Exp = {
  _and?: Maybe<Array<Contact_Assignments_Bool_Exp>>;
  _not?: Maybe<Contact_Assignments_Bool_Exp>;
  _or?: Maybe<Array<Contact_Assignments_Bool_Exp>>;
  contact_entry?: Maybe<Contact_Entries_Bool_Exp>;
  contact_entry_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_assignments" */
export enum Contact_Assignments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactAssignmentsPkey = 'contact_assignments_pkey',
  /** unique or primary key constraint on columns "contact_entry_id", "customer_id" */
  IndexContactAssignmentsOnCustomerIdAndContactEntryId = 'index_contact_assignments_on_customer_id_and_contact_entry_id'
}

/** input type for incrementing numeric columns in table "contact_assignments" */
export type Contact_Assignments_Inc_Input = {
  contact_entry_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact_assignments" */
export type Contact_Assignments_Insert_Input = {
  contact_entry?: Maybe<Contact_Entries_Obj_Rel_Insert_Input>;
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contact_Assignments_Max_Fields = {
  __typename?: 'contact_assignments_max_fields';
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contact_assignments" */
export type Contact_Assignments_Max_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Assignments_Min_Fields = {
  __typename?: 'contact_assignments_min_fields';
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contact_assignments" */
export type Contact_Assignments_Min_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "contact_assignments" */
export type Contact_Assignments_Mutation_Response = {
  __typename?: 'contact_assignments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Assignments>;
};

/** on_conflict condition type for table "contact_assignments" */
export type Contact_Assignments_On_Conflict = {
  constraint: Contact_Assignments_Constraint;
  update_columns?: Array<Contact_Assignments_Update_Column>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_assignments". */
export type Contact_Assignments_Order_By = {
  contact_entry?: Maybe<Contact_Entries_Order_By>;
  contact_entry_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: contact_assignments */
export type Contact_Assignments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contact_assignments" */
export enum Contact_Assignments_Select_Column {
  /** column name */
  ContactEntryId = 'contact_entry_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contact_assignments" */
export type Contact_Assignments_Set_Input = {
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Contact_Assignments_Stddev_Fields = {
  __typename?: 'contact_assignments_stddev_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contact_assignments" */
export type Contact_Assignments_Stddev_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contact_Assignments_Stddev_Pop_Fields = {
  __typename?: 'contact_assignments_stddev_pop_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contact_assignments" */
export type Contact_Assignments_Stddev_Pop_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contact_Assignments_Stddev_Samp_Fields = {
  __typename?: 'contact_assignments_stddev_samp_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contact_assignments" */
export type Contact_Assignments_Stddev_Samp_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "contact_assignments" */
export type Contact_Assignments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Assignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Assignments_Stream_Cursor_Value_Input = {
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Contact_Assignments_Sum_Fields = {
  __typename?: 'contact_assignments_sum_fields';
  contact_entry_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contact_assignments" */
export type Contact_Assignments_Sum_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "contact_assignments" */
export enum Contact_Assignments_Update_Column {
  /** column name */
  ContactEntryId = 'contact_entry_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Contact_Assignments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Contact_Assignments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Assignments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Assignments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contact_Assignments_Var_Pop_Fields = {
  __typename?: 'contact_assignments_var_pop_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contact_assignments" */
export type Contact_Assignments_Var_Pop_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contact_Assignments_Var_Samp_Fields = {
  __typename?: 'contact_assignments_var_samp_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contact_assignments" */
export type Contact_Assignments_Var_Samp_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Contact_Assignments_Variance_Fields = {
  __typename?: 'contact_assignments_variance_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contact_assignments" */
export type Contact_Assignments_Variance_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "contact_entries" */
export type Contact_Entries = {
  __typename?: 'contact_entries';
  /** An array relationship */
  contact_assignments: Array<Contact_Assignments>;
  /** An aggregate relationship */
  contact_assignments_aggregate: Contact_Assignments_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  do_not_contact?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  middle_name?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Array<Scalars['communication_types']>>;
  person_id?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['contact_role']>>;
  salutation?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};


/** columns and relationships of "contact_entries" */
export type Contact_EntriesContact_AssignmentsArgs = {
  distinct_on?: Maybe<Array<Contact_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Assignments_Order_By>>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};


/** columns and relationships of "contact_entries" */
export type Contact_EntriesContact_Assignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Assignments_Order_By>>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};

/** aggregated selection of "contact_entries" */
export type Contact_Entries_Aggregate = {
  __typename?: 'contact_entries_aggregate';
  aggregate?: Maybe<Contact_Entries_Aggregate_Fields>;
  nodes: Array<Contact_Entries>;
};

/** aggregate fields of "contact_entries" */
export type Contact_Entries_Aggregate_Fields = {
  __typename?: 'contact_entries_aggregate_fields';
  avg?: Maybe<Contact_Entries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Entries_Max_Fields>;
  min?: Maybe<Contact_Entries_Min_Fields>;
  stddev?: Maybe<Contact_Entries_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Entries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Entries_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Entries_Sum_Fields>;
  var_pop?: Maybe<Contact_Entries_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Entries_Var_Samp_Fields>;
  variance?: Maybe<Contact_Entries_Variance_Fields>;
};


/** aggregate fields of "contact_entries" */
export type Contact_Entries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Entries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Contact_Entries_Avg_Fields = {
  __typename?: 'contact_entries_avg_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "contact_entries". All fields are combined with a logical 'AND'. */
export type Contact_Entries_Bool_Exp = {
  _and?: Maybe<Array<Contact_Entries_Bool_Exp>>;
  _not?: Maybe<Contact_Entries_Bool_Exp>;
  _or?: Maybe<Array<Contact_Entries_Bool_Exp>>;
  contact_assignments?: Maybe<Contact_Assignments_Bool_Exp>;
  contact_assignments_aggregate?: Maybe<Contact_Assignments_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  do_not_contact?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  fax?: Maybe<String_Comparison_Exp>;
  given_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  middle_name?: Maybe<String_Comparison_Exp>;
  mobile_phone?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  opt_out?: Maybe<Communication_Types_Array_Comparison_Exp>;
  person_id?: Maybe<Int_Comparison_Exp>;
  roles?: Maybe<Contact_Role_Array_Comparison_Exp>;
  salutation?: Maybe<String_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<String_Comparison_Exp>;
  verification_token?: Maybe<String_Comparison_Exp>;
  work_phone?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_entries" */
export enum Contact_Entries_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactEntriesPkey = 'contact_entries_pkey',
  /** unique or primary key constraint on columns  */
  EmailIdxCe = 'email_idx_ce'
}

/** input type for incrementing numeric columns in table "contact_entries" */
export type Contact_Entries_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact_entries" */
export type Contact_Entries_Insert_Input = {
  contact_assignments?: Maybe<Contact_Assignments_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  do_not_contact?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  middle_name?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Array<Scalars['communication_types']>>;
  person_id?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['contact_role']>>;
  salutation?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contact_Entries_Max_Fields = {
  __typename?: 'contact_entries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  middle_name?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Array<Scalars['communication_types']>>;
  person_id?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['contact_role']>>;
  salutation?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Contact_Entries_Min_Fields = {
  __typename?: 'contact_entries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  middle_name?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Array<Scalars['communication_types']>>;
  person_id?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['contact_role']>>;
  salutation?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contact_entries" */
export type Contact_Entries_Mutation_Response = {
  __typename?: 'contact_entries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Entries>;
};

/** input type for inserting object relation for remote table "contact_entries" */
export type Contact_Entries_Obj_Rel_Insert_Input = {
  data: Contact_Entries_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Contact_Entries_On_Conflict>;
};

/** on_conflict condition type for table "contact_entries" */
export type Contact_Entries_On_Conflict = {
  constraint: Contact_Entries_Constraint;
  update_columns?: Array<Contact_Entries_Update_Column>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_entries". */
export type Contact_Entries_Order_By = {
  contact_assignments_aggregate?: Maybe<Contact_Assignments_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  do_not_contact?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fax?: Maybe<Order_By>;
  given_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile_phone?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  opt_out?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  roles?: Maybe<Order_By>;
  salutation?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  verification_token?: Maybe<Order_By>;
  work_phone?: Maybe<Order_By>;
};

/** primary key columns input for table: contact_entries */
export type Contact_Entries_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contact_entries" */
export enum Contact_Entries_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoNotContact = 'do_not_contact',
  /** column name */
  Email = 'email',
  /** column name */
  Fax = 'fax',
  /** column name */
  GivenName = 'given_name',
  /** column name */
  Id = 'id',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OptOut = 'opt_out',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  Roles = 'roles',
  /** column name */
  Salutation = 'salutation',
  /** column name */
  Surname = 'surname',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  VerificationToken = 'verification_token',
  /** column name */
  WorkPhone = 'work_phone'
}

/** input type for updating data in table "contact_entries" */
export type Contact_Entries_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  do_not_contact?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  middle_name?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Array<Scalars['communication_types']>>;
  person_id?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['contact_role']>>;
  salutation?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Contact_Entries_Stddev_Fields = {
  __typename?: 'contact_entries_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Contact_Entries_Stddev_Pop_Fields = {
  __typename?: 'contact_entries_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Contact_Entries_Stddev_Samp_Fields = {
  __typename?: 'contact_entries_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "contact_entries" */
export type Contact_Entries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Entries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Entries_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  do_not_contact?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  middle_name?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Array<Scalars['communication_types']>>;
  person_id?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['contact_role']>>;
  salutation?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Contact_Entries_Sum_Fields = {
  __typename?: 'contact_entries_sum_fields';
  id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "contact_entries" */
export enum Contact_Entries_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoNotContact = 'do_not_contact',
  /** column name */
  Email = 'email',
  /** column name */
  Fax = 'fax',
  /** column name */
  GivenName = 'given_name',
  /** column name */
  Id = 'id',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OptOut = 'opt_out',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  Roles = 'roles',
  /** column name */
  Salutation = 'salutation',
  /** column name */
  Surname = 'surname',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  VerificationToken = 'verification_token',
  /** column name */
  WorkPhone = 'work_phone'
}

export type Contact_Entries_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Contact_Entries_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Entries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Entries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contact_Entries_Var_Pop_Fields = {
  __typename?: 'contact_entries_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Contact_Entries_Var_Samp_Fields = {
  __typename?: 'contact_entries_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Contact_Entries_Variance_Fields = {
  __typename?: 'contact_entries_variance_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "contact_role". All fields are combined with logical 'AND'. */
export type Contact_Role_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars['contact_role']>>;
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars['contact_role']>>;
  _eq?: Maybe<Array<Scalars['contact_role']>>;
  _gt?: Maybe<Array<Scalars['contact_role']>>;
  _gte?: Maybe<Array<Scalars['contact_role']>>;
  _in?: Maybe<Array<Array<Scalars['contact_role']>>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Array<Scalars['contact_role']>>;
  _lte?: Maybe<Array<Scalars['contact_role']>>;
  _neq?: Maybe<Array<Scalars['contact_role']>>;
  _nin?: Maybe<Array<Array<Scalars['contact_role']>>>;
};

/** columns and relationships of "contacts" */
export type Contacts = {
  __typename?: 'contacts';
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  email_address?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** aggregated selection of "contacts" */
export type Contacts_Aggregate = {
  __typename?: 'contacts_aggregate';
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

export type Contacts_Aggregate_Bool_Exp = {
  count?: Maybe<Contacts_Aggregate_Bool_Exp_Count>;
};

export type Contacts_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Contacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_Fields = {
  __typename?: 'contacts_aggregate_fields';
  avg?: Maybe<Contacts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
  stddev?: Maybe<Contacts_Stddev_Fields>;
  stddev_pop?: Maybe<Contacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contacts_Stddev_Samp_Fields>;
  sum?: Maybe<Contacts_Sum_Fields>;
  var_pop?: Maybe<Contacts_Var_Pop_Fields>;
  var_samp?: Maybe<Contacts_Var_Samp_Fields>;
  variance?: Maybe<Contacts_Variance_Fields>;
};


/** aggregate fields of "contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contacts" */
export type Contacts_Aggregate_Order_By = {
  avg?: Maybe<Contacts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Contacts_Max_Order_By>;
  min?: Maybe<Contacts_Min_Order_By>;
  stddev?: Maybe<Contacts_Stddev_Order_By>;
  stddev_pop?: Maybe<Contacts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Contacts_Stddev_Samp_Order_By>;
  sum?: Maybe<Contacts_Sum_Order_By>;
  var_pop?: Maybe<Contacts_Var_Pop_Order_By>;
  var_samp?: Maybe<Contacts_Var_Samp_Order_By>;
  variance?: Maybe<Contacts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contacts" */
export type Contacts_Arr_Rel_Insert_Input = {
  data: Array<Contacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Contacts_On_Conflict>;
};

/** aggregate avg on columns */
export type Contacts_Avg_Fields = {
  __typename?: 'contacts_avg_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contacts" */
export type Contacts_Avg_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  _and?: Maybe<Array<Contacts_Bool_Exp>>;
  _not?: Maybe<Contacts_Bool_Exp>;
  _or?: Maybe<Array<Contacts_Bool_Exp>>;
  contact_entry_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  email_address?: Maybe<String_Comparison_Exp>;
  fax?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  mobile_phone?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  work_phone?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactsPkey = 'contacts_pkey'
}

/** input type for incrementing numeric columns in table "contacts" */
export type Contacts_Inc_Input = {
  contact_entry_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contacts" */
export type Contacts_Insert_Input = {
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  email_address?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  __typename?: 'contacts_max_fields';
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  email_address?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contacts" */
export type Contacts_Max_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email_address?: Maybe<Order_By>;
  fax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mobile_phone?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  work_phone?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  __typename?: 'contacts_min_fields';
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  email_address?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contacts" */
export type Contacts_Min_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email_address?: Maybe<Order_By>;
  fax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mobile_phone?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  work_phone?: Maybe<Order_By>;
};

/** response of any mutation on the table "contacts" */
export type Contacts_Mutation_Response = {
  __typename?: 'contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contacts>;
};

/** on_conflict condition type for table "contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns?: Array<Contacts_Update_Column>;
  where?: Maybe<Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "contacts". */
export type Contacts_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  email_address?: Maybe<Order_By>;
  fax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mobile_phone?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  work_phone?: Maybe<Order_By>;
};

/** primary key columns input for table: contacts */
export type Contacts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contacts" */
export enum Contacts_Select_Column {
  /** column name */
  ContactEntryId = 'contact_entry_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  Name = 'name',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPhone = 'work_phone'
}

/** input type for updating data in table "contacts" */
export type Contacts_Set_Input = {
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  email_address?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Contacts_Stddev_Fields = {
  __typename?: 'contacts_stddev_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contacts" */
export type Contacts_Stddev_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contacts_Stddev_Pop_Fields = {
  __typename?: 'contacts_stddev_pop_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contacts" */
export type Contacts_Stddev_Pop_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contacts_Stddev_Samp_Fields = {
  __typename?: 'contacts_stddev_samp_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contacts" */
export type Contacts_Stddev_Samp_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "contacts" */
export type Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contacts_Stream_Cursor_Value_Input = {
  contact_entry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  email_address?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  work_phone?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Contacts_Sum_Fields = {
  __typename?: 'contacts_sum_fields';
  contact_entry_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contacts" */
export type Contacts_Sum_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "contacts" */
export enum Contacts_Update_Column {
  /** column name */
  ContactEntryId = 'contact_entry_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  Name = 'name',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPhone = 'work_phone'
}

export type Contacts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Contacts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contacts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contacts_Var_Pop_Fields = {
  __typename?: 'contacts_var_pop_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contacts" */
export type Contacts_Var_Pop_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contacts_Var_Samp_Fields = {
  __typename?: 'contacts_var_samp_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contacts" */
export type Contacts_Var_Samp_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Contacts_Variance_Fields = {
  __typename?: 'contacts_variance_fields';
  contact_entry_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contacts" */
export type Contacts_Variance_Order_By = {
  contact_entry_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customer_categories" */
export type Customer_Categories = {
  __typename?: 'customer_categories';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "customer_categories" */
export type Customer_Categories_Aggregate = {
  __typename?: 'customer_categories_aggregate';
  aggregate?: Maybe<Customer_Categories_Aggregate_Fields>;
  nodes: Array<Customer_Categories>;
};

/** aggregate fields of "customer_categories" */
export type Customer_Categories_Aggregate_Fields = {
  __typename?: 'customer_categories_aggregate_fields';
  avg?: Maybe<Customer_Categories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Categories_Max_Fields>;
  min?: Maybe<Customer_Categories_Min_Fields>;
  stddev?: Maybe<Customer_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Categories_Sum_Fields>;
  var_pop?: Maybe<Customer_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Categories_Var_Samp_Fields>;
  variance?: Maybe<Customer_Categories_Variance_Fields>;
};


/** aggregate fields of "customer_categories" */
export type Customer_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customer_Categories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Customer_Categories_Avg_Fields = {
  __typename?: 'customer_categories_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "customer_categories". All fields are combined with a logical 'AND'. */
export type Customer_Categories_Bool_Exp = {
  _and?: Maybe<Array<Customer_Categories_Bool_Exp>>;
  _not?: Maybe<Customer_Categories_Bool_Exp>;
  _or?: Maybe<Array<Customer_Categories_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_categories" */
export enum Customer_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerCategoriesPkey = 'customer_categories_pkey'
}

/** input type for incrementing numeric columns in table "customer_categories" */
export type Customer_Categories_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_categories" */
export type Customer_Categories_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Customer_Categories_Max_Fields = {
  __typename?: 'customer_categories_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Customer_Categories_Min_Fields = {
  __typename?: 'customer_categories_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "customer_categories" */
export type Customer_Categories_Mutation_Response = {
  __typename?: 'customer_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Categories>;
};

/** input type for inserting object relation for remote table "customer_categories" */
export type Customer_Categories_Obj_Rel_Insert_Input = {
  data: Customer_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Customer_Categories_On_Conflict>;
};

/** on_conflict condition type for table "customer_categories" */
export type Customer_Categories_On_Conflict = {
  constraint: Customer_Categories_Constraint;
  update_columns?: Array<Customer_Categories_Update_Column>;
  where?: Maybe<Customer_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_categories". */
export type Customer_Categories_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: customer_categories */
export type Customer_Categories_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_categories" */
export enum Customer_Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "customer_categories" */
export type Customer_Categories_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Customer_Categories_Stddev_Fields = {
  __typename?: 'customer_categories_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Categories_Stddev_Pop_Fields = {
  __typename?: 'customer_categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Categories_Stddev_Samp_Fields = {
  __typename?: 'customer_categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "customer_categories" */
export type Customer_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Categories_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Customer_Categories_Sum_Fields = {
  __typename?: 'customer_categories_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "customer_categories" */
export enum Customer_Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Customer_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Customer_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customer_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Categories_Var_Pop_Fields = {
  __typename?: 'customer_categories_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Categories_Var_Samp_Fields = {
  __typename?: 'customer_categories_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Categories_Variance_Fields = {
  __typename?: 'customer_categories_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "customer_documents_view" */
export type Customer_Documents_View = {
  __typename?: 'customer_documents_view';
  active_storage_blob_id?: Maybe<Scalars['bigint']>;
  active_storage_created_at?: Maybe<Scalars['timestamp']>;
  active_storage_id?: Maybe<Scalars['bigint']>;
  active_storage_name?: Maybe<Scalars['String']>;
  blob_created_at?: Maybe<Scalars['timestamp']>;
  blob_id?: Maybe<Scalars['bigint']>;
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_document_id?: Maybe<Scalars['bigint']>;
  customer_id?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  record_id?: Maybe<Scalars['bigint']>;
  record_type?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "customer_documents_view" */
export type Customer_Documents_View_Aggregate = {
  __typename?: 'customer_documents_view_aggregate';
  aggregate?: Maybe<Customer_Documents_View_Aggregate_Fields>;
  nodes: Array<Customer_Documents_View>;
};

export type Customer_Documents_View_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Customer_Documents_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Customer_Documents_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Customer_Documents_View_Aggregate_Bool_Exp_Count>;
};

export type Customer_Documents_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Documents_View_Select_Column_Customer_Documents_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customer_Documents_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Documents_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Documents_View_Select_Column_Customer_Documents_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customer_Documents_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Documents_View_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Customer_Documents_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customer_Documents_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_documents_view" */
export type Customer_Documents_View_Aggregate_Fields = {
  __typename?: 'customer_documents_view_aggregate_fields';
  avg?: Maybe<Customer_Documents_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Documents_View_Max_Fields>;
  min?: Maybe<Customer_Documents_View_Min_Fields>;
  stddev?: Maybe<Customer_Documents_View_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Documents_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Documents_View_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Documents_View_Sum_Fields>;
  var_pop?: Maybe<Customer_Documents_View_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Documents_View_Var_Samp_Fields>;
  variance?: Maybe<Customer_Documents_View_Variance_Fields>;
};


/** aggregate fields of "customer_documents_view" */
export type Customer_Documents_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customer_Documents_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_documents_view" */
export type Customer_Documents_View_Aggregate_Order_By = {
  avg?: Maybe<Customer_Documents_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customer_Documents_View_Max_Order_By>;
  min?: Maybe<Customer_Documents_View_Min_Order_By>;
  stddev?: Maybe<Customer_Documents_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Customer_Documents_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customer_Documents_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Customer_Documents_View_Sum_Order_By>;
  var_pop?: Maybe<Customer_Documents_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Customer_Documents_View_Var_Samp_Order_By>;
  variance?: Maybe<Customer_Documents_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_documents_view" */
export type Customer_Documents_View_Arr_Rel_Insert_Input = {
  data: Array<Customer_Documents_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Customer_Documents_View_Avg_Fields = {
  __typename?: 'customer_documents_view_avg_fields';
  active_storage_blob_id?: Maybe<Scalars['Float']>;
  active_storage_id?: Maybe<Scalars['Float']>;
  blob_id?: Maybe<Scalars['Float']>;
  byte_size?: Maybe<Scalars['Float']>;
  customer_document_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  record_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Avg_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_documents_view". All fields are combined with a logical 'AND'. */
export type Customer_Documents_View_Bool_Exp = {
  _and?: Maybe<Array<Customer_Documents_View_Bool_Exp>>;
  _not?: Maybe<Customer_Documents_View_Bool_Exp>;
  _or?: Maybe<Array<Customer_Documents_View_Bool_Exp>>;
  active_storage_blob_id?: Maybe<Bigint_Comparison_Exp>;
  active_storage_created_at?: Maybe<Timestamp_Comparison_Exp>;
  active_storage_id?: Maybe<Bigint_Comparison_Exp>;
  active_storage_name?: Maybe<String_Comparison_Exp>;
  blob_created_at?: Maybe<Timestamp_Comparison_Exp>;
  blob_id?: Maybe<Bigint_Comparison_Exp>;
  byte_size?: Maybe<Bigint_Comparison_Exp>;
  checksum?: Maybe<String_Comparison_Exp>;
  content_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_document_id?: Maybe<Bigint_Comparison_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  record_id?: Maybe<Bigint_Comparison_Exp>;
  record_type?: Maybe<String_Comparison_Exp>;
  service_name?: Maybe<String_Comparison_Exp>;
  stage_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "customer_documents_view" */
export type Customer_Documents_View_Insert_Input = {
  active_storage_blob_id?: Maybe<Scalars['bigint']>;
  active_storage_created_at?: Maybe<Scalars['timestamp']>;
  active_storage_id?: Maybe<Scalars['bigint']>;
  active_storage_name?: Maybe<Scalars['String']>;
  blob_created_at?: Maybe<Scalars['timestamp']>;
  blob_id?: Maybe<Scalars['bigint']>;
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_document_id?: Maybe<Scalars['bigint']>;
  customer_id?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  record_id?: Maybe<Scalars['bigint']>;
  record_type?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Customer_Documents_View_Max_Fields = {
  __typename?: 'customer_documents_view_max_fields';
  active_storage_blob_id?: Maybe<Scalars['bigint']>;
  active_storage_created_at?: Maybe<Scalars['timestamp']>;
  active_storage_id?: Maybe<Scalars['bigint']>;
  active_storage_name?: Maybe<Scalars['String']>;
  blob_created_at?: Maybe<Scalars['timestamp']>;
  blob_id?: Maybe<Scalars['bigint']>;
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_document_id?: Maybe<Scalars['bigint']>;
  customer_id?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  record_id?: Maybe<Scalars['bigint']>;
  record_type?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Max_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_created_at?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  active_storage_name?: Maybe<Order_By>;
  blob_created_at?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  checksum?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  record_type?: Maybe<Order_By>;
  service_name?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Documents_View_Min_Fields = {
  __typename?: 'customer_documents_view_min_fields';
  active_storage_blob_id?: Maybe<Scalars['bigint']>;
  active_storage_created_at?: Maybe<Scalars['timestamp']>;
  active_storage_id?: Maybe<Scalars['bigint']>;
  active_storage_name?: Maybe<Scalars['String']>;
  blob_created_at?: Maybe<Scalars['timestamp']>;
  blob_id?: Maybe<Scalars['bigint']>;
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_document_id?: Maybe<Scalars['bigint']>;
  customer_id?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  record_id?: Maybe<Scalars['bigint']>;
  record_type?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Min_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_created_at?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  active_storage_name?: Maybe<Order_By>;
  blob_created_at?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  checksum?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  record_type?: Maybe<Order_By>;
  service_name?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** input type for inserting object relation for remote table "customer_documents_view" */
export type Customer_Documents_View_Obj_Rel_Insert_Input = {
  data: Customer_Documents_View_Insert_Input;
};

/** Ordering options when selecting data from "customer_documents_view". */
export type Customer_Documents_View_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_created_at?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  active_storage_name?: Maybe<Order_By>;
  blob_created_at?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  checksum?: Maybe<Order_By>;
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  record_type?: Maybe<Order_By>;
  service_name?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** select columns of table "customer_documents_view" */
export enum Customer_Documents_View_Select_Column {
  /** column name */
  ActiveStorageBlobId = 'active_storage_blob_id',
  /** column name */
  ActiveStorageCreatedAt = 'active_storage_created_at',
  /** column name */
  ActiveStorageId = 'active_storage_id',
  /** column name */
  ActiveStorageName = 'active_storage_name',
  /** column name */
  BlobCreatedAt = 'blob_created_at',
  /** column name */
  BlobId = 'blob_id',
  /** column name */
  ByteSize = 'byte_size',
  /** column name */
  Checksum = 'checksum',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerDocumentId = 'customer_document_id',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Key = 'key',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  RecordId = 'record_id',
  /** column name */
  RecordType = 'record_type',
  /** column name */
  ServiceName = 'service_name',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** select "customer_documents_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_documents_view" */
export enum Customer_Documents_View_Select_Column_Customer_Documents_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPublic = 'is_public'
}

/** select "customer_documents_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_documents_view" */
export enum Customer_Documents_View_Select_Column_Customer_Documents_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPublic = 'is_public'
}

/** aggregate stddev on columns */
export type Customer_Documents_View_Stddev_Fields = {
  __typename?: 'customer_documents_view_stddev_fields';
  active_storage_blob_id?: Maybe<Scalars['Float']>;
  active_storage_id?: Maybe<Scalars['Float']>;
  blob_id?: Maybe<Scalars['Float']>;
  byte_size?: Maybe<Scalars['Float']>;
  customer_document_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  record_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Stddev_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Documents_View_Stddev_Pop_Fields = {
  __typename?: 'customer_documents_view_stddev_pop_fields';
  active_storage_blob_id?: Maybe<Scalars['Float']>;
  active_storage_id?: Maybe<Scalars['Float']>;
  blob_id?: Maybe<Scalars['Float']>;
  byte_size?: Maybe<Scalars['Float']>;
  customer_document_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  record_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Stddev_Pop_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Documents_View_Stddev_Samp_Fields = {
  __typename?: 'customer_documents_view_stddev_samp_fields';
  active_storage_blob_id?: Maybe<Scalars['Float']>;
  active_storage_id?: Maybe<Scalars['Float']>;
  blob_id?: Maybe<Scalars['Float']>;
  byte_size?: Maybe<Scalars['Float']>;
  customer_document_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  record_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Stddev_Samp_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customer_documents_view" */
export type Customer_Documents_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Documents_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Documents_View_Stream_Cursor_Value_Input = {
  active_storage_blob_id?: Maybe<Scalars['bigint']>;
  active_storage_created_at?: Maybe<Scalars['timestamp']>;
  active_storage_id?: Maybe<Scalars['bigint']>;
  active_storage_name?: Maybe<Scalars['String']>;
  blob_created_at?: Maybe<Scalars['timestamp']>;
  blob_id?: Maybe<Scalars['bigint']>;
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_document_id?: Maybe<Scalars['bigint']>;
  customer_id?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  record_id?: Maybe<Scalars['bigint']>;
  record_type?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Documents_View_Sum_Fields = {
  __typename?: 'customer_documents_view_sum_fields';
  active_storage_blob_id?: Maybe<Scalars['bigint']>;
  active_storage_id?: Maybe<Scalars['bigint']>;
  blob_id?: Maybe<Scalars['bigint']>;
  byte_size?: Maybe<Scalars['bigint']>;
  customer_document_id?: Maybe<Scalars['bigint']>;
  customer_id?: Maybe<Scalars['Int']>;
  record_id?: Maybe<Scalars['bigint']>;
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Sum_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Customer_Documents_View_Var_Pop_Fields = {
  __typename?: 'customer_documents_view_var_pop_fields';
  active_storage_blob_id?: Maybe<Scalars['Float']>;
  active_storage_id?: Maybe<Scalars['Float']>;
  blob_id?: Maybe<Scalars['Float']>;
  byte_size?: Maybe<Scalars['Float']>;
  customer_document_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  record_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Var_Pop_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Documents_View_Var_Samp_Fields = {
  __typename?: 'customer_documents_view_var_samp_fields';
  active_storage_blob_id?: Maybe<Scalars['Float']>;
  active_storage_id?: Maybe<Scalars['Float']>;
  blob_id?: Maybe<Scalars['Float']>;
  byte_size?: Maybe<Scalars['Float']>;
  customer_document_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  record_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Var_Samp_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Documents_View_Variance_Fields = {
  __typename?: 'customer_documents_view_variance_fields';
  active_storage_blob_id?: Maybe<Scalars['Float']>;
  active_storage_id?: Maybe<Scalars['Float']>;
  blob_id?: Maybe<Scalars['Float']>;
  byte_size?: Maybe<Scalars['Float']>;
  customer_document_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  record_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_documents_view" */
export type Customer_Documents_View_Variance_Order_By = {
  active_storage_blob_id?: Maybe<Order_By>;
  active_storage_id?: Maybe<Order_By>;
  blob_id?: Maybe<Order_By>;
  byte_size?: Maybe<Order_By>;
  customer_document_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  record_id?: Maybe<Order_By>;
  stage_id?: Maybe<Order_By>;
};

/** columns and relationships of "customer_groups" */
export type Customer_Groups = {
  __typename?: 'customer_groups';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  group?: Maybe<Groups>;
  group_id?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "customer_groups" */
export type Customer_Groups_Aggregate = {
  __typename?: 'customer_groups_aggregate';
  aggregate?: Maybe<Customer_Groups_Aggregate_Fields>;
  nodes: Array<Customer_Groups>;
};

export type Customer_Groups_Aggregate_Bool_Exp = {
  count?: Maybe<Customer_Groups_Aggregate_Bool_Exp_Count>;
};

export type Customer_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Customer_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customer_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_groups" */
export type Customer_Groups_Aggregate_Fields = {
  __typename?: 'customer_groups_aggregate_fields';
  avg?: Maybe<Customer_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Groups_Max_Fields>;
  min?: Maybe<Customer_Groups_Min_Fields>;
  stddev?: Maybe<Customer_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Groups_Sum_Fields>;
  var_pop?: Maybe<Customer_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Groups_Var_Samp_Fields>;
  variance?: Maybe<Customer_Groups_Variance_Fields>;
};


/** aggregate fields of "customer_groups" */
export type Customer_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customer_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_groups" */
export type Customer_Groups_Aggregate_Order_By = {
  avg?: Maybe<Customer_Groups_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customer_Groups_Max_Order_By>;
  min?: Maybe<Customer_Groups_Min_Order_By>;
  stddev?: Maybe<Customer_Groups_Stddev_Order_By>;
  stddev_pop?: Maybe<Customer_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customer_Groups_Stddev_Samp_Order_By>;
  sum?: Maybe<Customer_Groups_Sum_Order_By>;
  var_pop?: Maybe<Customer_Groups_Var_Pop_Order_By>;
  var_samp?: Maybe<Customer_Groups_Var_Samp_Order_By>;
  variance?: Maybe<Customer_Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_groups" */
export type Customer_Groups_Arr_Rel_Insert_Input = {
  data: Array<Customer_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Customer_Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Groups_Avg_Fields = {
  __typename?: 'customer_groups_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_groups" */
export type Customer_Groups_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_groups". All fields are combined with a logical 'AND'. */
export type Customer_Groups_Bool_Exp = {
  _and?: Maybe<Array<Customer_Groups_Bool_Exp>>;
  _not?: Maybe<Customer_Groups_Bool_Exp>;
  _or?: Maybe<Array<Customer_Groups_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  group?: Maybe<Groups_Bool_Exp>;
  group_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_groups" */
export enum Customer_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerGroupsPkey = 'customer_groups_pkey'
}

/** input type for incrementing numeric columns in table "customer_groups" */
export type Customer_Groups_Inc_Input = {
  customer_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_groups" */
export type Customer_Groups_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  group?: Maybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Customer_Groups_Max_Fields = {
  __typename?: 'customer_groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "customer_groups" */
export type Customer_Groups_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Groups_Min_Fields = {
  __typename?: 'customer_groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "customer_groups" */
export type Customer_Groups_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "customer_groups" */
export type Customer_Groups_Mutation_Response = {
  __typename?: 'customer_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Groups>;
};

/** on_conflict condition type for table "customer_groups" */
export type Customer_Groups_On_Conflict = {
  constraint: Customer_Groups_Constraint;
  update_columns?: Array<Customer_Groups_Update_Column>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_groups". */
export type Customer_Groups_Order_By = {
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  group?: Maybe<Groups_Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: customer_groups */
export type Customer_Groups_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_groups" */
export enum Customer_Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "customer_groups" */
export type Customer_Groups_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Customer_Groups_Stddev_Fields = {
  __typename?: 'customer_groups_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_groups" */
export type Customer_Groups_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Groups_Stddev_Pop_Fields = {
  __typename?: 'customer_groups_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_groups" */
export type Customer_Groups_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Groups_Stddev_Samp_Fields = {
  __typename?: 'customer_groups_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_groups" */
export type Customer_Groups_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customer_groups" */
export type Customer_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Groups_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Customer_Groups_Sum_Fields = {
  __typename?: 'customer_groups_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_groups" */
export type Customer_Groups_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "customer_groups" */
export enum Customer_Groups_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Customer_Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Customer_Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customer_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Groups_Var_Pop_Fields = {
  __typename?: 'customer_groups_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_groups" */
export type Customer_Groups_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Groups_Var_Samp_Fields = {
  __typename?: 'customer_groups_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_groups" */
export type Customer_Groups_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Groups_Variance_Fields = {
  __typename?: 'customer_groups_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_groups" */
export type Customer_Groups_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "customers" */
export type Customers = {
  __typename?: 'customers';
  account_number?: Maybe<Scalars['String']>;
  account_number_ciphertext?: Maybe<Scalars['String']>;
  ach_account?: Maybe<Scalars['String']>;
  ach_on_file?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  ach_queues: Array<Ach_Queues>;
  /** An aggregate relationship */
  ach_queues_aggregate: Ach_Queues_Aggregate;
  ach_routing?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  address?: Maybe<Addresses>;
  address_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  addresses: Array<Addresses>;
  /** An aggregate relationship */
  addresses_aggregate: Addresses_Aggregate;
  aggregate?: Maybe<Scalars['Boolean']>;
  annual_dth?: Maybe<Scalars['float8']>;
  autopay?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  balance_dues: Array<Balance_Dues>;
  /** An aggregate relationship */
  balance_dues_aggregate: Balance_Dues_Aggregate;
  base_contract_date?: Maybe<Scalars['date']>;
  bill_day?: Maybe<Scalars['Int']>;
  /** An object relationship */
  company?: Maybe<Indices>;
  company_id?: Maybe<Scalars['Int']>;
  connect_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  contact_assignments: Array<Contact_Assignments>;
  /** An aggregate relationship */
  contact_assignments_aggregate: Contact_Assignments_Aggregate;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  contract_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer_category?: Maybe<Customer_Categories>;
  customer_category_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  customer_documents_view: Array<Customer_Documents_View>;
  /** An aggregate relationship */
  customer_documents_view_aggregate: Customer_Documents_View_Aggregate;
  /** An array relationship */
  customer_groups: Array<Customer_Groups>;
  /** An aggregate relationship */
  customer_groups_aggregate: Customer_Groups_Aggregate;
  /** An array relationship */
  customers_users: Array<Customers_Users>;
  /** An aggregate relationship */
  customers_users_aggregate: Customers_Users_Aggregate;
  /** An array relationship */
  deal_detail_volumes_sum_by_beg_date: Array<Deal_Detail_Volumes_Sum_By_Beg_Date>;
  /** An aggregate relationship */
  deal_detail_volumes_sum_by_beg_date_aggregate: Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate;
  /** An array relationship */
  deal_details_preview: Array<Deals_Detail_Preview>;
  /** An aggregate relationship */
  deal_details_preview_aggregate: Deals_Detail_Preview_Aggregate;
  /** An object relationship */
  dealmaker?: Maybe<Dealmakers>;
  dealmaker_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  deals: Array<Deals>;
  /** An aggregate relationship */
  deals_aggregate: Deals_Aggregate;
  duns_number?: Maybe<Scalars['String']>;
  end_month?: Maybe<Scalars['date']>;
  end_user?: Maybe<Scalars['Boolean']>;
  fr_req?: Maybe<Scalars['float8']>;
  group_fee?: Maybe<Scalars['float8']>;
  group_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  invitations_customers: Array<Invitations_Customers>;
  /** An aggregate relationship */
  invitations_customers_aggregate: Invitations_Customers_Aggregate;
  invite_status?: Maybe<Scalars['invite_status']>;
  /** An array relationship */
  invoice_aggregates: Array<Invoice_Aggregate>;
  /** An aggregate relationship */
  invoice_aggregates_aggregate: Invoice_Aggregate_Aggregate;
  /** An array relationship */
  invoice_data: Array<Invoice_Data>;
  /** An aggregate relationship */
  invoice_data_aggregate: Invoice_Data_Aggregate;
  /** An array relationship */
  invoice_data_sum_by_month: Array<Invoice_Data_Sum_By_Month>;
  /** An aggregate relationship */
  invoice_data_sum_by_month_aggregate: Invoice_Data_Sum_By_Month_Aggregate;
  /** An array relationship */
  invoice_numbers: Array<Invoice_Numbers>;
  /** An aggregate relationship */
  invoice_numbers_aggregate: Invoice_Numbers_Aggregate;
  invoicebyemail?: Maybe<Scalars['Boolean']>;
  invoicebymail?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  ldc_accounts_view: Array<Ldc_Accounts_View>;
  /** An aggregate relationship */
  ldc_accounts_view_aggregate: Ldc_Accounts_View_Aggregate;
  mdq?: Maybe<Scalars['float8']>;
  naesb?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  named_insured?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Scalars['Boolean']>;
  person_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  /** An object relationship */
  primary_contact?: Maybe<Contact_Entries>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  records: Array<Records>;
  /** An aggregate relationship */
  records_aggregate: Records_Aggregate;
  routing_number?: Maybe<Scalars['String']>;
  routing_number_ciphertext?: Maybe<Scalars['String']>;
  send_invoices?: Maybe<Scalars['Boolean']>;
  start_month?: Maybe<Scalars['date']>;
  suger_id?: Maybe<Scalars['String']>;
  summer_avg?: Maybe<Scalars['float8']>;
  tax_exempt?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_billing_name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verification_email?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
  waive_late_fee?: Maybe<Scalars['Boolean']>;
  waive_mail_fee?: Maybe<Scalars['Boolean']>;
  wholesale?: Maybe<Scalars['Boolean']>;
  winter_max?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "customers" */
export type CustomersAch_QueuesArgs = {
  distinct_on?: Maybe<Array<Ach_Queues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ach_Queues_Order_By>>;
  where?: Maybe<Ach_Queues_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersAch_Queues_AggregateArgs = {
  distinct_on?: Maybe<Array<Ach_Queues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ach_Queues_Order_By>>;
  where?: Maybe<Ach_Queues_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersAddressesArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersBalance_DuesArgs = {
  distinct_on?: Maybe<Array<Balance_Dues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balance_Dues_Order_By>>;
  where?: Maybe<Balance_Dues_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersBalance_Dues_AggregateArgs = {
  distinct_on?: Maybe<Array<Balance_Dues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balance_Dues_Order_By>>;
  where?: Maybe<Balance_Dues_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersContact_AssignmentsArgs = {
  distinct_on?: Maybe<Array<Contact_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Assignments_Order_By>>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersContact_Assignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Assignments_Order_By>>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomer_Documents_ViewArgs = {
  distinct_on?: Maybe<Array<Customer_Documents_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Documents_View_Order_By>>;
  where?: Maybe<Customer_Documents_View_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomer_Documents_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Documents_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Documents_View_Order_By>>;
  where?: Maybe<Customer_Documents_View_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomer_GroupsArgs = {
  distinct_on?: Maybe<Array<Customer_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Groups_Order_By>>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomer_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Groups_Order_By>>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomers_UsersArgs = {
  distinct_on?: Maybe<Array<Customers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Users_Order_By>>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomers_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Users_Order_By>>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersDeal_Detail_Volumes_Sum_By_Beg_DateArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersDeal_Detail_Volumes_Sum_By_Beg_Date_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersDeal_Details_PreviewArgs = {
  distinct_on?: Maybe<Array<Deals_Detail_Preview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Detail_Preview_Order_By>>;
  where?: Maybe<Deals_Detail_Preview_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersDeal_Details_Preview_AggregateArgs = {
  distinct_on?: Maybe<Array<Deals_Detail_Preview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Detail_Preview_Order_By>>;
  where?: Maybe<Deals_Detail_Preview_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersDealsArgs = {
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersDeals_AggregateArgs = {
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvitations_CustomersArgs = {
  distinct_on?: Maybe<Array<Invitations_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Customers_Order_By>>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvitations_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitations_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Customers_Order_By>>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvoice_AggregatesArgs = {
  distinct_on?: Maybe<Array<Invoice_Aggregate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Aggregate_Order_By>>;
  where?: Maybe<Invoice_Aggregate_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvoice_Aggregates_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Aggregate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Aggregate_Order_By>>;
  where?: Maybe<Invoice_Aggregate_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvoice_DataArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvoice_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvoice_Data_Sum_By_MonthArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Sum_By_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Sum_By_Month_Order_By>>;
  where?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvoice_Data_Sum_By_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Sum_By_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Sum_By_Month_Order_By>>;
  where?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvoice_NumbersArgs = {
  distinct_on?: Maybe<Array<Invoice_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Numbers_Order_By>>;
  where?: Maybe<Invoice_Numbers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersInvoice_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Numbers_Order_By>>;
  where?: Maybe<Invoice_Numbers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersLdc_Accounts_ViewArgs = {
  distinct_on?: Maybe<Array<Ldc_Accounts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Accounts_View_Order_By>>;
  where?: Maybe<Ldc_Accounts_View_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersLdc_Accounts_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Ldc_Accounts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Accounts_View_Order_By>>;
  where?: Maybe<Ldc_Accounts_View_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersRecordsArgs = {
  distinct_on?: Maybe<Array<Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Records_Order_By>>;
  where?: Maybe<Records_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersRecords_AggregateArgs = {
  distinct_on?: Maybe<Array<Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Records_Order_By>>;
  where?: Maybe<Records_Bool_Exp>;
};

/** aggregated selection of "customers" */
export type Customers_Aggregate = {
  __typename?: 'customers_aggregate';
  aggregate?: Maybe<Customers_Aggregate_Fields>;
  nodes: Array<Customers>;
};

export type Customers_Aggregate_Bool_Exp = {
  avg?: Maybe<Customers_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Customers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Customers_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Customers_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Customers_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Customers_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Customers_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Customers_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Customers_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Customers_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Customers_Aggregate_Bool_Exp_Var_Samp>;
};

export type Customers_Aggregate_Bool_Exp_Avg = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Corr = {
  arguments: Customers_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Customers_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Customers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Customers_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Customers_Aggregate_Bool_Exp_Max = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Min = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Sum = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "customers" */
export type Customers_Aggregate_Fields = {
  __typename?: 'customers_aggregate_fields';
  avg?: Maybe<Customers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customers_Max_Fields>;
  min?: Maybe<Customers_Min_Fields>;
  stddev?: Maybe<Customers_Stddev_Fields>;
  stddev_pop?: Maybe<Customers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customers_Stddev_Samp_Fields>;
  sum?: Maybe<Customers_Sum_Fields>;
  var_pop?: Maybe<Customers_Var_Pop_Fields>;
  var_samp?: Maybe<Customers_Var_Samp_Fields>;
  variance?: Maybe<Customers_Variance_Fields>;
};


/** aggregate fields of "customers" */
export type Customers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customers" */
export type Customers_Aggregate_Order_By = {
  avg?: Maybe<Customers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customers_Max_Order_By>;
  min?: Maybe<Customers_Min_Order_By>;
  stddev?: Maybe<Customers_Stddev_Order_By>;
  stddev_pop?: Maybe<Customers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customers_Stddev_Samp_Order_By>;
  sum?: Maybe<Customers_Sum_Order_By>;
  var_pop?: Maybe<Customers_Var_Pop_Order_By>;
  var_samp?: Maybe<Customers_Var_Samp_Order_By>;
  variance?: Maybe<Customers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customers" */
export type Customers_Arr_Rel_Insert_Input = {
  data: Array<Customers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Customers_On_Conflict>;
};

/** aggregate avg on columns */
export type Customers_Avg_Fields = {
  __typename?: 'customers_avg_fields';
  address_id?: Maybe<Scalars['Float']>;
  annual_dth?: Maybe<Scalars['Float']>;
  bill_day?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  connect_id?: Maybe<Scalars['Float']>;
  customer_category_id?: Maybe<Scalars['Float']>;
  dealmaker_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  summer_avg?: Maybe<Scalars['Float']>;
  winter_max?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customers" */
export type Customers_Avg_Order_By = {
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customers". All fields are combined with a logical 'AND'. */
export type Customers_Bool_Exp = {
  _and?: Maybe<Array<Customers_Bool_Exp>>;
  _not?: Maybe<Customers_Bool_Exp>;
  _or?: Maybe<Array<Customers_Bool_Exp>>;
  account_number?: Maybe<String_Comparison_Exp>;
  account_number_ciphertext?: Maybe<String_Comparison_Exp>;
  ach_account?: Maybe<String_Comparison_Exp>;
  ach_on_file?: Maybe<Boolean_Comparison_Exp>;
  ach_queues?: Maybe<Ach_Queues_Bool_Exp>;
  ach_queues_aggregate?: Maybe<Ach_Queues_Aggregate_Bool_Exp>;
  ach_routing?: Maybe<String_Comparison_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  address?: Maybe<Addresses_Bool_Exp>;
  address_id?: Maybe<Int_Comparison_Exp>;
  addresses?: Maybe<Addresses_Bool_Exp>;
  addresses_aggregate?: Maybe<Addresses_Aggregate_Bool_Exp>;
  aggregate?: Maybe<Boolean_Comparison_Exp>;
  annual_dth?: Maybe<Float8_Comparison_Exp>;
  autopay?: Maybe<Boolean_Comparison_Exp>;
  balance_dues?: Maybe<Balance_Dues_Bool_Exp>;
  balance_dues_aggregate?: Maybe<Balance_Dues_Aggregate_Bool_Exp>;
  base_contract_date?: Maybe<Date_Comparison_Exp>;
  bill_day?: Maybe<Int_Comparison_Exp>;
  company?: Maybe<Indices_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  connect_id?: Maybe<Int_Comparison_Exp>;
  contact_assignments?: Maybe<Contact_Assignments_Bool_Exp>;
  contact_assignments_aggregate?: Maybe<Contact_Assignments_Aggregate_Bool_Exp>;
  contacts?: Maybe<Contacts_Bool_Exp>;
  contacts_aggregate?: Maybe<Contacts_Aggregate_Bool_Exp>;
  contract_date?: Maybe<Date_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_category?: Maybe<Customer_Categories_Bool_Exp>;
  customer_category_id?: Maybe<Int_Comparison_Exp>;
  customer_documents_view?: Maybe<Customer_Documents_View_Bool_Exp>;
  customer_documents_view_aggregate?: Maybe<Customer_Documents_View_Aggregate_Bool_Exp>;
  customer_groups?: Maybe<Customer_Groups_Bool_Exp>;
  customer_groups_aggregate?: Maybe<Customer_Groups_Aggregate_Bool_Exp>;
  customers_users?: Maybe<Customers_Users_Bool_Exp>;
  customers_users_aggregate?: Maybe<Customers_Users_Aggregate_Bool_Exp>;
  deal_detail_volumes_sum_by_beg_date?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  deal_detail_volumes_sum_by_beg_date_aggregate?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp>;
  deal_details_preview?: Maybe<Deals_Detail_Preview_Bool_Exp>;
  deal_details_preview_aggregate?: Maybe<Deals_Detail_Preview_Aggregate_Bool_Exp>;
  dealmaker?: Maybe<Dealmakers_Bool_Exp>;
  dealmaker_id?: Maybe<Int_Comparison_Exp>;
  deals?: Maybe<Deals_Bool_Exp>;
  deals_aggregate?: Maybe<Deals_Aggregate_Bool_Exp>;
  duns_number?: Maybe<String_Comparison_Exp>;
  end_month?: Maybe<Date_Comparison_Exp>;
  end_user?: Maybe<Boolean_Comparison_Exp>;
  fr_req?: Maybe<Float8_Comparison_Exp>;
  group_fee?: Maybe<Float8_Comparison_Exp>;
  group_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invitations_customers?: Maybe<Invitations_Customers_Bool_Exp>;
  invitations_customers_aggregate?: Maybe<Invitations_Customers_Aggregate_Bool_Exp>;
  invite_status?: Maybe<Invite_Status_Comparison_Exp>;
  invoice_aggregates?: Maybe<Invoice_Aggregate_Bool_Exp>;
  invoice_aggregates_aggregate?: Maybe<Invoice_Aggregate_Aggregate_Bool_Exp>;
  invoice_data?: Maybe<Invoice_Data_Bool_Exp>;
  invoice_data_aggregate?: Maybe<Invoice_Data_Aggregate_Bool_Exp>;
  invoice_data_sum_by_month?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  invoice_data_sum_by_month_aggregate?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp>;
  invoice_numbers?: Maybe<Invoice_Numbers_Bool_Exp>;
  invoice_numbers_aggregate?: Maybe<Invoice_Numbers_Aggregate_Bool_Exp>;
  invoicebyemail?: Maybe<Boolean_Comparison_Exp>;
  invoicebymail?: Maybe<Boolean_Comparison_Exp>;
  ldc_accounts_view?: Maybe<Ldc_Accounts_View_Bool_Exp>;
  ldc_accounts_view_aggregate?: Maybe<Ldc_Accounts_View_Aggregate_Bool_Exp>;
  mdq?: Maybe<Float8_Comparison_Exp>;
  naesb?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  named_insured?: Maybe<Boolean_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  opt_out?: Maybe<Boolean_Comparison_Exp>;
  person_id?: Maybe<Int_Comparison_Exp>;
  pmt_terms?: Maybe<Int_Comparison_Exp>;
  primary_contact?: Maybe<Contact_Entries_Bool_Exp>;
  primary_contact_id?: Maybe<Int_Comparison_Exp>;
  records?: Maybe<Records_Bool_Exp>;
  records_aggregate?: Maybe<Records_Aggregate_Bool_Exp>;
  routing_number?: Maybe<String_Comparison_Exp>;
  routing_number_ciphertext?: Maybe<String_Comparison_Exp>;
  send_invoices?: Maybe<Boolean_Comparison_Exp>;
  start_month?: Maybe<Date_Comparison_Exp>;
  suger_id?: Maybe<String_Comparison_Exp>;
  summer_avg?: Maybe<Float8_Comparison_Exp>;
  tax_exempt?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  utility_billing_name?: Maybe<String_Comparison_Exp>;
  uuid?: Maybe<String_Comparison_Exp>;
  verification_email?: Maybe<String_Comparison_Exp>;
  verification_token?: Maybe<String_Comparison_Exp>;
  virtual?: Maybe<Boolean_Comparison_Exp>;
  waive_late_fee?: Maybe<Boolean_Comparison_Exp>;
  waive_mail_fee?: Maybe<Boolean_Comparison_Exp>;
  wholesale?: Maybe<Boolean_Comparison_Exp>;
  winter_max?: Maybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "customers" */
export enum Customers_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomersPkey = 'customers_pkey'
}

/** input type for incrementing numeric columns in table "customers" */
export type Customers_Inc_Input = {
  address_id?: Maybe<Scalars['Int']>;
  annual_dth?: Maybe<Scalars['float8']>;
  bill_day?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  connect_id?: Maybe<Scalars['Int']>;
  customer_category_id?: Maybe<Scalars['Int']>;
  dealmaker_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  group_fee?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  person_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  summer_avg?: Maybe<Scalars['float8']>;
  winter_max?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "customers" */
export type Customers_Insert_Input = {
  account_number?: Maybe<Scalars['String']>;
  account_number_ciphertext?: Maybe<Scalars['String']>;
  ach_account?: Maybe<Scalars['String']>;
  ach_on_file?: Maybe<Scalars['Boolean']>;
  ach_queues?: Maybe<Ach_Queues_Arr_Rel_Insert_Input>;
  ach_routing?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Addresses_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars['Int']>;
  addresses?: Maybe<Addresses_Arr_Rel_Insert_Input>;
  aggregate?: Maybe<Scalars['Boolean']>;
  annual_dth?: Maybe<Scalars['float8']>;
  autopay?: Maybe<Scalars['Boolean']>;
  balance_dues?: Maybe<Balance_Dues_Arr_Rel_Insert_Input>;
  base_contract_date?: Maybe<Scalars['date']>;
  bill_day?: Maybe<Scalars['Int']>;
  company?: Maybe<Indices_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  connect_id?: Maybe<Scalars['Int']>;
  contact_assignments?: Maybe<Contact_Assignments_Arr_Rel_Insert_Input>;
  contacts?: Maybe<Contacts_Arr_Rel_Insert_Input>;
  contract_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_category?: Maybe<Customer_Categories_Obj_Rel_Insert_Input>;
  customer_category_id?: Maybe<Scalars['Int']>;
  customer_documents_view?: Maybe<Customer_Documents_View_Arr_Rel_Insert_Input>;
  customer_groups?: Maybe<Customer_Groups_Arr_Rel_Insert_Input>;
  customers_users?: Maybe<Customers_Users_Arr_Rel_Insert_Input>;
  deal_detail_volumes_sum_by_beg_date?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Arr_Rel_Insert_Input>;
  deal_details_preview?: Maybe<Deals_Detail_Preview_Arr_Rel_Insert_Input>;
  dealmaker?: Maybe<Dealmakers_Obj_Rel_Insert_Input>;
  dealmaker_id?: Maybe<Scalars['Int']>;
  deals?: Maybe<Deals_Arr_Rel_Insert_Input>;
  duns_number?: Maybe<Scalars['String']>;
  end_month?: Maybe<Scalars['date']>;
  end_user?: Maybe<Scalars['Boolean']>;
  fr_req?: Maybe<Scalars['float8']>;
  group_fee?: Maybe<Scalars['float8']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invitations_customers?: Maybe<Invitations_Customers_Arr_Rel_Insert_Input>;
  invite_status?: Maybe<Scalars['invite_status']>;
  invoice_aggregates?: Maybe<Invoice_Aggregate_Arr_Rel_Insert_Input>;
  invoice_data?: Maybe<Invoice_Data_Arr_Rel_Insert_Input>;
  invoice_data_sum_by_month?: Maybe<Invoice_Data_Sum_By_Month_Arr_Rel_Insert_Input>;
  invoice_numbers?: Maybe<Invoice_Numbers_Arr_Rel_Insert_Input>;
  invoicebyemail?: Maybe<Scalars['Boolean']>;
  invoicebymail?: Maybe<Scalars['Boolean']>;
  ldc_accounts_view?: Maybe<Ldc_Accounts_View_Arr_Rel_Insert_Input>;
  mdq?: Maybe<Scalars['float8']>;
  naesb?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  named_insured?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Scalars['Boolean']>;
  person_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact?: Maybe<Contact_Entries_Obj_Rel_Insert_Input>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  records?: Maybe<Records_Arr_Rel_Insert_Input>;
  routing_number?: Maybe<Scalars['String']>;
  routing_number_ciphertext?: Maybe<Scalars['String']>;
  send_invoices?: Maybe<Scalars['Boolean']>;
  start_month?: Maybe<Scalars['date']>;
  suger_id?: Maybe<Scalars['String']>;
  summer_avg?: Maybe<Scalars['float8']>;
  tax_exempt?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_billing_name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verification_email?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
  waive_late_fee?: Maybe<Scalars['Boolean']>;
  waive_mail_fee?: Maybe<Scalars['Boolean']>;
  wholesale?: Maybe<Scalars['Boolean']>;
  winter_max?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Customers_Max_Fields = {
  __typename?: 'customers_max_fields';
  account_number?: Maybe<Scalars['String']>;
  account_number_ciphertext?: Maybe<Scalars['String']>;
  ach_account?: Maybe<Scalars['String']>;
  ach_routing?: Maybe<Scalars['String']>;
  address_id?: Maybe<Scalars['Int']>;
  annual_dth?: Maybe<Scalars['float8']>;
  base_contract_date?: Maybe<Scalars['date']>;
  bill_day?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  connect_id?: Maybe<Scalars['Int']>;
  contract_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_category_id?: Maybe<Scalars['Int']>;
  dealmaker_id?: Maybe<Scalars['Int']>;
  duns_number?: Maybe<Scalars['String']>;
  end_month?: Maybe<Scalars['date']>;
  fr_req?: Maybe<Scalars['float8']>;
  group_fee?: Maybe<Scalars['float8']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invite_status?: Maybe<Scalars['invite_status']>;
  mdq?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  routing_number?: Maybe<Scalars['String']>;
  routing_number_ciphertext?: Maybe<Scalars['String']>;
  start_month?: Maybe<Scalars['date']>;
  suger_id?: Maybe<Scalars['String']>;
  summer_avg?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_billing_name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verification_email?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  winter_max?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "customers" */
export type Customers_Max_Order_By = {
  account_number?: Maybe<Order_By>;
  account_number_ciphertext?: Maybe<Order_By>;
  ach_account?: Maybe<Order_By>;
  ach_routing?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  base_contract_date?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  contract_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  duns_number?: Maybe<Order_By>;
  end_month?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invite_status?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  routing_number?: Maybe<Order_By>;
  routing_number_ciphertext?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
  suger_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_billing_name?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  verification_email?: Maybe<Order_By>;
  verification_token?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Customers_Min_Fields = {
  __typename?: 'customers_min_fields';
  account_number?: Maybe<Scalars['String']>;
  account_number_ciphertext?: Maybe<Scalars['String']>;
  ach_account?: Maybe<Scalars['String']>;
  ach_routing?: Maybe<Scalars['String']>;
  address_id?: Maybe<Scalars['Int']>;
  annual_dth?: Maybe<Scalars['float8']>;
  base_contract_date?: Maybe<Scalars['date']>;
  bill_day?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  connect_id?: Maybe<Scalars['Int']>;
  contract_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_category_id?: Maybe<Scalars['Int']>;
  dealmaker_id?: Maybe<Scalars['Int']>;
  duns_number?: Maybe<Scalars['String']>;
  end_month?: Maybe<Scalars['date']>;
  fr_req?: Maybe<Scalars['float8']>;
  group_fee?: Maybe<Scalars['float8']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invite_status?: Maybe<Scalars['invite_status']>;
  mdq?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  routing_number?: Maybe<Scalars['String']>;
  routing_number_ciphertext?: Maybe<Scalars['String']>;
  start_month?: Maybe<Scalars['date']>;
  suger_id?: Maybe<Scalars['String']>;
  summer_avg?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_billing_name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verification_email?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  winter_max?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "customers" */
export type Customers_Min_Order_By = {
  account_number?: Maybe<Order_By>;
  account_number_ciphertext?: Maybe<Order_By>;
  ach_account?: Maybe<Order_By>;
  ach_routing?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  base_contract_date?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  contract_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  duns_number?: Maybe<Order_By>;
  end_month?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invite_status?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  routing_number?: Maybe<Order_By>;
  routing_number_ciphertext?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
  suger_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_billing_name?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  verification_email?: Maybe<Order_By>;
  verification_token?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** response of any mutation on the table "customers" */
export type Customers_Mutation_Response = {
  __typename?: 'customers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customers>;
};

/** input type for inserting object relation for remote table "customers" */
export type Customers_Obj_Rel_Insert_Input = {
  data: Customers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Customers_On_Conflict>;
};

/** on_conflict condition type for table "customers" */
export type Customers_On_Conflict = {
  constraint: Customers_Constraint;
  update_columns?: Array<Customers_Update_Column>;
  where?: Maybe<Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "customers". */
export type Customers_Order_By = {
  account_number?: Maybe<Order_By>;
  account_number_ciphertext?: Maybe<Order_By>;
  ach_account?: Maybe<Order_By>;
  ach_on_file?: Maybe<Order_By>;
  ach_queues_aggregate?: Maybe<Ach_Queues_Aggregate_Order_By>;
  ach_routing?: Maybe<Order_By>;
  active?: Maybe<Order_By>;
  address?: Maybe<Addresses_Order_By>;
  address_id?: Maybe<Order_By>;
  addresses_aggregate?: Maybe<Addresses_Aggregate_Order_By>;
  aggregate?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  autopay?: Maybe<Order_By>;
  balance_dues_aggregate?: Maybe<Balance_Dues_Aggregate_Order_By>;
  base_contract_date?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company?: Maybe<Indices_Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  contact_assignments_aggregate?: Maybe<Contact_Assignments_Aggregate_Order_By>;
  contacts_aggregate?: Maybe<Contacts_Aggregate_Order_By>;
  contract_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_category?: Maybe<Customer_Categories_Order_By>;
  customer_category_id?: Maybe<Order_By>;
  customer_documents_view_aggregate?: Maybe<Customer_Documents_View_Aggregate_Order_By>;
  customer_groups_aggregate?: Maybe<Customer_Groups_Aggregate_Order_By>;
  customers_users_aggregate?: Maybe<Customers_Users_Aggregate_Order_By>;
  deal_detail_volumes_sum_by_beg_date_aggregate?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Order_By>;
  deal_details_preview_aggregate?: Maybe<Deals_Detail_Preview_Aggregate_Order_By>;
  dealmaker?: Maybe<Dealmakers_Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  deals_aggregate?: Maybe<Deals_Aggregate_Order_By>;
  duns_number?: Maybe<Order_By>;
  end_month?: Maybe<Order_By>;
  end_user?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitations_customers_aggregate?: Maybe<Invitations_Customers_Aggregate_Order_By>;
  invite_status?: Maybe<Order_By>;
  invoice_aggregates_aggregate?: Maybe<Invoice_Aggregate_Aggregate_Order_By>;
  invoice_data_aggregate?: Maybe<Invoice_Data_Aggregate_Order_By>;
  invoice_data_sum_by_month_aggregate?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Order_By>;
  invoice_numbers_aggregate?: Maybe<Invoice_Numbers_Aggregate_Order_By>;
  invoicebyemail?: Maybe<Order_By>;
  invoicebymail?: Maybe<Order_By>;
  ldc_accounts_view_aggregate?: Maybe<Ldc_Accounts_View_Aggregate_Order_By>;
  mdq?: Maybe<Order_By>;
  naesb?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  named_insured?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  opt_out?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact?: Maybe<Contact_Entries_Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  records_aggregate?: Maybe<Records_Aggregate_Order_By>;
  routing_number?: Maybe<Order_By>;
  routing_number_ciphertext?: Maybe<Order_By>;
  send_invoices?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
  suger_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  tax_exempt?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_billing_name?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  verification_email?: Maybe<Order_By>;
  verification_token?: Maybe<Order_By>;
  virtual?: Maybe<Order_By>;
  waive_late_fee?: Maybe<Order_By>;
  waive_mail_fee?: Maybe<Order_By>;
  wholesale?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** primary key columns input for table: customers */
export type Customers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "customers" */
export enum Customers_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  AccountNumberCiphertext = 'account_number_ciphertext',
  /** column name */
  AchAccount = 'ach_account',
  /** column name */
  AchOnFile = 'ach_on_file',
  /** column name */
  AchRouting = 'ach_routing',
  /** column name */
  Active = 'active',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Aggregate = 'aggregate',
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  Autopay = 'autopay',
  /** column name */
  BaseContractDate = 'base_contract_date',
  /** column name */
  BillDay = 'bill_day',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ConnectId = 'connect_id',
  /** column name */
  ContractDate = 'contract_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerCategoryId = 'customer_category_id',
  /** column name */
  DealmakerId = 'dealmaker_id',
  /** column name */
  DunsNumber = 'duns_number',
  /** column name */
  EndMonth = 'end_month',
  /** column name */
  EndUser = 'end_user',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InviteStatus = 'invite_status',
  /** column name */
  Invoicebyemail = 'invoicebyemail',
  /** column name */
  Invoicebymail = 'invoicebymail',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  Naesb = 'naesb',
  /** column name */
  Name = 'name',
  /** column name */
  NamedInsured = 'named_insured',
  /** column name */
  Notes = 'notes',
  /** column name */
  OptOut = 'opt_out',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  PmtTerms = 'pmt_terms',
  /** column name */
  PrimaryContactId = 'primary_contact_id',
  /** column name */
  RoutingNumber = 'routing_number',
  /** column name */
  RoutingNumberCiphertext = 'routing_number_ciphertext',
  /** column name */
  SendInvoices = 'send_invoices',
  /** column name */
  StartMonth = 'start_month',
  /** column name */
  SugerId = 'suger_id',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  TaxExempt = 'tax_exempt',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityBillingName = 'utility_billing_name',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  VerificationEmail = 'verification_email',
  /** column name */
  VerificationToken = 'verification_token',
  /** column name */
  Virtual = 'virtual',
  /** column name */
  WaiveLateFee = 'waive_late_fee',
  /** column name */
  WaiveMailFee = 'waive_mail_fee',
  /** column name */
  Wholesale = 'wholesale',
  /** column name */
  WinterMax = 'winter_max'
}

/** select "customers_aggregate_bool_exp_avg_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  WinterMax = 'winter_max'
}

/** select "customers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AchOnFile = 'ach_on_file',
  /** column name */
  Active = 'active',
  /** column name */
  Aggregate = 'aggregate',
  /** column name */
  Autopay = 'autopay',
  /** column name */
  EndUser = 'end_user',
  /** column name */
  Invoicebyemail = 'invoicebyemail',
  /** column name */
  Invoicebymail = 'invoicebymail',
  /** column name */
  Naesb = 'naesb',
  /** column name */
  NamedInsured = 'named_insured',
  /** column name */
  OptOut = 'opt_out',
  /** column name */
  SendInvoices = 'send_invoices',
  /** column name */
  TaxExempt = 'tax_exempt',
  /** column name */
  Virtual = 'virtual',
  /** column name */
  WaiveLateFee = 'waive_late_fee',
  /** column name */
  WaiveMailFee = 'waive_mail_fee',
  /** column name */
  Wholesale = 'wholesale'
}

/** select "customers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AchOnFile = 'ach_on_file',
  /** column name */
  Active = 'active',
  /** column name */
  Aggregate = 'aggregate',
  /** column name */
  Autopay = 'autopay',
  /** column name */
  EndUser = 'end_user',
  /** column name */
  Invoicebyemail = 'invoicebyemail',
  /** column name */
  Invoicebymail = 'invoicebymail',
  /** column name */
  Naesb = 'naesb',
  /** column name */
  NamedInsured = 'named_insured',
  /** column name */
  OptOut = 'opt_out',
  /** column name */
  SendInvoices = 'send_invoices',
  /** column name */
  TaxExempt = 'tax_exempt',
  /** column name */
  Virtual = 'virtual',
  /** column name */
  WaiveLateFee = 'waive_late_fee',
  /** column name */
  WaiveMailFee = 'waive_mail_fee',
  /** column name */
  Wholesale = 'wholesale'
}

/** select "customers_aggregate_bool_exp_corr_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  WinterMax = 'winter_max'
}

/** select "customers_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  WinterMax = 'winter_max'
}

/** select "customers_aggregate_bool_exp_max_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  WinterMax = 'winter_max'
}

/** select "customers_aggregate_bool_exp_min_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  WinterMax = 'winter_max'
}

/** select "customers_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  WinterMax = 'winter_max'
}

/** select "customers_aggregate_bool_exp_sum_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  WinterMax = 'winter_max'
}

/** select "customers_aggregate_bool_exp_var_samp_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  WinterMax = 'winter_max'
}

/** input type for updating data in table "customers" */
export type Customers_Set_Input = {
  account_number?: Maybe<Scalars['String']>;
  account_number_ciphertext?: Maybe<Scalars['String']>;
  ach_account?: Maybe<Scalars['String']>;
  ach_on_file?: Maybe<Scalars['Boolean']>;
  ach_routing?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address_id?: Maybe<Scalars['Int']>;
  aggregate?: Maybe<Scalars['Boolean']>;
  annual_dth?: Maybe<Scalars['float8']>;
  autopay?: Maybe<Scalars['Boolean']>;
  base_contract_date?: Maybe<Scalars['date']>;
  bill_day?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  connect_id?: Maybe<Scalars['Int']>;
  contract_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_category_id?: Maybe<Scalars['Int']>;
  dealmaker_id?: Maybe<Scalars['Int']>;
  duns_number?: Maybe<Scalars['String']>;
  end_month?: Maybe<Scalars['date']>;
  end_user?: Maybe<Scalars['Boolean']>;
  fr_req?: Maybe<Scalars['float8']>;
  group_fee?: Maybe<Scalars['float8']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invite_status?: Maybe<Scalars['invite_status']>;
  invoicebyemail?: Maybe<Scalars['Boolean']>;
  invoicebymail?: Maybe<Scalars['Boolean']>;
  mdq?: Maybe<Scalars['float8']>;
  naesb?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  named_insured?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Scalars['Boolean']>;
  person_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  routing_number?: Maybe<Scalars['String']>;
  routing_number_ciphertext?: Maybe<Scalars['String']>;
  send_invoices?: Maybe<Scalars['Boolean']>;
  start_month?: Maybe<Scalars['date']>;
  suger_id?: Maybe<Scalars['String']>;
  summer_avg?: Maybe<Scalars['float8']>;
  tax_exempt?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_billing_name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verification_email?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
  waive_late_fee?: Maybe<Scalars['Boolean']>;
  waive_mail_fee?: Maybe<Scalars['Boolean']>;
  wholesale?: Maybe<Scalars['Boolean']>;
  winter_max?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Customers_Stddev_Fields = {
  __typename?: 'customers_stddev_fields';
  address_id?: Maybe<Scalars['Float']>;
  annual_dth?: Maybe<Scalars['Float']>;
  bill_day?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  connect_id?: Maybe<Scalars['Float']>;
  customer_category_id?: Maybe<Scalars['Float']>;
  dealmaker_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  summer_avg?: Maybe<Scalars['Float']>;
  winter_max?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customers" */
export type Customers_Stddev_Order_By = {
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customers_Stddev_Pop_Fields = {
  __typename?: 'customers_stddev_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  annual_dth?: Maybe<Scalars['Float']>;
  bill_day?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  connect_id?: Maybe<Scalars['Float']>;
  customer_category_id?: Maybe<Scalars['Float']>;
  dealmaker_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  summer_avg?: Maybe<Scalars['Float']>;
  winter_max?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customers" */
export type Customers_Stddev_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customers_Stddev_Samp_Fields = {
  __typename?: 'customers_stddev_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  annual_dth?: Maybe<Scalars['Float']>;
  bill_day?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  connect_id?: Maybe<Scalars['Float']>;
  customer_category_id?: Maybe<Scalars['Float']>;
  dealmaker_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  summer_avg?: Maybe<Scalars['Float']>;
  winter_max?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customers" */
export type Customers_Stddev_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customers" */
export type Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customers_Stream_Cursor_Value_Input = {
  account_number?: Maybe<Scalars['String']>;
  account_number_ciphertext?: Maybe<Scalars['String']>;
  ach_account?: Maybe<Scalars['String']>;
  ach_on_file?: Maybe<Scalars['Boolean']>;
  ach_routing?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address_id?: Maybe<Scalars['Int']>;
  aggregate?: Maybe<Scalars['Boolean']>;
  annual_dth?: Maybe<Scalars['float8']>;
  autopay?: Maybe<Scalars['Boolean']>;
  base_contract_date?: Maybe<Scalars['date']>;
  bill_day?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  connect_id?: Maybe<Scalars['Int']>;
  contract_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_category_id?: Maybe<Scalars['Int']>;
  dealmaker_id?: Maybe<Scalars['Int']>;
  duns_number?: Maybe<Scalars['String']>;
  end_month?: Maybe<Scalars['date']>;
  end_user?: Maybe<Scalars['Boolean']>;
  fr_req?: Maybe<Scalars['float8']>;
  group_fee?: Maybe<Scalars['float8']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invite_status?: Maybe<Scalars['invite_status']>;
  invoicebyemail?: Maybe<Scalars['Boolean']>;
  invoicebymail?: Maybe<Scalars['Boolean']>;
  mdq?: Maybe<Scalars['float8']>;
  naesb?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  named_insured?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  opt_out?: Maybe<Scalars['Boolean']>;
  person_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  routing_number?: Maybe<Scalars['String']>;
  routing_number_ciphertext?: Maybe<Scalars['String']>;
  send_invoices?: Maybe<Scalars['Boolean']>;
  start_month?: Maybe<Scalars['date']>;
  suger_id?: Maybe<Scalars['String']>;
  summer_avg?: Maybe<Scalars['float8']>;
  tax_exempt?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_billing_name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verification_email?: Maybe<Scalars['String']>;
  verification_token?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
  waive_late_fee?: Maybe<Scalars['Boolean']>;
  waive_mail_fee?: Maybe<Scalars['Boolean']>;
  wholesale?: Maybe<Scalars['Boolean']>;
  winter_max?: Maybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Customers_Sum_Fields = {
  __typename?: 'customers_sum_fields';
  address_id?: Maybe<Scalars['Int']>;
  annual_dth?: Maybe<Scalars['float8']>;
  bill_day?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  connect_id?: Maybe<Scalars['Int']>;
  customer_category_id?: Maybe<Scalars['Int']>;
  dealmaker_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  group_fee?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  person_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  summer_avg?: Maybe<Scalars['float8']>;
  winter_max?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "customers" */
export type Customers_Sum_Order_By = {
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** update columns of table "customers" */
export enum Customers_Update_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  AccountNumberCiphertext = 'account_number_ciphertext',
  /** column name */
  AchAccount = 'ach_account',
  /** column name */
  AchOnFile = 'ach_on_file',
  /** column name */
  AchRouting = 'ach_routing',
  /** column name */
  Active = 'active',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Aggregate = 'aggregate',
  /** column name */
  AnnualDth = 'annual_dth',
  /** column name */
  Autopay = 'autopay',
  /** column name */
  BaseContractDate = 'base_contract_date',
  /** column name */
  BillDay = 'bill_day',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ConnectId = 'connect_id',
  /** column name */
  ContractDate = 'contract_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerCategoryId = 'customer_category_id',
  /** column name */
  DealmakerId = 'dealmaker_id',
  /** column name */
  DunsNumber = 'duns_number',
  /** column name */
  EndMonth = 'end_month',
  /** column name */
  EndUser = 'end_user',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InviteStatus = 'invite_status',
  /** column name */
  Invoicebyemail = 'invoicebyemail',
  /** column name */
  Invoicebymail = 'invoicebymail',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  Naesb = 'naesb',
  /** column name */
  Name = 'name',
  /** column name */
  NamedInsured = 'named_insured',
  /** column name */
  Notes = 'notes',
  /** column name */
  OptOut = 'opt_out',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  PmtTerms = 'pmt_terms',
  /** column name */
  PrimaryContactId = 'primary_contact_id',
  /** column name */
  RoutingNumber = 'routing_number',
  /** column name */
  RoutingNumberCiphertext = 'routing_number_ciphertext',
  /** column name */
  SendInvoices = 'send_invoices',
  /** column name */
  StartMonth = 'start_month',
  /** column name */
  SugerId = 'suger_id',
  /** column name */
  SummerAvg = 'summer_avg',
  /** column name */
  TaxExempt = 'tax_exempt',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityBillingName = 'utility_billing_name',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  VerificationEmail = 'verification_email',
  /** column name */
  VerificationToken = 'verification_token',
  /** column name */
  Virtual = 'virtual',
  /** column name */
  WaiveLateFee = 'waive_late_fee',
  /** column name */
  WaiveMailFee = 'waive_mail_fee',
  /** column name */
  Wholesale = 'wholesale',
  /** column name */
  WinterMax = 'winter_max'
}

export type Customers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Customers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customers_Bool_Exp;
};

/** columns and relationships of "customers_users" */
export type Customers_Users = {
  __typename?: 'customers_users';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['Int'];
  id: Scalars['bigint'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user: Auth_Users;
  user_id: Scalars['Int'];
};

/** aggregated selection of "customers_users" */
export type Customers_Users_Aggregate = {
  __typename?: 'customers_users_aggregate';
  aggregate?: Maybe<Customers_Users_Aggregate_Fields>;
  nodes: Array<Customers_Users>;
};

export type Customers_Users_Aggregate_Bool_Exp = {
  count?: Maybe<Customers_Users_Aggregate_Bool_Exp_Count>;
};

export type Customers_Users_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Customers_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customers_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customers_users" */
export type Customers_Users_Aggregate_Fields = {
  __typename?: 'customers_users_aggregate_fields';
  avg?: Maybe<Customers_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customers_Users_Max_Fields>;
  min?: Maybe<Customers_Users_Min_Fields>;
  stddev?: Maybe<Customers_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Customers_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customers_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Customers_Users_Sum_Fields>;
  var_pop?: Maybe<Customers_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Customers_Users_Var_Samp_Fields>;
  variance?: Maybe<Customers_Users_Variance_Fields>;
};


/** aggregate fields of "customers_users" */
export type Customers_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customers_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customers_users" */
export type Customers_Users_Aggregate_Order_By = {
  avg?: Maybe<Customers_Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customers_Users_Max_Order_By>;
  min?: Maybe<Customers_Users_Min_Order_By>;
  stddev?: Maybe<Customers_Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Customers_Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customers_Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Customers_Users_Sum_Order_By>;
  var_pop?: Maybe<Customers_Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Customers_Users_Var_Samp_Order_By>;
  variance?: Maybe<Customers_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customers_users" */
export type Customers_Users_Arr_Rel_Insert_Input = {
  data: Array<Customers_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Customers_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Customers_Users_Avg_Fields = {
  __typename?: 'customers_users_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customers_users" */
export type Customers_Users_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customers_users". All fields are combined with a logical 'AND'. */
export type Customers_Users_Bool_Exp = {
  _and?: Maybe<Array<Customers_Users_Bool_Exp>>;
  _not?: Maybe<Customers_Users_Bool_Exp>;
  _or?: Maybe<Array<Customers_Users_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  user?: Maybe<Auth_Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "customers_users" */
export enum Customers_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomersUsersPkey = 'customers_users_pkey',
  /** unique or primary key constraint on columns "user_id", "customer_id" */
  IndexCustomersUsersOnCustomerIdAndUserId = 'index_customers_users_on_customer_id_and_user_id'
}

/** input type for incrementing numeric columns in table "customers_users" */
export type Customers_Users_Inc_Input = {
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "customers_users" */
export type Customers_Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user?: Maybe<Auth_Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Customers_Users_Max_Fields = {
  __typename?: 'customers_users_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "customers_users" */
export type Customers_Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Customers_Users_Min_Fields = {
  __typename?: 'customers_users_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "customers_users" */
export type Customers_Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "customers_users" */
export type Customers_Users_Mutation_Response = {
  __typename?: 'customers_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customers_Users>;
};

/** on_conflict condition type for table "customers_users" */
export type Customers_Users_On_Conflict = {
  constraint: Customers_Users_Constraint;
  update_columns?: Array<Customers_Users_Update_Column>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "customers_users". */
export type Customers_Users_Order_By = {
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Auth_Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: customers_users */
export type Customers_Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customers_users" */
export enum Customers_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "customers_users" */
export type Customers_Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Customers_Users_Stddev_Fields = {
  __typename?: 'customers_users_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customers_users" */
export type Customers_Users_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customers_Users_Stddev_Pop_Fields = {
  __typename?: 'customers_users_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customers_users" */
export type Customers_Users_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customers_Users_Stddev_Samp_Fields = {
  __typename?: 'customers_users_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customers_users" */
export type Customers_Users_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "customers_users" */
export type Customers_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customers_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customers_Users_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Customers_Users_Sum_Fields = {
  __typename?: 'customers_users_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customers_users" */
export type Customers_Users_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "customers_users" */
export enum Customers_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Customers_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Customers_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customers_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customers_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customers_Users_Var_Pop_Fields = {
  __typename?: 'customers_users_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customers_users" */
export type Customers_Users_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customers_Users_Var_Samp_Fields = {
  __typename?: 'customers_users_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customers_users" */
export type Customers_Users_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Customers_Users_Variance_Fields = {
  __typename?: 'customers_users_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customers_users" */
export type Customers_Users_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Customers_Var_Pop_Fields = {
  __typename?: 'customers_var_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  annual_dth?: Maybe<Scalars['Float']>;
  bill_day?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  connect_id?: Maybe<Scalars['Float']>;
  customer_category_id?: Maybe<Scalars['Float']>;
  dealmaker_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  summer_avg?: Maybe<Scalars['Float']>;
  winter_max?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customers" */
export type Customers_Var_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customers_Var_Samp_Fields = {
  __typename?: 'customers_var_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  annual_dth?: Maybe<Scalars['Float']>;
  bill_day?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  connect_id?: Maybe<Scalars['Float']>;
  customer_category_id?: Maybe<Scalars['Float']>;
  dealmaker_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  summer_avg?: Maybe<Scalars['Float']>;
  winter_max?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customers" */
export type Customers_Var_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Customers_Variance_Fields = {
  __typename?: 'customers_variance_fields';
  address_id?: Maybe<Scalars['Float']>;
  annual_dth?: Maybe<Scalars['Float']>;
  bill_day?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  connect_id?: Maybe<Scalars['Float']>;
  customer_category_id?: Maybe<Scalars['Float']>;
  dealmaker_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  summer_avg?: Maybe<Scalars['Float']>;
  winter_max?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customers" */
export type Customers_Variance_Order_By = {
  address_id?: Maybe<Order_By>;
  annual_dth?: Maybe<Order_By>;
  bill_day?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  connect_id?: Maybe<Order_By>;
  customer_category_id?: Maybe<Order_By>;
  dealmaker_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  summer_avg?: Maybe<Order_By>;
  winter_max?: Maybe<Order_By>;
};

/** columns and relationships of "cyborg_queries" */
export type Cyborg_Queries = {
  __typename?: 'cyborg_queries';
  /** An object relationship */
  author: Auth_Users;
  author_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  graphql: Scalars['String'];
  id: Scalars['Int'];
  js_functor: Scalars['String'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "cyborg_queries" */
export type Cyborg_Queries_Aggregate = {
  __typename?: 'cyborg_queries_aggregate';
  aggregate?: Maybe<Cyborg_Queries_Aggregate_Fields>;
  nodes: Array<Cyborg_Queries>;
};

/** aggregate fields of "cyborg_queries" */
export type Cyborg_Queries_Aggregate_Fields = {
  __typename?: 'cyborg_queries_aggregate_fields';
  avg?: Maybe<Cyborg_Queries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cyborg_Queries_Max_Fields>;
  min?: Maybe<Cyborg_Queries_Min_Fields>;
  stddev?: Maybe<Cyborg_Queries_Stddev_Fields>;
  stddev_pop?: Maybe<Cyborg_Queries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cyborg_Queries_Stddev_Samp_Fields>;
  sum?: Maybe<Cyborg_Queries_Sum_Fields>;
  var_pop?: Maybe<Cyborg_Queries_Var_Pop_Fields>;
  var_samp?: Maybe<Cyborg_Queries_Var_Samp_Fields>;
  variance?: Maybe<Cyborg_Queries_Variance_Fields>;
};


/** aggregate fields of "cyborg_queries" */
export type Cyborg_Queries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cyborg_Queries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Cyborg_Queries_Avg_Fields = {
  __typename?: 'cyborg_queries_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "cyborg_queries". All fields are combined with a logical 'AND'. */
export type Cyborg_Queries_Bool_Exp = {
  _and?: Maybe<Array<Cyborg_Queries_Bool_Exp>>;
  _not?: Maybe<Cyborg_Queries_Bool_Exp>;
  _or?: Maybe<Array<Cyborg_Queries_Bool_Exp>>;
  author?: Maybe<Auth_Users_Bool_Exp>;
  author_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  graphql?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  js_functor?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cyborg_queries" */
export enum Cyborg_Queries_Constraint {
  /** unique or primary key constraint on columns "id" */
  CyborgQueriesPkey = 'cyborg_queries_pkey'
}

/** input type for incrementing numeric columns in table "cyborg_queries" */
export type Cyborg_Queries_Inc_Input = {
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "cyborg_queries" */
export type Cyborg_Queries_Insert_Input = {
  author?: Maybe<Auth_Users_Obj_Rel_Insert_Input>;
  author_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  graphql?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  js_functor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Cyborg_Queries_Max_Fields = {
  __typename?: 'cyborg_queries_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  graphql?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  js_functor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Cyborg_Queries_Min_Fields = {
  __typename?: 'cyborg_queries_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  graphql?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  js_functor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "cyborg_queries" */
export type Cyborg_Queries_Mutation_Response = {
  __typename?: 'cyborg_queries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cyborg_Queries>;
};

/** on_conflict condition type for table "cyborg_queries" */
export type Cyborg_Queries_On_Conflict = {
  constraint: Cyborg_Queries_Constraint;
  update_columns?: Array<Cyborg_Queries_Update_Column>;
  where?: Maybe<Cyborg_Queries_Bool_Exp>;
};

/** Ordering options when selecting data from "cyborg_queries". */
export type Cyborg_Queries_Order_By = {
  author?: Maybe<Auth_Users_Order_By>;
  author_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  graphql?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  js_functor?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: cyborg_queries */
export type Cyborg_Queries_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "cyborg_queries" */
export enum Cyborg_Queries_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Graphql = 'graphql',
  /** column name */
  Id = 'id',
  /** column name */
  JsFunctor = 'js_functor',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "cyborg_queries" */
export type Cyborg_Queries_Set_Input = {
  author_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  graphql?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  js_functor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Cyborg_Queries_Stddev_Fields = {
  __typename?: 'cyborg_queries_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Cyborg_Queries_Stddev_Pop_Fields = {
  __typename?: 'cyborg_queries_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Cyborg_Queries_Stddev_Samp_Fields = {
  __typename?: 'cyborg_queries_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "cyborg_queries" */
export type Cyborg_Queries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cyborg_Queries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cyborg_Queries_Stream_Cursor_Value_Input = {
  author_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  graphql?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  js_functor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Cyborg_Queries_Sum_Fields = {
  __typename?: 'cyborg_queries_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "cyborg_queries" */
export enum Cyborg_Queries_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Graphql = 'graphql',
  /** column name */
  Id = 'id',
  /** column name */
  JsFunctor = 'js_functor',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Cyborg_Queries_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Cyborg_Queries_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Cyborg_Queries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cyborg_Queries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cyborg_Queries_Var_Pop_Fields = {
  __typename?: 'cyborg_queries_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Cyborg_Queries_Var_Samp_Fields = {
  __typename?: 'cyborg_queries_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Cyborg_Queries_Variance_Fields = {
  __typename?: 'cyborg_queries_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "deal_detail_volumes" */
export type Deal_Detail_Volumes = {
  __typename?: 'deal_detail_volumes';
  actual_mcf?: Maybe<Scalars['float8']>;
  actual_volume?: Maybe<Scalars['float8']>;
  actualized?: Maybe<Scalars['Boolean']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  contract_volume: Scalars['float8'];
  created_at?: Maybe<Scalars['timestamp']>;
  day_cnt?: Maybe<Scalars['Int']>;
  /** An object relationship */
  deal_detail?: Maybe<Deal_Details>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1?: Maybe<Scalars['float8']>;
  fee_2?: Maybe<Scalars['float8']>;
  fee_3?: Maybe<Scalars['float8']>;
  fee_4?: Maybe<Scalars['float8']>;
  fee_5?: Maybe<Scalars['float8']>;
  fee_6?: Maybe<Scalars['float8']>;
  fee_7?: Maybe<Scalars['float8']>;
  fee_8?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  normalized?: Maybe<Scalars['Boolean']>;
  read_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "deal_detail_volumes" */
export type Deal_Detail_Volumes_Aggregate = {
  __typename?: 'deal_detail_volumes_aggregate';
  aggregate?: Maybe<Deal_Detail_Volumes_Aggregate_Fields>;
  nodes: Array<Deal_Detail_Volumes>;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp = {
  avg?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp_Var_Samp>;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Avg = {
  arguments: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Bool_And = {
  arguments: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Corr = {
  arguments: Deal_Detail_Volumes_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Deal_Detail_Volumes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Deal_Detail_Volumes_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Max = {
  arguments: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Min = {
  arguments: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Sum = {
  arguments: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "deal_detail_volumes" */
export type Deal_Detail_Volumes_Aggregate_Fields = {
  __typename?: 'deal_detail_volumes_aggregate_fields';
  avg?: Maybe<Deal_Detail_Volumes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deal_Detail_Volumes_Max_Fields>;
  min?: Maybe<Deal_Detail_Volumes_Min_Fields>;
  stddev?: Maybe<Deal_Detail_Volumes_Stddev_Fields>;
  stddev_pop?: Maybe<Deal_Detail_Volumes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deal_Detail_Volumes_Stddev_Samp_Fields>;
  sum?: Maybe<Deal_Detail_Volumes_Sum_Fields>;
  var_pop?: Maybe<Deal_Detail_Volumes_Var_Pop_Fields>;
  var_samp?: Maybe<Deal_Detail_Volumes_Var_Samp_Fields>;
  variance?: Maybe<Deal_Detail_Volumes_Variance_Fields>;
};


/** aggregate fields of "deal_detail_volumes" */
export type Deal_Detail_Volumes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deal_Detail_Volumes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Aggregate_Order_By = {
  avg?: Maybe<Deal_Detail_Volumes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Deal_Detail_Volumes_Max_Order_By>;
  min?: Maybe<Deal_Detail_Volumes_Min_Order_By>;
  stddev?: Maybe<Deal_Detail_Volumes_Stddev_Order_By>;
  stddev_pop?: Maybe<Deal_Detail_Volumes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Deal_Detail_Volumes_Stddev_Samp_Order_By>;
  sum?: Maybe<Deal_Detail_Volumes_Sum_Order_By>;
  var_pop?: Maybe<Deal_Detail_Volumes_Var_Pop_Order_By>;
  var_samp?: Maybe<Deal_Detail_Volumes_Var_Samp_Order_By>;
  variance?: Maybe<Deal_Detail_Volumes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Arr_Rel_Insert_Input = {
  data: Array<Deal_Detail_Volumes_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deal_Detail_Volumes_On_Conflict>;
};

/** aggregate avg on columns */
export type Deal_Detail_Volumes_Avg_Fields = {
  __typename?: 'deal_detail_volumes_avg_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  day_cnt?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
  fee_1?: Maybe<Scalars['Float']>;
  fee_2?: Maybe<Scalars['Float']>;
  fee_3?: Maybe<Scalars['Float']>;
  fee_4?: Maybe<Scalars['Float']>;
  fee_5?: Maybe<Scalars['Float']>;
  fee_6?: Maybe<Scalars['Float']>;
  fee_7?: Maybe<Scalars['Float']>;
  fee_8?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Avg_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal_detail_volumes". All fields are combined with a logical 'AND'. */
export type Deal_Detail_Volumes_Bool_Exp = {
  _and?: Maybe<Array<Deal_Detail_Volumes_Bool_Exp>>;
  _not?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  _or?: Maybe<Array<Deal_Detail_Volumes_Bool_Exp>>;
  actual_mcf?: Maybe<Float8_Comparison_Exp>;
  actual_volume?: Maybe<Float8_Comparison_Exp>;
  actualized?: Maybe<Boolean_Comparison_Exp>;
  beg_date?: Maybe<Date_Comparison_Exp>;
  begin_read_date?: Maybe<Date_Comparison_Exp>;
  contract_volume?: Maybe<Float8_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  day_cnt?: Maybe<Int_Comparison_Exp>;
  deal_detail?: Maybe<Deal_Details_Bool_Exp>;
  deal_detail_id?: Maybe<Int_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  fee_1?: Maybe<Float8_Comparison_Exp>;
  fee_2?: Maybe<Float8_Comparison_Exp>;
  fee_3?: Maybe<Float8_Comparison_Exp>;
  fee_4?: Maybe<Float8_Comparison_Exp>;
  fee_5?: Maybe<Float8_Comparison_Exp>;
  fee_6?: Maybe<Float8_Comparison_Exp>;
  fee_7?: Maybe<Float8_Comparison_Exp>;
  fee_8?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  normalized?: Maybe<Boolean_Comparison_Exp>;
  read_date?: Maybe<Date_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealDetailVolumesPkey = 'deal_detail_volumes_pkey'
}

/** input type for incrementing numeric columns in table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Inc_Input = {
  actual_mcf?: Maybe<Scalars['float8']>;
  actual_volume?: Maybe<Scalars['float8']>;
  contract_volume?: Maybe<Scalars['float8']>;
  day_cnt?: Maybe<Scalars['Int']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  fee_1?: Maybe<Scalars['float8']>;
  fee_2?: Maybe<Scalars['float8']>;
  fee_3?: Maybe<Scalars['float8']>;
  fee_4?: Maybe<Scalars['float8']>;
  fee_5?: Maybe<Scalars['float8']>;
  fee_6?: Maybe<Scalars['float8']>;
  fee_7?: Maybe<Scalars['float8']>;
  fee_8?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Insert_Input = {
  actual_mcf?: Maybe<Scalars['float8']>;
  actual_volume?: Maybe<Scalars['float8']>;
  actualized?: Maybe<Scalars['Boolean']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  day_cnt?: Maybe<Scalars['Int']>;
  deal_detail?: Maybe<Deal_Details_Obj_Rel_Insert_Input>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1?: Maybe<Scalars['float8']>;
  fee_2?: Maybe<Scalars['float8']>;
  fee_3?: Maybe<Scalars['float8']>;
  fee_4?: Maybe<Scalars['float8']>;
  fee_5?: Maybe<Scalars['float8']>;
  fee_6?: Maybe<Scalars['float8']>;
  fee_7?: Maybe<Scalars['float8']>;
  fee_8?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  normalized?: Maybe<Scalars['Boolean']>;
  read_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Deal_Detail_Volumes_Max_Fields = {
  __typename?: 'deal_detail_volumes_max_fields';
  actual_mcf?: Maybe<Scalars['float8']>;
  actual_volume?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  day_cnt?: Maybe<Scalars['Int']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1?: Maybe<Scalars['float8']>;
  fee_2?: Maybe<Scalars['float8']>;
  fee_3?: Maybe<Scalars['float8']>;
  fee_4?: Maybe<Scalars['float8']>;
  fee_5?: Maybe<Scalars['float8']>;
  fee_6?: Maybe<Scalars['float8']>;
  fee_7?: Maybe<Scalars['float8']>;
  fee_8?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  read_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Max_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  begin_read_date?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  read_date?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deal_Detail_Volumes_Min_Fields = {
  __typename?: 'deal_detail_volumes_min_fields';
  actual_mcf?: Maybe<Scalars['float8']>;
  actual_volume?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  day_cnt?: Maybe<Scalars['Int']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1?: Maybe<Scalars['float8']>;
  fee_2?: Maybe<Scalars['float8']>;
  fee_3?: Maybe<Scalars['float8']>;
  fee_4?: Maybe<Scalars['float8']>;
  fee_5?: Maybe<Scalars['float8']>;
  fee_6?: Maybe<Scalars['float8']>;
  fee_7?: Maybe<Scalars['float8']>;
  fee_8?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  read_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Min_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  begin_read_date?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  read_date?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Mutation_Response = {
  __typename?: 'deal_detail_volumes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Detail_Volumes>;
};

/** on_conflict condition type for table "deal_detail_volumes" */
export type Deal_Detail_Volumes_On_Conflict = {
  constraint: Deal_Detail_Volumes_Constraint;
  update_columns?: Array<Deal_Detail_Volumes_Update_Column>;
  where?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_detail_volumes". */
export type Deal_Detail_Volumes_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  actualized?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  begin_read_date?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail?: Maybe<Deal_Details_Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  normalized?: Maybe<Order_By>;
  read_date?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: deal_detail_volumes */
export type Deal_Detail_Volumes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  Actualized = 'actualized',
  /** column name */
  BegDate = 'beg_date',
  /** column name */
  BeginReadDate = 'begin_read_date',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayCnt = 'day_cnt',
  /** column name */
  DealDetailId = 'deal_detail_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8',
  /** column name */
  Id = 'id',
  /** column name */
  Normalized = 'normalized',
  /** column name */
  ReadDate = 'read_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "deal_detail_volumes_aggregate_bool_exp_avg_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8'
}

/** select "deal_detail_volumes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Actualized = 'actualized',
  /** column name */
  Normalized = 'normalized'
}

/** select "deal_detail_volumes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Actualized = 'actualized',
  /** column name */
  Normalized = 'normalized'
}

/** select "deal_detail_volumes_aggregate_bool_exp_corr_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8'
}

/** select "deal_detail_volumes_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8'
}

/** select "deal_detail_volumes_aggregate_bool_exp_max_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8'
}

/** select "deal_detail_volumes_aggregate_bool_exp_min_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8'
}

/** select "deal_detail_volumes_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8'
}

/** select "deal_detail_volumes_aggregate_bool_exp_sum_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8'
}

/** select "deal_detail_volumes_aggregate_bool_exp_var_samp_arguments_columns" columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Select_Column_Deal_Detail_Volumes_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8'
}

/** input type for updating data in table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Set_Input = {
  actual_mcf?: Maybe<Scalars['float8']>;
  actual_volume?: Maybe<Scalars['float8']>;
  actualized?: Maybe<Scalars['Boolean']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  day_cnt?: Maybe<Scalars['Int']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1?: Maybe<Scalars['float8']>;
  fee_2?: Maybe<Scalars['float8']>;
  fee_3?: Maybe<Scalars['float8']>;
  fee_4?: Maybe<Scalars['float8']>;
  fee_5?: Maybe<Scalars['float8']>;
  fee_6?: Maybe<Scalars['float8']>;
  fee_7?: Maybe<Scalars['float8']>;
  fee_8?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  normalized?: Maybe<Scalars['Boolean']>;
  read_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Deal_Detail_Volumes_Stddev_Fields = {
  __typename?: 'deal_detail_volumes_stddev_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  day_cnt?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
  fee_1?: Maybe<Scalars['Float']>;
  fee_2?: Maybe<Scalars['Float']>;
  fee_3?: Maybe<Scalars['Float']>;
  fee_4?: Maybe<Scalars['Float']>;
  fee_5?: Maybe<Scalars['Float']>;
  fee_6?: Maybe<Scalars['Float']>;
  fee_7?: Maybe<Scalars['Float']>;
  fee_8?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Stddev_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deal_Detail_Volumes_Stddev_Pop_Fields = {
  __typename?: 'deal_detail_volumes_stddev_pop_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  day_cnt?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
  fee_1?: Maybe<Scalars['Float']>;
  fee_2?: Maybe<Scalars['Float']>;
  fee_3?: Maybe<Scalars['Float']>;
  fee_4?: Maybe<Scalars['Float']>;
  fee_5?: Maybe<Scalars['Float']>;
  fee_6?: Maybe<Scalars['Float']>;
  fee_7?: Maybe<Scalars['Float']>;
  fee_8?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Stddev_Pop_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deal_Detail_Volumes_Stddev_Samp_Fields = {
  __typename?: 'deal_detail_volumes_stddev_samp_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  day_cnt?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
  fee_1?: Maybe<Scalars['Float']>;
  fee_2?: Maybe<Scalars['Float']>;
  fee_3?: Maybe<Scalars['Float']>;
  fee_4?: Maybe<Scalars['Float']>;
  fee_5?: Maybe<Scalars['Float']>;
  fee_6?: Maybe<Scalars['Float']>;
  fee_7?: Maybe<Scalars['Float']>;
  fee_8?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Stddev_Samp_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deal_Detail_Volumes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Detail_Volumes_Stream_Cursor_Value_Input = {
  actual_mcf?: Maybe<Scalars['float8']>;
  actual_volume?: Maybe<Scalars['float8']>;
  actualized?: Maybe<Scalars['Boolean']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  day_cnt?: Maybe<Scalars['Int']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1?: Maybe<Scalars['float8']>;
  fee_2?: Maybe<Scalars['float8']>;
  fee_3?: Maybe<Scalars['float8']>;
  fee_4?: Maybe<Scalars['float8']>;
  fee_5?: Maybe<Scalars['float8']>;
  fee_6?: Maybe<Scalars['float8']>;
  fee_7?: Maybe<Scalars['float8']>;
  fee_8?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  normalized?: Maybe<Scalars['Boolean']>;
  read_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date';
  actual_volume?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  trigger_volume?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_aggregate';
  aggregate?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Fields>;
  nodes: Array<Deal_Detail_Volumes_Sum_By_Beg_Date>;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp = {
  avg?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Avg>;
  corr?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Var_Samp>;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Avg = {
  arguments: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Corr = {
  arguments: Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Max = {
  arguments: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Min = {
  arguments: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Sum = {
  arguments: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_aggregate_fields';
  avg?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Max_Fields>;
  min?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Min_Fields>;
  stddev?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Fields>;
  stddev_pop?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Samp_Fields>;
  sum?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Sum_Fields>;
  var_pop?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Var_Pop_Fields>;
  var_samp?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Var_Samp_Fields>;
  variance?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Variance_Fields>;
};


/** aggregate fields of "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Order_By = {
  avg?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Max_Order_By>;
  min?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Min_Order_By>;
  stddev?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Order_By>;
  stddev_pop?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Samp_Order_By>;
  sum?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Sum_Order_By>;
  var_pop?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Var_Pop_Order_By>;
  var_samp?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Var_Samp_Order_By>;
  variance?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Arr_Rel_Insert_Input = {
  data: Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Insert_Input>;
};

/** aggregate avg on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Avg_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_avg_fields';
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  trigger_volume?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Avg_Order_By = {
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal_detail_volumes_sum_by_beg_date". All fields are combined with a logical 'AND'. */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp = {
  _and?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>>;
  _not?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
  _or?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>>;
  actual_volume?: Maybe<Float8_Comparison_Exp>;
  beg_date?: Maybe<Date_Comparison_Exp>;
  contract_volume?: Maybe<Float8_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  trigger_volume?: Maybe<Float8_Comparison_Exp>;
};

/** input type for inserting data into table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Insert_Input = {
  actual_volume?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  trigger_volume?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Max_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_max_fields';
  actual_volume?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  trigger_volume?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Max_Order_By = {
  actual_volume?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Min_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_min_fields';
  actual_volume?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  trigger_volume?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Min_Order_By = {
  actual_volume?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "deal_detail_volumes_sum_by_beg_date". */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Order_By = {
  actual_volume?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** select columns of table "deal_detail_volumes_sum_by_beg_date" */
export enum Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column {
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  BegDate = 'beg_date',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  TriggerVolume = 'trigger_volume'
}

/** select "deal_detail_volumes_sum_by_beg_date_aggregate_bool_exp_avg_arguments_columns" columns of table "deal_detail_volumes_sum_by_beg_date" */
export enum Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  TriggerVolume = 'trigger_volume'
}

/** select "deal_detail_volumes_sum_by_beg_date_aggregate_bool_exp_corr_arguments_columns" columns of table "deal_detail_volumes_sum_by_beg_date" */
export enum Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  TriggerVolume = 'trigger_volume'
}

/** select "deal_detail_volumes_sum_by_beg_date_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "deal_detail_volumes_sum_by_beg_date" */
export enum Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  TriggerVolume = 'trigger_volume'
}

/** select "deal_detail_volumes_sum_by_beg_date_aggregate_bool_exp_max_arguments_columns" columns of table "deal_detail_volumes_sum_by_beg_date" */
export enum Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  TriggerVolume = 'trigger_volume'
}

/** select "deal_detail_volumes_sum_by_beg_date_aggregate_bool_exp_min_arguments_columns" columns of table "deal_detail_volumes_sum_by_beg_date" */
export enum Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  TriggerVolume = 'trigger_volume'
}

/** select "deal_detail_volumes_sum_by_beg_date_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "deal_detail_volumes_sum_by_beg_date" */
export enum Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  TriggerVolume = 'trigger_volume'
}

/** select "deal_detail_volumes_sum_by_beg_date_aggregate_bool_exp_sum_arguments_columns" columns of table "deal_detail_volumes_sum_by_beg_date" */
export enum Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  TriggerVolume = 'trigger_volume'
}

/** select "deal_detail_volumes_sum_by_beg_date_aggregate_bool_exp_var_samp_arguments_columns" columns of table "deal_detail_volumes_sum_by_beg_date" */
export enum Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column_Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  TriggerVolume = 'trigger_volume'
}

/** aggregate stddev on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_stddev_fields';
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  trigger_volume?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Order_By = {
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Pop_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_stddev_pop_fields';
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  trigger_volume?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Pop_Order_By = {
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Samp_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_stddev_samp_fields';
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  trigger_volume?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Stddev_Samp_Order_By = {
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** Streaming cursor of the table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deal_Detail_Volumes_Sum_By_Beg_Date_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Stream_Cursor_Value_Input = {
  actual_volume?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  contract_volume?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  trigger_volume?: Maybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Sum_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_sum_fields';
  actual_volume?: Maybe<Scalars['float8']>;
  contract_volume?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  trigger_volume?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Sum_Order_By = {
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Var_Pop_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_var_pop_fields';
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  trigger_volume?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Var_Pop_Order_By = {
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Var_Samp_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_var_samp_fields';
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  trigger_volume?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Var_Samp_Order_By = {
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Variance_Fields = {
  __typename?: 'deal_detail_volumes_sum_by_beg_date_variance_fields';
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  trigger_volume?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deal_detail_volumes_sum_by_beg_date" */
export type Deal_Detail_Volumes_Sum_By_Beg_Date_Variance_Order_By = {
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  trigger_volume?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Deal_Detail_Volumes_Sum_Fields = {
  __typename?: 'deal_detail_volumes_sum_fields';
  actual_mcf?: Maybe<Scalars['float8']>;
  actual_volume?: Maybe<Scalars['float8']>;
  contract_volume?: Maybe<Scalars['float8']>;
  day_cnt?: Maybe<Scalars['Int']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  fee_1?: Maybe<Scalars['float8']>;
  fee_2?: Maybe<Scalars['float8']>;
  fee_3?: Maybe<Scalars['float8']>;
  fee_4?: Maybe<Scalars['float8']>;
  fee_5?: Maybe<Scalars['float8']>;
  fee_6?: Maybe<Scalars['float8']>;
  fee_7?: Maybe<Scalars['float8']>;
  fee_8?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Sum_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "deal_detail_volumes" */
export enum Deal_Detail_Volumes_Update_Column {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  ActualVolume = 'actual_volume',
  /** column name */
  Actualized = 'actualized',
  /** column name */
  BegDate = 'beg_date',
  /** column name */
  BeginReadDate = 'begin_read_date',
  /** column name */
  ContractVolume = 'contract_volume',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayCnt = 'day_cnt',
  /** column name */
  DealDetailId = 'deal_detail_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Fee_1 = 'fee_1',
  /** column name */
  Fee_2 = 'fee_2',
  /** column name */
  Fee_3 = 'fee_3',
  /** column name */
  Fee_4 = 'fee_4',
  /** column name */
  Fee_5 = 'fee_5',
  /** column name */
  Fee_6 = 'fee_6',
  /** column name */
  Fee_7 = 'fee_7',
  /** column name */
  Fee_8 = 'fee_8',
  /** column name */
  Id = 'id',
  /** column name */
  Normalized = 'normalized',
  /** column name */
  ReadDate = 'read_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Deal_Detail_Volumes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Deal_Detail_Volumes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deal_Detail_Volumes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Detail_Volumes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deal_Detail_Volumes_Var_Pop_Fields = {
  __typename?: 'deal_detail_volumes_var_pop_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  day_cnt?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
  fee_1?: Maybe<Scalars['Float']>;
  fee_2?: Maybe<Scalars['Float']>;
  fee_3?: Maybe<Scalars['Float']>;
  fee_4?: Maybe<Scalars['Float']>;
  fee_5?: Maybe<Scalars['Float']>;
  fee_6?: Maybe<Scalars['Float']>;
  fee_7?: Maybe<Scalars['Float']>;
  fee_8?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Var_Pop_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deal_Detail_Volumes_Var_Samp_Fields = {
  __typename?: 'deal_detail_volumes_var_samp_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  day_cnt?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
  fee_1?: Maybe<Scalars['Float']>;
  fee_2?: Maybe<Scalars['Float']>;
  fee_3?: Maybe<Scalars['Float']>;
  fee_4?: Maybe<Scalars['Float']>;
  fee_5?: Maybe<Scalars['Float']>;
  fee_6?: Maybe<Scalars['Float']>;
  fee_7?: Maybe<Scalars['Float']>;
  fee_8?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Var_Samp_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Deal_Detail_Volumes_Variance_Fields = {
  __typename?: 'deal_detail_volumes_variance_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  actual_volume?: Maybe<Scalars['Float']>;
  contract_volume?: Maybe<Scalars['Float']>;
  day_cnt?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
  fee_1?: Maybe<Scalars['Float']>;
  fee_2?: Maybe<Scalars['Float']>;
  fee_3?: Maybe<Scalars['Float']>;
  fee_4?: Maybe<Scalars['Float']>;
  fee_5?: Maybe<Scalars['Float']>;
  fee_6?: Maybe<Scalars['Float']>;
  fee_7?: Maybe<Scalars['Float']>;
  fee_8?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deal_detail_volumes" */
export type Deal_Detail_Volumes_Variance_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  actual_volume?: Maybe<Order_By>;
  contract_volume?: Maybe<Order_By>;
  day_cnt?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  fee_1?: Maybe<Order_By>;
  fee_2?: Maybe<Order_By>;
  fee_3?: Maybe<Order_By>;
  fee_4?: Maybe<Order_By>;
  fee_5?: Maybe<Order_By>;
  fee_6?: Maybe<Order_By>;
  fee_7?: Maybe<Order_By>;
  fee_8?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "deal_details" */
export type Deal_Details = {
  __typename?: 'deal_details';
  account_number?: Maybe<Scalars['String']>;
  actual_mcf?: Maybe<Scalars['float8']>;
  additional_price_details?: Maybe<Scalars['jsonb']>;
  base_margin: Scalars['float8'];
  beg_date?: Maybe<Scalars['date']>;
  bill_code?: Maybe<Scalars['String']>;
  calc_override?: Maybe<Scalars['Boolean']>;
  commission_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  commodity?: Maybe<Indices>;
  commodity_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  current?: Maybe<Scalars['Boolean']>;
  daily_volume?: Maybe<Scalars['Boolean']>;
  dd_trade_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  deal?: Maybe<Deals>;
  /** An array relationship */
  deal_detail_volumes: Array<Deal_Detail_Volumes>;
  /** An aggregate relationship */
  deal_detail_volumes_aggregate: Deal_Detail_Volumes_Aggregate;
  deal_id?: Maybe<Scalars['Int']>;
  delivery_point?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['float8']>;
  down_stream?: Maybe<Scalars['Boolean']>;
  down_stream_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  fee_1?: Maybe<Fees>;
  fee_1_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fee_2?: Maybe<Fees>;
  fee_2_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fee_3?: Maybe<Fees>;
  fee_3_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fee_4?: Maybe<Fees>;
  fee_4_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fee_5?: Maybe<Fees>;
  fee_5_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fee_6?: Maybe<Fees>;
  fee_6_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fee_7?: Maybe<Fees>;
  fee_7_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fee_8?: Maybe<Fees>;
  fee_8_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  /** An object relationship */
  franchise_fee?: Maybe<Indices>;
  franchise_fee_id?: Maybe<Scalars['Int']>;
  from_sales?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  fuel?: Maybe<Indices>;
  fuel_id?: Maybe<Scalars['Int']>;
  fuel_percentage: Scalars['float8'];
  id: Scalars['Int'];
  incremental_margin?: Maybe<Scalars['float8']>;
  /** An object relationship */
  index?: Maybe<Indices>;
  index_id?: Maybe<Scalars['Int']>;
  index_premium: Scalars['float8'];
  /** An object relationship */
  invoice_data?: Maybe<Invoice_Data>;
  invoice_from_ldc?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  meter_number?: Maybe<Scalars['String']>;
  offset_supply_id?: Maybe<Scalars['Int']>;
  option_price?: Maybe<Scalars['float8']>;
  over_discount?: Maybe<Scalars['float8']>;
  over_premium?: Maybe<Scalars['float8']>;
  over_under_calc_type_id?: Maybe<Scalars['bigint']>;
  partial_requirements?: Maybe<Scalars['Boolean']>;
  pathed?: Maybe<Scalars['Boolean']>;
  pipe_commodity: Scalars['float8'];
  /** An object relationship */
  pipeline?: Maybe<Pipelines>;
  pipeline_id?: Maybe<Scalars['Int']>;
  premise_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  price_adj?: Maybe<Indices>;
  price_adj_id?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Boolean']>;
  reviewed?: Maybe<Scalars['Boolean']>;
  rfp?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  risk_type?: Maybe<Risk_Types>;
  risk_type_id?: Maybe<Scalars['Int']>;
  rolled_ind?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['Int']>;
  /** An object relationship */
  service_address?: Maybe<Addresses>;
  service_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  service_id_load_forecast: Array<Load_Forecasts>;
  /** An aggregate relationship */
  service_id_load_forecast_aggregate: Load_Forecasts_Aggregate;
  /** An object relationship */
  supply_fee?: Maybe<Indices>;
  supply_fee_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  swing_index?: Maybe<Indices>;
  swing_index_id?: Maybe<Scalars['Int']>;
  swing_line?: Maybe<Scalars['Boolean']>;
  telemetered?: Maybe<Scalars['Boolean']>;
  transport?: Maybe<Scalars['Boolean']>;
  transport_cost: Scalars['float8'];
  trigger?: Maybe<Scalars['Boolean']>;
  under_discount?: Maybe<Scalars['float8']>;
  under_premium: Scalars['float8'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  utility?: Maybe<Pipelines>;
  utility_billed?: Maybe<Scalars['Boolean']>;
  utility_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "deal_details" */
export type Deal_DetailsAdditional_Price_DetailsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "deal_details" */
export type Deal_DetailsDeal_Detail_VolumesArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
};


/** columns and relationships of "deal_details" */
export type Deal_DetailsDeal_Detail_Volumes_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
};


/** columns and relationships of "deal_details" */
export type Deal_DetailsService_Id_Load_ForecastArgs = {
  distinct_on?: Maybe<Array<Load_Forecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_Forecasts_Order_By>>;
  where?: Maybe<Load_Forecasts_Bool_Exp>;
};


/** columns and relationships of "deal_details" */
export type Deal_DetailsService_Id_Load_Forecast_AggregateArgs = {
  distinct_on?: Maybe<Array<Load_Forecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_Forecasts_Order_By>>;
  where?: Maybe<Load_Forecasts_Bool_Exp>;
};

/** aggregated selection of "deal_details" */
export type Deal_Details_Aggregate = {
  __typename?: 'deal_details_aggregate';
  aggregate?: Maybe<Deal_Details_Aggregate_Fields>;
  nodes: Array<Deal_Details>;
};

export type Deal_Details_Aggregate_Bool_Exp = {
  avg?: Maybe<Deal_Details_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Deal_Details_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Deal_Details_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Deal_Details_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Deal_Details_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Deal_Details_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Deal_Details_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Deal_Details_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Deal_Details_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Deal_Details_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Deal_Details_Aggregate_Bool_Exp_Var_Samp>;
};

export type Deal_Details_Aggregate_Bool_Exp_Avg = {
  arguments: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Bool_And = {
  arguments: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Corr = {
  arguments: Deal_Details_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Deal_Details_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Deal_Details_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Deal_Details_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Deal_Details_Aggregate_Bool_Exp_Max = {
  arguments: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Min = {
  arguments: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Sum = {
  arguments: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deal_Details_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deal_Details_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "deal_details" */
export type Deal_Details_Aggregate_Fields = {
  __typename?: 'deal_details_aggregate_fields';
  avg?: Maybe<Deal_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deal_Details_Max_Fields>;
  min?: Maybe<Deal_Details_Min_Fields>;
  stddev?: Maybe<Deal_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Deal_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deal_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Deal_Details_Sum_Fields>;
  var_pop?: Maybe<Deal_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Deal_Details_Var_Samp_Fields>;
  variance?: Maybe<Deal_Details_Variance_Fields>;
};


/** aggregate fields of "deal_details" */
export type Deal_Details_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deal_Details_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deal_details" */
export type Deal_Details_Aggregate_Order_By = {
  avg?: Maybe<Deal_Details_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Deal_Details_Max_Order_By>;
  min?: Maybe<Deal_Details_Min_Order_By>;
  stddev?: Maybe<Deal_Details_Stddev_Order_By>;
  stddev_pop?: Maybe<Deal_Details_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Deal_Details_Stddev_Samp_Order_By>;
  sum?: Maybe<Deal_Details_Sum_Order_By>;
  var_pop?: Maybe<Deal_Details_Var_Pop_Order_By>;
  var_samp?: Maybe<Deal_Details_Var_Samp_Order_By>;
  variance?: Maybe<Deal_Details_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Deal_Details_Append_Input = {
  additional_price_details?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "deal_details" */
export type Deal_Details_Arr_Rel_Insert_Input = {
  data: Array<Deal_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deal_Details_On_Conflict>;
};

/** aggregate avg on columns */
export type Deal_Details_Avg_Fields = {
  __typename?: 'deal_details_avg_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  base_margin?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  commodity_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  down_stream_id?: Maybe<Scalars['Float']>;
  fee_1_id?: Maybe<Scalars['Float']>;
  fee_2_id?: Maybe<Scalars['Float']>;
  fee_3_id?: Maybe<Scalars['Float']>;
  fee_4_id?: Maybe<Scalars['Float']>;
  fee_5_id?: Maybe<Scalars['Float']>;
  fee_6_id?: Maybe<Scalars['Float']>;
  fee_7_id?: Maybe<Scalars['Float']>;
  fee_8_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  franchise_fee_id?: Maybe<Scalars['Float']>;
  fuel_id?: Maybe<Scalars['Float']>;
  fuel_percentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incremental_margin?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  index_premium?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  offset_supply_id?: Maybe<Scalars['Float']>;
  option_price?: Maybe<Scalars['Float']>;
  over_discount?: Maybe<Scalars['Float']>;
  over_premium?: Maybe<Scalars['Float']>;
  over_under_calc_type_id?: Maybe<Scalars['Float']>;
  pipe_commodity?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  price_adj_id?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
  segment?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  supply_fee_id?: Maybe<Scalars['Float']>;
  swing_index_id?: Maybe<Scalars['Float']>;
  transport_cost?: Maybe<Scalars['Float']>;
  under_discount?: Maybe<Scalars['Float']>;
  under_premium?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deal_details" */
export type Deal_Details_Avg_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal_details". All fields are combined with a logical 'AND'. */
export type Deal_Details_Bool_Exp = {
  _and?: Maybe<Array<Deal_Details_Bool_Exp>>;
  _not?: Maybe<Deal_Details_Bool_Exp>;
  _or?: Maybe<Array<Deal_Details_Bool_Exp>>;
  account_number?: Maybe<String_Comparison_Exp>;
  actual_mcf?: Maybe<Float8_Comparison_Exp>;
  additional_price_details?: Maybe<Jsonb_Comparison_Exp>;
  base_margin?: Maybe<Float8_Comparison_Exp>;
  beg_date?: Maybe<Date_Comparison_Exp>;
  bill_code?: Maybe<String_Comparison_Exp>;
  calc_override?: Maybe<Boolean_Comparison_Exp>;
  commission_id?: Maybe<Int_Comparison_Exp>;
  commodity?: Maybe<Indices_Bool_Exp>;
  commodity_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current?: Maybe<Boolean_Comparison_Exp>;
  daily_volume?: Maybe<Boolean_Comparison_Exp>;
  dd_trade_date?: Maybe<Date_Comparison_Exp>;
  deal?: Maybe<Deals_Bool_Exp>;
  deal_detail_volumes?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
  deal_detail_volumes_aggregate?: Maybe<Deal_Detail_Volumes_Aggregate_Bool_Exp>;
  deal_id?: Maybe<Int_Comparison_Exp>;
  delivery_point?: Maybe<String_Comparison_Exp>;
  discount?: Maybe<Float8_Comparison_Exp>;
  down_stream?: Maybe<Boolean_Comparison_Exp>;
  down_stream_id?: Maybe<Int_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  fee_1?: Maybe<Fees_Bool_Exp>;
  fee_1_id?: Maybe<Int_Comparison_Exp>;
  fee_2?: Maybe<Fees_Bool_Exp>;
  fee_2_id?: Maybe<Int_Comparison_Exp>;
  fee_3?: Maybe<Fees_Bool_Exp>;
  fee_3_id?: Maybe<Int_Comparison_Exp>;
  fee_4?: Maybe<Fees_Bool_Exp>;
  fee_4_id?: Maybe<Int_Comparison_Exp>;
  fee_5?: Maybe<Fees_Bool_Exp>;
  fee_5_id?: Maybe<Int_Comparison_Exp>;
  fee_6?: Maybe<Fees_Bool_Exp>;
  fee_6_id?: Maybe<Int_Comparison_Exp>;
  fee_7?: Maybe<Fees_Bool_Exp>;
  fee_7_id?: Maybe<Int_Comparison_Exp>;
  fee_8?: Maybe<Fees_Bool_Exp>;
  fee_8_id?: Maybe<Int_Comparison_Exp>;
  fr_req?: Maybe<Float8_Comparison_Exp>;
  franchise_fee?: Maybe<Indices_Bool_Exp>;
  franchise_fee_id?: Maybe<Int_Comparison_Exp>;
  from_sales?: Maybe<Boolean_Comparison_Exp>;
  fuel?: Maybe<Indices_Bool_Exp>;
  fuel_id?: Maybe<Int_Comparison_Exp>;
  fuel_percentage?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  incremental_margin?: Maybe<Float8_Comparison_Exp>;
  index?: Maybe<Indices_Bool_Exp>;
  index_id?: Maybe<Int_Comparison_Exp>;
  index_premium?: Maybe<Float8_Comparison_Exp>;
  invoice_data?: Maybe<Invoice_Data_Bool_Exp>;
  invoice_from_ldc?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  mdq?: Maybe<Float8_Comparison_Exp>;
  meter_number?: Maybe<String_Comparison_Exp>;
  offset_supply_id?: Maybe<Int_Comparison_Exp>;
  option_price?: Maybe<Float8_Comparison_Exp>;
  over_discount?: Maybe<Float8_Comparison_Exp>;
  over_premium?: Maybe<Float8_Comparison_Exp>;
  over_under_calc_type_id?: Maybe<Bigint_Comparison_Exp>;
  partial_requirements?: Maybe<Boolean_Comparison_Exp>;
  pathed?: Maybe<Boolean_Comparison_Exp>;
  pipe_commodity?: Maybe<Float8_Comparison_Exp>;
  pipeline?: Maybe<Pipelines_Bool_Exp>;
  pipeline_id?: Maybe<Int_Comparison_Exp>;
  premise_number?: Maybe<String_Comparison_Exp>;
  price_adj?: Maybe<Indices_Bool_Exp>;
  price_adj_id?: Maybe<Int_Comparison_Exp>;
  primary?: Maybe<Boolean_Comparison_Exp>;
  reviewed?: Maybe<Boolean_Comparison_Exp>;
  rfp?: Maybe<Boolean_Comparison_Exp>;
  risk_type?: Maybe<Risk_Types_Bool_Exp>;
  risk_type_id?: Maybe<Int_Comparison_Exp>;
  rolled_ind?: Maybe<Boolean_Comparison_Exp>;
  segment?: Maybe<Int_Comparison_Exp>;
  service_address?: Maybe<Addresses_Bool_Exp>;
  service_id?: Maybe<Int_Comparison_Exp>;
  service_id_load_forecast?: Maybe<Load_Forecasts_Bool_Exp>;
  service_id_load_forecast_aggregate?: Maybe<Load_Forecasts_Aggregate_Bool_Exp>;
  supply_fee?: Maybe<Indices_Bool_Exp>;
  supply_fee_id?: Maybe<Int_Comparison_Exp>;
  swing_index?: Maybe<Indices_Bool_Exp>;
  swing_index_id?: Maybe<Int_Comparison_Exp>;
  swing_line?: Maybe<Boolean_Comparison_Exp>;
  telemetered?: Maybe<Boolean_Comparison_Exp>;
  transport?: Maybe<Boolean_Comparison_Exp>;
  transport_cost?: Maybe<Float8_Comparison_Exp>;
  trigger?: Maybe<Boolean_Comparison_Exp>;
  under_discount?: Maybe<Float8_Comparison_Exp>;
  under_premium?: Maybe<Float8_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  utility?: Maybe<Pipelines_Bool_Exp>;
  utility_billed?: Maybe<Boolean_Comparison_Exp>;
  utility_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "deal_details" */
export enum Deal_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealDetailsPkey = 'deal_details_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Deal_Details_Delete_At_Path_Input = {
  additional_price_details?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Deal_Details_Delete_Elem_Input = {
  additional_price_details?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Deal_Details_Delete_Key_Input = {
  additional_price_details?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "deal_details" */
export type Deal_Details_Inc_Input = {
  actual_mcf?: Maybe<Scalars['float8']>;
  base_margin?: Maybe<Scalars['float8']>;
  commission_id?: Maybe<Scalars['Int']>;
  commodity_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['float8']>;
  down_stream_id?: Maybe<Scalars['Int']>;
  fee_1_id?: Maybe<Scalars['Int']>;
  fee_2_id?: Maybe<Scalars['Int']>;
  fee_3_id?: Maybe<Scalars['Int']>;
  fee_4_id?: Maybe<Scalars['Int']>;
  fee_5_id?: Maybe<Scalars['Int']>;
  fee_6_id?: Maybe<Scalars['Int']>;
  fee_7_id?: Maybe<Scalars['Int']>;
  fee_8_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  franchise_fee_id?: Maybe<Scalars['Int']>;
  fuel_id?: Maybe<Scalars['Int']>;
  fuel_percentage?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  incremental_margin?: Maybe<Scalars['float8']>;
  index_id?: Maybe<Scalars['Int']>;
  index_premium?: Maybe<Scalars['float8']>;
  location_id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  offset_supply_id?: Maybe<Scalars['Int']>;
  option_price?: Maybe<Scalars['float8']>;
  over_discount?: Maybe<Scalars['float8']>;
  over_premium?: Maybe<Scalars['float8']>;
  over_under_calc_type_id?: Maybe<Scalars['bigint']>;
  pipe_commodity?: Maybe<Scalars['float8']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  price_adj_id?: Maybe<Scalars['Int']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  segment?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  supply_fee_id?: Maybe<Scalars['Int']>;
  swing_index_id?: Maybe<Scalars['Int']>;
  transport_cost?: Maybe<Scalars['float8']>;
  under_discount?: Maybe<Scalars['float8']>;
  under_premium?: Maybe<Scalars['float8']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "deal_details" */
export type Deal_Details_Insert_Input = {
  account_number?: Maybe<Scalars['String']>;
  actual_mcf?: Maybe<Scalars['float8']>;
  additional_price_details?: Maybe<Scalars['jsonb']>;
  base_margin?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  bill_code?: Maybe<Scalars['String']>;
  calc_override?: Maybe<Scalars['Boolean']>;
  commission_id?: Maybe<Scalars['Int']>;
  commodity?: Maybe<Indices_Obj_Rel_Insert_Input>;
  commodity_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['Boolean']>;
  daily_volume?: Maybe<Scalars['Boolean']>;
  dd_trade_date?: Maybe<Scalars['date']>;
  deal?: Maybe<Deals_Obj_Rel_Insert_Input>;
  deal_detail_volumes?: Maybe<Deal_Detail_Volumes_Arr_Rel_Insert_Input>;
  deal_id?: Maybe<Scalars['Int']>;
  delivery_point?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['float8']>;
  down_stream?: Maybe<Scalars['Boolean']>;
  down_stream_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1?: Maybe<Fees_Obj_Rel_Insert_Input>;
  fee_1_id?: Maybe<Scalars['Int']>;
  fee_2?: Maybe<Fees_Obj_Rel_Insert_Input>;
  fee_2_id?: Maybe<Scalars['Int']>;
  fee_3?: Maybe<Fees_Obj_Rel_Insert_Input>;
  fee_3_id?: Maybe<Scalars['Int']>;
  fee_4?: Maybe<Fees_Obj_Rel_Insert_Input>;
  fee_4_id?: Maybe<Scalars['Int']>;
  fee_5?: Maybe<Fees_Obj_Rel_Insert_Input>;
  fee_5_id?: Maybe<Scalars['Int']>;
  fee_6?: Maybe<Fees_Obj_Rel_Insert_Input>;
  fee_6_id?: Maybe<Scalars['Int']>;
  fee_7?: Maybe<Fees_Obj_Rel_Insert_Input>;
  fee_7_id?: Maybe<Scalars['Int']>;
  fee_8?: Maybe<Fees_Obj_Rel_Insert_Input>;
  fee_8_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  franchise_fee?: Maybe<Indices_Obj_Rel_Insert_Input>;
  franchise_fee_id?: Maybe<Scalars['Int']>;
  from_sales?: Maybe<Scalars['Boolean']>;
  fuel?: Maybe<Indices_Obj_Rel_Insert_Input>;
  fuel_id?: Maybe<Scalars['Int']>;
  fuel_percentage?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  incremental_margin?: Maybe<Scalars['float8']>;
  index?: Maybe<Indices_Obj_Rel_Insert_Input>;
  index_id?: Maybe<Scalars['Int']>;
  index_premium?: Maybe<Scalars['float8']>;
  invoice_data?: Maybe<Invoice_Data_Obj_Rel_Insert_Input>;
  invoice_from_ldc?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  meter_number?: Maybe<Scalars['String']>;
  offset_supply_id?: Maybe<Scalars['Int']>;
  option_price?: Maybe<Scalars['float8']>;
  over_discount?: Maybe<Scalars['float8']>;
  over_premium?: Maybe<Scalars['float8']>;
  over_under_calc_type_id?: Maybe<Scalars['bigint']>;
  partial_requirements?: Maybe<Scalars['Boolean']>;
  pathed?: Maybe<Scalars['Boolean']>;
  pipe_commodity?: Maybe<Scalars['float8']>;
  pipeline?: Maybe<Pipelines_Obj_Rel_Insert_Input>;
  pipeline_id?: Maybe<Scalars['Int']>;
  premise_number?: Maybe<Scalars['String']>;
  price_adj?: Maybe<Indices_Obj_Rel_Insert_Input>;
  price_adj_id?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Boolean']>;
  reviewed?: Maybe<Scalars['Boolean']>;
  rfp?: Maybe<Scalars['Boolean']>;
  risk_type?: Maybe<Risk_Types_Obj_Rel_Insert_Input>;
  risk_type_id?: Maybe<Scalars['Int']>;
  rolled_ind?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['Int']>;
  service_address?: Maybe<Addresses_Obj_Rel_Insert_Input>;
  service_id?: Maybe<Scalars['Int']>;
  service_id_load_forecast?: Maybe<Load_Forecasts_Arr_Rel_Insert_Input>;
  supply_fee?: Maybe<Indices_Obj_Rel_Insert_Input>;
  supply_fee_id?: Maybe<Scalars['Int']>;
  swing_index?: Maybe<Indices_Obj_Rel_Insert_Input>;
  swing_index_id?: Maybe<Scalars['Int']>;
  swing_line?: Maybe<Scalars['Boolean']>;
  telemetered?: Maybe<Scalars['Boolean']>;
  transport?: Maybe<Scalars['Boolean']>;
  transport_cost?: Maybe<Scalars['float8']>;
  trigger?: Maybe<Scalars['Boolean']>;
  under_discount?: Maybe<Scalars['float8']>;
  under_premium?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility?: Maybe<Pipelines_Obj_Rel_Insert_Input>;
  utility_billed?: Maybe<Scalars['Boolean']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Deal_Details_Max_Fields = {
  __typename?: 'deal_details_max_fields';
  account_number?: Maybe<Scalars['String']>;
  actual_mcf?: Maybe<Scalars['float8']>;
  base_margin?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  bill_code?: Maybe<Scalars['String']>;
  commission_id?: Maybe<Scalars['Int']>;
  commodity_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dd_trade_date?: Maybe<Scalars['date']>;
  deal_id?: Maybe<Scalars['Int']>;
  delivery_point?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['float8']>;
  down_stream_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1_id?: Maybe<Scalars['Int']>;
  fee_2_id?: Maybe<Scalars['Int']>;
  fee_3_id?: Maybe<Scalars['Int']>;
  fee_4_id?: Maybe<Scalars['Int']>;
  fee_5_id?: Maybe<Scalars['Int']>;
  fee_6_id?: Maybe<Scalars['Int']>;
  fee_7_id?: Maybe<Scalars['Int']>;
  fee_8_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  franchise_fee_id?: Maybe<Scalars['Int']>;
  fuel_id?: Maybe<Scalars['Int']>;
  fuel_percentage?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  incremental_margin?: Maybe<Scalars['float8']>;
  index_id?: Maybe<Scalars['Int']>;
  index_premium?: Maybe<Scalars['float8']>;
  location_id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  meter_number?: Maybe<Scalars['String']>;
  offset_supply_id?: Maybe<Scalars['Int']>;
  option_price?: Maybe<Scalars['float8']>;
  over_discount?: Maybe<Scalars['float8']>;
  over_premium?: Maybe<Scalars['float8']>;
  over_under_calc_type_id?: Maybe<Scalars['bigint']>;
  pipe_commodity?: Maybe<Scalars['float8']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  premise_number?: Maybe<Scalars['String']>;
  price_adj_id?: Maybe<Scalars['Int']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  segment?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  supply_fee_id?: Maybe<Scalars['Int']>;
  swing_index_id?: Maybe<Scalars['Int']>;
  transport_cost?: Maybe<Scalars['float8']>;
  under_discount?: Maybe<Scalars['float8']>;
  under_premium?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "deal_details" */
export type Deal_Details_Max_Order_By = {
  account_number?: Maybe<Order_By>;
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  bill_code?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dd_trade_date?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  delivery_point?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  meter_number?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  premise_number?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deal_Details_Min_Fields = {
  __typename?: 'deal_details_min_fields';
  account_number?: Maybe<Scalars['String']>;
  actual_mcf?: Maybe<Scalars['float8']>;
  base_margin?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  bill_code?: Maybe<Scalars['String']>;
  commission_id?: Maybe<Scalars['Int']>;
  commodity_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dd_trade_date?: Maybe<Scalars['date']>;
  deal_id?: Maybe<Scalars['Int']>;
  delivery_point?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['float8']>;
  down_stream_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1_id?: Maybe<Scalars['Int']>;
  fee_2_id?: Maybe<Scalars['Int']>;
  fee_3_id?: Maybe<Scalars['Int']>;
  fee_4_id?: Maybe<Scalars['Int']>;
  fee_5_id?: Maybe<Scalars['Int']>;
  fee_6_id?: Maybe<Scalars['Int']>;
  fee_7_id?: Maybe<Scalars['Int']>;
  fee_8_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  franchise_fee_id?: Maybe<Scalars['Int']>;
  fuel_id?: Maybe<Scalars['Int']>;
  fuel_percentage?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  incremental_margin?: Maybe<Scalars['float8']>;
  index_id?: Maybe<Scalars['Int']>;
  index_premium?: Maybe<Scalars['float8']>;
  location_id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  meter_number?: Maybe<Scalars['String']>;
  offset_supply_id?: Maybe<Scalars['Int']>;
  option_price?: Maybe<Scalars['float8']>;
  over_discount?: Maybe<Scalars['float8']>;
  over_premium?: Maybe<Scalars['float8']>;
  over_under_calc_type_id?: Maybe<Scalars['bigint']>;
  pipe_commodity?: Maybe<Scalars['float8']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  premise_number?: Maybe<Scalars['String']>;
  price_adj_id?: Maybe<Scalars['Int']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  segment?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  supply_fee_id?: Maybe<Scalars['Int']>;
  swing_index_id?: Maybe<Scalars['Int']>;
  transport_cost?: Maybe<Scalars['float8']>;
  under_discount?: Maybe<Scalars['float8']>;
  under_premium?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "deal_details" */
export type Deal_Details_Min_Order_By = {
  account_number?: Maybe<Order_By>;
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  bill_code?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dd_trade_date?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  delivery_point?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  meter_number?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  premise_number?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deal_details" */
export type Deal_Details_Mutation_Response = {
  __typename?: 'deal_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Details>;
};

/** input type for inserting object relation for remote table "deal_details" */
export type Deal_Details_Obj_Rel_Insert_Input = {
  data: Deal_Details_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deal_Details_On_Conflict>;
};

/** on_conflict condition type for table "deal_details" */
export type Deal_Details_On_Conflict = {
  constraint: Deal_Details_Constraint;
  update_columns?: Array<Deal_Details_Update_Column>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_details". */
export type Deal_Details_Order_By = {
  account_number?: Maybe<Order_By>;
  actual_mcf?: Maybe<Order_By>;
  additional_price_details?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  bill_code?: Maybe<Order_By>;
  calc_override?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity?: Maybe<Indices_Order_By>;
  commodity_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  daily_volume?: Maybe<Order_By>;
  dd_trade_date?: Maybe<Order_By>;
  deal?: Maybe<Deals_Order_By>;
  deal_detail_volumes_aggregate?: Maybe<Deal_Detail_Volumes_Aggregate_Order_By>;
  deal_id?: Maybe<Order_By>;
  delivery_point?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  fee_1?: Maybe<Fees_Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2?: Maybe<Fees_Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3?: Maybe<Fees_Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4?: Maybe<Fees_Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5?: Maybe<Fees_Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6?: Maybe<Fees_Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7?: Maybe<Fees_Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8?: Maybe<Fees_Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee?: Maybe<Indices_Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  from_sales?: Maybe<Order_By>;
  fuel?: Maybe<Indices_Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index?: Maybe<Indices_Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  invoice_data?: Maybe<Invoice_Data_Order_By>;
  invoice_from_ldc?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  meter_number?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  partial_requirements?: Maybe<Order_By>;
  pathed?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline?: Maybe<Pipelines_Order_By>;
  pipeline_id?: Maybe<Order_By>;
  premise_number?: Maybe<Order_By>;
  price_adj?: Maybe<Indices_Order_By>;
  price_adj_id?: Maybe<Order_By>;
  primary?: Maybe<Order_By>;
  reviewed?: Maybe<Order_By>;
  rfp?: Maybe<Order_By>;
  risk_type?: Maybe<Risk_Types_Order_By>;
  risk_type_id?: Maybe<Order_By>;
  rolled_ind?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_address?: Maybe<Addresses_Order_By>;
  service_id?: Maybe<Order_By>;
  service_id_load_forecast_aggregate?: Maybe<Load_Forecasts_Aggregate_Order_By>;
  supply_fee?: Maybe<Indices_Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index?: Maybe<Indices_Order_By>;
  swing_index_id?: Maybe<Order_By>;
  swing_line?: Maybe<Order_By>;
  telemetered?: Maybe<Order_By>;
  transport?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  trigger?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility?: Maybe<Pipelines_Order_By>;
  utility_billed?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deal_details */
export type Deal_Details_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Deal_Details_Prepend_Input = {
  additional_price_details?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "deal_details" */
export enum Deal_Details_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  AdditionalPriceDetails = 'additional_price_details',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  BegDate = 'beg_date',
  /** column name */
  BillCode = 'bill_code',
  /** column name */
  CalcOverride = 'calc_override',
  /** column name */
  CommissionId = 'commission_id',
  /** column name */
  CommodityId = 'commodity_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Current = 'current',
  /** column name */
  DailyVolume = 'daily_volume',
  /** column name */
  DdTradeDate = 'dd_trade_date',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DeliveryPoint = 'delivery_point',
  /** column name */
  Discount = 'discount',
  /** column name */
  DownStream = 'down_stream',
  /** column name */
  DownStreamId = 'down_stream_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Fee_1Id = 'fee_1_id',
  /** column name */
  Fee_2Id = 'fee_2_id',
  /** column name */
  Fee_3Id = 'fee_3_id',
  /** column name */
  Fee_4Id = 'fee_4_id',
  /** column name */
  Fee_5Id = 'fee_5_id',
  /** column name */
  Fee_6Id = 'fee_6_id',
  /** column name */
  Fee_7Id = 'fee_7_id',
  /** column name */
  Fee_8Id = 'fee_8_id',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FranchiseFeeId = 'franchise_fee_id',
  /** column name */
  FromSales = 'from_sales',
  /** column name */
  FuelId = 'fuel_id',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  Id = 'id',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  InvoiceFromLdc = 'invoice_from_ldc',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  MeterNumber = 'meter_number',
  /** column name */
  OffsetSupplyId = 'offset_supply_id',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  OverUnderCalcTypeId = 'over_under_calc_type_id',
  /** column name */
  PartialRequirements = 'partial_requirements',
  /** column name */
  Pathed = 'pathed',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  PremiseNumber = 'premise_number',
  /** column name */
  PriceAdjId = 'price_adj_id',
  /** column name */
  Primary = 'primary',
  /** column name */
  Reviewed = 'reviewed',
  /** column name */
  Rfp = 'rfp',
  /** column name */
  RiskTypeId = 'risk_type_id',
  /** column name */
  RolledInd = 'rolled_ind',
  /** column name */
  Segment = 'segment',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  SupplyFeeId = 'supply_fee_id',
  /** column name */
  SwingIndexId = 'swing_index_id',
  /** column name */
  SwingLine = 'swing_line',
  /** column name */
  Telemetered = 'telemetered',
  /** column name */
  Transport = 'transport',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  Trigger = 'trigger',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityBilled = 'utility_billed',
  /** column name */
  UtilityId = 'utility_id'
}

/** select "deal_details_aggregate_bool_exp_avg_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  Discount = 'discount',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium'
}

/** select "deal_details_aggregate_bool_exp_bool_and_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CalcOverride = 'calc_override',
  /** column name */
  Current = 'current',
  /** column name */
  DailyVolume = 'daily_volume',
  /** column name */
  DownStream = 'down_stream',
  /** column name */
  FromSales = 'from_sales',
  /** column name */
  InvoiceFromLdc = 'invoice_from_ldc',
  /** column name */
  PartialRequirements = 'partial_requirements',
  /** column name */
  Pathed = 'pathed',
  /** column name */
  Primary = 'primary',
  /** column name */
  Reviewed = 'reviewed',
  /** column name */
  Rfp = 'rfp',
  /** column name */
  RolledInd = 'rolled_ind',
  /** column name */
  SwingLine = 'swing_line',
  /** column name */
  Telemetered = 'telemetered',
  /** column name */
  Transport = 'transport',
  /** column name */
  Trigger = 'trigger',
  /** column name */
  UtilityBilled = 'utility_billed'
}

/** select "deal_details_aggregate_bool_exp_bool_or_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CalcOverride = 'calc_override',
  /** column name */
  Current = 'current',
  /** column name */
  DailyVolume = 'daily_volume',
  /** column name */
  DownStream = 'down_stream',
  /** column name */
  FromSales = 'from_sales',
  /** column name */
  InvoiceFromLdc = 'invoice_from_ldc',
  /** column name */
  PartialRequirements = 'partial_requirements',
  /** column name */
  Pathed = 'pathed',
  /** column name */
  Primary = 'primary',
  /** column name */
  Reviewed = 'reviewed',
  /** column name */
  Rfp = 'rfp',
  /** column name */
  RolledInd = 'rolled_ind',
  /** column name */
  SwingLine = 'swing_line',
  /** column name */
  Telemetered = 'telemetered',
  /** column name */
  Transport = 'transport',
  /** column name */
  Trigger = 'trigger',
  /** column name */
  UtilityBilled = 'utility_billed'
}

/** select "deal_details_aggregate_bool_exp_corr_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  Discount = 'discount',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium'
}

/** select "deal_details_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  Discount = 'discount',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium'
}

/** select "deal_details_aggregate_bool_exp_max_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  Discount = 'discount',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium'
}

/** select "deal_details_aggregate_bool_exp_min_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  Discount = 'discount',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium'
}

/** select "deal_details_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  Discount = 'discount',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium'
}

/** select "deal_details_aggregate_bool_exp_sum_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  Discount = 'discount',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium'
}

/** select "deal_details_aggregate_bool_exp_var_samp_arguments_columns" columns of table "deal_details" */
export enum Deal_Details_Select_Column_Deal_Details_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  Discount = 'discount',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium'
}

/** input type for updating data in table "deal_details" */
export type Deal_Details_Set_Input = {
  account_number?: Maybe<Scalars['String']>;
  actual_mcf?: Maybe<Scalars['float8']>;
  additional_price_details?: Maybe<Scalars['jsonb']>;
  base_margin?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  bill_code?: Maybe<Scalars['String']>;
  calc_override?: Maybe<Scalars['Boolean']>;
  commission_id?: Maybe<Scalars['Int']>;
  commodity_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['Boolean']>;
  daily_volume?: Maybe<Scalars['Boolean']>;
  dd_trade_date?: Maybe<Scalars['date']>;
  deal_id?: Maybe<Scalars['Int']>;
  delivery_point?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['float8']>;
  down_stream?: Maybe<Scalars['Boolean']>;
  down_stream_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1_id?: Maybe<Scalars['Int']>;
  fee_2_id?: Maybe<Scalars['Int']>;
  fee_3_id?: Maybe<Scalars['Int']>;
  fee_4_id?: Maybe<Scalars['Int']>;
  fee_5_id?: Maybe<Scalars['Int']>;
  fee_6_id?: Maybe<Scalars['Int']>;
  fee_7_id?: Maybe<Scalars['Int']>;
  fee_8_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  franchise_fee_id?: Maybe<Scalars['Int']>;
  from_sales?: Maybe<Scalars['Boolean']>;
  fuel_id?: Maybe<Scalars['Int']>;
  fuel_percentage?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  incremental_margin?: Maybe<Scalars['float8']>;
  index_id?: Maybe<Scalars['Int']>;
  index_premium?: Maybe<Scalars['float8']>;
  invoice_from_ldc?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  meter_number?: Maybe<Scalars['String']>;
  offset_supply_id?: Maybe<Scalars['Int']>;
  option_price?: Maybe<Scalars['float8']>;
  over_discount?: Maybe<Scalars['float8']>;
  over_premium?: Maybe<Scalars['float8']>;
  over_under_calc_type_id?: Maybe<Scalars['bigint']>;
  partial_requirements?: Maybe<Scalars['Boolean']>;
  pathed?: Maybe<Scalars['Boolean']>;
  pipe_commodity?: Maybe<Scalars['float8']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  premise_number?: Maybe<Scalars['String']>;
  price_adj_id?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Boolean']>;
  reviewed?: Maybe<Scalars['Boolean']>;
  rfp?: Maybe<Scalars['Boolean']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  rolled_ind?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  supply_fee_id?: Maybe<Scalars['Int']>;
  swing_index_id?: Maybe<Scalars['Int']>;
  swing_line?: Maybe<Scalars['Boolean']>;
  telemetered?: Maybe<Scalars['Boolean']>;
  transport?: Maybe<Scalars['Boolean']>;
  transport_cost?: Maybe<Scalars['float8']>;
  trigger?: Maybe<Scalars['Boolean']>;
  under_discount?: Maybe<Scalars['float8']>;
  under_premium?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_billed?: Maybe<Scalars['Boolean']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Deal_Details_Stddev_Fields = {
  __typename?: 'deal_details_stddev_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  base_margin?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  commodity_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  down_stream_id?: Maybe<Scalars['Float']>;
  fee_1_id?: Maybe<Scalars['Float']>;
  fee_2_id?: Maybe<Scalars['Float']>;
  fee_3_id?: Maybe<Scalars['Float']>;
  fee_4_id?: Maybe<Scalars['Float']>;
  fee_5_id?: Maybe<Scalars['Float']>;
  fee_6_id?: Maybe<Scalars['Float']>;
  fee_7_id?: Maybe<Scalars['Float']>;
  fee_8_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  franchise_fee_id?: Maybe<Scalars['Float']>;
  fuel_id?: Maybe<Scalars['Float']>;
  fuel_percentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incremental_margin?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  index_premium?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  offset_supply_id?: Maybe<Scalars['Float']>;
  option_price?: Maybe<Scalars['Float']>;
  over_discount?: Maybe<Scalars['Float']>;
  over_premium?: Maybe<Scalars['Float']>;
  over_under_calc_type_id?: Maybe<Scalars['Float']>;
  pipe_commodity?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  price_adj_id?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
  segment?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  supply_fee_id?: Maybe<Scalars['Float']>;
  swing_index_id?: Maybe<Scalars['Float']>;
  transport_cost?: Maybe<Scalars['Float']>;
  under_discount?: Maybe<Scalars['Float']>;
  under_premium?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deal_details" */
export type Deal_Details_Stddev_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deal_Details_Stddev_Pop_Fields = {
  __typename?: 'deal_details_stddev_pop_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  base_margin?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  commodity_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  down_stream_id?: Maybe<Scalars['Float']>;
  fee_1_id?: Maybe<Scalars['Float']>;
  fee_2_id?: Maybe<Scalars['Float']>;
  fee_3_id?: Maybe<Scalars['Float']>;
  fee_4_id?: Maybe<Scalars['Float']>;
  fee_5_id?: Maybe<Scalars['Float']>;
  fee_6_id?: Maybe<Scalars['Float']>;
  fee_7_id?: Maybe<Scalars['Float']>;
  fee_8_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  franchise_fee_id?: Maybe<Scalars['Float']>;
  fuel_id?: Maybe<Scalars['Float']>;
  fuel_percentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incremental_margin?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  index_premium?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  offset_supply_id?: Maybe<Scalars['Float']>;
  option_price?: Maybe<Scalars['Float']>;
  over_discount?: Maybe<Scalars['Float']>;
  over_premium?: Maybe<Scalars['Float']>;
  over_under_calc_type_id?: Maybe<Scalars['Float']>;
  pipe_commodity?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  price_adj_id?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
  segment?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  supply_fee_id?: Maybe<Scalars['Float']>;
  swing_index_id?: Maybe<Scalars['Float']>;
  transport_cost?: Maybe<Scalars['Float']>;
  under_discount?: Maybe<Scalars['Float']>;
  under_premium?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deal_details" */
export type Deal_Details_Stddev_Pop_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deal_Details_Stddev_Samp_Fields = {
  __typename?: 'deal_details_stddev_samp_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  base_margin?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  commodity_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  down_stream_id?: Maybe<Scalars['Float']>;
  fee_1_id?: Maybe<Scalars['Float']>;
  fee_2_id?: Maybe<Scalars['Float']>;
  fee_3_id?: Maybe<Scalars['Float']>;
  fee_4_id?: Maybe<Scalars['Float']>;
  fee_5_id?: Maybe<Scalars['Float']>;
  fee_6_id?: Maybe<Scalars['Float']>;
  fee_7_id?: Maybe<Scalars['Float']>;
  fee_8_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  franchise_fee_id?: Maybe<Scalars['Float']>;
  fuel_id?: Maybe<Scalars['Float']>;
  fuel_percentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incremental_margin?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  index_premium?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  offset_supply_id?: Maybe<Scalars['Float']>;
  option_price?: Maybe<Scalars['Float']>;
  over_discount?: Maybe<Scalars['Float']>;
  over_premium?: Maybe<Scalars['Float']>;
  over_under_calc_type_id?: Maybe<Scalars['Float']>;
  pipe_commodity?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  price_adj_id?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
  segment?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  supply_fee_id?: Maybe<Scalars['Float']>;
  swing_index_id?: Maybe<Scalars['Float']>;
  transport_cost?: Maybe<Scalars['Float']>;
  under_discount?: Maybe<Scalars['Float']>;
  under_premium?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deal_details" */
export type Deal_Details_Stddev_Samp_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "deal_details" */
export type Deal_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deal_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Details_Stream_Cursor_Value_Input = {
  account_number?: Maybe<Scalars['String']>;
  actual_mcf?: Maybe<Scalars['float8']>;
  additional_price_details?: Maybe<Scalars['jsonb']>;
  base_margin?: Maybe<Scalars['float8']>;
  beg_date?: Maybe<Scalars['date']>;
  bill_code?: Maybe<Scalars['String']>;
  calc_override?: Maybe<Scalars['Boolean']>;
  commission_id?: Maybe<Scalars['Int']>;
  commodity_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['Boolean']>;
  daily_volume?: Maybe<Scalars['Boolean']>;
  dd_trade_date?: Maybe<Scalars['date']>;
  deal_id?: Maybe<Scalars['Int']>;
  delivery_point?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['float8']>;
  down_stream?: Maybe<Scalars['Boolean']>;
  down_stream_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  fee_1_id?: Maybe<Scalars['Int']>;
  fee_2_id?: Maybe<Scalars['Int']>;
  fee_3_id?: Maybe<Scalars['Int']>;
  fee_4_id?: Maybe<Scalars['Int']>;
  fee_5_id?: Maybe<Scalars['Int']>;
  fee_6_id?: Maybe<Scalars['Int']>;
  fee_7_id?: Maybe<Scalars['Int']>;
  fee_8_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  franchise_fee_id?: Maybe<Scalars['Int']>;
  from_sales?: Maybe<Scalars['Boolean']>;
  fuel_id?: Maybe<Scalars['Int']>;
  fuel_percentage?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  incremental_margin?: Maybe<Scalars['float8']>;
  index_id?: Maybe<Scalars['Int']>;
  index_premium?: Maybe<Scalars['float8']>;
  invoice_from_ldc?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  meter_number?: Maybe<Scalars['String']>;
  offset_supply_id?: Maybe<Scalars['Int']>;
  option_price?: Maybe<Scalars['float8']>;
  over_discount?: Maybe<Scalars['float8']>;
  over_premium?: Maybe<Scalars['float8']>;
  over_under_calc_type_id?: Maybe<Scalars['bigint']>;
  partial_requirements?: Maybe<Scalars['Boolean']>;
  pathed?: Maybe<Scalars['Boolean']>;
  pipe_commodity?: Maybe<Scalars['float8']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  premise_number?: Maybe<Scalars['String']>;
  price_adj_id?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Boolean']>;
  reviewed?: Maybe<Scalars['Boolean']>;
  rfp?: Maybe<Scalars['Boolean']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  rolled_ind?: Maybe<Scalars['Boolean']>;
  segment?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  supply_fee_id?: Maybe<Scalars['Int']>;
  swing_index_id?: Maybe<Scalars['Int']>;
  swing_line?: Maybe<Scalars['Boolean']>;
  telemetered?: Maybe<Scalars['Boolean']>;
  transport?: Maybe<Scalars['Boolean']>;
  transport_cost?: Maybe<Scalars['float8']>;
  trigger?: Maybe<Scalars['Boolean']>;
  under_discount?: Maybe<Scalars['float8']>;
  under_premium?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_billed?: Maybe<Scalars['Boolean']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Deal_Details_Sum_Fields = {
  __typename?: 'deal_details_sum_fields';
  actual_mcf?: Maybe<Scalars['float8']>;
  base_margin?: Maybe<Scalars['float8']>;
  commission_id?: Maybe<Scalars['Int']>;
  commodity_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['float8']>;
  down_stream_id?: Maybe<Scalars['Int']>;
  fee_1_id?: Maybe<Scalars['Int']>;
  fee_2_id?: Maybe<Scalars['Int']>;
  fee_3_id?: Maybe<Scalars['Int']>;
  fee_4_id?: Maybe<Scalars['Int']>;
  fee_5_id?: Maybe<Scalars['Int']>;
  fee_6_id?: Maybe<Scalars['Int']>;
  fee_7_id?: Maybe<Scalars['Int']>;
  fee_8_id?: Maybe<Scalars['Int']>;
  fr_req?: Maybe<Scalars['float8']>;
  franchise_fee_id?: Maybe<Scalars['Int']>;
  fuel_id?: Maybe<Scalars['Int']>;
  fuel_percentage?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  incremental_margin?: Maybe<Scalars['float8']>;
  index_id?: Maybe<Scalars['Int']>;
  index_premium?: Maybe<Scalars['float8']>;
  location_id?: Maybe<Scalars['Int']>;
  mdq?: Maybe<Scalars['float8']>;
  offset_supply_id?: Maybe<Scalars['Int']>;
  option_price?: Maybe<Scalars['float8']>;
  over_discount?: Maybe<Scalars['float8']>;
  over_premium?: Maybe<Scalars['float8']>;
  over_under_calc_type_id?: Maybe<Scalars['bigint']>;
  pipe_commodity?: Maybe<Scalars['float8']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  price_adj_id?: Maybe<Scalars['Int']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  segment?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  supply_fee_id?: Maybe<Scalars['Int']>;
  swing_index_id?: Maybe<Scalars['Int']>;
  transport_cost?: Maybe<Scalars['float8']>;
  under_discount?: Maybe<Scalars['float8']>;
  under_premium?: Maybe<Scalars['float8']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "deal_details" */
export type Deal_Details_Sum_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** update columns of table "deal_details" */
export enum Deal_Details_Update_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  ActualMcf = 'actual_mcf',
  /** column name */
  AdditionalPriceDetails = 'additional_price_details',
  /** column name */
  BaseMargin = 'base_margin',
  /** column name */
  BegDate = 'beg_date',
  /** column name */
  BillCode = 'bill_code',
  /** column name */
  CalcOverride = 'calc_override',
  /** column name */
  CommissionId = 'commission_id',
  /** column name */
  CommodityId = 'commodity_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Current = 'current',
  /** column name */
  DailyVolume = 'daily_volume',
  /** column name */
  DdTradeDate = 'dd_trade_date',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DeliveryPoint = 'delivery_point',
  /** column name */
  Discount = 'discount',
  /** column name */
  DownStream = 'down_stream',
  /** column name */
  DownStreamId = 'down_stream_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Fee_1Id = 'fee_1_id',
  /** column name */
  Fee_2Id = 'fee_2_id',
  /** column name */
  Fee_3Id = 'fee_3_id',
  /** column name */
  Fee_4Id = 'fee_4_id',
  /** column name */
  Fee_5Id = 'fee_5_id',
  /** column name */
  Fee_6Id = 'fee_6_id',
  /** column name */
  Fee_7Id = 'fee_7_id',
  /** column name */
  Fee_8Id = 'fee_8_id',
  /** column name */
  FrReq = 'fr_req',
  /** column name */
  FranchiseFeeId = 'franchise_fee_id',
  /** column name */
  FromSales = 'from_sales',
  /** column name */
  FuelId = 'fuel_id',
  /** column name */
  FuelPercentage = 'fuel_percentage',
  /** column name */
  Id = 'id',
  /** column name */
  IncrementalMargin = 'incremental_margin',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  IndexPremium = 'index_premium',
  /** column name */
  InvoiceFromLdc = 'invoice_from_ldc',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  MeterNumber = 'meter_number',
  /** column name */
  OffsetSupplyId = 'offset_supply_id',
  /** column name */
  OptionPrice = 'option_price',
  /** column name */
  OverDiscount = 'over_discount',
  /** column name */
  OverPremium = 'over_premium',
  /** column name */
  OverUnderCalcTypeId = 'over_under_calc_type_id',
  /** column name */
  PartialRequirements = 'partial_requirements',
  /** column name */
  Pathed = 'pathed',
  /** column name */
  PipeCommodity = 'pipe_commodity',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  PremiseNumber = 'premise_number',
  /** column name */
  PriceAdjId = 'price_adj_id',
  /** column name */
  Primary = 'primary',
  /** column name */
  Reviewed = 'reviewed',
  /** column name */
  Rfp = 'rfp',
  /** column name */
  RiskTypeId = 'risk_type_id',
  /** column name */
  RolledInd = 'rolled_ind',
  /** column name */
  Segment = 'segment',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  SupplyFeeId = 'supply_fee_id',
  /** column name */
  SwingIndexId = 'swing_index_id',
  /** column name */
  SwingLine = 'swing_line',
  /** column name */
  Telemetered = 'telemetered',
  /** column name */
  Transport = 'transport',
  /** column name */
  TransportCost = 'transport_cost',
  /** column name */
  Trigger = 'trigger',
  /** column name */
  UnderDiscount = 'under_discount',
  /** column name */
  UnderPremium = 'under_premium',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityBilled = 'utility_billed',
  /** column name */
  UtilityId = 'utility_id'
}

export type Deal_Details_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Deal_Details_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Deal_Details_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Deal_Details_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Deal_Details_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Deal_Details_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Deal_Details_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deal_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Details_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deal_Details_Var_Pop_Fields = {
  __typename?: 'deal_details_var_pop_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  base_margin?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  commodity_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  down_stream_id?: Maybe<Scalars['Float']>;
  fee_1_id?: Maybe<Scalars['Float']>;
  fee_2_id?: Maybe<Scalars['Float']>;
  fee_3_id?: Maybe<Scalars['Float']>;
  fee_4_id?: Maybe<Scalars['Float']>;
  fee_5_id?: Maybe<Scalars['Float']>;
  fee_6_id?: Maybe<Scalars['Float']>;
  fee_7_id?: Maybe<Scalars['Float']>;
  fee_8_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  franchise_fee_id?: Maybe<Scalars['Float']>;
  fuel_id?: Maybe<Scalars['Float']>;
  fuel_percentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incremental_margin?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  index_premium?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  offset_supply_id?: Maybe<Scalars['Float']>;
  option_price?: Maybe<Scalars['Float']>;
  over_discount?: Maybe<Scalars['Float']>;
  over_premium?: Maybe<Scalars['Float']>;
  over_under_calc_type_id?: Maybe<Scalars['Float']>;
  pipe_commodity?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  price_adj_id?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
  segment?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  supply_fee_id?: Maybe<Scalars['Float']>;
  swing_index_id?: Maybe<Scalars['Float']>;
  transport_cost?: Maybe<Scalars['Float']>;
  under_discount?: Maybe<Scalars['Float']>;
  under_premium?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deal_details" */
export type Deal_Details_Var_Pop_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deal_Details_Var_Samp_Fields = {
  __typename?: 'deal_details_var_samp_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  base_margin?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  commodity_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  down_stream_id?: Maybe<Scalars['Float']>;
  fee_1_id?: Maybe<Scalars['Float']>;
  fee_2_id?: Maybe<Scalars['Float']>;
  fee_3_id?: Maybe<Scalars['Float']>;
  fee_4_id?: Maybe<Scalars['Float']>;
  fee_5_id?: Maybe<Scalars['Float']>;
  fee_6_id?: Maybe<Scalars['Float']>;
  fee_7_id?: Maybe<Scalars['Float']>;
  fee_8_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  franchise_fee_id?: Maybe<Scalars['Float']>;
  fuel_id?: Maybe<Scalars['Float']>;
  fuel_percentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incremental_margin?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  index_premium?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  offset_supply_id?: Maybe<Scalars['Float']>;
  option_price?: Maybe<Scalars['Float']>;
  over_discount?: Maybe<Scalars['Float']>;
  over_premium?: Maybe<Scalars['Float']>;
  over_under_calc_type_id?: Maybe<Scalars['Float']>;
  pipe_commodity?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  price_adj_id?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
  segment?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  supply_fee_id?: Maybe<Scalars['Float']>;
  swing_index_id?: Maybe<Scalars['Float']>;
  transport_cost?: Maybe<Scalars['Float']>;
  under_discount?: Maybe<Scalars['Float']>;
  under_premium?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deal_details" */
export type Deal_Details_Var_Samp_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Deal_Details_Variance_Fields = {
  __typename?: 'deal_details_variance_fields';
  actual_mcf?: Maybe<Scalars['Float']>;
  base_margin?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  commodity_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  down_stream_id?: Maybe<Scalars['Float']>;
  fee_1_id?: Maybe<Scalars['Float']>;
  fee_2_id?: Maybe<Scalars['Float']>;
  fee_3_id?: Maybe<Scalars['Float']>;
  fee_4_id?: Maybe<Scalars['Float']>;
  fee_5_id?: Maybe<Scalars['Float']>;
  fee_6_id?: Maybe<Scalars['Float']>;
  fee_7_id?: Maybe<Scalars['Float']>;
  fee_8_id?: Maybe<Scalars['Float']>;
  fr_req?: Maybe<Scalars['Float']>;
  franchise_fee_id?: Maybe<Scalars['Float']>;
  fuel_id?: Maybe<Scalars['Float']>;
  fuel_percentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incremental_margin?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  index_premium?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  mdq?: Maybe<Scalars['Float']>;
  offset_supply_id?: Maybe<Scalars['Float']>;
  option_price?: Maybe<Scalars['Float']>;
  over_discount?: Maybe<Scalars['Float']>;
  over_premium?: Maybe<Scalars['Float']>;
  over_under_calc_type_id?: Maybe<Scalars['Float']>;
  pipe_commodity?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  price_adj_id?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
  segment?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  supply_fee_id?: Maybe<Scalars['Float']>;
  swing_index_id?: Maybe<Scalars['Float']>;
  transport_cost?: Maybe<Scalars['Float']>;
  under_discount?: Maybe<Scalars['Float']>;
  under_premium?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deal_details" */
export type Deal_Details_Variance_Order_By = {
  actual_mcf?: Maybe<Order_By>;
  base_margin?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  commodity_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  down_stream_id?: Maybe<Order_By>;
  fee_1_id?: Maybe<Order_By>;
  fee_2_id?: Maybe<Order_By>;
  fee_3_id?: Maybe<Order_By>;
  fee_4_id?: Maybe<Order_By>;
  fee_5_id?: Maybe<Order_By>;
  fee_6_id?: Maybe<Order_By>;
  fee_7_id?: Maybe<Order_By>;
  fee_8_id?: Maybe<Order_By>;
  fr_req?: Maybe<Order_By>;
  franchise_fee_id?: Maybe<Order_By>;
  fuel_id?: Maybe<Order_By>;
  fuel_percentage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incremental_margin?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_premium?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  offset_supply_id?: Maybe<Order_By>;
  option_price?: Maybe<Order_By>;
  over_discount?: Maybe<Order_By>;
  over_premium?: Maybe<Order_By>;
  over_under_calc_type_id?: Maybe<Order_By>;
  pipe_commodity?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  price_adj_id?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  segment?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  supply_fee_id?: Maybe<Order_By>;
  swing_index_id?: Maybe<Order_By>;
  transport_cost?: Maybe<Order_By>;
  under_discount?: Maybe<Order_By>;
  under_premium?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** columns and relationships of "dealmakers" */
export type Dealmakers = {
  __typename?: 'dealmakers';
  active?: Maybe<Scalars['Boolean']>;
  calc_commission?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  revised_calc?: Maybe<Scalars['Boolean']>;
  send_invoices?: Maybe<Scalars['Boolean']>;
  suger_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['String']>;
};


/** columns and relationships of "dealmakers" */
export type DealmakersCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


/** columns and relationships of "dealmakers" */
export type DealmakersCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};

/** aggregated selection of "dealmakers" */
export type Dealmakers_Aggregate = {
  __typename?: 'dealmakers_aggregate';
  aggregate?: Maybe<Dealmakers_Aggregate_Fields>;
  nodes: Array<Dealmakers>;
};

/** aggregate fields of "dealmakers" */
export type Dealmakers_Aggregate_Fields = {
  __typename?: 'dealmakers_aggregate_fields';
  avg?: Maybe<Dealmakers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dealmakers_Max_Fields>;
  min?: Maybe<Dealmakers_Min_Fields>;
  stddev?: Maybe<Dealmakers_Stddev_Fields>;
  stddev_pop?: Maybe<Dealmakers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dealmakers_Stddev_Samp_Fields>;
  sum?: Maybe<Dealmakers_Sum_Fields>;
  var_pop?: Maybe<Dealmakers_Var_Pop_Fields>;
  var_samp?: Maybe<Dealmakers_Var_Samp_Fields>;
  variance?: Maybe<Dealmakers_Variance_Fields>;
};


/** aggregate fields of "dealmakers" */
export type Dealmakers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dealmakers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dealmakers_Avg_Fields = {
  __typename?: 'dealmakers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dealmakers". All fields are combined with a logical 'AND'. */
export type Dealmakers_Bool_Exp = {
  _and?: Maybe<Array<Dealmakers_Bool_Exp>>;
  _not?: Maybe<Dealmakers_Bool_Exp>;
  _or?: Maybe<Array<Dealmakers_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  calc_commission?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customers?: Maybe<Customers_Bool_Exp>;
  customers_aggregate?: Maybe<Customers_Aggregate_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  fax?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  person_id?: Maybe<Int_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  region_id?: Maybe<Int_Comparison_Exp>;
  revised_calc?: Maybe<Boolean_Comparison_Exp>;
  send_invoices?: Maybe<Boolean_Comparison_Exp>;
  suger_id?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  uuid?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "dealmakers" */
export enum Dealmakers_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealmakersPkey = 'dealmakers_pkey',
  /** unique or primary key constraint on columns "email" */
  IndexDealmakersOnEmail = 'index_dealmakers_on_email'
}

/** input type for incrementing numeric columns in table "dealmakers" */
export type Dealmakers_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "dealmakers" */
export type Dealmakers_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  calc_commission?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customers?: Maybe<Customers_Arr_Rel_Insert_Input>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  revised_calc?: Maybe<Scalars['Boolean']>;
  send_invoices?: Maybe<Scalars['Boolean']>;
  suger_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dealmakers_Max_Fields = {
  __typename?: 'dealmakers_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  suger_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dealmakers_Min_Fields = {
  __typename?: 'dealmakers_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  suger_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "dealmakers" */
export type Dealmakers_Mutation_Response = {
  __typename?: 'dealmakers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dealmakers>;
};

/** input type for inserting object relation for remote table "dealmakers" */
export type Dealmakers_Obj_Rel_Insert_Input = {
  data: Dealmakers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Dealmakers_On_Conflict>;
};

/** on_conflict condition type for table "dealmakers" */
export type Dealmakers_On_Conflict = {
  constraint: Dealmakers_Constraint;
  update_columns?: Array<Dealmakers_Update_Column>;
  where?: Maybe<Dealmakers_Bool_Exp>;
};

/** Ordering options when selecting data from "dealmakers". */
export type Dealmakers_Order_By = {
  active?: Maybe<Order_By>;
  calc_commission?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customers_aggregate?: Maybe<Customers_Aggregate_Order_By>;
  email?: Maybe<Order_By>;
  fax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  revised_calc?: Maybe<Order_By>;
  send_invoices?: Maybe<Order_By>;
  suger_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: dealmakers */
export type Dealmakers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "dealmakers" */
export enum Dealmakers_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CalcCommission = 'calc_commission',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RevisedCalc = 'revised_calc',
  /** column name */
  SendInvoices = 'send_invoices',
  /** column name */
  SugerId = 'suger_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "dealmakers" */
export type Dealmakers_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  calc_commission?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  revised_calc?: Maybe<Scalars['Boolean']>;
  send_invoices?: Maybe<Scalars['Boolean']>;
  suger_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Dealmakers_Stddev_Fields = {
  __typename?: 'dealmakers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dealmakers_Stddev_Pop_Fields = {
  __typename?: 'dealmakers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dealmakers_Stddev_Samp_Fields = {
  __typename?: 'dealmakers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dealmakers" */
export type Dealmakers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dealmakers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dealmakers_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>;
  calc_commission?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  revised_calc?: Maybe<Scalars['Boolean']>;
  send_invoices?: Maybe<Scalars['Boolean']>;
  suger_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Dealmakers_Sum_Fields = {
  __typename?: 'dealmakers_sum_fields';
  id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "dealmakers" */
export enum Dealmakers_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CalcCommission = 'calc_commission',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RevisedCalc = 'revised_calc',
  /** column name */
  SendInvoices = 'send_invoices',
  /** column name */
  SugerId = 'suger_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

export type Dealmakers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Dealmakers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Dealmakers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dealmakers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dealmakers_Var_Pop_Fields = {
  __typename?: 'dealmakers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dealmakers_Var_Samp_Fields = {
  __typename?: 'dealmakers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dealmakers_Variance_Fields = {
  __typename?: 'dealmakers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "deals" */
export type Deals = {
  __typename?: 'deals';
  bid_week?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  billto_address?: Maybe<Addresses>;
  billto_id?: Maybe<Scalars['Int']>;
  commission_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  company?: Maybe<Indices>;
  company_id?: Maybe<Scalars['Int']>;
  confirmed_by?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['Int']>;
  counterparty_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  deal_details: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_aggregate: Deal_Details_Aggregate;
  deleted?: Maybe<Scalars['Boolean']>;
  evergreen?: Maybe<Scalars['Int']>;
  evergreen_type: Scalars['evergreen_types'];
  file_uploaded?: Maybe<Scalars['Boolean']>;
  financial?: Maybe<Scalars['Boolean']>;
  group_fee?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  /** An array relationship */
  invoice_data: Array<Invoice_Data>;
  /** An aggregate relationship */
  invoice_data_aggregate: Invoice_Data_Aggregate;
  link?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  orig_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  price_file_calc?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  primary_contact?: Maybe<Contact_Entries>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  service_address?: Maybe<Addresses>;
  service_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['status']>;
  trade_date?: Maybe<Scalars['date']>;
  transaction_type_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "deals" */
export type DealsDeal_DetailsArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "deals" */
export type DealsDeal_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "deals" */
export type DealsInvoice_DataArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


/** columns and relationships of "deals" */
export type DealsInvoice_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};

/** aggregated selection of "deals" */
export type Deals_Aggregate = {
  __typename?: 'deals_aggregate';
  aggregate?: Maybe<Deals_Aggregate_Fields>;
  nodes: Array<Deals>;
};

export type Deals_Aggregate_Bool_Exp = {
  avg?: Maybe<Deals_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Deals_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Deals_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Deals_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Deals_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Deals_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Deals_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Deals_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Deals_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Deals_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Deals_Aggregate_Bool_Exp_Var_Samp>;
};

export type Deals_Aggregate_Bool_Exp_Avg = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Bool_And = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Corr = {
  arguments: Deals_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Deals_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Deals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Deals_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Deals_Aggregate_Bool_Exp_Max = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Min = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Sum = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "deals" */
export type Deals_Aggregate_Fields = {
  __typename?: 'deals_aggregate_fields';
  avg?: Maybe<Deals_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deals_Max_Fields>;
  min?: Maybe<Deals_Min_Fields>;
  stddev?: Maybe<Deals_Stddev_Fields>;
  stddev_pop?: Maybe<Deals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deals_Stddev_Samp_Fields>;
  sum?: Maybe<Deals_Sum_Fields>;
  var_pop?: Maybe<Deals_Var_Pop_Fields>;
  var_samp?: Maybe<Deals_Var_Samp_Fields>;
  variance?: Maybe<Deals_Variance_Fields>;
};


/** aggregate fields of "deals" */
export type Deals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deals" */
export type Deals_Aggregate_Order_By = {
  avg?: Maybe<Deals_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Deals_Max_Order_By>;
  min?: Maybe<Deals_Min_Order_By>;
  stddev?: Maybe<Deals_Stddev_Order_By>;
  stddev_pop?: Maybe<Deals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Deals_Stddev_Samp_Order_By>;
  sum?: Maybe<Deals_Sum_Order_By>;
  var_pop?: Maybe<Deals_Var_Pop_Order_By>;
  var_samp?: Maybe<Deals_Var_Samp_Order_By>;
  variance?: Maybe<Deals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "deals" */
export type Deals_Arr_Rel_Insert_Input = {
  data: Array<Deals_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Deals_On_Conflict>;
};

/** aggregate avg on columns */
export type Deals_Avg_Fields = {
  __typename?: 'deals_avg_fields';
  billto_id?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  evergreen?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orig_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  transaction_type_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deals" */
export type Deals_Avg_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deals". All fields are combined with a logical 'AND'. */
export type Deals_Bool_Exp = {
  _and?: Maybe<Array<Deals_Bool_Exp>>;
  _not?: Maybe<Deals_Bool_Exp>;
  _or?: Maybe<Array<Deals_Bool_Exp>>;
  bid_week?: Maybe<Boolean_Comparison_Exp>;
  billto_address?: Maybe<Addresses_Bool_Exp>;
  billto_id?: Maybe<Int_Comparison_Exp>;
  commission_id?: Maybe<Int_Comparison_Exp>;
  company?: Maybe<Indices_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  confirmed_by?: Maybe<String_Comparison_Exp>;
  contact_id?: Maybe<Int_Comparison_Exp>;
  counterparty_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  deal_details?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  evergreen?: Maybe<Int_Comparison_Exp>;
  evergreen_type?: Maybe<Evergreen_Types_Comparison_Exp>;
  file_uploaded?: Maybe<Boolean_Comparison_Exp>;
  financial?: Maybe<Boolean_Comparison_Exp>;
  group_fee?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_data?: Maybe<Invoice_Data_Bool_Exp>;
  invoice_data_aggregate?: Maybe<Invoice_Data_Aggregate_Bool_Exp>;
  link?: Maybe<String_Comparison_Exp>;
  market?: Maybe<Boolean_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  orig_id?: Maybe<Int_Comparison_Exp>;
  pmt_terms?: Maybe<Int_Comparison_Exp>;
  price_file_calc?: Maybe<Boolean_Comparison_Exp>;
  primary_contact?: Maybe<Contact_Entries_Bool_Exp>;
  primary_contact_id?: Maybe<Int_Comparison_Exp>;
  service_address?: Maybe<Addresses_Bool_Exp>;
  service_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Status_Comparison_Exp>;
  trade_date?: Maybe<Date_Comparison_Exp>;
  transaction_type_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "deals" */
export enum Deals_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealsPkey = 'deals_pkey'
}

/** columns and relationships of "deals_detail_preview" */
export type Deals_Detail_Preview = {
  __typename?: 'deals_detail_preview';
  account_number?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customer_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  ending?: Maybe<Scalars['date']>;
  index_id?: Maybe<Scalars['Int']>;
  index_name?: Maybe<Scalars['String']>;
  ldc?: Maybe<Scalars['String']>;
  legs?: Maybe<Scalars['bigint']>;
  meter_number?: Maybe<Scalars['String']>;
  premise_number?: Maybe<Scalars['String']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  risk_type_name?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['date']>;
  utility?: Maybe<Scalars['String']>;
};

/** aggregated selection of "deals_detail_preview" */
export type Deals_Detail_Preview_Aggregate = {
  __typename?: 'deals_detail_preview_aggregate';
  aggregate?: Maybe<Deals_Detail_Preview_Aggregate_Fields>;
  nodes: Array<Deals_Detail_Preview>;
};

export type Deals_Detail_Preview_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Deals_Detail_Preview_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Deals_Detail_Preview_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Deals_Detail_Preview_Aggregate_Bool_Exp_Count>;
};

export type Deals_Detail_Preview_Aggregate_Bool_Exp_Bool_And = {
  arguments: Deals_Detail_Preview_Select_Column_Deals_Detail_Preview_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Detail_Preview_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deals_Detail_Preview_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Deals_Detail_Preview_Select_Column_Deals_Detail_Preview_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Detail_Preview_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deals_Detail_Preview_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Deals_Detail_Preview_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Deals_Detail_Preview_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "deals_detail_preview" */
export type Deals_Detail_Preview_Aggregate_Fields = {
  __typename?: 'deals_detail_preview_aggregate_fields';
  avg?: Maybe<Deals_Detail_Preview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deals_Detail_Preview_Max_Fields>;
  min?: Maybe<Deals_Detail_Preview_Min_Fields>;
  stddev?: Maybe<Deals_Detail_Preview_Stddev_Fields>;
  stddev_pop?: Maybe<Deals_Detail_Preview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deals_Detail_Preview_Stddev_Samp_Fields>;
  sum?: Maybe<Deals_Detail_Preview_Sum_Fields>;
  var_pop?: Maybe<Deals_Detail_Preview_Var_Pop_Fields>;
  var_samp?: Maybe<Deals_Detail_Preview_Var_Samp_Fields>;
  variance?: Maybe<Deals_Detail_Preview_Variance_Fields>;
};


/** aggregate fields of "deals_detail_preview" */
export type Deals_Detail_Preview_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deals_Detail_Preview_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deals_detail_preview" */
export type Deals_Detail_Preview_Aggregate_Order_By = {
  avg?: Maybe<Deals_Detail_Preview_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Deals_Detail_Preview_Max_Order_By>;
  min?: Maybe<Deals_Detail_Preview_Min_Order_By>;
  stddev?: Maybe<Deals_Detail_Preview_Stddev_Order_By>;
  stddev_pop?: Maybe<Deals_Detail_Preview_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Deals_Detail_Preview_Stddev_Samp_Order_By>;
  sum?: Maybe<Deals_Detail_Preview_Sum_Order_By>;
  var_pop?: Maybe<Deals_Detail_Preview_Var_Pop_Order_By>;
  var_samp?: Maybe<Deals_Detail_Preview_Var_Samp_Order_By>;
  variance?: Maybe<Deals_Detail_Preview_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "deals_detail_preview" */
export type Deals_Detail_Preview_Arr_Rel_Insert_Input = {
  data: Array<Deals_Detail_Preview_Insert_Input>;
};

/** aggregate avg on columns */
export type Deals_Detail_Preview_Avg_Fields = {
  __typename?: 'deals_detail_preview_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  legs?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deals_detail_preview". All fields are combined with a logical 'AND'. */
export type Deals_Detail_Preview_Bool_Exp = {
  _and?: Maybe<Array<Deals_Detail_Preview_Bool_Exp>>;
  _not?: Maybe<Deals_Detail_Preview_Bool_Exp>;
  _or?: Maybe<Array<Deals_Detail_Preview_Bool_Exp>>;
  account_number?: Maybe<String_Comparison_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  deal_id?: Maybe<Int_Comparison_Exp>;
  ending?: Maybe<Date_Comparison_Exp>;
  index_id?: Maybe<Int_Comparison_Exp>;
  index_name?: Maybe<String_Comparison_Exp>;
  ldc?: Maybe<String_Comparison_Exp>;
  legs?: Maybe<Bigint_Comparison_Exp>;
  meter_number?: Maybe<String_Comparison_Exp>;
  premise_number?: Maybe<String_Comparison_Exp>;
  risk_type_id?: Maybe<Int_Comparison_Exp>;
  risk_type_name?: Maybe<String_Comparison_Exp>;
  started?: Maybe<Date_Comparison_Exp>;
  utility?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "deals_detail_preview" */
export type Deals_Detail_Preview_Insert_Input = {
  account_number?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customer_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  ending?: Maybe<Scalars['date']>;
  index_id?: Maybe<Scalars['Int']>;
  index_name?: Maybe<Scalars['String']>;
  ldc?: Maybe<Scalars['String']>;
  legs?: Maybe<Scalars['bigint']>;
  meter_number?: Maybe<Scalars['String']>;
  premise_number?: Maybe<Scalars['String']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  risk_type_name?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['date']>;
  utility?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Deals_Detail_Preview_Max_Fields = {
  __typename?: 'deals_detail_preview_max_fields';
  account_number?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  ending?: Maybe<Scalars['date']>;
  index_id?: Maybe<Scalars['Int']>;
  index_name?: Maybe<Scalars['String']>;
  ldc?: Maybe<Scalars['String']>;
  legs?: Maybe<Scalars['bigint']>;
  meter_number?: Maybe<Scalars['String']>;
  premise_number?: Maybe<Scalars['String']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  risk_type_name?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['date']>;
  utility?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Max_Order_By = {
  account_number?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  ending?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_name?: Maybe<Order_By>;
  ldc?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  meter_number?: Maybe<Order_By>;
  premise_number?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  risk_type_name?: Maybe<Order_By>;
  started?: Maybe<Order_By>;
  utility?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deals_Detail_Preview_Min_Fields = {
  __typename?: 'deals_detail_preview_min_fields';
  account_number?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  ending?: Maybe<Scalars['date']>;
  index_id?: Maybe<Scalars['Int']>;
  index_name?: Maybe<Scalars['String']>;
  ldc?: Maybe<Scalars['String']>;
  legs?: Maybe<Scalars['bigint']>;
  meter_number?: Maybe<Scalars['String']>;
  premise_number?: Maybe<Scalars['String']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  risk_type_name?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['date']>;
  utility?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Min_Order_By = {
  account_number?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  ending?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_name?: Maybe<Order_By>;
  ldc?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  meter_number?: Maybe<Order_By>;
  premise_number?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  risk_type_name?: Maybe<Order_By>;
  started?: Maybe<Order_By>;
  utility?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "deals_detail_preview". */
export type Deals_Detail_Preview_Order_By = {
  account_number?: Maybe<Order_By>;
  active?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  ending?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  index_name?: Maybe<Order_By>;
  ldc?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  meter_number?: Maybe<Order_By>;
  premise_number?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
  risk_type_name?: Maybe<Order_By>;
  started?: Maybe<Order_By>;
  utility?: Maybe<Order_By>;
};

/** select columns of table "deals_detail_preview" */
export enum Deals_Detail_Preview_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  Active = 'active',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  Ending = 'ending',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  IndexName = 'index_name',
  /** column name */
  Ldc = 'ldc',
  /** column name */
  Legs = 'legs',
  /** column name */
  MeterNumber = 'meter_number',
  /** column name */
  PremiseNumber = 'premise_number',
  /** column name */
  RiskTypeId = 'risk_type_id',
  /** column name */
  RiskTypeName = 'risk_type_name',
  /** column name */
  Started = 'started',
  /** column name */
  Utility = 'utility'
}

/** select "deals_detail_preview_aggregate_bool_exp_bool_and_arguments_columns" columns of table "deals_detail_preview" */
export enum Deals_Detail_Preview_Select_Column_Deals_Detail_Preview_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "deals_detail_preview_aggregate_bool_exp_bool_or_arguments_columns" columns of table "deals_detail_preview" */
export enum Deals_Detail_Preview_Select_Column_Deals_Detail_Preview_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** aggregate stddev on columns */
export type Deals_Detail_Preview_Stddev_Fields = {
  __typename?: 'deals_detail_preview_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  legs?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deals_Detail_Preview_Stddev_Pop_Fields = {
  __typename?: 'deals_detail_preview_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  legs?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deals_Detail_Preview_Stddev_Samp_Fields = {
  __typename?: 'deals_detail_preview_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  legs?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "deals_detail_preview" */
export type Deals_Detail_Preview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deals_Detail_Preview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deals_Detail_Preview_Stream_Cursor_Value_Input = {
  account_number?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customer_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  ending?: Maybe<Scalars['date']>;
  index_id?: Maybe<Scalars['Int']>;
  index_name?: Maybe<Scalars['String']>;
  ldc?: Maybe<Scalars['String']>;
  legs?: Maybe<Scalars['bigint']>;
  meter_number?: Maybe<Scalars['String']>;
  premise_number?: Maybe<Scalars['String']>;
  risk_type_id?: Maybe<Scalars['Int']>;
  risk_type_name?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['date']>;
  utility?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Deals_Detail_Preview_Sum_Fields = {
  __typename?: 'deals_detail_preview_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  legs?: Maybe<Scalars['bigint']>;
  risk_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Deals_Detail_Preview_Var_Pop_Fields = {
  __typename?: 'deals_detail_preview_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  legs?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deals_Detail_Preview_Var_Samp_Fields = {
  __typename?: 'deals_detail_preview_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  legs?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Deals_Detail_Preview_Variance_Fields = {
  __typename?: 'deals_detail_preview_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  legs?: Maybe<Scalars['Float']>;
  risk_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deals_detail_preview" */
export type Deals_Detail_Preview_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  legs?: Maybe<Order_By>;
  risk_type_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "deals" */
export type Deals_Inc_Input = {
  billto_id?: Maybe<Scalars['Int']>;
  commission_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contact_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  evergreen?: Maybe<Scalars['Int']>;
  group_fee?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  orig_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  transaction_type_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "deals" */
export type Deals_Insert_Input = {
  bid_week?: Maybe<Scalars['Boolean']>;
  billto_address?: Maybe<Addresses_Obj_Rel_Insert_Input>;
  billto_id?: Maybe<Scalars['Int']>;
  commission_id?: Maybe<Scalars['Int']>;
  company?: Maybe<Indices_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  confirmed_by?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['Int']>;
  counterparty_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  deal_details?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  deleted?: Maybe<Scalars['Boolean']>;
  evergreen?: Maybe<Scalars['Int']>;
  evergreen_type?: Maybe<Scalars['evergreen_types']>;
  file_uploaded?: Maybe<Scalars['Boolean']>;
  financial?: Maybe<Scalars['Boolean']>;
  group_fee?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  invoice_data?: Maybe<Invoice_Data_Arr_Rel_Insert_Input>;
  link?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  orig_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  price_file_calc?: Maybe<Scalars['Boolean']>;
  primary_contact?: Maybe<Contact_Entries_Obj_Rel_Insert_Input>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  service_address?: Maybe<Addresses_Obj_Rel_Insert_Input>;
  service_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['status']>;
  trade_date?: Maybe<Scalars['date']>;
  transaction_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Deals_Max_Fields = {
  __typename?: 'deals_max_fields';
  billto_id?: Maybe<Scalars['Int']>;
  commission_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  confirmed_by?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['Int']>;
  counterparty_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  evergreen?: Maybe<Scalars['Int']>;
  evergreen_type?: Maybe<Scalars['evergreen_types']>;
  group_fee?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orig_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['status']>;
  trade_date?: Maybe<Scalars['date']>;
  transaction_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "deals" */
export type Deals_Max_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  confirmed_by?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  counterparty_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  evergreen_type?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  trade_date?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deals_Min_Fields = {
  __typename?: 'deals_min_fields';
  billto_id?: Maybe<Scalars['Int']>;
  commission_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  confirmed_by?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['Int']>;
  counterparty_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  evergreen?: Maybe<Scalars['Int']>;
  evergreen_type?: Maybe<Scalars['evergreen_types']>;
  group_fee?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orig_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['status']>;
  trade_date?: Maybe<Scalars['date']>;
  transaction_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "deals" */
export type Deals_Min_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  confirmed_by?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  counterparty_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  evergreen_type?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  trade_date?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "deals" */
export type Deals_Mutation_Response = {
  __typename?: 'deals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deals>;
};

/** input type for inserting object relation for remote table "deals" */
export type Deals_Obj_Rel_Insert_Input = {
  data: Deals_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deals_On_Conflict>;
};

/** on_conflict condition type for table "deals" */
export type Deals_On_Conflict = {
  constraint: Deals_Constraint;
  update_columns?: Array<Deals_Update_Column>;
  where?: Maybe<Deals_Bool_Exp>;
};

/** Ordering options when selecting data from "deals". */
export type Deals_Order_By = {
  bid_week?: Maybe<Order_By>;
  billto_address?: Maybe<Addresses_Order_By>;
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company?: Maybe<Indices_Order_By>;
  company_id?: Maybe<Order_By>;
  confirmed_by?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  counterparty_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  deal_details_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  deleted?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  evergreen_type?: Maybe<Order_By>;
  file_uploaded?: Maybe<Order_By>;
  financial?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_data_aggregate?: Maybe<Invoice_Data_Aggregate_Order_By>;
  link?: Maybe<Order_By>;
  market?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  price_file_calc?: Maybe<Order_By>;
  primary_contact?: Maybe<Contact_Entries_Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_address?: Maybe<Addresses_Order_By>;
  service_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  trade_date?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: deals */
export type Deals_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "deals" */
export enum Deals_Select_Column {
  /** column name */
  BidWeek = 'bid_week',
  /** column name */
  BilltoId = 'billto_id',
  /** column name */
  CommissionId = 'commission_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ConfirmedBy = 'confirmed_by',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Evergreen = 'evergreen',
  /** column name */
  EvergreenType = 'evergreen_type',
  /** column name */
  FileUploaded = 'file_uploaded',
  /** column name */
  Financial = 'financial',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Market = 'market',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrigId = 'orig_id',
  /** column name */
  PmtTerms = 'pmt_terms',
  /** column name */
  PriceFileCalc = 'price_file_calc',
  /** column name */
  PrimaryContactId = 'primary_contact_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  Status = 'status',
  /** column name */
  TradeDate = 'trade_date',
  /** column name */
  TransactionTypeId = 'transaction_type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "deals_aggregate_bool_exp_avg_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  GroupFee = 'group_fee'
}

/** select "deals_aggregate_bool_exp_bool_and_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  BidWeek = 'bid_week',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  FileUploaded = 'file_uploaded',
  /** column name */
  Financial = 'financial',
  /** column name */
  Market = 'market',
  /** column name */
  PriceFileCalc = 'price_file_calc'
}

/** select "deals_aggregate_bool_exp_bool_or_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  BidWeek = 'bid_week',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  FileUploaded = 'file_uploaded',
  /** column name */
  Financial = 'financial',
  /** column name */
  Market = 'market',
  /** column name */
  PriceFileCalc = 'price_file_calc'
}

/** select "deals_aggregate_bool_exp_corr_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  GroupFee = 'group_fee'
}

/** select "deals_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  GroupFee = 'group_fee'
}

/** select "deals_aggregate_bool_exp_max_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  GroupFee = 'group_fee'
}

/** select "deals_aggregate_bool_exp_min_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  GroupFee = 'group_fee'
}

/** select "deals_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  GroupFee = 'group_fee'
}

/** select "deals_aggregate_bool_exp_sum_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  GroupFee = 'group_fee'
}

/** select "deals_aggregate_bool_exp_var_samp_arguments_columns" columns of table "deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  GroupFee = 'group_fee'
}

/** input type for updating data in table "deals" */
export type Deals_Set_Input = {
  bid_week?: Maybe<Scalars['Boolean']>;
  billto_id?: Maybe<Scalars['Int']>;
  commission_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  confirmed_by?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['Int']>;
  counterparty_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  evergreen?: Maybe<Scalars['Int']>;
  evergreen_type?: Maybe<Scalars['evergreen_types']>;
  file_uploaded?: Maybe<Scalars['Boolean']>;
  financial?: Maybe<Scalars['Boolean']>;
  group_fee?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  orig_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  price_file_calc?: Maybe<Scalars['Boolean']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['status']>;
  trade_date?: Maybe<Scalars['date']>;
  transaction_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Deals_Stddev_Fields = {
  __typename?: 'deals_stddev_fields';
  billto_id?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  evergreen?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orig_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  transaction_type_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deals" */
export type Deals_Stddev_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deals_Stddev_Pop_Fields = {
  __typename?: 'deals_stddev_pop_fields';
  billto_id?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  evergreen?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orig_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  transaction_type_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deals" */
export type Deals_Stddev_Pop_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deals_Stddev_Samp_Fields = {
  __typename?: 'deals_stddev_samp_fields';
  billto_id?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  evergreen?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orig_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  transaction_type_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deals" */
export type Deals_Stddev_Samp_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "deals" */
export type Deals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deals_Stream_Cursor_Value_Input = {
  bid_week?: Maybe<Scalars['Boolean']>;
  billto_id?: Maybe<Scalars['Int']>;
  commission_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  confirmed_by?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['Int']>;
  counterparty_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  evergreen?: Maybe<Scalars['Int']>;
  evergreen_type?: Maybe<Scalars['evergreen_types']>;
  file_uploaded?: Maybe<Scalars['Boolean']>;
  financial?: Maybe<Scalars['Boolean']>;
  group_fee?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  orig_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  price_file_calc?: Maybe<Scalars['Boolean']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['status']>;
  trade_date?: Maybe<Scalars['date']>;
  transaction_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Deals_Sum_Fields = {
  __typename?: 'deals_sum_fields';
  billto_id?: Maybe<Scalars['Int']>;
  commission_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contact_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  evergreen?: Maybe<Scalars['Int']>;
  group_fee?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  orig_id?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  primary_contact_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  transaction_type_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "deals" */
export type Deals_Sum_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "deals" */
export enum Deals_Update_Column {
  /** column name */
  BidWeek = 'bid_week',
  /** column name */
  BilltoId = 'billto_id',
  /** column name */
  CommissionId = 'commission_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ConfirmedBy = 'confirmed_by',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Evergreen = 'evergreen',
  /** column name */
  EvergreenType = 'evergreen_type',
  /** column name */
  FileUploaded = 'file_uploaded',
  /** column name */
  Financial = 'financial',
  /** column name */
  GroupFee = 'group_fee',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Market = 'market',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrigId = 'orig_id',
  /** column name */
  PmtTerms = 'pmt_terms',
  /** column name */
  PriceFileCalc = 'price_file_calc',
  /** column name */
  PrimaryContactId = 'primary_contact_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  Status = 'status',
  /** column name */
  TradeDate = 'trade_date',
  /** column name */
  TransactionTypeId = 'transaction_type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Deals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Deals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deals_Var_Pop_Fields = {
  __typename?: 'deals_var_pop_fields';
  billto_id?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  evergreen?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orig_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  transaction_type_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deals" */
export type Deals_Var_Pop_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deals_Var_Samp_Fields = {
  __typename?: 'deals_var_samp_fields';
  billto_id?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  evergreen?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orig_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  transaction_type_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deals" */
export type Deals_Var_Samp_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Deals_Variance_Fields = {
  __typename?: 'deals_variance_fields';
  billto_id?: Maybe<Scalars['Float']>;
  commission_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  evergreen?: Maybe<Scalars['Float']>;
  group_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orig_id?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  primary_contact_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  transaction_type_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deals" */
export type Deals_Variance_Order_By = {
  billto_id?: Maybe<Order_By>;
  commission_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  evergreen?: Maybe<Order_By>;
  group_fee?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  orig_id?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  primary_contact_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  transaction_type_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "email.email_attachments" */
export type Email_Email_Attachments = {
  __typename?: 'email_email_attachments';
  /** An object relationship */
  active_storage_blob: Active_Storage_Blobs;
  active_storage_blob_key: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  email: Email_Emails;
  email_id: Scalars['bigint'];
  filename: Scalars['String'];
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "email.email_attachments" */
export type Email_Email_Attachments_Aggregate = {
  __typename?: 'email_email_attachments_aggregate';
  aggregate?: Maybe<Email_Email_Attachments_Aggregate_Fields>;
  nodes: Array<Email_Email_Attachments>;
};

export type Email_Email_Attachments_Aggregate_Bool_Exp = {
  count?: Maybe<Email_Email_Attachments_Aggregate_Bool_Exp_Count>;
};

export type Email_Email_Attachments_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Email_Email_Attachments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Email_Email_Attachments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "email.email_attachments" */
export type Email_Email_Attachments_Aggregate_Fields = {
  __typename?: 'email_email_attachments_aggregate_fields';
  avg?: Maybe<Email_Email_Attachments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Email_Email_Attachments_Max_Fields>;
  min?: Maybe<Email_Email_Attachments_Min_Fields>;
  stddev?: Maybe<Email_Email_Attachments_Stddev_Fields>;
  stddev_pop?: Maybe<Email_Email_Attachments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Email_Email_Attachments_Stddev_Samp_Fields>;
  sum?: Maybe<Email_Email_Attachments_Sum_Fields>;
  var_pop?: Maybe<Email_Email_Attachments_Var_Pop_Fields>;
  var_samp?: Maybe<Email_Email_Attachments_Var_Samp_Fields>;
  variance?: Maybe<Email_Email_Attachments_Variance_Fields>;
};


/** aggregate fields of "email.email_attachments" */
export type Email_Email_Attachments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Email_Attachments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email.email_attachments" */
export type Email_Email_Attachments_Aggregate_Order_By = {
  avg?: Maybe<Email_Email_Attachments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Email_Attachments_Max_Order_By>;
  min?: Maybe<Email_Email_Attachments_Min_Order_By>;
  stddev?: Maybe<Email_Email_Attachments_Stddev_Order_By>;
  stddev_pop?: Maybe<Email_Email_Attachments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Email_Email_Attachments_Stddev_Samp_Order_By>;
  sum?: Maybe<Email_Email_Attachments_Sum_Order_By>;
  var_pop?: Maybe<Email_Email_Attachments_Var_Pop_Order_By>;
  var_samp?: Maybe<Email_Email_Attachments_Var_Samp_Order_By>;
  variance?: Maybe<Email_Email_Attachments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "email.email_attachments" */
export type Email_Email_Attachments_Arr_Rel_Insert_Input = {
  data: Array<Email_Email_Attachments_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Email_Email_Attachments_On_Conflict>;
};

/** aggregate avg on columns */
export type Email_Email_Attachments_Avg_Fields = {
  __typename?: 'email_email_attachments_avg_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Avg_Order_By = {
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "email.email_attachments". All fields are combined with a logical 'AND'. */
export type Email_Email_Attachments_Bool_Exp = {
  _and?: Maybe<Array<Email_Email_Attachments_Bool_Exp>>;
  _not?: Maybe<Email_Email_Attachments_Bool_Exp>;
  _or?: Maybe<Array<Email_Email_Attachments_Bool_Exp>>;
  active_storage_blob?: Maybe<Active_Storage_Blobs_Bool_Exp>;
  active_storage_blob_key?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<Email_Emails_Bool_Exp>;
  email_id?: Maybe<Bigint_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email.email_attachments" */
export enum Email_Email_Attachments_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailAttachmentsPkey = 'email_attachments_pkey'
}

/** input type for incrementing numeric columns in table "email.email_attachments" */
export type Email_Email_Attachments_Inc_Input = {
  email_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "email.email_attachments" */
export type Email_Email_Attachments_Insert_Input = {
  active_storage_blob?: Maybe<Active_Storage_Blobs_Obj_Rel_Insert_Input>;
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Email_Emails_Obj_Rel_Insert_Input>;
  email_id?: Maybe<Scalars['bigint']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Email_Attachments_Max_Fields = {
  __typename?: 'email_email_attachments_max_fields';
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['bigint']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Max_Order_By = {
  active_storage_blob_key?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Email_Attachments_Min_Fields = {
  __typename?: 'email_email_attachments_min_fields';
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['bigint']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Min_Order_By = {
  active_storage_blob_key?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "email.email_attachments" */
export type Email_Email_Attachments_Mutation_Response = {
  __typename?: 'email_email_attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Email_Attachments>;
};

/** on_conflict condition type for table "email.email_attachments" */
export type Email_Email_Attachments_On_Conflict = {
  constraint: Email_Email_Attachments_Constraint;
  update_columns?: Array<Email_Email_Attachments_Update_Column>;
  where?: Maybe<Email_Email_Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "email.email_attachments". */
export type Email_Email_Attachments_Order_By = {
  active_storage_blob?: Maybe<Active_Storage_Blobs_Order_By>;
  active_storage_blob_key?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Email_Emails_Order_By>;
  email_id?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: email.email_attachments */
export type Email_Email_Attachments_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "email.email_attachments" */
export enum Email_Email_Attachments_Select_Column {
  /** column name */
  ActiveStorageBlobKey = 'active_storage_blob_key',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "email.email_attachments" */
export type Email_Email_Attachments_Set_Input = {
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['bigint']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Email_Email_Attachments_Stddev_Fields = {
  __typename?: 'email_email_attachments_stddev_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Stddev_Order_By = {
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Email_Email_Attachments_Stddev_Pop_Fields = {
  __typename?: 'email_email_attachments_stddev_pop_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Stddev_Pop_Order_By = {
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Email_Email_Attachments_Stddev_Samp_Fields = {
  __typename?: 'email_email_attachments_stddev_samp_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Stddev_Samp_Order_By = {
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "email_email_attachments" */
export type Email_Email_Attachments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Email_Attachments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Email_Attachments_Stream_Cursor_Value_Input = {
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['bigint']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Email_Email_Attachments_Sum_Fields = {
  __typename?: 'email_email_attachments_sum_fields';
  email_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Sum_Order_By = {
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "email.email_attachments" */
export enum Email_Email_Attachments_Update_Column {
  /** column name */
  ActiveStorageBlobKey = 'active_storage_blob_key',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Email_Email_Attachments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Email_Email_Attachments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Email_Email_Attachments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Email_Attachments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Email_Email_Attachments_Var_Pop_Fields = {
  __typename?: 'email_email_attachments_var_pop_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Var_Pop_Order_By = {
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Email_Email_Attachments_Var_Samp_Fields = {
  __typename?: 'email_email_attachments_var_samp_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Var_Samp_Order_By = {
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Email_Email_Attachments_Variance_Fields = {
  __typename?: 'email_email_attachments_variance_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "email.email_attachments" */
export type Email_Email_Attachments_Variance_Order_By = {
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "email.emails" */
export type Email_Emails = {
  __typename?: 'email_emails';
  attempts: Scalars['bigint'];
  bcc_addresses: Array<Scalars['String']>;
  body_html: Scalars['String'];
  cc_addresses: Array<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  document_render_result?: Maybe<Files_Document_Render_Results>;
  /** An array relationship */
  email_attachments: Array<Email_Email_Attachments>;
  /** An aggregate relationship */
  email_attachments_aggregate: Email_Email_Attachments_Aggregate;
  from_address: Scalars['String'];
  id: Scalars['bigint'];
  lock_expiration?: Maybe<Scalars['timestamp']>;
  send_scheduled_for: Scalars['timestamptz'];
  state: Scalars['jsonb'];
  subject: Scalars['String'];
  to_addresses: Array<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "email.emails" */
export type Email_EmailsEmail_AttachmentsArgs = {
  distinct_on?: Maybe<Array<Email_Email_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Email_Attachments_Order_By>>;
  where?: Maybe<Email_Email_Attachments_Bool_Exp>;
};


/** columns and relationships of "email.emails" */
export type Email_EmailsEmail_Attachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Email_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Email_Attachments_Order_By>>;
  where?: Maybe<Email_Email_Attachments_Bool_Exp>;
};


/** columns and relationships of "email.emails" */
export type Email_EmailsStateArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "email.emails" */
export type Email_Emails_Aggregate = {
  __typename?: 'email_emails_aggregate';
  aggregate?: Maybe<Email_Emails_Aggregate_Fields>;
  nodes: Array<Email_Emails>;
};

/** aggregate fields of "email.emails" */
export type Email_Emails_Aggregate_Fields = {
  __typename?: 'email_emails_aggregate_fields';
  avg?: Maybe<Email_Emails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Email_Emails_Max_Fields>;
  min?: Maybe<Email_Emails_Min_Fields>;
  stddev?: Maybe<Email_Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Email_Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Email_Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Email_Emails_Sum_Fields>;
  var_pop?: Maybe<Email_Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Email_Emails_Var_Samp_Fields>;
  variance?: Maybe<Email_Emails_Variance_Fields>;
};


/** aggregate fields of "email.emails" */
export type Email_Emails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Emails_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Emails_Append_Input = {
  state?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Email_Emails_Avg_Fields = {
  __typename?: 'email_emails_avg_fields';
  attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "email.emails". All fields are combined with a logical 'AND'. */
export type Email_Emails_Bool_Exp = {
  _and?: Maybe<Array<Email_Emails_Bool_Exp>>;
  _not?: Maybe<Email_Emails_Bool_Exp>;
  _or?: Maybe<Array<Email_Emails_Bool_Exp>>;
  attempts?: Maybe<Bigint_Comparison_Exp>;
  bcc_addresses?: Maybe<String_Array_Comparison_Exp>;
  body_html?: Maybe<String_Comparison_Exp>;
  cc_addresses?: Maybe<String_Array_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_render_result?: Maybe<Files_Document_Render_Results_Bool_Exp>;
  email_attachments?: Maybe<Email_Email_Attachments_Bool_Exp>;
  email_attachments_aggregate?: Maybe<Email_Email_Attachments_Aggregate_Bool_Exp>;
  from_address?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  lock_expiration?: Maybe<Timestamp_Comparison_Exp>;
  send_scheduled_for?: Maybe<Timestamptz_Comparison_Exp>;
  state?: Maybe<Jsonb_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  to_addresses?: Maybe<String_Array_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email.emails" */
export enum Email_Emails_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailsPkey = 'emails_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Emails_Delete_At_Path_Input = {
  state?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Emails_Delete_Elem_Input = {
  state?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Emails_Delete_Key_Input = {
  state?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "email.emails" */
export type Email_Emails_Inc_Input = {
  attempts?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "email.emails" */
export type Email_Emails_Insert_Input = {
  attempts?: Maybe<Scalars['bigint']>;
  bcc_addresses?: Maybe<Array<Scalars['String']>>;
  body_html?: Maybe<Scalars['String']>;
  cc_addresses?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_render_result?: Maybe<Files_Document_Render_Results_Obj_Rel_Insert_Input>;
  email_attachments?: Maybe<Email_Email_Attachments_Arr_Rel_Insert_Input>;
  from_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  send_scheduled_for?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['jsonb']>;
  subject?: Maybe<Scalars['String']>;
  to_addresses?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Emails_Max_Fields = {
  __typename?: 'email_emails_max_fields';
  attempts?: Maybe<Scalars['bigint']>;
  bcc_addresses?: Maybe<Array<Scalars['String']>>;
  body_html?: Maybe<Scalars['String']>;
  cc_addresses?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  send_scheduled_for?: Maybe<Scalars['timestamptz']>;
  subject?: Maybe<Scalars['String']>;
  to_addresses?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Email_Emails_Min_Fields = {
  __typename?: 'email_emails_min_fields';
  attempts?: Maybe<Scalars['bigint']>;
  bcc_addresses?: Maybe<Array<Scalars['String']>>;
  body_html?: Maybe<Scalars['String']>;
  cc_addresses?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  send_scheduled_for?: Maybe<Scalars['timestamptz']>;
  subject?: Maybe<Scalars['String']>;
  to_addresses?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "email.emails" */
export type Email_Emails_Mutation_Response = {
  __typename?: 'email_emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Emails>;
};

/** input type for inserting object relation for remote table "email.emails" */
export type Email_Emails_Obj_Rel_Insert_Input = {
  data: Email_Emails_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Email_Emails_On_Conflict>;
};

/** on_conflict condition type for table "email.emails" */
export type Email_Emails_On_Conflict = {
  constraint: Email_Emails_Constraint;
  update_columns?: Array<Email_Emails_Update_Column>;
  where?: Maybe<Email_Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "email.emails". */
export type Email_Emails_Order_By = {
  attempts?: Maybe<Order_By>;
  bcc_addresses?: Maybe<Order_By>;
  body_html?: Maybe<Order_By>;
  cc_addresses?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  document_render_result?: Maybe<Files_Document_Render_Results_Order_By>;
  email_attachments_aggregate?: Maybe<Email_Email_Attachments_Aggregate_Order_By>;
  from_address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lock_expiration?: Maybe<Order_By>;
  send_scheduled_for?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to_addresses?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: email.emails */
export type Email_Emails_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Emails_Prepend_Input = {
  state?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "email.emails" */
export enum Email_Emails_Select_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  BccAddresses = 'bcc_addresses',
  /** column name */
  BodyHtml = 'body_html',
  /** column name */
  CcAddresses = 'cc_addresses',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromAddress = 'from_address',
  /** column name */
  Id = 'id',
  /** column name */
  LockExpiration = 'lock_expiration',
  /** column name */
  SendScheduledFor = 'send_scheduled_for',
  /** column name */
  State = 'state',
  /** column name */
  Subject = 'subject',
  /** column name */
  ToAddresses = 'to_addresses',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "email.emails" */
export type Email_Emails_Set_Input = {
  attempts?: Maybe<Scalars['bigint']>;
  bcc_addresses?: Maybe<Array<Scalars['String']>>;
  body_html?: Maybe<Scalars['String']>;
  cc_addresses?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  send_scheduled_for?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['jsonb']>;
  subject?: Maybe<Scalars['String']>;
  to_addresses?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Email_Emails_Stddev_Fields = {
  __typename?: 'email_emails_stddev_fields';
  attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Email_Emails_Stddev_Pop_Fields = {
  __typename?: 'email_emails_stddev_pop_fields';
  attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Email_Emails_Stddev_Samp_Fields = {
  __typename?: 'email_emails_stddev_samp_fields';
  attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "email_emails" */
export type Email_Emails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Emails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Emails_Stream_Cursor_Value_Input = {
  attempts?: Maybe<Scalars['bigint']>;
  bcc_addresses?: Maybe<Array<Scalars['String']>>;
  body_html?: Maybe<Scalars['String']>;
  cc_addresses?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  send_scheduled_for?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['jsonb']>;
  subject?: Maybe<Scalars['String']>;
  to_addresses?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Email_Emails_Sum_Fields = {
  __typename?: 'email_emails_sum_fields';
  attempts?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "email.emails" */
export enum Email_Emails_Update_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  BccAddresses = 'bcc_addresses',
  /** column name */
  BodyHtml = 'body_html',
  /** column name */
  CcAddresses = 'cc_addresses',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromAddress = 'from_address',
  /** column name */
  Id = 'id',
  /** column name */
  LockExpiration = 'lock_expiration',
  /** column name */
  SendScheduledFor = 'send_scheduled_for',
  /** column name */
  State = 'state',
  /** column name */
  Subject = 'subject',
  /** column name */
  ToAddresses = 'to_addresses',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Email_Emails_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Email_Emails_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Email_Emails_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Email_Emails_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Email_Emails_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Email_Emails_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Email_Emails_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Email_Emails_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Emails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Email_Emails_Var_Pop_Fields = {
  __typename?: 'email_emails_var_pop_fields';
  attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Email_Emails_Var_Samp_Fields = {
  __typename?: 'email_emails_var_samp_fields';
  attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Email_Emails_Variance_Fields = {
  __typename?: 'email_emails_variance_fields';
  attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "enum_types" */
export type Enum_Types = {
  __typename?: 'enum_types';
  type_name?: Maybe<Scalars['name']>;
  variant?: Maybe<Scalars['name']>;
};

/** aggregated selection of "enum_types" */
export type Enum_Types_Aggregate = {
  __typename?: 'enum_types_aggregate';
  aggregate?: Maybe<Enum_Types_Aggregate_Fields>;
  nodes: Array<Enum_Types>;
};

/** aggregate fields of "enum_types" */
export type Enum_Types_Aggregate_Fields = {
  __typename?: 'enum_types_aggregate_fields';
  count: Scalars['Int'];
};


/** aggregate fields of "enum_types" */
export type Enum_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_types". All fields are combined with a logical 'AND'. */
export type Enum_Types_Bool_Exp = {
  _and?: Maybe<Array<Enum_Types_Bool_Exp>>;
  _not?: Maybe<Enum_Types_Bool_Exp>;
  _or?: Maybe<Array<Enum_Types_Bool_Exp>>;
  type_name?: Maybe<Name_Comparison_Exp>;
  variant?: Maybe<Name_Comparison_Exp>;
};

/** Ordering options when selecting data from "enum_types". */
export type Enum_Types_Order_By = {
  type_name?: Maybe<Order_By>;
  variant?: Maybe<Order_By>;
};

/** select columns of table "enum_types" */
export enum Enum_Types_Select_Column {
  /** column name */
  TypeName = 'type_name',
  /** column name */
  Variant = 'variant'
}

/** Streaming cursor of the table "enum_types" */
export type Enum_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Types_Stream_Cursor_Value_Input = {
  type_name?: Maybe<Scalars['name']>;
  variant?: Maybe<Scalars['name']>;
};

/** Boolean expression to compare columns of type "evergreen_types". All fields are combined with logical 'AND'. */
export type Evergreen_Types_Comparison_Exp = {
  _eq?: Maybe<Scalars['evergreen_types']>;
  _gt?: Maybe<Scalars['evergreen_types']>;
  _gte?: Maybe<Scalars['evergreen_types']>;
  _in?: Maybe<Array<Scalars['evergreen_types']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['evergreen_types']>;
  _lte?: Maybe<Scalars['evergreen_types']>;
  _neq?: Maybe<Scalars['evergreen_types']>;
  _nin?: Maybe<Array<Scalars['evergreen_types']>>;
};

/** columns and relationships of "fee_types" */
export type Fee_Types = {
  __typename?: 'fee_types';
  calc_list?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamp'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "fee_types" */
export type Fee_Types_Aggregate = {
  __typename?: 'fee_types_aggregate';
  aggregate?: Maybe<Fee_Types_Aggregate_Fields>;
  nodes: Array<Fee_Types>;
};

/** aggregate fields of "fee_types" */
export type Fee_Types_Aggregate_Fields = {
  __typename?: 'fee_types_aggregate_fields';
  avg?: Maybe<Fee_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fee_Types_Max_Fields>;
  min?: Maybe<Fee_Types_Min_Fields>;
  stddev?: Maybe<Fee_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Fee_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fee_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Fee_Types_Sum_Fields>;
  var_pop?: Maybe<Fee_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Fee_Types_Var_Samp_Fields>;
  variance?: Maybe<Fee_Types_Variance_Fields>;
};


/** aggregate fields of "fee_types" */
export type Fee_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fee_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Fee_Types_Avg_Fields = {
  __typename?: 'fee_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fee_types". All fields are combined with a logical 'AND'. */
export type Fee_Types_Bool_Exp = {
  _and?: Maybe<Array<Fee_Types_Bool_Exp>>;
  _not?: Maybe<Fee_Types_Bool_Exp>;
  _or?: Maybe<Array<Fee_Types_Bool_Exp>>;
  calc_list?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "fee_types" */
export enum Fee_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeeTypesPkey = 'fee_types_pkey'
}

/** input type for incrementing numeric columns in table "fee_types" */
export type Fee_Types_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "fee_types" */
export type Fee_Types_Insert_Input = {
  calc_list?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Fee_Types_Max_Fields = {
  __typename?: 'fee_types_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Fee_Types_Min_Fields = {
  __typename?: 'fee_types_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "fee_types" */
export type Fee_Types_Mutation_Response = {
  __typename?: 'fee_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fee_Types>;
};

/** input type for inserting object relation for remote table "fee_types" */
export type Fee_Types_Obj_Rel_Insert_Input = {
  data: Fee_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Fee_Types_On_Conflict>;
};

/** on_conflict condition type for table "fee_types" */
export type Fee_Types_On_Conflict = {
  constraint: Fee_Types_Constraint;
  update_columns?: Array<Fee_Types_Update_Column>;
  where?: Maybe<Fee_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "fee_types". */
export type Fee_Types_Order_By = {
  calc_list?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: fee_types */
export type Fee_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "fee_types" */
export enum Fee_Types_Select_Column {
  /** column name */
  CalcList = 'calc_list',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "fee_types" */
export type Fee_Types_Set_Input = {
  calc_list?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Fee_Types_Stddev_Fields = {
  __typename?: 'fee_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Fee_Types_Stddev_Pop_Fields = {
  __typename?: 'fee_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Fee_Types_Stddev_Samp_Fields = {
  __typename?: 'fee_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fee_types" */
export type Fee_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fee_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fee_Types_Stream_Cursor_Value_Input = {
  calc_list?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Fee_Types_Sum_Fields = {
  __typename?: 'fee_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "fee_types" */
export enum Fee_Types_Update_Column {
  /** column name */
  CalcList = 'calc_list',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Fee_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Fee_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fee_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fee_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fee_Types_Var_Pop_Fields = {
  __typename?: 'fee_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Fee_Types_Var_Samp_Fields = {
  __typename?: 'fee_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Fee_Types_Variance_Fields = {
  __typename?: 'fee_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "fees" */
export type Fees = {
  __typename?: 'fees';
  calc_fuel?: Maybe<Scalars['Boolean']>;
  commodity_only?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  daily?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  deal_details_fee_1: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_fee_1_aggregate: Deal_Details_Aggregate;
  /** An array relationship */
  deal_details_fee_2: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_fee_2_aggregate: Deal_Details_Aggregate;
  /** An array relationship */
  deal_details_fee_3: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_fee_3_aggregate: Deal_Details_Aggregate;
  /** An array relationship */
  deal_details_fee_4: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_fee_4_aggregate: Deal_Details_Aggregate;
  /** An array relationship */
  deal_details_fee_5: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_fee_5_aggregate: Deal_Details_Aggregate;
  /** An array relationship */
  deal_details_fee_6: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_fee_6_aggregate: Deal_Details_Aggregate;
  /** An array relationship */
  deal_details_fee_7: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_fee_7_aggregate: Deal_Details_Aggregate;
  /** An array relationship */
  deal_details_fee_8: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_fee_8_aggregate: Deal_Details_Aggregate;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fee_type?: Maybe<Fee_Types>;
  fee_type_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  index_id?: Maybe<Scalars['Int']>;
  max_bill_qty?: Maybe<Scalars['float8']>;
  mdq?: Maybe<Scalars['Boolean']>;
  min_bill_qty?: Maybe<Scalars['float8']>;
  percent?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_id?: Maybe<Scalars['Int']>;
  volumetric?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_1Args = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_1_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_2Args = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_2_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_3Args = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_3_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_4Args = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_4_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_5Args = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_5_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_6Args = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_6_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_7Args = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_7_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_8Args = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "fees" */
export type FeesDeal_Details_Fee_8_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};

/** aggregated selection of "fees" */
export type Fees_Aggregate = {
  __typename?: 'fees_aggregate';
  aggregate?: Maybe<Fees_Aggregate_Fields>;
  nodes: Array<Fees>;
};

/** aggregate fields of "fees" */
export type Fees_Aggregate_Fields = {
  __typename?: 'fees_aggregate_fields';
  avg?: Maybe<Fees_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fees_Max_Fields>;
  min?: Maybe<Fees_Min_Fields>;
  stddev?: Maybe<Fees_Stddev_Fields>;
  stddev_pop?: Maybe<Fees_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fees_Stddev_Samp_Fields>;
  sum?: Maybe<Fees_Sum_Fields>;
  var_pop?: Maybe<Fees_Var_Pop_Fields>;
  var_samp?: Maybe<Fees_Var_Samp_Fields>;
  variance?: Maybe<Fees_Variance_Fields>;
};


/** aggregate fields of "fees" */
export type Fees_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fees_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Fees_Avg_Fields = {
  __typename?: 'fees_avg_fields';
  display_order?: Maybe<Scalars['Float']>;
  fee_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  max_bill_qty?: Maybe<Scalars['Float']>;
  min_bill_qty?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fees". All fields are combined with a logical 'AND'. */
export type Fees_Bool_Exp = {
  _and?: Maybe<Array<Fees_Bool_Exp>>;
  _not?: Maybe<Fees_Bool_Exp>;
  _or?: Maybe<Array<Fees_Bool_Exp>>;
  calc_fuel?: Maybe<Boolean_Comparison_Exp>;
  commodity_only?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  daily?: Maybe<Boolean_Comparison_Exp>;
  deal_details_fee_1?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_fee_1_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  deal_details_fee_2?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_fee_2_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  deal_details_fee_3?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_fee_3_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  deal_details_fee_4?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_fee_4_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  deal_details_fee_5?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_fee_5_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  deal_details_fee_6?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_fee_6_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  deal_details_fee_7?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_fee_7_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  deal_details_fee_8?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_fee_8_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  discount?: Maybe<Boolean_Comparison_Exp>;
  display_order?: Maybe<Int_Comparison_Exp>;
  fee_type?: Maybe<Fee_Types_Bool_Exp>;
  fee_type_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  index_id?: Maybe<Int_Comparison_Exp>;
  max_bill_qty?: Maybe<Float8_Comparison_Exp>;
  mdq?: Maybe<Boolean_Comparison_Exp>;
  min_bill_qty?: Maybe<Float8_Comparison_Exp>;
  percent?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  utility_id?: Maybe<Int_Comparison_Exp>;
  volumetric?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "fees" */
export enum Fees_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeesPkey = 'fees_pkey'
}

/** input type for incrementing numeric columns in table "fees" */
export type Fees_Inc_Input = {
  display_order?: Maybe<Scalars['Int']>;
  fee_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  max_bill_qty?: Maybe<Scalars['float8']>;
  min_bill_qty?: Maybe<Scalars['float8']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "fees" */
export type Fees_Insert_Input = {
  calc_fuel?: Maybe<Scalars['Boolean']>;
  commodity_only?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  daily?: Maybe<Scalars['Boolean']>;
  deal_details_fee_1?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  deal_details_fee_2?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  deal_details_fee_3?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  deal_details_fee_4?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  deal_details_fee_5?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  deal_details_fee_6?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  deal_details_fee_7?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  deal_details_fee_8?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Int']>;
  fee_type?: Maybe<Fee_Types_Obj_Rel_Insert_Input>;
  fee_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  max_bill_qty?: Maybe<Scalars['float8']>;
  mdq?: Maybe<Scalars['Boolean']>;
  min_bill_qty?: Maybe<Scalars['float8']>;
  percent?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_id?: Maybe<Scalars['Int']>;
  volumetric?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Fees_Max_Fields = {
  __typename?: 'fees_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  display_order?: Maybe<Scalars['Int']>;
  fee_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  max_bill_qty?: Maybe<Scalars['float8']>;
  min_bill_qty?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Fees_Min_Fields = {
  __typename?: 'fees_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  display_order?: Maybe<Scalars['Int']>;
  fee_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  max_bill_qty?: Maybe<Scalars['float8']>;
  min_bill_qty?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "fees" */
export type Fees_Mutation_Response = {
  __typename?: 'fees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fees>;
};

/** input type for inserting object relation for remote table "fees" */
export type Fees_Obj_Rel_Insert_Input = {
  data: Fees_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Fees_On_Conflict>;
};

/** on_conflict condition type for table "fees" */
export type Fees_On_Conflict = {
  constraint: Fees_Constraint;
  update_columns?: Array<Fees_Update_Column>;
  where?: Maybe<Fees_Bool_Exp>;
};

/** Ordering options when selecting data from "fees". */
export type Fees_Order_By = {
  calc_fuel?: Maybe<Order_By>;
  commodity_only?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  daily?: Maybe<Order_By>;
  deal_details_fee_1_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  deal_details_fee_2_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  deal_details_fee_3_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  deal_details_fee_4_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  deal_details_fee_5_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  deal_details_fee_6_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  deal_details_fee_7_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  deal_details_fee_8_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  display_order?: Maybe<Order_By>;
  fee_type?: Maybe<Fee_Types_Order_By>;
  fee_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  max_bill_qty?: Maybe<Order_By>;
  mdq?: Maybe<Order_By>;
  min_bill_qty?: Maybe<Order_By>;
  percent?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
  volumetric?: Maybe<Order_By>;
};

/** primary key columns input for table: fees */
export type Fees_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "fees" */
export enum Fees_Select_Column {
  /** column name */
  CalcFuel = 'calc_fuel',
  /** column name */
  CommodityOnly = 'commodity_only',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Daily = 'daily',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  FeeTypeId = 'fee_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  MaxBillQty = 'max_bill_qty',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  MinBillQty = 'min_bill_qty',
  /** column name */
  Percent = 'percent',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityId = 'utility_id',
  /** column name */
  Volumetric = 'volumetric'
}

/** input type for updating data in table "fees" */
export type Fees_Set_Input = {
  calc_fuel?: Maybe<Scalars['Boolean']>;
  commodity_only?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  daily?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Int']>;
  fee_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  max_bill_qty?: Maybe<Scalars['float8']>;
  mdq?: Maybe<Scalars['Boolean']>;
  min_bill_qty?: Maybe<Scalars['float8']>;
  percent?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_id?: Maybe<Scalars['Int']>;
  volumetric?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Fees_Stddev_Fields = {
  __typename?: 'fees_stddev_fields';
  display_order?: Maybe<Scalars['Float']>;
  fee_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  max_bill_qty?: Maybe<Scalars['Float']>;
  min_bill_qty?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Fees_Stddev_Pop_Fields = {
  __typename?: 'fees_stddev_pop_fields';
  display_order?: Maybe<Scalars['Float']>;
  fee_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  max_bill_qty?: Maybe<Scalars['Float']>;
  min_bill_qty?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Fees_Stddev_Samp_Fields = {
  __typename?: 'fees_stddev_samp_fields';
  display_order?: Maybe<Scalars['Float']>;
  fee_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  max_bill_qty?: Maybe<Scalars['Float']>;
  min_bill_qty?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fees" */
export type Fees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fees_Stream_Cursor_Value_Input = {
  calc_fuel?: Maybe<Scalars['Boolean']>;
  commodity_only?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  daily?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Int']>;
  fee_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  max_bill_qty?: Maybe<Scalars['float8']>;
  mdq?: Maybe<Scalars['Boolean']>;
  min_bill_qty?: Maybe<Scalars['float8']>;
  percent?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_id?: Maybe<Scalars['Int']>;
  volumetric?: Maybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Fees_Sum_Fields = {
  __typename?: 'fees_sum_fields';
  display_order?: Maybe<Scalars['Int']>;
  fee_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  max_bill_qty?: Maybe<Scalars['float8']>;
  min_bill_qty?: Maybe<Scalars['float8']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "fees" */
export enum Fees_Update_Column {
  /** column name */
  CalcFuel = 'calc_fuel',
  /** column name */
  CommodityOnly = 'commodity_only',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Daily = 'daily',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  FeeTypeId = 'fee_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  MaxBillQty = 'max_bill_qty',
  /** column name */
  Mdq = 'mdq',
  /** column name */
  MinBillQty = 'min_bill_qty',
  /** column name */
  Percent = 'percent',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityId = 'utility_id',
  /** column name */
  Volumetric = 'volumetric'
}

export type Fees_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Fees_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fees_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fees_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fees_Var_Pop_Fields = {
  __typename?: 'fees_var_pop_fields';
  display_order?: Maybe<Scalars['Float']>;
  fee_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  max_bill_qty?: Maybe<Scalars['Float']>;
  min_bill_qty?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Fees_Var_Samp_Fields = {
  __typename?: 'fees_var_samp_fields';
  display_order?: Maybe<Scalars['Float']>;
  fee_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  max_bill_qty?: Maybe<Scalars['Float']>;
  min_bill_qty?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Fees_Variance_Fields = {
  __typename?: 'fees_variance_fields';
  display_order?: Maybe<Scalars['Float']>;
  fee_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  max_bill_qty?: Maybe<Scalars['Float']>;
  min_bill_qty?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "files.document_render_results" */
export type Files_Document_Render_Results = {
  __typename?: 'files_document_render_results';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  /** An object relationship */
  document_template_execution: Files_Document_Template_Executions;
  document_template_execution_id: Scalars['bigint'];
  /** An object relationship */
  email?: Maybe<Email_Emails>;
  email_id?: Maybe<Scalars['bigint']>;
  html: Scalars['String'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An object relationship */
  pdf_render?: Maybe<Files_Pdf_Render>;
  pdf_render_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "files.document_render_results" */
export type Files_Document_Render_ResultsDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "files.document_render_results" */
export type Files_Document_Render_Results_Aggregate = {
  __typename?: 'files_document_render_results_aggregate';
  aggregate?: Maybe<Files_Document_Render_Results_Aggregate_Fields>;
  nodes: Array<Files_Document_Render_Results>;
};

export type Files_Document_Render_Results_Aggregate_Bool_Exp = {
  count?: Maybe<Files_Document_Render_Results_Aggregate_Bool_Exp_Count>;
};

export type Files_Document_Render_Results_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Files_Document_Render_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "files.document_render_results" */
export type Files_Document_Render_Results_Aggregate_Fields = {
  __typename?: 'files_document_render_results_aggregate_fields';
  avg?: Maybe<Files_Document_Render_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Files_Document_Render_Results_Max_Fields>;
  min?: Maybe<Files_Document_Render_Results_Min_Fields>;
  stddev?: Maybe<Files_Document_Render_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Document_Render_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Document_Render_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Document_Render_Results_Sum_Fields>;
  var_pop?: Maybe<Files_Document_Render_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Document_Render_Results_Var_Samp_Fields>;
  variance?: Maybe<Files_Document_Render_Results_Variance_Fields>;
};


/** aggregate fields of "files.document_render_results" */
export type Files_Document_Render_Results_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "files.document_render_results" */
export type Files_Document_Render_Results_Aggregate_Order_By = {
  avg?: Maybe<Files_Document_Render_Results_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Files_Document_Render_Results_Max_Order_By>;
  min?: Maybe<Files_Document_Render_Results_Min_Order_By>;
  stddev?: Maybe<Files_Document_Render_Results_Stddev_Order_By>;
  stddev_pop?: Maybe<Files_Document_Render_Results_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Files_Document_Render_Results_Stddev_Samp_Order_By>;
  sum?: Maybe<Files_Document_Render_Results_Sum_Order_By>;
  var_pop?: Maybe<Files_Document_Render_Results_Var_Pop_Order_By>;
  var_samp?: Maybe<Files_Document_Render_Results_Var_Samp_Order_By>;
  variance?: Maybe<Files_Document_Render_Results_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Files_Document_Render_Results_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "files.document_render_results" */
export type Files_Document_Render_Results_Arr_Rel_Insert_Input = {
  data: Array<Files_Document_Render_Results_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Files_Document_Render_Results_On_Conflict>;
};

/** aggregate avg on columns */
export type Files_Document_Render_Results_Avg_Fields = {
  __typename?: 'files_document_render_results_avg_fields';
  document_template_execution_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdf_render_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Avg_Order_By = {
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "files.document_render_results". All fields are combined with a logical 'AND'. */
export type Files_Document_Render_Results_Bool_Exp = {
  _and?: Maybe<Array<Files_Document_Render_Results_Bool_Exp>>;
  _not?: Maybe<Files_Document_Render_Results_Bool_Exp>;
  _or?: Maybe<Array<Files_Document_Render_Results_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  document_template_execution?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
  document_template_execution_id?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<Email_Emails_Bool_Exp>;
  email_id?: Maybe<Bigint_Comparison_Exp>;
  html?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  pdf_render?: Maybe<Files_Pdf_Render_Bool_Exp>;
  pdf_render_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "files.document_render_results" */
export enum Files_Document_Render_Results_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentRenderResultsPkey = 'document_render_results_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Files_Document_Render_Results_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Files_Document_Render_Results_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Files_Document_Render_Results_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "files.document_render_results" */
export type Files_Document_Render_Results_Inc_Input = {
  document_template_execution_id?: Maybe<Scalars['bigint']>;
  email_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_render_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "files.document_render_results" */
export type Files_Document_Render_Results_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  document_template_execution?: Maybe<Files_Document_Template_Executions_Obj_Rel_Insert_Input>;
  document_template_execution_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Email_Emails_Obj_Rel_Insert_Input>;
  email_id?: Maybe<Scalars['bigint']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pdf_render?: Maybe<Files_Pdf_Render_Obj_Rel_Insert_Input>;
  pdf_render_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Files_Document_Render_Results_Max_Fields = {
  __typename?: 'files_document_render_results_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  document_template_execution_id?: Maybe<Scalars['bigint']>;
  email_id?: Maybe<Scalars['bigint']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pdf_render_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  html?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Document_Render_Results_Min_Fields = {
  __typename?: 'files_document_render_results_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  document_template_execution_id?: Maybe<Scalars['bigint']>;
  email_id?: Maybe<Scalars['bigint']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pdf_render_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  html?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "files.document_render_results" */
export type Files_Document_Render_Results_Mutation_Response = {
  __typename?: 'files_document_render_results_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files_Document_Render_Results>;
};

/** input type for inserting object relation for remote table "files.document_render_results" */
export type Files_Document_Render_Results_Obj_Rel_Insert_Input = {
  data: Files_Document_Render_Results_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Files_Document_Render_Results_On_Conflict>;
};

/** on_conflict condition type for table "files.document_render_results" */
export type Files_Document_Render_Results_On_Conflict = {
  constraint: Files_Document_Render_Results_Constraint;
  update_columns?: Array<Files_Document_Render_Results_Update_Column>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};

/** Ordering options when selecting data from "files.document_render_results". */
export type Files_Document_Render_Results_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  document_template_execution?: Maybe<Files_Document_Template_Executions_Order_By>;
  document_template_execution_id?: Maybe<Order_By>;
  email?: Maybe<Email_Emails_Order_By>;
  email_id?: Maybe<Order_By>;
  html?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pdf_render?: Maybe<Files_Pdf_Render_Order_By>;
  pdf_render_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: files.document_render_results */
export type Files_Document_Render_Results_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Files_Document_Render_Results_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "files.document_render_results" */
export enum Files_Document_Render_Results_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DocumentTemplateExecutionId = 'document_template_execution_id',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PdfRenderId = 'pdf_render_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "files.document_render_results" */
export type Files_Document_Render_Results_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  document_template_execution_id?: Maybe<Scalars['bigint']>;
  email_id?: Maybe<Scalars['bigint']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pdf_render_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Files_Document_Render_Results_Stddev_Fields = {
  __typename?: 'files_document_render_results_stddev_fields';
  document_template_execution_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdf_render_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Stddev_Order_By = {
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Files_Document_Render_Results_Stddev_Pop_Fields = {
  __typename?: 'files_document_render_results_stddev_pop_fields';
  document_template_execution_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdf_render_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Stddev_Pop_Order_By = {
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Files_Document_Render_Results_Stddev_Samp_Fields = {
  __typename?: 'files_document_render_results_stddev_samp_fields';
  document_template_execution_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdf_render_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Stddev_Samp_Order_By = {
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "files_document_render_results" */
export type Files_Document_Render_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Document_Render_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Document_Render_Results_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  document_template_execution_id?: Maybe<Scalars['bigint']>;
  email_id?: Maybe<Scalars['bigint']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pdf_render_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Files_Document_Render_Results_Sum_Fields = {
  __typename?: 'files_document_render_results_sum_fields';
  document_template_execution_id?: Maybe<Scalars['bigint']>;
  email_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_render_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Sum_Order_By = {
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
};

/** update columns of table "files.document_render_results" */
export enum Files_Document_Render_Results_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DocumentTemplateExecutionId = 'document_template_execution_id',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PdfRenderId = 'pdf_render_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Files_Document_Render_Results_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Files_Document_Render_Results_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Files_Document_Render_Results_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Files_Document_Render_Results_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Files_Document_Render_Results_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Files_Document_Render_Results_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Files_Document_Render_Results_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Files_Document_Render_Results_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Document_Render_Results_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Document_Render_Results_Var_Pop_Fields = {
  __typename?: 'files_document_render_results_var_pop_fields';
  document_template_execution_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdf_render_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Var_Pop_Order_By = {
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Files_Document_Render_Results_Var_Samp_Fields = {
  __typename?: 'files_document_render_results_var_samp_fields';
  document_template_execution_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdf_render_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Var_Samp_Order_By = {
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Files_Document_Render_Results_Variance_Fields = {
  __typename?: 'files_document_render_results_variance_fields';
  document_template_execution_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdf_render_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "files.document_render_results" */
export type Files_Document_Render_Results_Variance_Order_By = {
  document_template_execution_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_render_id?: Maybe<Order_By>;
};

/** columns and relationships of "files.document_template_executions" */
export type Files_Document_Template_Executions = {
  __typename?: 'files_document_template_executions';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  document_render_results: Array<Files_Document_Render_Results>;
  /** An aggregate relationship */
  document_render_results_aggregate: Files_Document_Render_Results_Aggregate;
  /** An object relationship */
  document_template: Files_Document_Templates;
  document_template_id: Scalars['bigint'];
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "files.document_template_executions" */
export type Files_Document_Template_ExecutionsDocument_Render_ResultsArgs = {
  distinct_on?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Render_Results_Order_By>>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};


/** columns and relationships of "files.document_template_executions" */
export type Files_Document_Template_ExecutionsDocument_Render_Results_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Render_Results_Order_By>>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};

/** aggregated selection of "files.document_template_executions" */
export type Files_Document_Template_Executions_Aggregate = {
  __typename?: 'files_document_template_executions_aggregate';
  aggregate?: Maybe<Files_Document_Template_Executions_Aggregate_Fields>;
  nodes: Array<Files_Document_Template_Executions>;
};

export type Files_Document_Template_Executions_Aggregate_Bool_Exp = {
  count?: Maybe<Files_Document_Template_Executions_Aggregate_Bool_Exp_Count>;
};

export type Files_Document_Template_Executions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Files_Document_Template_Executions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "files.document_template_executions" */
export type Files_Document_Template_Executions_Aggregate_Fields = {
  __typename?: 'files_document_template_executions_aggregate_fields';
  avg?: Maybe<Files_Document_Template_Executions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Files_Document_Template_Executions_Max_Fields>;
  min?: Maybe<Files_Document_Template_Executions_Min_Fields>;
  stddev?: Maybe<Files_Document_Template_Executions_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Document_Template_Executions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Document_Template_Executions_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Document_Template_Executions_Sum_Fields>;
  var_pop?: Maybe<Files_Document_Template_Executions_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Document_Template_Executions_Var_Samp_Fields>;
  variance?: Maybe<Files_Document_Template_Executions_Variance_Fields>;
};


/** aggregate fields of "files.document_template_executions" */
export type Files_Document_Template_Executions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Files_Document_Template_Executions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Aggregate_Order_By = {
  avg?: Maybe<Files_Document_Template_Executions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Files_Document_Template_Executions_Max_Order_By>;
  min?: Maybe<Files_Document_Template_Executions_Min_Order_By>;
  stddev?: Maybe<Files_Document_Template_Executions_Stddev_Order_By>;
  stddev_pop?: Maybe<Files_Document_Template_Executions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Files_Document_Template_Executions_Stddev_Samp_Order_By>;
  sum?: Maybe<Files_Document_Template_Executions_Sum_Order_By>;
  var_pop?: Maybe<Files_Document_Template_Executions_Var_Pop_Order_By>;
  var_samp?: Maybe<Files_Document_Template_Executions_Var_Samp_Order_By>;
  variance?: Maybe<Files_Document_Template_Executions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "files.document_template_executions" */
export type Files_Document_Template_Executions_Arr_Rel_Insert_Input = {
  data: Array<Files_Document_Template_Executions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Files_Document_Template_Executions_On_Conflict>;
};

/** aggregate avg on columns */
export type Files_Document_Template_Executions_Avg_Fields = {
  __typename?: 'files_document_template_executions_avg_fields';
  document_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Avg_Order_By = {
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "files.document_template_executions". All fields are combined with a logical 'AND'. */
export type Files_Document_Template_Executions_Bool_Exp = {
  _and?: Maybe<Array<Files_Document_Template_Executions_Bool_Exp>>;
  _not?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
  _or?: Maybe<Array<Files_Document_Template_Executions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_render_results?: Maybe<Files_Document_Render_Results_Bool_Exp>;
  document_render_results_aggregate?: Maybe<Files_Document_Render_Results_Aggregate_Bool_Exp>;
  document_template?: Maybe<Files_Document_Templates_Bool_Exp>;
  document_template_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "files.document_template_executions" */
export enum Files_Document_Template_Executions_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentTemplateExecutionsPkey = 'document_template_executions_pkey'
}

/** input type for incrementing numeric columns in table "files.document_template_executions" */
export type Files_Document_Template_Executions_Inc_Input = {
  document_template_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "files.document_template_executions" */
export type Files_Document_Template_Executions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  document_render_results?: Maybe<Files_Document_Render_Results_Arr_Rel_Insert_Input>;
  document_template?: Maybe<Files_Document_Templates_Obj_Rel_Insert_Input>;
  document_template_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Files_Document_Template_Executions_Max_Fields = {
  __typename?: 'files_document_template_executions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  document_template_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Document_Template_Executions_Min_Fields = {
  __typename?: 'files_document_template_executions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  document_template_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "files.document_template_executions" */
export type Files_Document_Template_Executions_Mutation_Response = {
  __typename?: 'files_document_template_executions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files_Document_Template_Executions>;
};

/** input type for inserting object relation for remote table "files.document_template_executions" */
export type Files_Document_Template_Executions_Obj_Rel_Insert_Input = {
  data: Files_Document_Template_Executions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Files_Document_Template_Executions_On_Conflict>;
};

/** on_conflict condition type for table "files.document_template_executions" */
export type Files_Document_Template_Executions_On_Conflict = {
  constraint: Files_Document_Template_Executions_Constraint;
  update_columns?: Array<Files_Document_Template_Executions_Update_Column>;
  where?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
};

/** Ordering options when selecting data from "files.document_template_executions". */
export type Files_Document_Template_Executions_Order_By = {
  created_at?: Maybe<Order_By>;
  document_render_results_aggregate?: Maybe<Files_Document_Render_Results_Aggregate_Order_By>;
  document_template?: Maybe<Files_Document_Templates_Order_By>;
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: files.document_template_executions */
export type Files_Document_Template_Executions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "files.document_template_executions" */
export enum Files_Document_Template_Executions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentTemplateId = 'document_template_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "files.document_template_executions" */
export type Files_Document_Template_Executions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  document_template_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Files_Document_Template_Executions_Stddev_Fields = {
  __typename?: 'files_document_template_executions_stddev_fields';
  document_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Stddev_Order_By = {
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Files_Document_Template_Executions_Stddev_Pop_Fields = {
  __typename?: 'files_document_template_executions_stddev_pop_fields';
  document_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Stddev_Pop_Order_By = {
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Files_Document_Template_Executions_Stddev_Samp_Fields = {
  __typename?: 'files_document_template_executions_stddev_samp_fields';
  document_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Stddev_Samp_Order_By = {
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "files_document_template_executions" */
export type Files_Document_Template_Executions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Document_Template_Executions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Document_Template_Executions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  document_template_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Files_Document_Template_Executions_Sum_Fields = {
  __typename?: 'files_document_template_executions_sum_fields';
  document_template_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Sum_Order_By = {
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "files.document_template_executions" */
export enum Files_Document_Template_Executions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentTemplateId = 'document_template_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Files_Document_Template_Executions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Files_Document_Template_Executions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Files_Document_Template_Executions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Document_Template_Executions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Document_Template_Executions_Var_Pop_Fields = {
  __typename?: 'files_document_template_executions_var_pop_fields';
  document_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Var_Pop_Order_By = {
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Files_Document_Template_Executions_Var_Samp_Fields = {
  __typename?: 'files_document_template_executions_var_samp_fields';
  document_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Var_Samp_Order_By = {
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Files_Document_Template_Executions_Variance_Fields = {
  __typename?: 'files_document_template_executions_variance_fields';
  document_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "files.document_template_executions" */
export type Files_Document_Template_Executions_Variance_Order_By = {
  document_template_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "files.document_templates" */
export type Files_Document_Templates = {
  __typename?: 'files_document_templates';
  /** An object relationship */
  author: Auth_Users;
  author_id: Scalars['Int'];
  body_template: Scalars['String'];
  created_at: Scalars['timestamptz'];
  daily_rate_limit?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  document_template_executions: Array<Files_Document_Template_Executions>;
  /** An aggregate relationship */
  document_template_executions_aggregate: Files_Document_Template_Executions_Aggregate;
  engine: Scalars['Int'];
  footer_template?: Maybe<Scalars['String']>;
  header_template?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  input_sql: Scalars['String'];
  lock_expiration?: Maybe<Scalars['timestamp']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  on_commit_sql?: Maybe<Scalars['String']>;
  paused: Scalars['Boolean'];
  preflight_sql?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  version: Scalars['Int'];
};


/** columns and relationships of "files.document_templates" */
export type Files_Document_TemplatesDocument_Template_ExecutionsArgs = {
  distinct_on?: Maybe<Array<Files_Document_Template_Executions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Template_Executions_Order_By>>;
  where?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
};


/** columns and relationships of "files.document_templates" */
export type Files_Document_TemplatesDocument_Template_Executions_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Document_Template_Executions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Template_Executions_Order_By>>;
  where?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
};

/** aggregated selection of "files.document_templates" */
export type Files_Document_Templates_Aggregate = {
  __typename?: 'files_document_templates_aggregate';
  aggregate?: Maybe<Files_Document_Templates_Aggregate_Fields>;
  nodes: Array<Files_Document_Templates>;
};

/** aggregate fields of "files.document_templates" */
export type Files_Document_Templates_Aggregate_Fields = {
  __typename?: 'files_document_templates_aggregate_fields';
  avg?: Maybe<Files_Document_Templates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Files_Document_Templates_Max_Fields>;
  min?: Maybe<Files_Document_Templates_Min_Fields>;
  stddev?: Maybe<Files_Document_Templates_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Document_Templates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Document_Templates_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Document_Templates_Sum_Fields>;
  var_pop?: Maybe<Files_Document_Templates_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Document_Templates_Var_Samp_Fields>;
  variance?: Maybe<Files_Document_Templates_Variance_Fields>;
};


/** aggregate fields of "files.document_templates" */
export type Files_Document_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Files_Document_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Files_Document_Templates_Avg_Fields = {
  __typename?: 'files_document_templates_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  daily_rate_limit?: Maybe<Scalars['Float']>;
  engine?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "files.document_templates". All fields are combined with a logical 'AND'. */
export type Files_Document_Templates_Bool_Exp = {
  _and?: Maybe<Array<Files_Document_Templates_Bool_Exp>>;
  _not?: Maybe<Files_Document_Templates_Bool_Exp>;
  _or?: Maybe<Array<Files_Document_Templates_Bool_Exp>>;
  author?: Maybe<Auth_Users_Bool_Exp>;
  author_id?: Maybe<Int_Comparison_Exp>;
  body_template?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  daily_rate_limit?: Maybe<Bigint_Comparison_Exp>;
  document_template_executions?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
  document_template_executions_aggregate?: Maybe<Files_Document_Template_Executions_Aggregate_Bool_Exp>;
  engine?: Maybe<Int_Comparison_Exp>;
  footer_template?: Maybe<String_Comparison_Exp>;
  header_template?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  input_sql?: Maybe<String_Comparison_Exp>;
  lock_expiration?: Maybe<Timestamp_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  on_commit_sql?: Maybe<String_Comparison_Exp>;
  paused?: Maybe<Boolean_Comparison_Exp>;
  preflight_sql?: Maybe<String_Comparison_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  version?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "files.document_templates" */
export enum Files_Document_Templates_Constraint {
  /** unique or primary key constraint on columns "name", "version" */
  DocumentTemplatesNameVersionKey = 'document_templates_name_version_key',
  /** unique or primary key constraint on columns "id" */
  DocumentTemplatesPkey = 'document_templates_pkey'
}

/** input type for incrementing numeric columns in table "files.document_templates" */
export type Files_Document_Templates_Inc_Input = {
  author_id?: Maybe<Scalars['Int']>;
  daily_rate_limit?: Maybe<Scalars['bigint']>;
  engine?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "files.document_templates" */
export type Files_Document_Templates_Insert_Input = {
  author?: Maybe<Auth_Users_Obj_Rel_Insert_Input>;
  author_id?: Maybe<Scalars['Int']>;
  body_template?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_rate_limit?: Maybe<Scalars['bigint']>;
  document_template_executions?: Maybe<Files_Document_Template_Executions_Arr_Rel_Insert_Input>;
  engine?: Maybe<Scalars['Int']>;
  footer_template?: Maybe<Scalars['String']>;
  header_template?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  input_sql?: Maybe<Scalars['String']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  on_commit_sql?: Maybe<Scalars['String']>;
  paused?: Maybe<Scalars['Boolean']>;
  preflight_sql?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Files_Document_Templates_Max_Fields = {
  __typename?: 'files_document_templates_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  body_template?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_rate_limit?: Maybe<Scalars['bigint']>;
  engine?: Maybe<Scalars['Int']>;
  footer_template?: Maybe<Scalars['String']>;
  header_template?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  input_sql?: Maybe<Scalars['String']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  on_commit_sql?: Maybe<Scalars['String']>;
  preflight_sql?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Files_Document_Templates_Min_Fields = {
  __typename?: 'files_document_templates_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  body_template?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_rate_limit?: Maybe<Scalars['bigint']>;
  engine?: Maybe<Scalars['Int']>;
  footer_template?: Maybe<Scalars['String']>;
  header_template?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  input_sql?: Maybe<Scalars['String']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  on_commit_sql?: Maybe<Scalars['String']>;
  preflight_sql?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "files.document_templates" */
export type Files_Document_Templates_Mutation_Response = {
  __typename?: 'files_document_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files_Document_Templates>;
};

/** input type for inserting object relation for remote table "files.document_templates" */
export type Files_Document_Templates_Obj_Rel_Insert_Input = {
  data: Files_Document_Templates_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Files_Document_Templates_On_Conflict>;
};

/** on_conflict condition type for table "files.document_templates" */
export type Files_Document_Templates_On_Conflict = {
  constraint: Files_Document_Templates_Constraint;
  update_columns?: Array<Files_Document_Templates_Update_Column>;
  where?: Maybe<Files_Document_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "files.document_templates". */
export type Files_Document_Templates_Order_By = {
  author?: Maybe<Auth_Users_Order_By>;
  author_id?: Maybe<Order_By>;
  body_template?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  daily_rate_limit?: Maybe<Order_By>;
  document_template_executions_aggregate?: Maybe<Files_Document_Template_Executions_Aggregate_Order_By>;
  engine?: Maybe<Order_By>;
  footer_template?: Maybe<Order_By>;
  header_template?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_sql?: Maybe<Order_By>;
  lock_expiration?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  on_commit_sql?: Maybe<Order_By>;
  paused?: Maybe<Order_By>;
  preflight_sql?: Maybe<Order_By>;
  published?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: files.document_templates */
export type Files_Document_Templates_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "files.document_templates" */
export enum Files_Document_Templates_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  BodyTemplate = 'body_template',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DailyRateLimit = 'daily_rate_limit',
  /** column name */
  Engine = 'engine',
  /** column name */
  FooterTemplate = 'footer_template',
  /** column name */
  HeaderTemplate = 'header_template',
  /** column name */
  Id = 'id',
  /** column name */
  InputSql = 'input_sql',
  /** column name */
  LockExpiration = 'lock_expiration',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OnCommitSql = 'on_commit_sql',
  /** column name */
  Paused = 'paused',
  /** column name */
  PreflightSql = 'preflight_sql',
  /** column name */
  Published = 'published',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "files.document_templates" */
export type Files_Document_Templates_Set_Input = {
  author_id?: Maybe<Scalars['Int']>;
  body_template?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_rate_limit?: Maybe<Scalars['bigint']>;
  engine?: Maybe<Scalars['Int']>;
  footer_template?: Maybe<Scalars['String']>;
  header_template?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  input_sql?: Maybe<Scalars['String']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  on_commit_sql?: Maybe<Scalars['String']>;
  paused?: Maybe<Scalars['Boolean']>;
  preflight_sql?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Files_Document_Templates_Stddev_Fields = {
  __typename?: 'files_document_templates_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  daily_rate_limit?: Maybe<Scalars['Float']>;
  engine?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Files_Document_Templates_Stddev_Pop_Fields = {
  __typename?: 'files_document_templates_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  daily_rate_limit?: Maybe<Scalars['Float']>;
  engine?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Files_Document_Templates_Stddev_Samp_Fields = {
  __typename?: 'files_document_templates_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  daily_rate_limit?: Maybe<Scalars['Float']>;
  engine?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "files_document_templates" */
export type Files_Document_Templates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Document_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Document_Templates_Stream_Cursor_Value_Input = {
  author_id?: Maybe<Scalars['Int']>;
  body_template?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_rate_limit?: Maybe<Scalars['bigint']>;
  engine?: Maybe<Scalars['Int']>;
  footer_template?: Maybe<Scalars['String']>;
  header_template?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  input_sql?: Maybe<Scalars['String']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  on_commit_sql?: Maybe<Scalars['String']>;
  paused?: Maybe<Scalars['Boolean']>;
  preflight_sql?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Files_Document_Templates_Sum_Fields = {
  __typename?: 'files_document_templates_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  daily_rate_limit?: Maybe<Scalars['bigint']>;
  engine?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['Int']>;
};

/** update columns of table "files.document_templates" */
export enum Files_Document_Templates_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  BodyTemplate = 'body_template',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DailyRateLimit = 'daily_rate_limit',
  /** column name */
  Engine = 'engine',
  /** column name */
  FooterTemplate = 'footer_template',
  /** column name */
  HeaderTemplate = 'header_template',
  /** column name */
  Id = 'id',
  /** column name */
  InputSql = 'input_sql',
  /** column name */
  LockExpiration = 'lock_expiration',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OnCommitSql = 'on_commit_sql',
  /** column name */
  Paused = 'paused',
  /** column name */
  PreflightSql = 'preflight_sql',
  /** column name */
  Published = 'published',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

export type Files_Document_Templates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Files_Document_Templates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Files_Document_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Document_Templates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Document_Templates_Var_Pop_Fields = {
  __typename?: 'files_document_templates_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  daily_rate_limit?: Maybe<Scalars['Float']>;
  engine?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Files_Document_Templates_Var_Samp_Fields = {
  __typename?: 'files_document_templates_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  daily_rate_limit?: Maybe<Scalars['Float']>;
  engine?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Files_Document_Templates_Variance_Fields = {
  __typename?: 'files_document_templates_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  daily_rate_limit?: Maybe<Scalars['Float']>;
  engine?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "files.pdf_render" */
export type Files_Pdf_Render = {
  __typename?: 'files_pdf_render';
  /** An object relationship */
  active_storage_blob?: Maybe<Active_Storage_Blobs>;
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer_document?: Maybe<Customer_Documents_View>;
  customer_documents_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  document_render_results: Array<Files_Document_Render_Results>;
  /** An aggregate relationship */
  document_render_results_aggregate: Files_Document_Render_Results_Aggregate;
  filename: Scalars['String'];
  html: Scalars['String'];
  id: Scalars['bigint'];
  lock_expiration?: Maybe<Scalars['timestamp']>;
  render_error?: Maybe<Scalars['String']>;
  target_customer_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "files.pdf_render" */
export type Files_Pdf_RenderDocument_Render_ResultsArgs = {
  distinct_on?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Render_Results_Order_By>>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};


/** columns and relationships of "files.pdf_render" */
export type Files_Pdf_RenderDocument_Render_Results_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Render_Results_Order_By>>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};

/** aggregated selection of "files.pdf_render" */
export type Files_Pdf_Render_Aggregate = {
  __typename?: 'files_pdf_render_aggregate';
  aggregate?: Maybe<Files_Pdf_Render_Aggregate_Fields>;
  nodes: Array<Files_Pdf_Render>;
};

/** aggregate fields of "files.pdf_render" */
export type Files_Pdf_Render_Aggregate_Fields = {
  __typename?: 'files_pdf_render_aggregate_fields';
  avg?: Maybe<Files_Pdf_Render_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Files_Pdf_Render_Max_Fields>;
  min?: Maybe<Files_Pdf_Render_Min_Fields>;
  stddev?: Maybe<Files_Pdf_Render_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Pdf_Render_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Pdf_Render_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Pdf_Render_Sum_Fields>;
  var_pop?: Maybe<Files_Pdf_Render_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Pdf_Render_Var_Samp_Fields>;
  variance?: Maybe<Files_Pdf_Render_Variance_Fields>;
};


/** aggregate fields of "files.pdf_render" */
export type Files_Pdf_Render_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Files_Pdf_Render_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Files_Pdf_Render_Avg_Fields = {
  __typename?: 'files_pdf_render_avg_fields';
  customer_documents_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_customer_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "files.pdf_render". All fields are combined with a logical 'AND'. */
export type Files_Pdf_Render_Bool_Exp = {
  _and?: Maybe<Array<Files_Pdf_Render_Bool_Exp>>;
  _not?: Maybe<Files_Pdf_Render_Bool_Exp>;
  _or?: Maybe<Array<Files_Pdf_Render_Bool_Exp>>;
  active_storage_blob?: Maybe<Active_Storage_Blobs_Bool_Exp>;
  active_storage_blob_key?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_document?: Maybe<Customer_Documents_View_Bool_Exp>;
  customer_documents_id?: Maybe<Bigint_Comparison_Exp>;
  document_render_results?: Maybe<Files_Document_Render_Results_Bool_Exp>;
  document_render_results_aggregate?: Maybe<Files_Document_Render_Results_Aggregate_Bool_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  html?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  lock_expiration?: Maybe<Timestamp_Comparison_Exp>;
  render_error?: Maybe<String_Comparison_Exp>;
  target_customer_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "files.pdf_render" */
export enum Files_Pdf_Render_Constraint {
  /** unique or primary key constraint on columns "id" */
  PdfRenderPkey = 'pdf_render_pkey'
}

/** input type for incrementing numeric columns in table "files.pdf_render" */
export type Files_Pdf_Render_Inc_Input = {
  customer_documents_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  target_customer_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "files.pdf_render" */
export type Files_Pdf_Render_Insert_Input = {
  active_storage_blob?: Maybe<Active_Storage_Blobs_Obj_Rel_Insert_Input>;
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_document?: Maybe<Customer_Documents_View_Obj_Rel_Insert_Input>;
  customer_documents_id?: Maybe<Scalars['bigint']>;
  document_render_results?: Maybe<Files_Document_Render_Results_Arr_Rel_Insert_Input>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  render_error?: Maybe<Scalars['String']>;
  target_customer_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Files_Pdf_Render_Max_Fields = {
  __typename?: 'files_pdf_render_max_fields';
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_documents_id?: Maybe<Scalars['bigint']>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  render_error?: Maybe<Scalars['String']>;
  target_customer_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Files_Pdf_Render_Min_Fields = {
  __typename?: 'files_pdf_render_min_fields';
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_documents_id?: Maybe<Scalars['bigint']>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  render_error?: Maybe<Scalars['String']>;
  target_customer_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "files.pdf_render" */
export type Files_Pdf_Render_Mutation_Response = {
  __typename?: 'files_pdf_render_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files_Pdf_Render>;
};

/** input type for inserting object relation for remote table "files.pdf_render" */
export type Files_Pdf_Render_Obj_Rel_Insert_Input = {
  data: Files_Pdf_Render_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Files_Pdf_Render_On_Conflict>;
};

/** on_conflict condition type for table "files.pdf_render" */
export type Files_Pdf_Render_On_Conflict = {
  constraint: Files_Pdf_Render_Constraint;
  update_columns?: Array<Files_Pdf_Render_Update_Column>;
  where?: Maybe<Files_Pdf_Render_Bool_Exp>;
};

/** Ordering options when selecting data from "files.pdf_render". */
export type Files_Pdf_Render_Order_By = {
  active_storage_blob?: Maybe<Active_Storage_Blobs_Order_By>;
  active_storage_blob_key?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_document?: Maybe<Customer_Documents_View_Order_By>;
  customer_documents_id?: Maybe<Order_By>;
  document_render_results_aggregate?: Maybe<Files_Document_Render_Results_Aggregate_Order_By>;
  filename?: Maybe<Order_By>;
  html?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lock_expiration?: Maybe<Order_By>;
  render_error?: Maybe<Order_By>;
  target_customer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: files.pdf_render */
export type Files_Pdf_Render_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "files.pdf_render" */
export enum Files_Pdf_Render_Select_Column {
  /** column name */
  ActiveStorageBlobKey = 'active_storage_blob_key',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerDocumentsId = 'customer_documents_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  LockExpiration = 'lock_expiration',
  /** column name */
  RenderError = 'render_error',
  /** column name */
  TargetCustomerId = 'target_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "files.pdf_render" */
export type Files_Pdf_Render_Set_Input = {
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_documents_id?: Maybe<Scalars['bigint']>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  render_error?: Maybe<Scalars['String']>;
  target_customer_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Files_Pdf_Render_Stddev_Fields = {
  __typename?: 'files_pdf_render_stddev_fields';
  customer_documents_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_customer_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Files_Pdf_Render_Stddev_Pop_Fields = {
  __typename?: 'files_pdf_render_stddev_pop_fields';
  customer_documents_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_customer_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Files_Pdf_Render_Stddev_Samp_Fields = {
  __typename?: 'files_pdf_render_stddev_samp_fields';
  customer_documents_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_customer_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "files_pdf_render" */
export type Files_Pdf_Render_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Pdf_Render_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Pdf_Render_Stream_Cursor_Value_Input = {
  active_storage_blob_key?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_documents_id?: Maybe<Scalars['bigint']>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lock_expiration?: Maybe<Scalars['timestamp']>;
  render_error?: Maybe<Scalars['String']>;
  target_customer_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Files_Pdf_Render_Sum_Fields = {
  __typename?: 'files_pdf_render_sum_fields';
  customer_documents_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  target_customer_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "files.pdf_render" */
export enum Files_Pdf_Render_Update_Column {
  /** column name */
  ActiveStorageBlobKey = 'active_storage_blob_key',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerDocumentsId = 'customer_documents_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  LockExpiration = 'lock_expiration',
  /** column name */
  RenderError = 'render_error',
  /** column name */
  TargetCustomerId = 'target_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Files_Pdf_Render_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Files_Pdf_Render_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Files_Pdf_Render_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Pdf_Render_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Pdf_Render_Var_Pop_Fields = {
  __typename?: 'files_pdf_render_var_pop_fields';
  customer_documents_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_customer_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Files_Pdf_Render_Var_Samp_Fields = {
  __typename?: 'files_pdf_render_var_samp_fields';
  customer_documents_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_customer_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Files_Pdf_Render_Variance_Fields = {
  __typename?: 'files_pdf_render_variance_fields';
  customer_documents_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  target_customer_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "forecast_types" */
export type Forecast_Types = {
  __typename?: 'forecast_types';
  created_at: Scalars['timestamp'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  num_years?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "forecast_types" */
export type Forecast_Types_Aggregate = {
  __typename?: 'forecast_types_aggregate';
  aggregate?: Maybe<Forecast_Types_Aggregate_Fields>;
  nodes: Array<Forecast_Types>;
};

export type Forecast_Types_Aggregate_Bool_Exp = {
  count?: Maybe<Forecast_Types_Aggregate_Bool_Exp_Count>;
};

export type Forecast_Types_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Forecast_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Forecast_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "forecast_types" */
export type Forecast_Types_Aggregate_Fields = {
  __typename?: 'forecast_types_aggregate_fields';
  avg?: Maybe<Forecast_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Forecast_Types_Max_Fields>;
  min?: Maybe<Forecast_Types_Min_Fields>;
  stddev?: Maybe<Forecast_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Forecast_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Forecast_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Forecast_Types_Sum_Fields>;
  var_pop?: Maybe<Forecast_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Forecast_Types_Var_Samp_Fields>;
  variance?: Maybe<Forecast_Types_Variance_Fields>;
};


/** aggregate fields of "forecast_types" */
export type Forecast_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Forecast_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "forecast_types" */
export type Forecast_Types_Aggregate_Order_By = {
  avg?: Maybe<Forecast_Types_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Forecast_Types_Max_Order_By>;
  min?: Maybe<Forecast_Types_Min_Order_By>;
  stddev?: Maybe<Forecast_Types_Stddev_Order_By>;
  stddev_pop?: Maybe<Forecast_Types_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Forecast_Types_Stddev_Samp_Order_By>;
  sum?: Maybe<Forecast_Types_Sum_Order_By>;
  var_pop?: Maybe<Forecast_Types_Var_Pop_Order_By>;
  var_samp?: Maybe<Forecast_Types_Var_Samp_Order_By>;
  variance?: Maybe<Forecast_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "forecast_types" */
export type Forecast_Types_Arr_Rel_Insert_Input = {
  data: Array<Forecast_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Forecast_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Forecast_Types_Avg_Fields = {
  __typename?: 'forecast_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  num_years?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "forecast_types" */
export type Forecast_Types_Avg_Order_By = {
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "forecast_types". All fields are combined with a logical 'AND'. */
export type Forecast_Types_Bool_Exp = {
  _and?: Maybe<Array<Forecast_Types_Bool_Exp>>;
  _not?: Maybe<Forecast_Types_Bool_Exp>;
  _or?: Maybe<Array<Forecast_Types_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  num_years?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "forecast_types" */
export enum Forecast_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  ForecastTypesPkey = 'forecast_types_pkey'
}

/** input type for incrementing numeric columns in table "forecast_types" */
export type Forecast_Types_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  num_years?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "forecast_types" */
export type Forecast_Types_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  num_years?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Forecast_Types_Max_Fields = {
  __typename?: 'forecast_types_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  num_years?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "forecast_types" */
export type Forecast_Types_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Forecast_Types_Min_Fields = {
  __typename?: 'forecast_types_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  num_years?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "forecast_types" */
export type Forecast_Types_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "forecast_types" */
export type Forecast_Types_Mutation_Response = {
  __typename?: 'forecast_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Forecast_Types>;
};

/** on_conflict condition type for table "forecast_types" */
export type Forecast_Types_On_Conflict = {
  constraint: Forecast_Types_Constraint;
  update_columns?: Array<Forecast_Types_Update_Column>;
  where?: Maybe<Forecast_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "forecast_types". */
export type Forecast_Types_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: forecast_types */
export type Forecast_Types_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "forecast_types" */
export enum Forecast_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NumYears = 'num_years',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "forecast_types" */
export type Forecast_Types_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  num_years?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Forecast_Types_Stddev_Fields = {
  __typename?: 'forecast_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  num_years?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "forecast_types" */
export type Forecast_Types_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Forecast_Types_Stddev_Pop_Fields = {
  __typename?: 'forecast_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  num_years?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "forecast_types" */
export type Forecast_Types_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Forecast_Types_Stddev_Samp_Fields = {
  __typename?: 'forecast_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  num_years?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "forecast_types" */
export type Forecast_Types_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
};

/** Streaming cursor of the table "forecast_types" */
export type Forecast_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Forecast_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Forecast_Types_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  num_years?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Forecast_Types_Sum_Fields = {
  __typename?: 'forecast_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  num_years?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "forecast_types" */
export type Forecast_Types_Sum_Order_By = {
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
};

/** update columns of table "forecast_types" */
export enum Forecast_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NumYears = 'num_years',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Forecast_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Forecast_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Forecast_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Forecast_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Forecast_Types_Var_Pop_Fields = {
  __typename?: 'forecast_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  num_years?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "forecast_types" */
export type Forecast_Types_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Forecast_Types_Var_Samp_Fields = {
  __typename?: 'forecast_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  num_years?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "forecast_types" */
export type Forecast_Types_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Forecast_Types_Variance_Fields = {
  __typename?: 'forecast_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  num_years?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "forecast_types" */
export type Forecast_Types_Variance_Order_By = {
  id?: Maybe<Order_By>;
  num_years?: Maybe<Order_By>;
};

/** columns and relationships of "group_types" */
export type Group_Types = {
  __typename?: 'group_types';
  created_at: Scalars['timestamp'];
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "group_types" */
export type Group_TypesGroupsArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


/** columns and relationships of "group_types" */
export type Group_TypesGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};

/** aggregated selection of "group_types" */
export type Group_Types_Aggregate = {
  __typename?: 'group_types_aggregate';
  aggregate?: Maybe<Group_Types_Aggregate_Fields>;
  nodes: Array<Group_Types>;
};

/** aggregate fields of "group_types" */
export type Group_Types_Aggregate_Fields = {
  __typename?: 'group_types_aggregate_fields';
  avg?: Maybe<Group_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Group_Types_Max_Fields>;
  min?: Maybe<Group_Types_Min_Fields>;
  stddev?: Maybe<Group_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Group_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Group_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Group_Types_Sum_Fields>;
  var_pop?: Maybe<Group_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Group_Types_Var_Samp_Fields>;
  variance?: Maybe<Group_Types_Variance_Fields>;
};


/** aggregate fields of "group_types" */
export type Group_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Group_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Group_Types_Avg_Fields = {
  __typename?: 'group_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "group_types". All fields are combined with a logical 'AND'. */
export type Group_Types_Bool_Exp = {
  _and?: Maybe<Array<Group_Types_Bool_Exp>>;
  _not?: Maybe<Group_Types_Bool_Exp>;
  _or?: Maybe<Array<Group_Types_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  groups?: Maybe<Groups_Bool_Exp>;
  groups_aggregate?: Maybe<Groups_Aggregate_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_types" */
export enum Group_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupTypesPkey = 'group_types_pkey'
}

/** input type for incrementing numeric columns in table "group_types" */
export type Group_Types_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "group_types" */
export type Group_Types_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  groups?: Maybe<Groups_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Group_Types_Max_Fields = {
  __typename?: 'group_types_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Group_Types_Min_Fields = {
  __typename?: 'group_types_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "group_types" */
export type Group_Types_Mutation_Response = {
  __typename?: 'group_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Group_Types>;
};

/** input type for inserting object relation for remote table "group_types" */
export type Group_Types_Obj_Rel_Insert_Input = {
  data: Group_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Group_Types_On_Conflict>;
};

/** on_conflict condition type for table "group_types" */
export type Group_Types_On_Conflict = {
  constraint: Group_Types_Constraint;
  update_columns?: Array<Group_Types_Update_Column>;
  where?: Maybe<Group_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "group_types". */
export type Group_Types_Order_By = {
  created_at?: Maybe<Order_By>;
  groups_aggregate?: Maybe<Groups_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: group_types */
export type Group_Types_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "group_types" */
export enum Group_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "group_types" */
export type Group_Types_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Group_Types_Stddev_Fields = {
  __typename?: 'group_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Group_Types_Stddev_Pop_Fields = {
  __typename?: 'group_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Group_Types_Stddev_Samp_Fields = {
  __typename?: 'group_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "group_types" */
export type Group_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Group_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Group_Types_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Group_Types_Sum_Fields = {
  __typename?: 'group_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "group_types" */
export enum Group_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Group_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Group_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Group_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Group_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Group_Types_Var_Pop_Fields = {
  __typename?: 'group_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Group_Types_Var_Samp_Fields = {
  __typename?: 'group_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Group_Types_Variance_Fields = {
  __typename?: 'group_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "groups" */
export type Groups = {
  __typename?: 'groups';
  beg_date?: Maybe<Scalars['date']>;
  commission_rate?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  customer_groups: Array<Customer_Groups>;
  /** An aggregate relationship */
  customer_groups_aggregate: Customer_Groups_Aggregate;
  data_source_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  group_type?: Maybe<Group_Types>;
  group_type_id?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pmt_frequency?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  search_term?: Maybe<Scalars['String']>;
  start_month?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "groups" */
export type GroupsCustomer_GroupsArgs = {
  distinct_on?: Maybe<Array<Customer_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Groups_Order_By>>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsCustomer_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Groups_Order_By>>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};

/** aggregated selection of "groups" */
export type Groups_Aggregate = {
  __typename?: 'groups_aggregate';
  aggregate?: Maybe<Groups_Aggregate_Fields>;
  nodes: Array<Groups>;
};

export type Groups_Aggregate_Bool_Exp = {
  avg?: Maybe<Groups_Aggregate_Bool_Exp_Avg>;
  corr?: Maybe<Groups_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Groups_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Groups_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Groups_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Groups_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Groups_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Groups_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Groups_Aggregate_Bool_Exp_Var_Samp>;
};

export type Groups_Aggregate_Bool_Exp_Avg = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Groups_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Corr = {
  arguments: Groups_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Groups_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Groups_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Groups_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Groups_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Groups_Aggregate_Bool_Exp_Max = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Groups_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Min = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Groups_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Groups_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Sum = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Groups_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Groups_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "groups" */
export type Groups_Aggregate_Fields = {
  __typename?: 'groups_aggregate_fields';
  avg?: Maybe<Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Groups_Max_Fields>;
  min?: Maybe<Groups_Min_Fields>;
  stddev?: Maybe<Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Groups_Sum_Fields>;
  var_pop?: Maybe<Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Groups_Var_Samp_Fields>;
  variance?: Maybe<Groups_Variance_Fields>;
};


/** aggregate fields of "groups" */
export type Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups" */
export type Groups_Aggregate_Order_By = {
  avg?: Maybe<Groups_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Groups_Max_Order_By>;
  min?: Maybe<Groups_Min_Order_By>;
  stddev?: Maybe<Groups_Stddev_Order_By>;
  stddev_pop?: Maybe<Groups_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Groups_Stddev_Samp_Order_By>;
  sum?: Maybe<Groups_Sum_Order_By>;
  var_pop?: Maybe<Groups_Var_Pop_Order_By>;
  var_samp?: Maybe<Groups_Var_Samp_Order_By>;
  variance?: Maybe<Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "groups" */
export type Groups_Arr_Rel_Insert_Input = {
  data: Array<Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Groups_Avg_Fields = {
  __typename?: 'groups_avg_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  data_source_id?: Maybe<Scalars['Float']>;
  group_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  pmt_frequency?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  start_month?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "groups" */
export type Groups_Avg_Order_By = {
  commission_rate?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  _and?: Maybe<Array<Groups_Bool_Exp>>;
  _not?: Maybe<Groups_Bool_Exp>;
  _or?: Maybe<Array<Groups_Bool_Exp>>;
  beg_date?: Maybe<Date_Comparison_Exp>;
  commission_rate?: Maybe<Float8_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_groups?: Maybe<Customer_Groups_Bool_Exp>;
  customer_groups_aggregate?: Maybe<Customer_Groups_Aggregate_Bool_Exp>;
  data_source_id?: Maybe<Int_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  group_type?: Maybe<Group_Types_Bool_Exp>;
  group_type_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  index_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  pmt_frequency?: Maybe<Int_Comparison_Exp>;
  pmt_terms?: Maybe<Int_Comparison_Exp>;
  search_term?: Maybe<String_Comparison_Exp>;
  start_month?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey'
}

/** input type for incrementing numeric columns in table "groups" */
export type Groups_Inc_Input = {
  commission_rate?: Maybe<Scalars['float8']>;
  data_source_id?: Maybe<Scalars['Int']>;
  group_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  index_id?: Maybe<Scalars['Int']>;
  pmt_frequency?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  start_month?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "groups" */
export type Groups_Insert_Input = {
  beg_date?: Maybe<Scalars['date']>;
  commission_rate?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_groups?: Maybe<Customer_Groups_Arr_Rel_Insert_Input>;
  data_source_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  group_type?: Maybe<Group_Types_Obj_Rel_Insert_Input>;
  group_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pmt_frequency?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  search_term?: Maybe<Scalars['String']>;
  start_month?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Groups_Max_Fields = {
  __typename?: 'groups_max_fields';
  beg_date?: Maybe<Scalars['date']>;
  commission_rate?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_source_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  group_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pmt_frequency?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  search_term?: Maybe<Scalars['String']>;
  start_month?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "groups" */
export type Groups_Max_Order_By = {
  beg_date?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  search_term?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Min_Fields = {
  __typename?: 'groups_min_fields';
  beg_date?: Maybe<Scalars['date']>;
  commission_rate?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_source_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  group_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pmt_frequency?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  search_term?: Maybe<Scalars['String']>;
  start_month?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "groups" */
export type Groups_Min_Order_By = {
  beg_date?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  search_term?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "groups" */
export type Groups_Mutation_Response = {
  __typename?: 'groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups>;
};

/** input type for inserting object relation for remote table "groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Groups_On_Conflict>;
};

/** on_conflict condition type for table "groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint;
  update_columns?: Array<Groups_Update_Column>;
  where?: Maybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Groups_Order_By = {
  beg_date?: Maybe<Order_By>;
  commission_rate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_groups_aggregate?: Maybe<Customer_Groups_Aggregate_Order_By>;
  data_source_id?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  group_type?: Maybe<Group_Types_Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  search_term?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "groups" */
export enum Groups_Select_Column {
  /** column name */
  BegDate = 'beg_date',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataSourceId = 'data_source_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  GroupTypeId = 'group_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  Name = 'name',
  /** column name */
  PmtFrequency = 'pmt_frequency',
  /** column name */
  PmtTerms = 'pmt_terms',
  /** column name */
  SearchTerm = 'search_term',
  /** column name */
  StartMonth = 'start_month',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "groups_aggregate_bool_exp_avg_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  CommissionRate = 'commission_rate'
}

/** select "groups_aggregate_bool_exp_corr_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  CommissionRate = 'commission_rate'
}

/** select "groups_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  CommissionRate = 'commission_rate'
}

/** select "groups_aggregate_bool_exp_max_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  CommissionRate = 'commission_rate'
}

/** select "groups_aggregate_bool_exp_min_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  CommissionRate = 'commission_rate'
}

/** select "groups_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  CommissionRate = 'commission_rate'
}

/** select "groups_aggregate_bool_exp_sum_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  CommissionRate = 'commission_rate'
}

/** select "groups_aggregate_bool_exp_var_samp_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  CommissionRate = 'commission_rate'
}

/** input type for updating data in table "groups" */
export type Groups_Set_Input = {
  beg_date?: Maybe<Scalars['date']>;
  commission_rate?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_source_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  group_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pmt_frequency?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  search_term?: Maybe<Scalars['String']>;
  start_month?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Groups_Stddev_Fields = {
  __typename?: 'groups_stddev_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  data_source_id?: Maybe<Scalars['Float']>;
  group_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  pmt_frequency?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  start_month?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "groups" */
export type Groups_Stddev_Order_By = {
  commission_rate?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Groups_Stddev_Pop_Fields = {
  __typename?: 'groups_stddev_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  data_source_id?: Maybe<Scalars['Float']>;
  group_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  pmt_frequency?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  start_month?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "groups" */
export type Groups_Stddev_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Groups_Stddev_Samp_Fields = {
  __typename?: 'groups_stddev_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  data_source_id?: Maybe<Scalars['Float']>;
  group_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  pmt_frequency?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  start_month?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "groups" */
export type Groups_Stddev_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
};

/** Streaming cursor of the table "groups" */
export type Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Stream_Cursor_Value_Input = {
  beg_date?: Maybe<Scalars['date']>;
  commission_rate?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_source_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  group_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pmt_frequency?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  search_term?: Maybe<Scalars['String']>;
  start_month?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Groups_Sum_Fields = {
  __typename?: 'groups_sum_fields';
  commission_rate?: Maybe<Scalars['float8']>;
  data_source_id?: Maybe<Scalars['Int']>;
  group_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  index_id?: Maybe<Scalars['Int']>;
  pmt_frequency?: Maybe<Scalars['Int']>;
  pmt_terms?: Maybe<Scalars['Int']>;
  start_month?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "groups" */
export type Groups_Sum_Order_By = {
  commission_rate?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
};

/** update columns of table "groups" */
export enum Groups_Update_Column {
  /** column name */
  BegDate = 'beg_date',
  /** column name */
  CommissionRate = 'commission_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataSourceId = 'data_source_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  GroupTypeId = 'group_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  Name = 'name',
  /** column name */
  PmtFrequency = 'pmt_frequency',
  /** column name */
  PmtTerms = 'pmt_terms',
  /** column name */
  SearchTerm = 'search_term',
  /** column name */
  StartMonth = 'start_month',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Groups_Var_Pop_Fields = {
  __typename?: 'groups_var_pop_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  data_source_id?: Maybe<Scalars['Float']>;
  group_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  pmt_frequency?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  start_month?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "groups" */
export type Groups_Var_Pop_Order_By = {
  commission_rate?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Groups_Var_Samp_Fields = {
  __typename?: 'groups_var_samp_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  data_source_id?: Maybe<Scalars['Float']>;
  group_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  pmt_frequency?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  start_month?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "groups" */
export type Groups_Var_Samp_Order_By = {
  commission_rate?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Groups_Variance_Fields = {
  __typename?: 'groups_variance_fields';
  commission_rate?: Maybe<Scalars['Float']>;
  data_source_id?: Maybe<Scalars['Float']>;
  group_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
  pmt_frequency?: Maybe<Scalars['Float']>;
  pmt_terms?: Maybe<Scalars['Float']>;
  start_month?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "groups" */
export type Groups_Variance_Order_By = {
  commission_rate?: Maybe<Order_By>;
  data_source_id?: Maybe<Order_By>;
  group_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  pmt_frequency?: Maybe<Order_By>;
  pmt_terms?: Maybe<Order_By>;
  start_month?: Maybe<Order_By>;
};

/** columns and relationships of "ind_types" */
export type Ind_Types = {
  __typename?: 'ind_types';
  acct_code?: Maybe<Scalars['String']>;
  calc_override?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "ind_types" */
export type Ind_Types_Aggregate = {
  __typename?: 'ind_types_aggregate';
  aggregate?: Maybe<Ind_Types_Aggregate_Fields>;
  nodes: Array<Ind_Types>;
};

/** aggregate fields of "ind_types" */
export type Ind_Types_Aggregate_Fields = {
  __typename?: 'ind_types_aggregate_fields';
  avg?: Maybe<Ind_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ind_Types_Max_Fields>;
  min?: Maybe<Ind_Types_Min_Fields>;
  stddev?: Maybe<Ind_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Ind_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ind_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Ind_Types_Sum_Fields>;
  var_pop?: Maybe<Ind_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Ind_Types_Var_Samp_Fields>;
  variance?: Maybe<Ind_Types_Variance_Fields>;
};


/** aggregate fields of "ind_types" */
export type Ind_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ind_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ind_Types_Avg_Fields = {
  __typename?: 'ind_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ind_types". All fields are combined with a logical 'AND'. */
export type Ind_Types_Bool_Exp = {
  _and?: Maybe<Array<Ind_Types_Bool_Exp>>;
  _not?: Maybe<Ind_Types_Bool_Exp>;
  _or?: Maybe<Array<Ind_Types_Bool_Exp>>;
  acct_code?: Maybe<String_Comparison_Exp>;
  calc_override?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  index_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  short_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "ind_types" */
export enum Ind_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  IndTypesPkey = 'ind_types_pkey'
}

/** input type for incrementing numeric columns in table "ind_types" */
export type Ind_Types_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ind_types" */
export type Ind_Types_Insert_Input = {
  acct_code?: Maybe<Scalars['String']>;
  calc_override?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Ind_Types_Max_Fields = {
  __typename?: 'ind_types_max_fields';
  acct_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Ind_Types_Min_Fields = {
  __typename?: 'ind_types_min_fields';
  acct_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "ind_types" */
export type Ind_Types_Mutation_Response = {
  __typename?: 'ind_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ind_Types>;
};

/** input type for inserting object relation for remote table "ind_types" */
export type Ind_Types_Obj_Rel_Insert_Input = {
  data: Ind_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ind_Types_On_Conflict>;
};

/** on_conflict condition type for table "ind_types" */
export type Ind_Types_On_Conflict = {
  constraint: Ind_Types_Constraint;
  update_columns?: Array<Ind_Types_Update_Column>;
  where?: Maybe<Ind_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "ind_types". */
export type Ind_Types_Order_By = {
  acct_code?: Maybe<Order_By>;
  calc_override?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  short_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: ind_types */
export type Ind_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ind_types" */
export enum Ind_Types_Select_Column {
  /** column name */
  AcctCode = 'acct_code',
  /** column name */
  CalcOverride = 'calc_override',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ind_types" */
export type Ind_Types_Set_Input = {
  acct_code?: Maybe<Scalars['String']>;
  calc_override?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Ind_Types_Stddev_Fields = {
  __typename?: 'ind_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ind_Types_Stddev_Pop_Fields = {
  __typename?: 'ind_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ind_Types_Stddev_Samp_Fields = {
  __typename?: 'ind_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ind_types" */
export type Ind_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ind_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ind_Types_Stream_Cursor_Value_Input = {
  acct_code?: Maybe<Scalars['String']>;
  calc_override?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Ind_Types_Sum_Fields = {
  __typename?: 'ind_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
  index_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ind_types" */
export enum Ind_Types_Update_Column {
  /** column name */
  AcctCode = 'acct_code',
  /** column name */
  CalcOverride = 'calc_override',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Ind_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ind_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ind_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ind_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ind_Types_Var_Pop_Fields = {
  __typename?: 'ind_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ind_Types_Var_Samp_Fields = {
  __typename?: 'ind_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ind_Types_Variance_Fields = {
  __typename?: 'ind_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  index_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "indices" */
export type Indices = {
  __typename?: 'indices';
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  customers_company: Array<Customers>;
  /** An aggregate relationship */
  customers_company_aggregate: Customers_Aggregate;
  description?: Maybe<Scalars['String']>;
  fee_id?: Maybe<Scalars['Int']>;
  forward_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  ind_type?: Maybe<Ind_Types>;
  ind_type_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  platts_id?: Maybe<Scalars['String']>;
  ref_note?: Maybe<Scalars['jsonb']>;
  shortid?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  tracked?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};


/** columns and relationships of "indices" */
export type IndicesCustomers_CompanyArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


/** columns and relationships of "indices" */
export type IndicesCustomers_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


/** columns and relationships of "indices" */
export type IndicesRef_NoteArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "indices" */
export type Indices_Aggregate = {
  __typename?: 'indices_aggregate';
  aggregate?: Maybe<Indices_Aggregate_Fields>;
  nodes: Array<Indices>;
};

/** aggregate fields of "indices" */
export type Indices_Aggregate_Fields = {
  __typename?: 'indices_aggregate_fields';
  avg?: Maybe<Indices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Indices_Max_Fields>;
  min?: Maybe<Indices_Min_Fields>;
  stddev?: Maybe<Indices_Stddev_Fields>;
  stddev_pop?: Maybe<Indices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Indices_Stddev_Samp_Fields>;
  sum?: Maybe<Indices_Sum_Fields>;
  var_pop?: Maybe<Indices_Var_Pop_Fields>;
  var_samp?: Maybe<Indices_Var_Samp_Fields>;
  variance?: Maybe<Indices_Variance_Fields>;
};


/** aggregate fields of "indices" */
export type Indices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Indices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Indices_Append_Input = {
  ref_note?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Indices_Avg_Fields = {
  __typename?: 'indices_avg_fields';
  currency_id?: Maybe<Scalars['Float']>;
  fee_id?: Maybe<Scalars['Float']>;
  forward_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ind_type_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "indices". All fields are combined with a logical 'AND'. */
export type Indices_Bool_Exp = {
  _and?: Maybe<Array<Indices_Bool_Exp>>;
  _not?: Maybe<Indices_Bool_Exp>;
  _or?: Maybe<Array<Indices_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  currency_id?: Maybe<Int_Comparison_Exp>;
  customers_company?: Maybe<Customers_Bool_Exp>;
  customers_company_aggregate?: Maybe<Customers_Aggregate_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  fee_id?: Maybe<Int_Comparison_Exp>;
  forward_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  ind_type?: Maybe<Ind_Types_Bool_Exp>;
  ind_type_id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  pipeline_id?: Maybe<Int_Comparison_Exp>;
  platts_id?: Maybe<String_Comparison_Exp>;
  ref_note?: Maybe<Jsonb_Comparison_Exp>;
  shortid?: Maybe<String_Comparison_Exp>;
  subtotal?: Maybe<Int_Comparison_Exp>;
  tracked?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "indices" */
export enum Indices_Constraint {
  /** unique or primary key constraint on columns "id" */
  IndicesPkey = 'indices_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Indices_Delete_At_Path_Input = {
  ref_note?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Indices_Delete_Elem_Input = {
  ref_note?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Indices_Delete_Key_Input = {
  ref_note?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "indices" */
export type Indices_Inc_Input = {
  currency_id?: Maybe<Scalars['Int']>;
  fee_id?: Maybe<Scalars['Int']>;
  forward_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ind_type_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  subtotal?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "indices" */
export type Indices_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency_id?: Maybe<Scalars['Int']>;
  customers_company?: Maybe<Customers_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  fee_id?: Maybe<Scalars['Int']>;
  forward_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ind_type?: Maybe<Ind_Types_Obj_Rel_Insert_Input>;
  ind_type_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  platts_id?: Maybe<Scalars['String']>;
  ref_note?: Maybe<Scalars['jsonb']>;
  shortid?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  tracked?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Indices_Max_Fields = {
  __typename?: 'indices_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  currency_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fee_id?: Maybe<Scalars['Int']>;
  forward_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ind_type_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  platts_id?: Maybe<Scalars['String']>;
  shortid?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Indices_Min_Fields = {
  __typename?: 'indices_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  currency_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fee_id?: Maybe<Scalars['Int']>;
  forward_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ind_type_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  platts_id?: Maybe<Scalars['String']>;
  shortid?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "indices" */
export type Indices_Mutation_Response = {
  __typename?: 'indices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Indices>;
};

/** input type for inserting object relation for remote table "indices" */
export type Indices_Obj_Rel_Insert_Input = {
  data: Indices_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Indices_On_Conflict>;
};

/** on_conflict condition type for table "indices" */
export type Indices_On_Conflict = {
  constraint: Indices_Constraint;
  update_columns?: Array<Indices_Update_Column>;
  where?: Maybe<Indices_Bool_Exp>;
};

/** Ordering options when selecting data from "indices". */
export type Indices_Order_By = {
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency_id?: Maybe<Order_By>;
  customers_company_aggregate?: Maybe<Customers_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  fee_id?: Maybe<Order_By>;
  forward_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ind_type?: Maybe<Ind_Types_Order_By>;
  ind_type_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  platts_id?: Maybe<Order_By>;
  ref_note?: Maybe<Order_By>;
  shortid?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
  tracked?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: indices */
export type Indices_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Indices_Prepend_Input = {
  ref_note?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "indices" */
export enum Indices_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  Description = 'description',
  /** column name */
  FeeId = 'fee_id',
  /** column name */
  ForwardId = 'forward_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndTypeId = 'ind_type_id',
  /** column name */
  Order = 'order',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  PlattsId = 'platts_id',
  /** column name */
  RefNote = 'ref_note',
  /** column name */
  Shortid = 'shortid',
  /** column name */
  Subtotal = 'subtotal',
  /** column name */
  Tracked = 'tracked',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "indices" */
export type Indices_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fee_id?: Maybe<Scalars['Int']>;
  forward_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ind_type_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  platts_id?: Maybe<Scalars['String']>;
  ref_note?: Maybe<Scalars['jsonb']>;
  shortid?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  tracked?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Indices_Stddev_Fields = {
  __typename?: 'indices_stddev_fields';
  currency_id?: Maybe<Scalars['Float']>;
  fee_id?: Maybe<Scalars['Float']>;
  forward_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ind_type_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Indices_Stddev_Pop_Fields = {
  __typename?: 'indices_stddev_pop_fields';
  currency_id?: Maybe<Scalars['Float']>;
  fee_id?: Maybe<Scalars['Float']>;
  forward_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ind_type_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Indices_Stddev_Samp_Fields = {
  __typename?: 'indices_stddev_samp_fields';
  currency_id?: Maybe<Scalars['Float']>;
  fee_id?: Maybe<Scalars['Float']>;
  forward_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ind_type_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "indices" */
export type Indices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Indices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Indices_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fee_id?: Maybe<Scalars['Int']>;
  forward_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ind_type_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  platts_id?: Maybe<Scalars['String']>;
  ref_note?: Maybe<Scalars['jsonb']>;
  shortid?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  tracked?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Indices_Sum_Fields = {
  __typename?: 'indices_sum_fields';
  currency_id?: Maybe<Scalars['Int']>;
  fee_id?: Maybe<Scalars['Int']>;
  forward_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ind_type_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  subtotal?: Maybe<Scalars['Int']>;
};

/** update columns of table "indices" */
export enum Indices_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  Description = 'description',
  /** column name */
  FeeId = 'fee_id',
  /** column name */
  ForwardId = 'forward_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndTypeId = 'ind_type_id',
  /** column name */
  Order = 'order',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  PlattsId = 'platts_id',
  /** column name */
  RefNote = 'ref_note',
  /** column name */
  Shortid = 'shortid',
  /** column name */
  Subtotal = 'subtotal',
  /** column name */
  Tracked = 'tracked',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Indices_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Indices_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Indices_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Indices_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Indices_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Indices_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Indices_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Indices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Indices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Indices_Var_Pop_Fields = {
  __typename?: 'indices_var_pop_fields';
  currency_id?: Maybe<Scalars['Float']>;
  fee_id?: Maybe<Scalars['Float']>;
  forward_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ind_type_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Indices_Var_Samp_Fields = {
  __typename?: 'indices_var_samp_fields';
  currency_id?: Maybe<Scalars['Float']>;
  fee_id?: Maybe<Scalars['Float']>;
  forward_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ind_type_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Indices_Variance_Fields = {
  __typename?: 'indices_variance_fields';
  currency_id?: Maybe<Scalars['Float']>;
  fee_id?: Maybe<Scalars['Float']>;
  forward_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ind_type_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?: Maybe<Scalars['inet']>;
  _gt?: Maybe<Scalars['inet']>;
  _gte?: Maybe<Scalars['inet']>;
  _in?: Maybe<Array<Scalars['inet']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['inet']>;
  _lte?: Maybe<Scalars['inet']>;
  _neq?: Maybe<Scalars['inet']>;
  _nin?: Maybe<Array<Scalars['inet']>>;
};

/** columns and relationships of "invitations" */
export type Invitations = {
  __typename?: 'invitations';
  client_data?: Maybe<Scalars['jsonb']>;
  completed: Scalars['Boolean'];
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  group?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  invitations_customers: Array<Invitations_Customers>;
  /** An aggregate relationship */
  invitations_customers_aggregate: Invitations_Customers_Aggregate;
  locked: Scalars['Boolean'];
  metadata?: Maybe<Scalars['jsonb']>;
  token: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "invitations" */
export type InvitationsClient_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "invitations" */
export type InvitationsInvitations_CustomersArgs = {
  distinct_on?: Maybe<Array<Invitations_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Customers_Order_By>>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};


/** columns and relationships of "invitations" */
export type InvitationsInvitations_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitations_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Customers_Order_By>>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};


/** columns and relationships of "invitations" */
export type InvitationsMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "invitations" */
export type Invitations_Aggregate = {
  __typename?: 'invitations_aggregate';
  aggregate?: Maybe<Invitations_Aggregate_Fields>;
  nodes: Array<Invitations>;
};

/** aggregate fields of "invitations" */
export type Invitations_Aggregate_Fields = {
  __typename?: 'invitations_aggregate_fields';
  avg?: Maybe<Invitations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invitations_Max_Fields>;
  min?: Maybe<Invitations_Min_Fields>;
  stddev?: Maybe<Invitations_Stddev_Fields>;
  stddev_pop?: Maybe<Invitations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invitations_Stddev_Samp_Fields>;
  sum?: Maybe<Invitations_Sum_Fields>;
  var_pop?: Maybe<Invitations_Var_Pop_Fields>;
  var_samp?: Maybe<Invitations_Var_Samp_Fields>;
  variance?: Maybe<Invitations_Variance_Fields>;
};


/** aggregate fields of "invitations" */
export type Invitations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invitations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Invitations_Append_Input = {
  client_data?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Invitations_Avg_Fields = {
  __typename?: 'invitations_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "invitations". All fields are combined with a logical 'AND'. */
export type Invitations_Bool_Exp = {
  _and?: Maybe<Array<Invitations_Bool_Exp>>;
  _not?: Maybe<Invitations_Bool_Exp>;
  _or?: Maybe<Array<Invitations_Bool_Exp>>;
  client_data?: Maybe<Jsonb_Comparison_Exp>;
  completed?: Maybe<Boolean_Comparison_Exp>;
  completed_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  group?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invitations_customers?: Maybe<Invitations_Customers_Bool_Exp>;
  invitations_customers_aggregate?: Maybe<Invitations_Customers_Aggregate_Bool_Exp>;
  locked?: Maybe<Boolean_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invitations" */
export enum Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvitationsPkey = 'invitations_pkey',
  /** unique or primary key constraint on columns "token" */
  InvitationsTokenKey = 'invitations_token_key'
}

/** columns and relationships of "invitations_customers" */
export type Invitations_Customers = {
  __typename?: 'invitations_customers';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer: Customers;
  customer_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  invitation: Invitations;
  invitation_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "invitations_customers" */
export type Invitations_Customers_Aggregate = {
  __typename?: 'invitations_customers_aggregate';
  aggregate?: Maybe<Invitations_Customers_Aggregate_Fields>;
  nodes: Array<Invitations_Customers>;
};

export type Invitations_Customers_Aggregate_Bool_Exp = {
  count?: Maybe<Invitations_Customers_Aggregate_Bool_Exp_Count>;
};

export type Invitations_Customers_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Invitations_Customers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invitations_Customers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invitations_customers" */
export type Invitations_Customers_Aggregate_Fields = {
  __typename?: 'invitations_customers_aggregate_fields';
  avg?: Maybe<Invitations_Customers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invitations_Customers_Max_Fields>;
  min?: Maybe<Invitations_Customers_Min_Fields>;
  stddev?: Maybe<Invitations_Customers_Stddev_Fields>;
  stddev_pop?: Maybe<Invitations_Customers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invitations_Customers_Stddev_Samp_Fields>;
  sum?: Maybe<Invitations_Customers_Sum_Fields>;
  var_pop?: Maybe<Invitations_Customers_Var_Pop_Fields>;
  var_samp?: Maybe<Invitations_Customers_Var_Samp_Fields>;
  variance?: Maybe<Invitations_Customers_Variance_Fields>;
};


/** aggregate fields of "invitations_customers" */
export type Invitations_Customers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invitations_Customers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invitations_customers" */
export type Invitations_Customers_Aggregate_Order_By = {
  avg?: Maybe<Invitations_Customers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invitations_Customers_Max_Order_By>;
  min?: Maybe<Invitations_Customers_Min_Order_By>;
  stddev?: Maybe<Invitations_Customers_Stddev_Order_By>;
  stddev_pop?: Maybe<Invitations_Customers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invitations_Customers_Stddev_Samp_Order_By>;
  sum?: Maybe<Invitations_Customers_Sum_Order_By>;
  var_pop?: Maybe<Invitations_Customers_Var_Pop_Order_By>;
  var_samp?: Maybe<Invitations_Customers_Var_Samp_Order_By>;
  variance?: Maybe<Invitations_Customers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invitations_customers" */
export type Invitations_Customers_Arr_Rel_Insert_Input = {
  data: Array<Invitations_Customers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Invitations_Customers_On_Conflict>;
};

/** aggregate avg on columns */
export type Invitations_Customers_Avg_Fields = {
  __typename?: 'invitations_customers_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invitations_customers" */
export type Invitations_Customers_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invitations_customers". All fields are combined with a logical 'AND'. */
export type Invitations_Customers_Bool_Exp = {
  _and?: Maybe<Array<Invitations_Customers_Bool_Exp>>;
  _not?: Maybe<Invitations_Customers_Bool_Exp>;
  _or?: Maybe<Array<Invitations_Customers_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invitation?: Maybe<Invitations_Bool_Exp>;
  invitation_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invitations_customers" */
export enum Invitations_Customers_Constraint {
  /** unique or primary key constraint on columns "invitation_id", "customer_id" */
  InvitationsCustomersCustomerIdInvitationIdKey = 'invitations_customers_customer_id_invitation_id_key',
  /** unique or primary key constraint on columns "id" */
  InvitationsCustomersPkey = 'invitations_customers_pkey'
}

/** input type for incrementing numeric columns in table "invitations_customers" */
export type Invitations_Customers_Inc_Input = {
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invitation_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "invitations_customers" */
export type Invitations_Customers_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invitation?: Maybe<Invitations_Obj_Rel_Insert_Input>;
  invitation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invitations_Customers_Max_Fields = {
  __typename?: 'invitations_customers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invitation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invitations_customers" */
export type Invitations_Customers_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invitations_Customers_Min_Fields = {
  __typename?: 'invitations_customers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invitation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invitations_customers" */
export type Invitations_Customers_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "invitations_customers" */
export type Invitations_Customers_Mutation_Response = {
  __typename?: 'invitations_customers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invitations_Customers>;
};

/** on_conflict condition type for table "invitations_customers" */
export type Invitations_Customers_On_Conflict = {
  constraint: Invitations_Customers_Constraint;
  update_columns?: Array<Invitations_Customers_Update_Column>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "invitations_customers". */
export type Invitations_Customers_Order_By = {
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation?: Maybe<Invitations_Order_By>;
  invitation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: invitations_customers */
export type Invitations_Customers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "invitations_customers" */
export enum Invitations_Customers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvitationId = 'invitation_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invitations_customers" */
export type Invitations_Customers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invitation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Invitations_Customers_Stddev_Fields = {
  __typename?: 'invitations_customers_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invitations_customers" */
export type Invitations_Customers_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invitations_Customers_Stddev_Pop_Fields = {
  __typename?: 'invitations_customers_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invitations_customers" */
export type Invitations_Customers_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invitations_Customers_Stddev_Samp_Fields = {
  __typename?: 'invitations_customers_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invitations_customers" */
export type Invitations_Customers_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "invitations_customers" */
export type Invitations_Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invitations_Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invitations_Customers_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invitation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Invitations_Customers_Sum_Fields = {
  __typename?: 'invitations_customers_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invitation_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invitations_customers" */
export type Invitations_Customers_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** update columns of table "invitations_customers" */
export enum Invitations_Customers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvitationId = 'invitation_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Invitations_Customers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Invitations_Customers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Invitations_Customers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invitations_Customers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invitations_Customers_Var_Pop_Fields = {
  __typename?: 'invitations_customers_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invitations_customers" */
export type Invitations_Customers_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invitations_Customers_Var_Samp_Fields = {
  __typename?: 'invitations_customers_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invitations_customers" */
export type Invitations_Customers_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Invitations_Customers_Variance_Fields = {
  __typename?: 'invitations_customers_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invitations_customers" */
export type Invitations_Customers_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Invitations_Delete_At_Path_Input = {
  client_data?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Invitations_Delete_Elem_Input = {
  client_data?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Invitations_Delete_Key_Input = {
  client_data?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "invitations" */
export type Invitations_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "invitations" */
export type Invitations_Insert_Input = {
  client_data?: Maybe<Scalars['jsonb']>;
  completed?: Maybe<Scalars['Boolean']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invitations_customers?: Maybe<Invitations_Customers_Arr_Rel_Insert_Input>;
  locked?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['jsonb']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invitations_Max_Fields = {
  __typename?: 'invitations_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Invitations_Min_Fields = {
  __typename?: 'invitations_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "invitations" */
export type Invitations_Mutation_Response = {
  __typename?: 'invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invitations>;
};

/** input type for inserting object relation for remote table "invitations" */
export type Invitations_Obj_Rel_Insert_Input = {
  data: Invitations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Invitations_On_Conflict>;
};

/** on_conflict condition type for table "invitations" */
export type Invitations_On_Conflict = {
  constraint: Invitations_Constraint;
  update_columns?: Array<Invitations_Update_Column>;
  where?: Maybe<Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "invitations". */
export type Invitations_Order_By = {
  client_data?: Maybe<Order_By>;
  completed?: Maybe<Order_By>;
  completed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  group?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitations_customers_aggregate?: Maybe<Invitations_Customers_Aggregate_Order_By>;
  locked?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: invitations */
export type Invitations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Invitations_Prepend_Input = {
  client_data?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "invitations" */
export enum Invitations_Select_Column {
  /** column name */
  ClientData = 'client_data',
  /** column name */
  Completed = 'completed',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invitations" */
export type Invitations_Set_Input = {
  client_data?: Maybe<Scalars['jsonb']>;
  completed?: Maybe<Scalars['Boolean']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  locked?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['jsonb']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Invitations_Stddev_Fields = {
  __typename?: 'invitations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Invitations_Stddev_Pop_Fields = {
  __typename?: 'invitations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Invitations_Stddev_Samp_Fields = {
  __typename?: 'invitations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "invitations" */
export type Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invitations_Stream_Cursor_Value_Input = {
  client_data?: Maybe<Scalars['jsonb']>;
  completed?: Maybe<Scalars['Boolean']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  locked?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['jsonb']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Invitations_Sum_Fields = {
  __typename?: 'invitations_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "invitations" */
export enum Invitations_Update_Column {
  /** column name */
  ClientData = 'client_data',
  /** column name */
  Completed = 'completed',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Invitations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Invitations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Invitations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Invitations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Invitations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Invitations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Invitations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Invitations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invitations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invitations_Var_Pop_Fields = {
  __typename?: 'invitations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Invitations_Var_Samp_Fields = {
  __typename?: 'invitations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Invitations_Variance_Fields = {
  __typename?: 'invitations_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "invite_status". All fields are combined with logical 'AND'. */
export type Invite_Status_Comparison_Exp = {
  _eq?: Maybe<Scalars['invite_status']>;
  _gt?: Maybe<Scalars['invite_status']>;
  _gte?: Maybe<Scalars['invite_status']>;
  _in?: Maybe<Array<Scalars['invite_status']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['invite_status']>;
  _lte?: Maybe<Scalars['invite_status']>;
  _neq?: Maybe<Scalars['invite_status']>;
  _nin?: Maybe<Array<Scalars['invite_status']>>;
};

/** columns and relationships of "invoice_aggregate" */
export type Invoice_Aggregate = {
  __typename?: 'invoice_aggregate';
  accounting_month?: Maybe<Scalars['date']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['numeric']>;
  ext?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  invoice_data?: Maybe<Invoice_Data>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
  /** An object relationship */
  invoice_number_link?: Maybe<Invoice_Numbers>;
};

/** aggregated selection of "invoice_aggregate" */
export type Invoice_Aggregate_Aggregate = {
  __typename?: 'invoice_aggregate_aggregate';
  aggregate?: Maybe<Invoice_Aggregate_Aggregate_Fields>;
  nodes: Array<Invoice_Aggregate>;
};

export type Invoice_Aggregate_Aggregate_Bool_Exp = {
  count?: Maybe<Invoice_Aggregate_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Aggregate_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Invoice_Aggregate_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Aggregate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoice_aggregate" */
export type Invoice_Aggregate_Aggregate_Fields = {
  __typename?: 'invoice_aggregate_aggregate_fields';
  avg?: Maybe<Invoice_Aggregate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Aggregate_Max_Fields>;
  min?: Maybe<Invoice_Aggregate_Min_Fields>;
  stddev?: Maybe<Invoice_Aggregate_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Aggregate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Aggregate_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Aggregate_Sum_Fields>;
  var_pop?: Maybe<Invoice_Aggregate_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Aggregate_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Aggregate_Variance_Fields>;
};


/** aggregate fields of "invoice_aggregate" */
export type Invoice_Aggregate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_Aggregate_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice_aggregate" */
export type Invoice_Aggregate_Aggregate_Order_By = {
  avg?: Maybe<Invoice_Aggregate_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_Aggregate_Max_Order_By>;
  min?: Maybe<Invoice_Aggregate_Min_Order_By>;
  stddev?: Maybe<Invoice_Aggregate_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoice_Aggregate_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoice_Aggregate_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoice_Aggregate_Sum_Order_By>;
  var_pop?: Maybe<Invoice_Aggregate_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoice_Aggregate_Var_Samp_Order_By>;
  variance?: Maybe<Invoice_Aggregate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_aggregate" */
export type Invoice_Aggregate_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Aggregate_Insert_Input>;
};

/** aggregate avg on columns */
export type Invoice_Aggregate_Avg_Fields = {
  __typename?: 'invoice_aggregate_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_aggregate". All fields are combined with a logical 'AND'. */
export type Invoice_Aggregate_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Aggregate_Bool_Exp>>;
  _not?: Maybe<Invoice_Aggregate_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Aggregate_Bool_Exp>>;
  accounting_month?: Maybe<Date_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  dth?: Maybe<Numeric_Comparison_Exp>;
  ext?: Maybe<Numeric_Comparison_Exp>;
  invoice_data?: Maybe<Invoice_Data_Bool_Exp>;
  invoice_date?: Maybe<Date_Comparison_Exp>;
  invoice_number?: Maybe<Int_Comparison_Exp>;
  invoice_number_link?: Maybe<Invoice_Numbers_Bool_Exp>;
};

/** input type for inserting data into table "invoice_aggregate" */
export type Invoice_Aggregate_Insert_Input = {
  accounting_month?: Maybe<Scalars['date']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['numeric']>;
  ext?: Maybe<Scalars['numeric']>;
  invoice_data?: Maybe<Invoice_Data_Obj_Rel_Insert_Input>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
  invoice_number_link?: Maybe<Invoice_Numbers_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Invoice_Aggregate_Max_Fields = {
  __typename?: 'invoice_aggregate_max_fields';
  accounting_month?: Maybe<Scalars['date']>;
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['numeric']>;
  ext?: Maybe<Scalars['numeric']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Max_Order_By = {
  accounting_month?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Aggregate_Min_Fields = {
  __typename?: 'invoice_aggregate_min_fields';
  accounting_month?: Maybe<Scalars['date']>;
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['numeric']>;
  ext?: Maybe<Scalars['numeric']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Min_Order_By = {
  accounting_month?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** input type for inserting object relation for remote table "invoice_aggregate" */
export type Invoice_Aggregate_Obj_Rel_Insert_Input = {
  data: Invoice_Aggregate_Insert_Input;
};

/** Ordering options when selecting data from "invoice_aggregate". */
export type Invoice_Aggregate_Order_By = {
  accounting_month?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_data?: Maybe<Invoice_Data_Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  invoice_number_link?: Maybe<Invoice_Numbers_Order_By>;
};

/** select columns of table "invoice_aggregate" */
export enum Invoice_Aggregate_Select_Column {
  /** column name */
  AccountingMonth = 'accounting_month',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceNumber = 'invoice_number'
}

/** aggregate stddev on columns */
export type Invoice_Aggregate_Stddev_Fields = {
  __typename?: 'invoice_aggregate_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Aggregate_Stddev_Pop_Fields = {
  __typename?: 'invoice_aggregate_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Aggregate_Stddev_Samp_Fields = {
  __typename?: 'invoice_aggregate_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** Streaming cursor of the table "invoice_aggregate" */
export type Invoice_Aggregate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Aggregate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Aggregate_Stream_Cursor_Value_Input = {
  accounting_month?: Maybe<Scalars['date']>;
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['numeric']>;
  ext?: Maybe<Scalars['numeric']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Invoice_Aggregate_Sum_Fields = {
  __typename?: 'invoice_aggregate_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['numeric']>;
  ext?: Maybe<Scalars['numeric']>;
  invoice_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Invoice_Aggregate_Var_Pop_Fields = {
  __typename?: 'invoice_aggregate_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Aggregate_Var_Samp_Fields = {
  __typename?: 'invoice_aggregate_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Aggregate_Variance_Fields = {
  __typename?: 'invoice_aggregate_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice_aggregate" */
export type Invoice_Aggregate_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** columns and relationships of "invoice_data" */
export type Invoice_Data = {
  __typename?: 'invoice_data';
  accounting_month?: Maybe<Scalars['date']>;
  acct_code?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  dd_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  deal?: Maybe<Deals>;
  /** An object relationship */
  deal_detail?: Maybe<Deal_Details>;
  deal_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  ext?: Maybe<Scalars['float8']>;
  fuel?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  inv_row?: Maybe<Scalars['Int']>;
  /** An object relationship */
  invoice_aggregate?: Maybe<Invoice_Aggregate>;
  invoice_data_header_id?: Maybe<Scalars['bigint']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
  ldc?: Maybe<Scalars['String']>;
  linetype?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['Boolean']>;
  pipeline?: Maybe<Scalars['String']>;
  pmt_due?: Maybe<Scalars['date']>;
  posted?: Maybe<Scalars['Boolean']>;
  premise_number?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  prod_month?: Maybe<Scalars['date']>;
  read_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  service_address?: Maybe<Addresses>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_billed?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "invoice_data" */
export type Invoice_Data_Aggregate = {
  __typename?: 'invoice_data_aggregate';
  aggregate?: Maybe<Invoice_Data_Aggregate_Fields>;
  nodes: Array<Invoice_Data>;
};

export type Invoice_Data_Aggregate_Bool_Exp = {
  avg?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Invoice_Data_Aggregate_Bool_Exp_Var_Samp>;
};

export type Invoice_Data_Aggregate_Bool_Exp_Avg = {
  arguments: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Corr = {
  arguments: Invoice_Data_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Invoice_Data_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Invoice_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Invoice_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Invoice_Data_Aggregate_Bool_Exp_Max = {
  arguments: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Min = {
  arguments: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Sum = {
  arguments: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoice_data" */
export type Invoice_Data_Aggregate_Fields = {
  __typename?: 'invoice_data_aggregate_fields';
  avg?: Maybe<Invoice_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Data_Max_Fields>;
  min?: Maybe<Invoice_Data_Min_Fields>;
  stddev?: Maybe<Invoice_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Data_Sum_Fields>;
  var_pop?: Maybe<Invoice_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Data_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Data_Variance_Fields>;
};


/** aggregate fields of "invoice_data" */
export type Invoice_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice_data" */
export type Invoice_Data_Aggregate_Order_By = {
  avg?: Maybe<Invoice_Data_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_Data_Max_Order_By>;
  min?: Maybe<Invoice_Data_Min_Order_By>;
  stddev?: Maybe<Invoice_Data_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoice_Data_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoice_Data_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoice_Data_Sum_Order_By>;
  var_pop?: Maybe<Invoice_Data_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoice_Data_Var_Samp_Order_By>;
  variance?: Maybe<Invoice_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_data" */
export type Invoice_Data_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Data_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Invoice_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Data_Avg_Fields = {
  __typename?: 'invoice_data_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dd_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inv_row?: Maybe<Scalars['Float']>;
  invoice_data_header_id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice_data" */
export type Invoice_Data_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_data". All fields are combined with a logical 'AND'. */
export type Invoice_Data_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Data_Bool_Exp>>;
  _not?: Maybe<Invoice_Data_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Data_Bool_Exp>>;
  accounting_month?: Maybe<Date_Comparison_Exp>;
  acct_code?: Maybe<String_Comparison_Exp>;
  archived?: Maybe<Boolean_Comparison_Exp>;
  beg_date?: Maybe<Date_Comparison_Exp>;
  begin_read_date?: Maybe<Date_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  customer_name?: Maybe<String_Comparison_Exp>;
  dd_id?: Maybe<Int_Comparison_Exp>;
  deal?: Maybe<Deals_Bool_Exp>;
  deal_detail?: Maybe<Deal_Details_Bool_Exp>;
  deal_id?: Maybe<Int_Comparison_Exp>;
  dth?: Maybe<Float8_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  ext?: Maybe<Float8_Comparison_Exp>;
  fuel?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inv_row?: Maybe<Int_Comparison_Exp>;
  invoice_aggregate?: Maybe<Invoice_Aggregate_Bool_Exp>;
  invoice_data_header_id?: Maybe<Bigint_Comparison_Exp>;
  invoice_date?: Maybe<Date_Comparison_Exp>;
  invoice_number?: Maybe<Int_Comparison_Exp>;
  ldc?: Maybe<String_Comparison_Exp>;
  linetype?: Maybe<String_Comparison_Exp>;
  market?: Maybe<Boolean_Comparison_Exp>;
  pipeline?: Maybe<String_Comparison_Exp>;
  pmt_due?: Maybe<Date_Comparison_Exp>;
  posted?: Maybe<Boolean_Comparison_Exp>;
  premise_number?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  prod_month?: Maybe<Date_Comparison_Exp>;
  read_date?: Maybe<Date_Comparison_Exp>;
  service_address?: Maybe<Addresses_Bool_Exp>;
  service_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  utility_billed?: Maybe<Boolean_Comparison_Exp>;
  version?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_data" */
export enum Invoice_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceDataPkey = 'invoice_data_pkey'
}

/** input type for incrementing numeric columns in table "invoice_data" */
export type Invoice_Data_Inc_Input = {
  customer_id?: Maybe<Scalars['Int']>;
  dd_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  ext?: Maybe<Scalars['float8']>;
  fuel?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  inv_row?: Maybe<Scalars['Int']>;
  invoice_data_header_id?: Maybe<Scalars['bigint']>;
  invoice_number?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  service_id?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoice_data" */
export type Invoice_Data_Insert_Input = {
  accounting_month?: Maybe<Scalars['date']>;
  acct_code?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  dd_id?: Maybe<Scalars['Int']>;
  deal?: Maybe<Deals_Obj_Rel_Insert_Input>;
  deal_detail?: Maybe<Deal_Details_Obj_Rel_Insert_Input>;
  deal_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  ext?: Maybe<Scalars['float8']>;
  fuel?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  inv_row?: Maybe<Scalars['Int']>;
  invoice_aggregate?: Maybe<Invoice_Aggregate_Obj_Rel_Insert_Input>;
  invoice_data_header_id?: Maybe<Scalars['bigint']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
  ldc?: Maybe<Scalars['String']>;
  linetype?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['Boolean']>;
  pipeline?: Maybe<Scalars['String']>;
  pmt_due?: Maybe<Scalars['date']>;
  posted?: Maybe<Scalars['Boolean']>;
  premise_number?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  prod_month?: Maybe<Scalars['date']>;
  read_date?: Maybe<Scalars['date']>;
  service_address?: Maybe<Addresses_Obj_Rel_Insert_Input>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_billed?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Invoice_Data_Max_Fields = {
  __typename?: 'invoice_data_max_fields';
  accounting_month?: Maybe<Scalars['date']>;
  acct_code?: Maybe<Scalars['String']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  dd_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  ext?: Maybe<Scalars['float8']>;
  fuel?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  inv_row?: Maybe<Scalars['Int']>;
  invoice_data_header_id?: Maybe<Scalars['bigint']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
  ldc?: Maybe<Scalars['String']>;
  linetype?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Scalars['String']>;
  pmt_due?: Maybe<Scalars['date']>;
  premise_number?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  prod_month?: Maybe<Scalars['date']>;
  read_date?: Maybe<Scalars['date']>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "invoice_data" */
export type Invoice_Data_Max_Order_By = {
  accounting_month?: Maybe<Order_By>;
  acct_code?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  begin_read_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_name?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  ldc?: Maybe<Order_By>;
  linetype?: Maybe<Order_By>;
  pipeline?: Maybe<Order_By>;
  pmt_due?: Maybe<Order_By>;
  premise_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  prod_month?: Maybe<Order_By>;
  read_date?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Data_Min_Fields = {
  __typename?: 'invoice_data_min_fields';
  accounting_month?: Maybe<Scalars['date']>;
  acct_code?: Maybe<Scalars['String']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  dd_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  ext?: Maybe<Scalars['float8']>;
  fuel?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  inv_row?: Maybe<Scalars['Int']>;
  invoice_data_header_id?: Maybe<Scalars['bigint']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
  ldc?: Maybe<Scalars['String']>;
  linetype?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Scalars['String']>;
  pmt_due?: Maybe<Scalars['date']>;
  premise_number?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  prod_month?: Maybe<Scalars['date']>;
  read_date?: Maybe<Scalars['date']>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "invoice_data" */
export type Invoice_Data_Min_Order_By = {
  accounting_month?: Maybe<Order_By>;
  acct_code?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  begin_read_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_name?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  ldc?: Maybe<Order_By>;
  linetype?: Maybe<Order_By>;
  pipeline?: Maybe<Order_By>;
  pmt_due?: Maybe<Order_By>;
  premise_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  prod_month?: Maybe<Order_By>;
  read_date?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** response of any mutation on the table "invoice_data" */
export type Invoice_Data_Mutation_Response = {
  __typename?: 'invoice_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Data>;
};

/** input type for inserting object relation for remote table "invoice_data" */
export type Invoice_Data_Obj_Rel_Insert_Input = {
  data: Invoice_Data_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Invoice_Data_On_Conflict>;
};

/** on_conflict condition type for table "invoice_data" */
export type Invoice_Data_On_Conflict = {
  constraint: Invoice_Data_Constraint;
  update_columns?: Array<Invoice_Data_Update_Column>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_data". */
export type Invoice_Data_Order_By = {
  accounting_month?: Maybe<Order_By>;
  acct_code?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  beg_date?: Maybe<Order_By>;
  begin_read_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_name?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal?: Maybe<Deals_Order_By>;
  deal_detail?: Maybe<Deal_Details_Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_aggregate?: Maybe<Invoice_Aggregate_Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  ldc?: Maybe<Order_By>;
  linetype?: Maybe<Order_By>;
  market?: Maybe<Order_By>;
  pipeline?: Maybe<Order_By>;
  pmt_due?: Maybe<Order_By>;
  posted?: Maybe<Order_By>;
  premise_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  prod_month?: Maybe<Order_By>;
  read_date?: Maybe<Order_By>;
  service_address?: Maybe<Addresses_Order_By>;
  service_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_billed?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: invoice_data */
export type Invoice_Data_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "invoice_data" */
export enum Invoice_Data_Select_Column {
  /** column name */
  AccountingMonth = 'accounting_month',
  /** column name */
  AcctCode = 'acct_code',
  /** column name */
  Archived = 'archived',
  /** column name */
  BegDate = 'beg_date',
  /** column name */
  BeginReadDate = 'begin_read_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  DdId = 'dd_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  Dth = 'dth',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Id = 'id',
  /** column name */
  InvRow = 'inv_row',
  /** column name */
  InvoiceDataHeaderId = 'invoice_data_header_id',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  Ldc = 'ldc',
  /** column name */
  Linetype = 'linetype',
  /** column name */
  Market = 'market',
  /** column name */
  Pipeline = 'pipeline',
  /** column name */
  PmtDue = 'pmt_due',
  /** column name */
  Posted = 'posted',
  /** column name */
  PremiseNumber = 'premise_number',
  /** column name */
  Price = 'price',
  /** column name */
  ProdMonth = 'prod_month',
  /** column name */
  ReadDate = 'read_date',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityBilled = 'utility_billed',
  /** column name */
  Version = 'version'
}

/** select "invoice_data_aggregate_bool_exp_avg_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Price = 'price'
}

/** select "invoice_data_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Market = 'market',
  /** column name */
  Posted = 'posted',
  /** column name */
  UtilityBilled = 'utility_billed'
}

/** select "invoice_data_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Market = 'market',
  /** column name */
  Posted = 'posted',
  /** column name */
  UtilityBilled = 'utility_billed'
}

/** select "invoice_data_aggregate_bool_exp_corr_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Price = 'price'
}

/** select "invoice_data_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Price = 'price'
}

/** select "invoice_data_aggregate_bool_exp_max_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Price = 'price'
}

/** select "invoice_data_aggregate_bool_exp_min_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Price = 'price'
}

/** select "invoice_data_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Price = 'price'
}

/** select "invoice_data_aggregate_bool_exp_sum_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Price = 'price'
}

/** select "invoice_data_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoice_data" */
export enum Invoice_Data_Select_Column_Invoice_Data_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "invoice_data" */
export type Invoice_Data_Set_Input = {
  accounting_month?: Maybe<Scalars['date']>;
  acct_code?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  dd_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  ext?: Maybe<Scalars['float8']>;
  fuel?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  inv_row?: Maybe<Scalars['Int']>;
  invoice_data_header_id?: Maybe<Scalars['bigint']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
  ldc?: Maybe<Scalars['String']>;
  linetype?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['Boolean']>;
  pipeline?: Maybe<Scalars['String']>;
  pmt_due?: Maybe<Scalars['date']>;
  posted?: Maybe<Scalars['Boolean']>;
  premise_number?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  prod_month?: Maybe<Scalars['date']>;
  read_date?: Maybe<Scalars['date']>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_billed?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Invoice_Data_Stddev_Fields = {
  __typename?: 'invoice_data_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dd_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inv_row?: Maybe<Scalars['Float']>;
  invoice_data_header_id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice_data" */
export type Invoice_Data_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Data_Stddev_Pop_Fields = {
  __typename?: 'invoice_data_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dd_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inv_row?: Maybe<Scalars['Float']>;
  invoice_data_header_id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice_data" */
export type Invoice_Data_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Data_Stddev_Samp_Fields = {
  __typename?: 'invoice_data_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dd_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inv_row?: Maybe<Scalars['Float']>;
  invoice_data_header_id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice_data" */
export type Invoice_Data_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** Streaming cursor of the table "invoice_data" */
export type Invoice_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Data_Stream_Cursor_Value_Input = {
  accounting_month?: Maybe<Scalars['date']>;
  acct_code?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  beg_date?: Maybe<Scalars['date']>;
  begin_read_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  dd_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  ext?: Maybe<Scalars['float8']>;
  fuel?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  inv_row?: Maybe<Scalars['Int']>;
  invoice_data_header_id?: Maybe<Scalars['bigint']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['Int']>;
  ldc?: Maybe<Scalars['String']>;
  linetype?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['Boolean']>;
  pipeline?: Maybe<Scalars['String']>;
  pmt_due?: Maybe<Scalars['date']>;
  posted?: Maybe<Scalars['Boolean']>;
  premise_number?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  prod_month?: Maybe<Scalars['date']>;
  read_date?: Maybe<Scalars['date']>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_billed?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month = {
  __typename?: 'invoice_data_sum_by_month';
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  ext?: Maybe<Scalars['float8']>;
  month?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Aggregate = {
  __typename?: 'invoice_data_sum_by_month_aggregate';
  aggregate?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Fields>;
  nodes: Array<Invoice_Data_Sum_By_Month>;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp = {
  avg?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Avg>;
  corr?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Var_Samp>;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Avg = {
  arguments: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Corr = {
  arguments: Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Invoice_Data_Sum_By_Month_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Max = {
  arguments: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Min = {
  arguments: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Sum = {
  arguments: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Aggregate_Fields = {
  __typename?: 'invoice_data_sum_by_month_aggregate_fields';
  avg?: Maybe<Invoice_Data_Sum_By_Month_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Data_Sum_By_Month_Max_Fields>;
  min?: Maybe<Invoice_Data_Sum_By_Month_Min_Fields>;
  stddev?: Maybe<Invoice_Data_Sum_By_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Data_Sum_By_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Data_Sum_By_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Data_Sum_By_Month_Sum_Fields>;
  var_pop?: Maybe<Invoice_Data_Sum_By_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Data_Sum_By_Month_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Data_Sum_By_Month_Variance_Fields>;
};


/** aggregate fields of "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_Data_Sum_By_Month_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Aggregate_Order_By = {
  avg?: Maybe<Invoice_Data_Sum_By_Month_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_Data_Sum_By_Month_Max_Order_By>;
  min?: Maybe<Invoice_Data_Sum_By_Month_Min_Order_By>;
  stddev?: Maybe<Invoice_Data_Sum_By_Month_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoice_Data_Sum_By_Month_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoice_Data_Sum_By_Month_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoice_Data_Sum_By_Month_Sum_Order_By>;
  var_pop?: Maybe<Invoice_Data_Sum_By_Month_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoice_Data_Sum_By_Month_Var_Samp_Order_By>;
  variance?: Maybe<Invoice_Data_Sum_By_Month_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Data_Sum_By_Month_Insert_Input>;
};

/** aggregate avg on columns */
export type Invoice_Data_Sum_By_Month_Avg_Fields = {
  __typename?: 'invoice_data_sum_by_month_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_data_sum_by_month". All fields are combined with a logical 'AND'. */
export type Invoice_Data_Sum_By_Month_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Data_Sum_By_Month_Bool_Exp>>;
  _not?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Data_Sum_By_Month_Bool_Exp>>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  dth?: Maybe<Float8_Comparison_Exp>;
  ext?: Maybe<Float8_Comparison_Exp>;
  month?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Insert_Input = {
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  ext?: Maybe<Scalars['float8']>;
  month?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invoice_Data_Sum_By_Month_Max_Fields = {
  __typename?: 'invoice_data_sum_by_month_max_fields';
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  ext?: Maybe<Scalars['float8']>;
  month?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Max_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Data_Sum_By_Month_Min_Fields = {
  __typename?: 'invoice_data_sum_by_month_min_fields';
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  ext?: Maybe<Scalars['float8']>;
  month?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Min_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "invoice_data_sum_by_month". */
export type Invoice_Data_Sum_By_Month_Order_By = {
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
};

/** select columns of table "invoice_data_sum_by_month" */
export enum Invoice_Data_Sum_By_Month_Select_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext',
  /** column name */
  Month = 'month'
}

/** select "invoice_data_sum_by_month_aggregate_bool_exp_avg_arguments_columns" columns of table "invoice_data_sum_by_month" */
export enum Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext'
}

/** select "invoice_data_sum_by_month_aggregate_bool_exp_corr_arguments_columns" columns of table "invoice_data_sum_by_month" */
export enum Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext'
}

/** select "invoice_data_sum_by_month_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoice_data_sum_by_month" */
export enum Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext'
}

/** select "invoice_data_sum_by_month_aggregate_bool_exp_max_arguments_columns" columns of table "invoice_data_sum_by_month" */
export enum Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext'
}

/** select "invoice_data_sum_by_month_aggregate_bool_exp_min_arguments_columns" columns of table "invoice_data_sum_by_month" */
export enum Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext'
}

/** select "invoice_data_sum_by_month_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoice_data_sum_by_month" */
export enum Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext'
}

/** select "invoice_data_sum_by_month_aggregate_bool_exp_sum_arguments_columns" columns of table "invoice_data_sum_by_month" */
export enum Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext'
}

/** select "invoice_data_sum_by_month_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoice_data_sum_by_month" */
export enum Invoice_Data_Sum_By_Month_Select_Column_Invoice_Data_Sum_By_Month_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Dth = 'dth',
  /** column name */
  Ext = 'ext'
}

/** aggregate stddev on columns */
export type Invoice_Data_Sum_By_Month_Stddev_Fields = {
  __typename?: 'invoice_data_sum_by_month_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Data_Sum_By_Month_Stddev_Pop_Fields = {
  __typename?: 'invoice_data_sum_by_month_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Data_Sum_By_Month_Stddev_Samp_Fields = {
  __typename?: 'invoice_data_sum_by_month_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
};

/** Streaming cursor of the table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Data_Sum_By_Month_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Data_Sum_By_Month_Stream_Cursor_Value_Input = {
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  ext?: Maybe<Scalars['float8']>;
  month?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Invoice_Data_Sum_By_Month_Sum_Fields = {
  __typename?: 'invoice_data_sum_by_month_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  ext?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Invoice_Data_Sum_By_Month_Var_Pop_Fields = {
  __typename?: 'invoice_data_sum_by_month_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Data_Sum_By_Month_Var_Samp_Fields = {
  __typename?: 'invoice_data_sum_by_month_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Data_Sum_By_Month_Variance_Fields = {
  __typename?: 'invoice_data_sum_by_month_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice_data_sum_by_month" */
export type Invoice_Data_Sum_By_Month_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Invoice_Data_Sum_Fields = {
  __typename?: 'invoice_data_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  dd_id?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['float8']>;
  ext?: Maybe<Scalars['float8']>;
  fuel?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  inv_row?: Maybe<Scalars['Int']>;
  invoice_data_header_id?: Maybe<Scalars['bigint']>;
  invoice_number?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  service_id?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoice_data" */
export type Invoice_Data_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** update columns of table "invoice_data" */
export enum Invoice_Data_Update_Column {
  /** column name */
  AccountingMonth = 'accounting_month',
  /** column name */
  AcctCode = 'acct_code',
  /** column name */
  Archived = 'archived',
  /** column name */
  BegDate = 'beg_date',
  /** column name */
  BeginReadDate = 'begin_read_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  DdId = 'dd_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  Dth = 'dth',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Ext = 'ext',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  Id = 'id',
  /** column name */
  InvRow = 'inv_row',
  /** column name */
  InvoiceDataHeaderId = 'invoice_data_header_id',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  Ldc = 'ldc',
  /** column name */
  Linetype = 'linetype',
  /** column name */
  Market = 'market',
  /** column name */
  Pipeline = 'pipeline',
  /** column name */
  PmtDue = 'pmt_due',
  /** column name */
  Posted = 'posted',
  /** column name */
  PremiseNumber = 'premise_number',
  /** column name */
  Price = 'price',
  /** column name */
  ProdMonth = 'prod_month',
  /** column name */
  ReadDate = 'read_date',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityBilled = 'utility_billed',
  /** column name */
  Version = 'version'
}

export type Invoice_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Invoice_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Invoice_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Data_Var_Pop_Fields = {
  __typename?: 'invoice_data_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dd_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inv_row?: Maybe<Scalars['Float']>;
  invoice_data_header_id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice_data" */
export type Invoice_Data_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Data_Var_Samp_Fields = {
  __typename?: 'invoice_data_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dd_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inv_row?: Maybe<Scalars['Float']>;
  invoice_data_header_id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice_data" */
export type Invoice_Data_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Data_Variance_Fields = {
  __typename?: 'invoice_data_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  dd_id?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inv_row?: Maybe<Scalars['Float']>;
  invoice_data_header_id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice_data" */
export type Invoice_Data_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  dd_id?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  ext?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inv_row?: Maybe<Order_By>;
  invoice_data_header_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** columns and relationships of "invoice_numbers" */
export type Invoice_Numbers = {
  __typename?: 'invoice_numbers';
  accounting_mth?: Maybe<Scalars['date']>;
  created_at: Scalars['timestamp'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  cycle_month?: Maybe<Scalars['date']>;
  due_date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  /** An object relationship */
  invoice_aggregate?: Maybe<Invoice_Aggregate>;
  /** An array relationship */
  invoice_data: Array<Invoice_Data>;
  /** An aggregate relationship */
  invoice_data_aggregate: Invoice_Data_Aggregate;
  invoice_file_url?: Maybe<Scalars['String']>;
  invoice_number?: Maybe<Scalars['Int']>;
  late_notice?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  posted?: Maybe<Scalars['Boolean']>;
  second_notice?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "invoice_numbers" */
export type Invoice_NumbersInvoice_DataArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


/** columns and relationships of "invoice_numbers" */
export type Invoice_NumbersInvoice_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};

/** aggregated selection of "invoice_numbers" */
export type Invoice_Numbers_Aggregate = {
  __typename?: 'invoice_numbers_aggregate';
  aggregate?: Maybe<Invoice_Numbers_Aggregate_Fields>;
  nodes: Array<Invoice_Numbers>;
};

export type Invoice_Numbers_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Invoice_Numbers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Invoice_Numbers_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Invoice_Numbers_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Numbers_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invoice_Numbers_Select_Column_Invoice_Numbers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Numbers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Numbers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invoice_Numbers_Select_Column_Invoice_Numbers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Numbers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Numbers_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Invoice_Numbers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Invoice_Numbers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoice_numbers" */
export type Invoice_Numbers_Aggregate_Fields = {
  __typename?: 'invoice_numbers_aggregate_fields';
  avg?: Maybe<Invoice_Numbers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Numbers_Max_Fields>;
  min?: Maybe<Invoice_Numbers_Min_Fields>;
  stddev?: Maybe<Invoice_Numbers_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Numbers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Numbers_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Numbers_Sum_Fields>;
  var_pop?: Maybe<Invoice_Numbers_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Numbers_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Numbers_Variance_Fields>;
};


/** aggregate fields of "invoice_numbers" */
export type Invoice_Numbers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoice_Numbers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice_numbers" */
export type Invoice_Numbers_Aggregate_Order_By = {
  avg?: Maybe<Invoice_Numbers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoice_Numbers_Max_Order_By>;
  min?: Maybe<Invoice_Numbers_Min_Order_By>;
  stddev?: Maybe<Invoice_Numbers_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoice_Numbers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoice_Numbers_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoice_Numbers_Sum_Order_By>;
  var_pop?: Maybe<Invoice_Numbers_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoice_Numbers_Var_Samp_Order_By>;
  variance?: Maybe<Invoice_Numbers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_numbers" */
export type Invoice_Numbers_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Numbers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Invoice_Numbers_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Numbers_Avg_Fields = {
  __typename?: 'invoice_numbers_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_numbers". All fields are combined with a logical 'AND'. */
export type Invoice_Numbers_Bool_Exp = {
  _and?: Maybe<Array<Invoice_Numbers_Bool_Exp>>;
  _not?: Maybe<Invoice_Numbers_Bool_Exp>;
  _or?: Maybe<Array<Invoice_Numbers_Bool_Exp>>;
  accounting_mth?: Maybe<Date_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  cycle_month?: Maybe<Date_Comparison_Exp>;
  due_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_aggregate?: Maybe<Invoice_Aggregate_Bool_Exp>;
  invoice_data?: Maybe<Invoice_Data_Bool_Exp>;
  invoice_data_aggregate?: Maybe<Invoice_Data_Aggregate_Bool_Exp>;
  invoice_number?: Maybe<Int_Comparison_Exp>;
  late_notice?: Maybe<Boolean_Comparison_Exp>;
  link?: Maybe<String_Comparison_Exp>;
  posted?: Maybe<Boolean_Comparison_Exp>;
  second_notice?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_numbers" */
export enum Invoice_Numbers_Constraint {
  /** unique or primary key constraint on columns "accounting_mth", "customer_id" */
  IndexInvoiceNumbersByInvoicenumber = 'index_invoice_numbers_by_invoicenumber',
  /** unique or primary key constraint on columns "id" */
  InvoiceNumbersPkey = 'invoice_numbers_pkey'
}

/** input type for incrementing numeric columns in table "invoice_numbers" */
export type Invoice_Numbers_Inc_Input = {
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoice_numbers" */
export type Invoice_Numbers_Insert_Input = {
  accounting_mth?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  cycle_month?: Maybe<Scalars['date']>;
  due_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_aggregate?: Maybe<Invoice_Aggregate_Obj_Rel_Insert_Input>;
  invoice_data?: Maybe<Invoice_Data_Arr_Rel_Insert_Input>;
  invoice_number?: Maybe<Scalars['Int']>;
  late_notice?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  posted?: Maybe<Scalars['Boolean']>;
  second_notice?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Invoice_Numbers_Max_Fields = {
  __typename?: 'invoice_numbers_max_fields';
  accounting_mth?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  cycle_month?: Maybe<Scalars['date']>;
  due_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Max_Order_By = {
  accounting_mth?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  cycle_month?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Numbers_Min_Fields = {
  __typename?: 'invoice_numbers_min_fields';
  accounting_mth?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  cycle_month?: Maybe<Scalars['date']>;
  due_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Min_Order_By = {
  accounting_mth?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  cycle_month?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "invoice_numbers" */
export type Invoice_Numbers_Mutation_Response = {
  __typename?: 'invoice_numbers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Numbers>;
};

/** input type for inserting object relation for remote table "invoice_numbers" */
export type Invoice_Numbers_Obj_Rel_Insert_Input = {
  data: Invoice_Numbers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Invoice_Numbers_On_Conflict>;
};

/** on_conflict condition type for table "invoice_numbers" */
export type Invoice_Numbers_On_Conflict = {
  constraint: Invoice_Numbers_Constraint;
  update_columns?: Array<Invoice_Numbers_Update_Column>;
  where?: Maybe<Invoice_Numbers_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_numbers". */
export type Invoice_Numbers_Order_By = {
  accounting_mth?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  cycle_month?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_aggregate?: Maybe<Invoice_Aggregate_Order_By>;
  invoice_data_aggregate?: Maybe<Invoice_Data_Aggregate_Order_By>;
  invoice_number?: Maybe<Order_By>;
  late_notice?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  posted?: Maybe<Order_By>;
  second_notice?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: invoice_numbers */
export type Invoice_Numbers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "invoice_numbers" */
export enum Invoice_Numbers_Select_Column {
  /** column name */
  AccountingMth = 'accounting_mth',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CycleMonth = 'cycle_month',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  LateNotice = 'late_notice',
  /** column name */
  Link = 'link',
  /** column name */
  Posted = 'posted',
  /** column name */
  SecondNotice = 'second_notice',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "invoice_numbers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoice_numbers" */
export enum Invoice_Numbers_Select_Column_Invoice_Numbers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  LateNotice = 'late_notice',
  /** column name */
  Posted = 'posted',
  /** column name */
  SecondNotice = 'second_notice'
}

/** select "invoice_numbers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoice_numbers" */
export enum Invoice_Numbers_Select_Column_Invoice_Numbers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  LateNotice = 'late_notice',
  /** column name */
  Posted = 'posted',
  /** column name */
  SecondNotice = 'second_notice'
}

/** input type for updating data in table "invoice_numbers" */
export type Invoice_Numbers_Set_Input = {
  accounting_mth?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  cycle_month?: Maybe<Scalars['date']>;
  due_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  late_notice?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  posted?: Maybe<Scalars['Boolean']>;
  second_notice?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Invoice_Numbers_Stddev_Fields = {
  __typename?: 'invoice_numbers_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Numbers_Stddev_Pop_Fields = {
  __typename?: 'invoice_numbers_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Numbers_Stddev_Samp_Fields = {
  __typename?: 'invoice_numbers_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** Streaming cursor of the table "invoice_numbers" */
export type Invoice_Numbers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Numbers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Numbers_Stream_Cursor_Value_Input = {
  accounting_mth?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  cycle_month?: Maybe<Scalars['date']>;
  due_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
  late_notice?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  posted?: Maybe<Scalars['Boolean']>;
  second_notice?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Invoice_Numbers_Sum_Fields = {
  __typename?: 'invoice_numbers_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** update columns of table "invoice_numbers" */
export enum Invoice_Numbers_Update_Column {
  /** column name */
  AccountingMth = 'accounting_mth',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CycleMonth = 'cycle_month',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  LateNotice = 'late_notice',
  /** column name */
  Link = 'link',
  /** column name */
  Posted = 'posted',
  /** column name */
  SecondNotice = 'second_notice',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Invoice_Numbers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Invoice_Numbers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Invoice_Numbers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Numbers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Numbers_Var_Pop_Fields = {
  __typename?: 'invoice_numbers_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Numbers_Var_Samp_Fields = {
  __typename?: 'invoice_numbers_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Numbers_Variance_Fields = {
  __typename?: 'invoice_numbers_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice_numbers" */
export type Invoice_Numbers_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "ldc_accounts_view" */
export type Ldc_Accounts_View = {
  __typename?: 'ldc_accounts_view';
  account_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  /** An array relationship */
  ldc_invoices_view: Array<Ldc_Invoices_View>;
  /** An aggregate relationship */
  ldc_invoices_view_aggregate: Ldc_Invoices_View_Aggregate;
  ldc_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "ldc_accounts_view" */
export type Ldc_Accounts_ViewLdc_Invoices_ViewArgs = {
  distinct_on?: Maybe<Array<Ldc_Invoices_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Invoices_View_Order_By>>;
  where?: Maybe<Ldc_Invoices_View_Bool_Exp>;
};


/** columns and relationships of "ldc_accounts_view" */
export type Ldc_Accounts_ViewLdc_Invoices_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Ldc_Invoices_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Invoices_View_Order_By>>;
  where?: Maybe<Ldc_Invoices_View_Bool_Exp>;
};

/** aggregated selection of "ldc_accounts_view" */
export type Ldc_Accounts_View_Aggregate = {
  __typename?: 'ldc_accounts_view_aggregate';
  aggregate?: Maybe<Ldc_Accounts_View_Aggregate_Fields>;
  nodes: Array<Ldc_Accounts_View>;
};

export type Ldc_Accounts_View_Aggregate_Bool_Exp = {
  count?: Maybe<Ldc_Accounts_View_Aggregate_Bool_Exp_Count>;
};

export type Ldc_Accounts_View_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Ldc_Accounts_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Accounts_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ldc_accounts_view" */
export type Ldc_Accounts_View_Aggregate_Fields = {
  __typename?: 'ldc_accounts_view_aggregate_fields';
  avg?: Maybe<Ldc_Accounts_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ldc_Accounts_View_Max_Fields>;
  min?: Maybe<Ldc_Accounts_View_Min_Fields>;
  stddev?: Maybe<Ldc_Accounts_View_Stddev_Fields>;
  stddev_pop?: Maybe<Ldc_Accounts_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ldc_Accounts_View_Stddev_Samp_Fields>;
  sum?: Maybe<Ldc_Accounts_View_Sum_Fields>;
  var_pop?: Maybe<Ldc_Accounts_View_Var_Pop_Fields>;
  var_samp?: Maybe<Ldc_Accounts_View_Var_Samp_Fields>;
  variance?: Maybe<Ldc_Accounts_View_Variance_Fields>;
};


/** aggregate fields of "ldc_accounts_view" */
export type Ldc_Accounts_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ldc_Accounts_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Aggregate_Order_By = {
  avg?: Maybe<Ldc_Accounts_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ldc_Accounts_View_Max_Order_By>;
  min?: Maybe<Ldc_Accounts_View_Min_Order_By>;
  stddev?: Maybe<Ldc_Accounts_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Ldc_Accounts_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ldc_Accounts_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Ldc_Accounts_View_Sum_Order_By>;
  var_pop?: Maybe<Ldc_Accounts_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Ldc_Accounts_View_Var_Samp_Order_By>;
  variance?: Maybe<Ldc_Accounts_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ldc_accounts_view" */
export type Ldc_Accounts_View_Arr_Rel_Insert_Input = {
  data: Array<Ldc_Accounts_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Ldc_Accounts_View_Avg_Fields = {
  __typename?: 'ldc_accounts_view_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ldc_accounts_view". All fields are combined with a logical 'AND'. */
export type Ldc_Accounts_View_Bool_Exp = {
  _and?: Maybe<Array<Ldc_Accounts_View_Bool_Exp>>;
  _not?: Maybe<Ldc_Accounts_View_Bool_Exp>;
  _or?: Maybe<Array<Ldc_Accounts_View_Bool_Exp>>;
  account_number?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  ldc_invoices_view?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  ldc_invoices_view_aggregate?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp>;
  ldc_type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "ldc_accounts_view" */
export type Ldc_Accounts_View_Insert_Input = {
  account_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  ldc_invoices_view?: Maybe<Ldc_Invoices_View_Arr_Rel_Insert_Input>;
  ldc_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ldc_Accounts_View_Max_Fields = {
  __typename?: 'ldc_accounts_view_max_fields';
  account_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Max_Order_By = {
  account_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ldc_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ldc_Accounts_View_Min_Fields = {
  __typename?: 'ldc_accounts_view_min_fields';
  account_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Min_Order_By = {
  account_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ldc_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** input type for inserting object relation for remote table "ldc_accounts_view" */
export type Ldc_Accounts_View_Obj_Rel_Insert_Input = {
  data: Ldc_Accounts_View_Insert_Input;
};

/** Ordering options when selecting data from "ldc_accounts_view". */
export type Ldc_Accounts_View_Order_By = {
  account_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ldc_invoices_view_aggregate?: Maybe<Ldc_Invoices_View_Aggregate_Order_By>;
  ldc_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "ldc_accounts_view" */
export enum Ldc_Accounts_View_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  LdcType = 'ldc_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Ldc_Accounts_View_Stddev_Fields = {
  __typename?: 'ldc_accounts_view_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ldc_Accounts_View_Stddev_Pop_Fields = {
  __typename?: 'ldc_accounts_view_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ldc_Accounts_View_Stddev_Samp_Fields = {
  __typename?: 'ldc_accounts_view_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "ldc_accounts_view" */
export type Ldc_Accounts_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ldc_Accounts_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ldc_Accounts_View_Stream_Cursor_Value_Input = {
  account_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Ldc_Accounts_View_Sum_Fields = {
  __typename?: 'ldc_accounts_view_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ldc_Accounts_View_Var_Pop_Fields = {
  __typename?: 'ldc_accounts_view_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ldc_Accounts_View_Var_Samp_Fields = {
  __typename?: 'ldc_accounts_view_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ldc_Accounts_View_Variance_Fields = {
  __typename?: 'ldc_accounts_view_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ldc_accounts_view" */
export type Ldc_Accounts_View_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
};

/** columns and relationships of "ldc_invoices_view" */
export type Ldc_Invoices_View = {
  __typename?: 'ldc_invoices_view';
  account_number?: Maybe<Scalars['String']>;
  amount_now_due?: Maybe<Scalars['float8']>;
  balance_forward?: Maybe<Scalars['float8']>;
  bill_amount?: Maybe<Scalars['float8']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  fees_and_adjustment?: Maybe<Scalars['float8']>;
  has_sales_tax?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoice_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  ldc_account_view?: Maybe<Ldc_Accounts_View>;
  ldc_accounts_view_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  ldc_service_charges_view: Array<Ldc_Service_Charges_View>;
  /** An aggregate relationship */
  ldc_service_charges_view_aggregate: Ldc_Service_Charges_View_Aggregate;
  ldc_type?: Maybe<Scalars['String']>;
  non_utility_charges?: Maybe<Scalars['float8']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  posted_payments?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "ldc_invoices_view" */
export type Ldc_Invoices_ViewLdc_Service_Charges_ViewArgs = {
  distinct_on?: Maybe<Array<Ldc_Service_Charges_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Service_Charges_View_Order_By>>;
  where?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
};


/** columns and relationships of "ldc_invoices_view" */
export type Ldc_Invoices_ViewLdc_Service_Charges_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Ldc_Service_Charges_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Service_Charges_View_Order_By>>;
  where?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
};

/** aggregated selection of "ldc_invoices_view" */
export type Ldc_Invoices_View_Aggregate = {
  __typename?: 'ldc_invoices_view_aggregate';
  aggregate?: Maybe<Ldc_Invoices_View_Aggregate_Fields>;
  nodes: Array<Ldc_Invoices_View>;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp = {
  avg?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Ldc_Invoices_View_Aggregate_Bool_Exp_Var_Samp>;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Avg = {
  arguments: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Corr = {
  arguments: Ldc_Invoices_View_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Ldc_Invoices_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Ldc_Invoices_View_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Max = {
  arguments: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Min = {
  arguments: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Sum = {
  arguments: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ldc_Invoices_View_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "ldc_invoices_view" */
export type Ldc_Invoices_View_Aggregate_Fields = {
  __typename?: 'ldc_invoices_view_aggregate_fields';
  avg?: Maybe<Ldc_Invoices_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ldc_Invoices_View_Max_Fields>;
  min?: Maybe<Ldc_Invoices_View_Min_Fields>;
  stddev?: Maybe<Ldc_Invoices_View_Stddev_Fields>;
  stddev_pop?: Maybe<Ldc_Invoices_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ldc_Invoices_View_Stddev_Samp_Fields>;
  sum?: Maybe<Ldc_Invoices_View_Sum_Fields>;
  var_pop?: Maybe<Ldc_Invoices_View_Var_Pop_Fields>;
  var_samp?: Maybe<Ldc_Invoices_View_Var_Samp_Fields>;
  variance?: Maybe<Ldc_Invoices_View_Variance_Fields>;
};


/** aggregate fields of "ldc_invoices_view" */
export type Ldc_Invoices_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ldc_Invoices_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Aggregate_Order_By = {
  avg?: Maybe<Ldc_Invoices_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ldc_Invoices_View_Max_Order_By>;
  min?: Maybe<Ldc_Invoices_View_Min_Order_By>;
  stddev?: Maybe<Ldc_Invoices_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Ldc_Invoices_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ldc_Invoices_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Ldc_Invoices_View_Sum_Order_By>;
  var_pop?: Maybe<Ldc_Invoices_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Ldc_Invoices_View_Var_Samp_Order_By>;
  variance?: Maybe<Ldc_Invoices_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ldc_invoices_view" */
export type Ldc_Invoices_View_Arr_Rel_Insert_Input = {
  data: Array<Ldc_Invoices_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Ldc_Invoices_View_Avg_Fields = {
  __typename?: 'ldc_invoices_view_avg_fields';
  amount_now_due?: Maybe<Scalars['Float']>;
  balance_forward?: Maybe<Scalars['Float']>;
  bill_amount?: Maybe<Scalars['Float']>;
  fees_and_adjustment?: Maybe<Scalars['Float']>;
  non_utility_charges?: Maybe<Scalars['Float']>;
  posted_payments?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Avg_Order_By = {
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ldc_invoices_view". All fields are combined with a logical 'AND'. */
export type Ldc_Invoices_View_Bool_Exp = {
  _and?: Maybe<Array<Ldc_Invoices_View_Bool_Exp>>;
  _not?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  _or?: Maybe<Array<Ldc_Invoices_View_Bool_Exp>>;
  account_number?: Maybe<String_Comparison_Exp>;
  amount_now_due?: Maybe<Float8_Comparison_Exp>;
  balance_forward?: Maybe<Float8_Comparison_Exp>;
  bill_amount?: Maybe<Float8_Comparison_Exp>;
  create_date?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  due_date?: Maybe<Timestamptz_Comparison_Exp>;
  fees_and_adjustment?: Maybe<Float8_Comparison_Exp>;
  has_sales_tax?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  invoice_number?: Maybe<String_Comparison_Exp>;
  ldc_account_view?: Maybe<Ldc_Accounts_View_Bool_Exp>;
  ldc_accounts_view_id?: Maybe<String_Comparison_Exp>;
  ldc_service_charges_view?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
  ldc_service_charges_view_aggregate?: Maybe<Ldc_Service_Charges_View_Aggregate_Bool_Exp>;
  ldc_type?: Maybe<String_Comparison_Exp>;
  non_utility_charges?: Maybe<Float8_Comparison_Exp>;
  pdf_text?: Maybe<String_Comparison_Exp>;
  pdf_url?: Maybe<String_Comparison_Exp>;
  posted_payments?: Maybe<Float8_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "ldc_invoices_view" */
export type Ldc_Invoices_View_Insert_Input = {
  account_number?: Maybe<Scalars['String']>;
  amount_now_due?: Maybe<Scalars['float8']>;
  balance_forward?: Maybe<Scalars['float8']>;
  bill_amount?: Maybe<Scalars['float8']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  fees_and_adjustment?: Maybe<Scalars['float8']>;
  has_sales_tax?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoice_number?: Maybe<Scalars['String']>;
  ldc_account_view?: Maybe<Ldc_Accounts_View_Obj_Rel_Insert_Input>;
  ldc_accounts_view_id?: Maybe<Scalars['String']>;
  ldc_service_charges_view?: Maybe<Ldc_Service_Charges_View_Arr_Rel_Insert_Input>;
  ldc_type?: Maybe<Scalars['String']>;
  non_utility_charges?: Maybe<Scalars['float8']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  posted_payments?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ldc_Invoices_View_Max_Fields = {
  __typename?: 'ldc_invoices_view_max_fields';
  account_number?: Maybe<Scalars['String']>;
  amount_now_due?: Maybe<Scalars['float8']>;
  balance_forward?: Maybe<Scalars['float8']>;
  bill_amount?: Maybe<Scalars['float8']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  fees_and_adjustment?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
  invoice_number?: Maybe<Scalars['String']>;
  ldc_accounts_view_id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  non_utility_charges?: Maybe<Scalars['float8']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  posted_payments?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Max_Order_By = {
  account_number?: Maybe<Order_By>;
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  create_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  ldc_accounts_view_id?: Maybe<Order_By>;
  ldc_type?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  pdf_text?: Maybe<Order_By>;
  pdf_url?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ldc_Invoices_View_Min_Fields = {
  __typename?: 'ldc_invoices_view_min_fields';
  account_number?: Maybe<Scalars['String']>;
  amount_now_due?: Maybe<Scalars['float8']>;
  balance_forward?: Maybe<Scalars['float8']>;
  bill_amount?: Maybe<Scalars['float8']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  fees_and_adjustment?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
  invoice_number?: Maybe<Scalars['String']>;
  ldc_accounts_view_id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  non_utility_charges?: Maybe<Scalars['float8']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  posted_payments?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Min_Order_By = {
  account_number?: Maybe<Order_By>;
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  create_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  ldc_accounts_view_id?: Maybe<Order_By>;
  ldc_type?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  pdf_text?: Maybe<Order_By>;
  pdf_url?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** input type for inserting object relation for remote table "ldc_invoices_view" */
export type Ldc_Invoices_View_Obj_Rel_Insert_Input = {
  data: Ldc_Invoices_View_Insert_Input;
};

/** Ordering options when selecting data from "ldc_invoices_view". */
export type Ldc_Invoices_View_Order_By = {
  account_number?: Maybe<Order_By>;
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  create_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  has_sales_tax?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  ldc_account_view?: Maybe<Ldc_Accounts_View_Order_By>;
  ldc_accounts_view_id?: Maybe<Order_By>;
  ldc_service_charges_view_aggregate?: Maybe<Ldc_Service_Charges_View_Aggregate_Order_By>;
  ldc_type?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  pdf_text?: Maybe<Order_By>;
  pdf_url?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  AmountNowDue = 'amount_now_due',
  /** column name */
  BalanceForward = 'balance_forward',
  /** column name */
  BillAmount = 'bill_amount',
  /** column name */
  CreateDate = 'create_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  FeesAndAdjustment = 'fees_and_adjustment',
  /** column name */
  HasSalesTax = 'has_sales_tax',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  LdcAccountsViewId = 'ldc_accounts_view_id',
  /** column name */
  LdcType = 'ldc_type',
  /** column name */
  NonUtilityCharges = 'non_utility_charges',
  /** column name */
  PdfText = 'pdf_text',
  /** column name */
  PdfUrl = 'pdf_url',
  /** column name */
  PostedPayments = 'posted_payments',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "ldc_invoices_view_aggregate_bool_exp_avg_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AmountNowDue = 'amount_now_due',
  /** column name */
  BalanceForward = 'balance_forward',
  /** column name */
  BillAmount = 'bill_amount',
  /** column name */
  FeesAndAdjustment = 'fees_and_adjustment',
  /** column name */
  NonUtilityCharges = 'non_utility_charges',
  /** column name */
  PostedPayments = 'posted_payments'
}

/** select "ldc_invoices_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasSalesTax = 'has_sales_tax'
}

/** select "ldc_invoices_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasSalesTax = 'has_sales_tax'
}

/** select "ldc_invoices_view_aggregate_bool_exp_corr_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AmountNowDue = 'amount_now_due',
  /** column name */
  BalanceForward = 'balance_forward',
  /** column name */
  BillAmount = 'bill_amount',
  /** column name */
  FeesAndAdjustment = 'fees_and_adjustment',
  /** column name */
  NonUtilityCharges = 'non_utility_charges',
  /** column name */
  PostedPayments = 'posted_payments'
}

/** select "ldc_invoices_view_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AmountNowDue = 'amount_now_due',
  /** column name */
  BalanceForward = 'balance_forward',
  /** column name */
  BillAmount = 'bill_amount',
  /** column name */
  FeesAndAdjustment = 'fees_and_adjustment',
  /** column name */
  NonUtilityCharges = 'non_utility_charges',
  /** column name */
  PostedPayments = 'posted_payments'
}

/** select "ldc_invoices_view_aggregate_bool_exp_max_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AmountNowDue = 'amount_now_due',
  /** column name */
  BalanceForward = 'balance_forward',
  /** column name */
  BillAmount = 'bill_amount',
  /** column name */
  FeesAndAdjustment = 'fees_and_adjustment',
  /** column name */
  NonUtilityCharges = 'non_utility_charges',
  /** column name */
  PostedPayments = 'posted_payments'
}

/** select "ldc_invoices_view_aggregate_bool_exp_min_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AmountNowDue = 'amount_now_due',
  /** column name */
  BalanceForward = 'balance_forward',
  /** column name */
  BillAmount = 'bill_amount',
  /** column name */
  FeesAndAdjustment = 'fees_and_adjustment',
  /** column name */
  NonUtilityCharges = 'non_utility_charges',
  /** column name */
  PostedPayments = 'posted_payments'
}

/** select "ldc_invoices_view_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AmountNowDue = 'amount_now_due',
  /** column name */
  BalanceForward = 'balance_forward',
  /** column name */
  BillAmount = 'bill_amount',
  /** column name */
  FeesAndAdjustment = 'fees_and_adjustment',
  /** column name */
  NonUtilityCharges = 'non_utility_charges',
  /** column name */
  PostedPayments = 'posted_payments'
}

/** select "ldc_invoices_view_aggregate_bool_exp_sum_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AmountNowDue = 'amount_now_due',
  /** column name */
  BalanceForward = 'balance_forward',
  /** column name */
  BillAmount = 'bill_amount',
  /** column name */
  FeesAndAdjustment = 'fees_and_adjustment',
  /** column name */
  NonUtilityCharges = 'non_utility_charges',
  /** column name */
  PostedPayments = 'posted_payments'
}

/** select "ldc_invoices_view_aggregate_bool_exp_var_samp_arguments_columns" columns of table "ldc_invoices_view" */
export enum Ldc_Invoices_View_Select_Column_Ldc_Invoices_View_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AmountNowDue = 'amount_now_due',
  /** column name */
  BalanceForward = 'balance_forward',
  /** column name */
  BillAmount = 'bill_amount',
  /** column name */
  FeesAndAdjustment = 'fees_and_adjustment',
  /** column name */
  NonUtilityCharges = 'non_utility_charges',
  /** column name */
  PostedPayments = 'posted_payments'
}

/** aggregate stddev on columns */
export type Ldc_Invoices_View_Stddev_Fields = {
  __typename?: 'ldc_invoices_view_stddev_fields';
  amount_now_due?: Maybe<Scalars['Float']>;
  balance_forward?: Maybe<Scalars['Float']>;
  bill_amount?: Maybe<Scalars['Float']>;
  fees_and_adjustment?: Maybe<Scalars['Float']>;
  non_utility_charges?: Maybe<Scalars['Float']>;
  posted_payments?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Stddev_Order_By = {
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ldc_Invoices_View_Stddev_Pop_Fields = {
  __typename?: 'ldc_invoices_view_stddev_pop_fields';
  amount_now_due?: Maybe<Scalars['Float']>;
  balance_forward?: Maybe<Scalars['Float']>;
  bill_amount?: Maybe<Scalars['Float']>;
  fees_and_adjustment?: Maybe<Scalars['Float']>;
  non_utility_charges?: Maybe<Scalars['Float']>;
  posted_payments?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Stddev_Pop_Order_By = {
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ldc_Invoices_View_Stddev_Samp_Fields = {
  __typename?: 'ldc_invoices_view_stddev_samp_fields';
  amount_now_due?: Maybe<Scalars['Float']>;
  balance_forward?: Maybe<Scalars['Float']>;
  bill_amount?: Maybe<Scalars['Float']>;
  fees_and_adjustment?: Maybe<Scalars['Float']>;
  non_utility_charges?: Maybe<Scalars['Float']>;
  posted_payments?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Stddev_Samp_Order_By = {
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
};

/** Streaming cursor of the table "ldc_invoices_view" */
export type Ldc_Invoices_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ldc_Invoices_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ldc_Invoices_View_Stream_Cursor_Value_Input = {
  account_number?: Maybe<Scalars['String']>;
  amount_now_due?: Maybe<Scalars['float8']>;
  balance_forward?: Maybe<Scalars['float8']>;
  bill_amount?: Maybe<Scalars['float8']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  fees_and_adjustment?: Maybe<Scalars['float8']>;
  has_sales_tax?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoice_number?: Maybe<Scalars['String']>;
  ldc_accounts_view_id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  non_utility_charges?: Maybe<Scalars['float8']>;
  pdf_text?: Maybe<Scalars['String']>;
  pdf_url?: Maybe<Scalars['String']>;
  posted_payments?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Ldc_Invoices_View_Sum_Fields = {
  __typename?: 'ldc_invoices_view_sum_fields';
  amount_now_due?: Maybe<Scalars['float8']>;
  balance_forward?: Maybe<Scalars['float8']>;
  bill_amount?: Maybe<Scalars['float8']>;
  fees_and_adjustment?: Maybe<Scalars['float8']>;
  non_utility_charges?: Maybe<Scalars['float8']>;
  posted_payments?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Sum_Order_By = {
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ldc_Invoices_View_Var_Pop_Fields = {
  __typename?: 'ldc_invoices_view_var_pop_fields';
  amount_now_due?: Maybe<Scalars['Float']>;
  balance_forward?: Maybe<Scalars['Float']>;
  bill_amount?: Maybe<Scalars['Float']>;
  fees_and_adjustment?: Maybe<Scalars['Float']>;
  non_utility_charges?: Maybe<Scalars['Float']>;
  posted_payments?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Var_Pop_Order_By = {
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ldc_Invoices_View_Var_Samp_Fields = {
  __typename?: 'ldc_invoices_view_var_samp_fields';
  amount_now_due?: Maybe<Scalars['Float']>;
  balance_forward?: Maybe<Scalars['Float']>;
  bill_amount?: Maybe<Scalars['Float']>;
  fees_and_adjustment?: Maybe<Scalars['Float']>;
  non_utility_charges?: Maybe<Scalars['Float']>;
  posted_payments?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Var_Samp_Order_By = {
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ldc_Invoices_View_Variance_Fields = {
  __typename?: 'ldc_invoices_view_variance_fields';
  amount_now_due?: Maybe<Scalars['Float']>;
  balance_forward?: Maybe<Scalars['Float']>;
  bill_amount?: Maybe<Scalars['Float']>;
  fees_and_adjustment?: Maybe<Scalars['Float']>;
  non_utility_charges?: Maybe<Scalars['Float']>;
  posted_payments?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ldc_invoices_view" */
export type Ldc_Invoices_View_Variance_Order_By = {
  amount_now_due?: Maybe<Order_By>;
  balance_forward?: Maybe<Order_By>;
  bill_amount?: Maybe<Order_By>;
  fees_and_adjustment?: Maybe<Order_By>;
  non_utility_charges?: Maybe<Order_By>;
  posted_payments?: Maybe<Order_By>;
};

/** columns and relationships of "ldc_service_charges_view" */
export type Ldc_Service_Charges_View = {
  __typename?: 'ldc_service_charges_view';
  account_number?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  deal_detail?: Maybe<Deal_Details>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  ldc_invoice_view?: Maybe<Ldc_Invoices_View>;
  ldc_invoices_view_id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
  readings?: Maybe<Scalars['jsonb']>;
  tax?: Maybe<Scalars['jsonb']>;
  volume_dth?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "ldc_service_charges_view" */
export type Ldc_Service_Charges_ViewReadingsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "ldc_service_charges_view" */
export type Ldc_Service_Charges_ViewTaxArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "ldc_service_charges_view" */
export type Ldc_Service_Charges_ViewVolume_DthArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Aggregate = {
  __typename?: 'ldc_service_charges_view_aggregate';
  aggregate?: Maybe<Ldc_Service_Charges_View_Aggregate_Fields>;
  nodes: Array<Ldc_Service_Charges_View>;
};

export type Ldc_Service_Charges_View_Aggregate_Bool_Exp = {
  count?: Maybe<Ldc_Service_Charges_View_Aggregate_Bool_Exp_Count>;
};

export type Ldc_Service_Charges_View_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Ldc_Service_Charges_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Aggregate_Fields = {
  __typename?: 'ldc_service_charges_view_aggregate_fields';
  avg?: Maybe<Ldc_Service_Charges_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ldc_Service_Charges_View_Max_Fields>;
  min?: Maybe<Ldc_Service_Charges_View_Min_Fields>;
  stddev?: Maybe<Ldc_Service_Charges_View_Stddev_Fields>;
  stddev_pop?: Maybe<Ldc_Service_Charges_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ldc_Service_Charges_View_Stddev_Samp_Fields>;
  sum?: Maybe<Ldc_Service_Charges_View_Sum_Fields>;
  var_pop?: Maybe<Ldc_Service_Charges_View_Var_Pop_Fields>;
  var_samp?: Maybe<Ldc_Service_Charges_View_Var_Samp_Fields>;
  variance?: Maybe<Ldc_Service_Charges_View_Variance_Fields>;
};


/** aggregate fields of "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ldc_Service_Charges_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Aggregate_Order_By = {
  avg?: Maybe<Ldc_Service_Charges_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ldc_Service_Charges_View_Max_Order_By>;
  min?: Maybe<Ldc_Service_Charges_View_Min_Order_By>;
  stddev?: Maybe<Ldc_Service_Charges_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Ldc_Service_Charges_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ldc_Service_Charges_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Ldc_Service_Charges_View_Sum_Order_By>;
  var_pop?: Maybe<Ldc_Service_Charges_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Ldc_Service_Charges_View_Var_Samp_Order_By>;
  variance?: Maybe<Ldc_Service_Charges_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Arr_Rel_Insert_Input = {
  data: Array<Ldc_Service_Charges_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Ldc_Service_Charges_View_Avg_Fields = {
  __typename?: 'ldc_service_charges_view_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ldc_service_charges_view". All fields are combined with a logical 'AND'. */
export type Ldc_Service_Charges_View_Bool_Exp = {
  _and?: Maybe<Array<Ldc_Service_Charges_View_Bool_Exp>>;
  _not?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
  _or?: Maybe<Array<Ldc_Service_Charges_View_Bool_Exp>>;
  account_number?: Maybe<String_Comparison_Exp>;
  address?: Maybe<String_Comparison_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  create_date?: Maybe<Timestamptz_Comparison_Exp>;
  deal_detail?: Maybe<Deal_Details_Bool_Exp>;
  deal_detail_id?: Maybe<Int_Comparison_Exp>;
  invoice_number?: Maybe<String_Comparison_Exp>;
  ldc_invoice_view?: Maybe<Ldc_Invoices_View_Bool_Exp>;
  ldc_invoices_view_id?: Maybe<String_Comparison_Exp>;
  ldc_type?: Maybe<String_Comparison_Exp>;
  meter?: Maybe<String_Comparison_Exp>;
  readings?: Maybe<Jsonb_Comparison_Exp>;
  tax?: Maybe<Jsonb_Comparison_Exp>;
  volume_dth?: Maybe<Jsonb_Comparison_Exp>;
};

/** input type for inserting data into table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Insert_Input = {
  account_number?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  deal_detail?: Maybe<Deal_Details_Obj_Rel_Insert_Input>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['String']>;
  ldc_invoice_view?: Maybe<Ldc_Invoices_View_Obj_Rel_Insert_Input>;
  ldc_invoices_view_id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
  readings?: Maybe<Scalars['jsonb']>;
  tax?: Maybe<Scalars['jsonb']>;
  volume_dth?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Ldc_Service_Charges_View_Max_Fields = {
  __typename?: 'ldc_service_charges_view_max_fields';
  account_number?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['String']>;
  ldc_invoices_view_id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Max_Order_By = {
  account_number?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  create_date?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  ldc_invoices_view_id?: Maybe<Order_By>;
  ldc_type?: Maybe<Order_By>;
  meter?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ldc_Service_Charges_View_Min_Fields = {
  __typename?: 'ldc_service_charges_view_min_fields';
  account_number?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['String']>;
  ldc_invoices_view_id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Min_Order_By = {
  account_number?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  create_date?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  ldc_invoices_view_id?: Maybe<Order_By>;
  ldc_type?: Maybe<Order_By>;
  meter?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "ldc_service_charges_view". */
export type Ldc_Service_Charges_View_Order_By = {
  account_number?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  create_date?: Maybe<Order_By>;
  deal_detail?: Maybe<Deal_Details_Order_By>;
  deal_detail_id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  ldc_invoice_view?: Maybe<Ldc_Invoices_View_Order_By>;
  ldc_invoices_view_id?: Maybe<Order_By>;
  ldc_type?: Maybe<Order_By>;
  meter?: Maybe<Order_By>;
  readings?: Maybe<Order_By>;
  tax?: Maybe<Order_By>;
  volume_dth?: Maybe<Order_By>;
};

/** select columns of table "ldc_service_charges_view" */
export enum Ldc_Service_Charges_View_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  Address = 'address',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreateDate = 'create_date',
  /** column name */
  DealDetailId = 'deal_detail_id',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  LdcInvoicesViewId = 'ldc_invoices_view_id',
  /** column name */
  LdcType = 'ldc_type',
  /** column name */
  Meter = 'meter',
  /** column name */
  Readings = 'readings',
  /** column name */
  Tax = 'tax',
  /** column name */
  VolumeDth = 'volume_dth'
}

/** aggregate stddev on columns */
export type Ldc_Service_Charges_View_Stddev_Fields = {
  __typename?: 'ldc_service_charges_view_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ldc_Service_Charges_View_Stddev_Pop_Fields = {
  __typename?: 'ldc_service_charges_view_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ldc_Service_Charges_View_Stddev_Samp_Fields = {
  __typename?: 'ldc_service_charges_view_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ldc_Service_Charges_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ldc_Service_Charges_View_Stream_Cursor_Value_Input = {
  account_number?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  create_date?: Maybe<Scalars['timestamptz']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['String']>;
  ldc_invoices_view_id?: Maybe<Scalars['String']>;
  ldc_type?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
  readings?: Maybe<Scalars['jsonb']>;
  tax?: Maybe<Scalars['jsonb']>;
  volume_dth?: Maybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Ldc_Service_Charges_View_Sum_Fields = {
  __typename?: 'ldc_service_charges_view_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  deal_detail_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ldc_Service_Charges_View_Var_Pop_Fields = {
  __typename?: 'ldc_service_charges_view_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ldc_Service_Charges_View_Var_Samp_Fields = {
  __typename?: 'ldc_service_charges_view_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ldc_Service_Charges_View_Variance_Fields = {
  __typename?: 'ldc_service_charges_view_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  deal_detail_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ldc_service_charges_view" */
export type Ldc_Service_Charges_View_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  deal_detail_id?: Maybe<Order_By>;
};

/** columns and relationships of "load_forecasts" */
export type Load_Forecasts = {
  __typename?: 'load_forecasts';
  active?: Maybe<Scalars['Boolean']>;
  annual_load?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamp'];
  customer_id?: Maybe<Scalars['Int']>;
  customer_location?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  forecast_customer_id?: Maybe<Customers>;
  /** An array relationship */
  forecast_type: Array<Forecast_Types>;
  /** An aggregate relationship */
  forecast_type_aggregate: Forecast_Types_Aggregate;
  forecast_type_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  service_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "load_forecasts" */
export type Load_ForecastsForecast_TypeArgs = {
  distinct_on?: Maybe<Array<Forecast_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Types_Order_By>>;
  where?: Maybe<Forecast_Types_Bool_Exp>;
};


/** columns and relationships of "load_forecasts" */
export type Load_ForecastsForecast_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Types_Order_By>>;
  where?: Maybe<Forecast_Types_Bool_Exp>;
};

/** aggregated selection of "load_forecasts" */
export type Load_Forecasts_Aggregate = {
  __typename?: 'load_forecasts_aggregate';
  aggregate?: Maybe<Load_Forecasts_Aggregate_Fields>;
  nodes: Array<Load_Forecasts>;
};

export type Load_Forecasts_Aggregate_Bool_Exp = {
  avg?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Load_Forecasts_Aggregate_Bool_Exp_Var_Samp>;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Avg = {
  arguments: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Corr = {
  arguments: Load_Forecasts_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Load_Forecasts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Load_Forecasts_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Max = {
  arguments: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Min = {
  arguments: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Sum = {
  arguments: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Load_Forecasts_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Load_Forecasts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "load_forecasts" */
export type Load_Forecasts_Aggregate_Fields = {
  __typename?: 'load_forecasts_aggregate_fields';
  avg?: Maybe<Load_Forecasts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Load_Forecasts_Max_Fields>;
  min?: Maybe<Load_Forecasts_Min_Fields>;
  stddev?: Maybe<Load_Forecasts_Stddev_Fields>;
  stddev_pop?: Maybe<Load_Forecasts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Load_Forecasts_Stddev_Samp_Fields>;
  sum?: Maybe<Load_Forecasts_Sum_Fields>;
  var_pop?: Maybe<Load_Forecasts_Var_Pop_Fields>;
  var_samp?: Maybe<Load_Forecasts_Var_Samp_Fields>;
  variance?: Maybe<Load_Forecasts_Variance_Fields>;
};


/** aggregate fields of "load_forecasts" */
export type Load_Forecasts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Load_Forecasts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "load_forecasts" */
export type Load_Forecasts_Aggregate_Order_By = {
  avg?: Maybe<Load_Forecasts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Load_Forecasts_Max_Order_By>;
  min?: Maybe<Load_Forecasts_Min_Order_By>;
  stddev?: Maybe<Load_Forecasts_Stddev_Order_By>;
  stddev_pop?: Maybe<Load_Forecasts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Load_Forecasts_Stddev_Samp_Order_By>;
  sum?: Maybe<Load_Forecasts_Sum_Order_By>;
  var_pop?: Maybe<Load_Forecasts_Var_Pop_Order_By>;
  var_samp?: Maybe<Load_Forecasts_Var_Samp_Order_By>;
  variance?: Maybe<Load_Forecasts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "load_forecasts" */
export type Load_Forecasts_Arr_Rel_Insert_Input = {
  data: Array<Load_Forecasts_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Load_Forecasts_On_Conflict>;
};

/** aggregate avg on columns */
export type Load_Forecasts_Avg_Fields = {
  __typename?: 'load_forecasts_avg_fields';
  annual_load?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  forecast_type_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "load_forecasts" */
export type Load_Forecasts_Avg_Order_By = {
  annual_load?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "load_forecasts". All fields are combined with a logical 'AND'. */
export type Load_Forecasts_Bool_Exp = {
  _and?: Maybe<Array<Load_Forecasts_Bool_Exp>>;
  _not?: Maybe<Load_Forecasts_Bool_Exp>;
  _or?: Maybe<Array<Load_Forecasts_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  annual_load?: Maybe<Float8_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  customer_location?: Maybe<Boolean_Comparison_Exp>;
  forecast_customer_id?: Maybe<Customers_Bool_Exp>;
  forecast_type?: Maybe<Forecast_Types_Bool_Exp>;
  forecast_type_aggregate?: Maybe<Forecast_Types_Aggregate_Bool_Exp>;
  forecast_type_id?: Maybe<Int_Comparison_Exp>;
  group_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  service_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "load_forecasts" */
export enum Load_Forecasts_Constraint {
  /** unique or primary key constraint on columns "id" */
  LoadForecastsPkey = 'load_forecasts_pkey'
}

/** input type for incrementing numeric columns in table "load_forecasts" */
export type Load_Forecasts_Inc_Input = {
  annual_load?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  forecast_type_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "load_forecasts" */
export type Load_Forecasts_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_load?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_location?: Maybe<Scalars['Boolean']>;
  forecast_customer_id?: Maybe<Customers_Obj_Rel_Insert_Input>;
  forecast_type?: Maybe<Forecast_Types_Arr_Rel_Insert_Input>;
  forecast_type_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Load_Forecasts_Max_Fields = {
  __typename?: 'load_forecasts_max_fields';
  annual_load?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  forecast_type_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "load_forecasts" */
export type Load_Forecasts_Max_Order_By = {
  annual_load?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Load_Forecasts_Min_Fields = {
  __typename?: 'load_forecasts_min_fields';
  annual_load?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  forecast_type_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "load_forecasts" */
export type Load_Forecasts_Min_Order_By = {
  annual_load?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "load_forecasts" */
export type Load_Forecasts_Mutation_Response = {
  __typename?: 'load_forecasts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Load_Forecasts>;
};

/** on_conflict condition type for table "load_forecasts" */
export type Load_Forecasts_On_Conflict = {
  constraint: Load_Forecasts_Constraint;
  update_columns?: Array<Load_Forecasts_Update_Column>;
  where?: Maybe<Load_Forecasts_Bool_Exp>;
};

/** Ordering options when selecting data from "load_forecasts". */
export type Load_Forecasts_Order_By = {
  active?: Maybe<Order_By>;
  annual_load?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_location?: Maybe<Order_By>;
  forecast_customer_id?: Maybe<Customers_Order_By>;
  forecast_type_aggregate?: Maybe<Forecast_Types_Aggregate_Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: load_forecasts */
export type Load_Forecasts_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualLoad = 'annual_load',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerLocation = 'customer_location',
  /** column name */
  ForecastTypeId = 'forecast_type_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "load_forecasts_aggregate_bool_exp_avg_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AnnualLoad = 'annual_load'
}

/** select "load_forecasts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  CustomerLocation = 'customer_location'
}

/** select "load_forecasts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  CustomerLocation = 'customer_location'
}

/** select "load_forecasts_aggregate_bool_exp_corr_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AnnualLoad = 'annual_load'
}

/** select "load_forecasts_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AnnualLoad = 'annual_load'
}

/** select "load_forecasts_aggregate_bool_exp_max_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AnnualLoad = 'annual_load'
}

/** select "load_forecasts_aggregate_bool_exp_min_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AnnualLoad = 'annual_load'
}

/** select "load_forecasts_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AnnualLoad = 'annual_load'
}

/** select "load_forecasts_aggregate_bool_exp_sum_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AnnualLoad = 'annual_load'
}

/** select "load_forecasts_aggregate_bool_exp_var_samp_arguments_columns" columns of table "load_forecasts" */
export enum Load_Forecasts_Select_Column_Load_Forecasts_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AnnualLoad = 'annual_load'
}

/** input type for updating data in table "load_forecasts" */
export type Load_Forecasts_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_load?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_location?: Maybe<Scalars['Boolean']>;
  forecast_type_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Load_Forecasts_Stddev_Fields = {
  __typename?: 'load_forecasts_stddev_fields';
  annual_load?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  forecast_type_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "load_forecasts" */
export type Load_Forecasts_Stddev_Order_By = {
  annual_load?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Load_Forecasts_Stddev_Pop_Fields = {
  __typename?: 'load_forecasts_stddev_pop_fields';
  annual_load?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  forecast_type_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "load_forecasts" */
export type Load_Forecasts_Stddev_Pop_Order_By = {
  annual_load?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Load_Forecasts_Stddev_Samp_Fields = {
  __typename?: 'load_forecasts_stddev_samp_fields';
  annual_load?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  forecast_type_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "load_forecasts" */
export type Load_Forecasts_Stddev_Samp_Order_By = {
  annual_load?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "load_forecasts" */
export type Load_Forecasts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Load_Forecasts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Load_Forecasts_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_load?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_location?: Maybe<Scalars['Boolean']>;
  forecast_type_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Load_Forecasts_Sum_Fields = {
  __typename?: 'load_forecasts_sum_fields';
  annual_load?: Maybe<Scalars['float8']>;
  customer_id?: Maybe<Scalars['Int']>;
  forecast_type_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "load_forecasts" */
export type Load_Forecasts_Sum_Order_By = {
  annual_load?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** update columns of table "load_forecasts" */
export enum Load_Forecasts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualLoad = 'annual_load',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerLocation = 'customer_location',
  /** column name */
  ForecastTypeId = 'forecast_type_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Load_Forecasts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Load_Forecasts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Load_Forecasts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Load_Forecasts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Load_Forecasts_Var_Pop_Fields = {
  __typename?: 'load_forecasts_var_pop_fields';
  annual_load?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  forecast_type_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "load_forecasts" */
export type Load_Forecasts_Var_Pop_Order_By = {
  annual_load?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Load_Forecasts_Var_Samp_Fields = {
  __typename?: 'load_forecasts_var_samp_fields';
  annual_load?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  forecast_type_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "load_forecasts" */
export type Load_Forecasts_Var_Samp_Order_By = {
  annual_load?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Load_Forecasts_Variance_Fields = {
  __typename?: 'load_forecasts_variance_fields';
  annual_load?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  forecast_type_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "load_forecasts" */
export type Load_Forecasts_Variance_Order_By = {
  annual_load?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  forecast_type_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  deal_details: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_aggregate: Deal_Details_Aggregate;
  default_location?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  delivery_locations: Array<Paths>;
  /** An aggregate relationship */
  delivery_locations_aggregate: Paths_Aggregate;
  id: Scalars['Int'];
  loc_descriptors?: Maybe<Scalars['jsonb']>;
  loc_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  pipeline?: Maybe<Pipelines>;
  pipeline_id?: Maybe<Scalars['Int']>;
  pool_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  receipt_locations: Array<Paths>;
  /** An aggregate relationship */
  receipt_locations_aggregate: Paths_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "locations" */
export type LocationsDeal_DetailsArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsDeal_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsDelivery_LocationsArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsDelivery_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsLoc_DescriptorsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "locations" */
export type LocationsReceipt_LocationsArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsReceipt_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

export type Locations_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Locations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Locations_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Locations_Aggregate_Bool_Exp_Count>;
};

export type Locations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Locations_Select_Column_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Locations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Locations_Select_Column_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Locations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  avg?: Maybe<Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
  stddev?: Maybe<Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Locations_Sum_Fields>;
  var_pop?: Maybe<Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Locations_Var_Samp_Fields>;
  variance?: Maybe<Locations_Variance_Fields>;
};


/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locations" */
export type Locations_Aggregate_Order_By = {
  avg?: Maybe<Locations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Locations_Max_Order_By>;
  min?: Maybe<Locations_Min_Order_By>;
  stddev?: Maybe<Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<Locations_Sum_Order_By>;
  var_pop?: Maybe<Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<Locations_Var_Samp_Order_By>;
  variance?: Maybe<Locations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Locations_Append_Input = {
  loc_descriptors?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "locations" */
export type Locations_Arr_Rel_Insert_Input = {
  data: Array<Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Locations_Avg_Fields = {
  __typename?: 'locations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  pool_id?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "locations" */
export type Locations_Avg_Order_By = {
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: Maybe<Array<Locations_Bool_Exp>>;
  _not?: Maybe<Locations_Bool_Exp>;
  _or?: Maybe<Array<Locations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deal_details?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  default_location?: Maybe<Boolean_Comparison_Exp>;
  delivery_locations?: Maybe<Paths_Bool_Exp>;
  delivery_locations_aggregate?: Maybe<Paths_Aggregate_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  loc_descriptors?: Maybe<Jsonb_Comparison_Exp>;
  loc_id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  pipeline?: Maybe<Pipelines_Bool_Exp>;
  pipeline_id?: Maybe<Int_Comparison_Exp>;
  pool_id?: Maybe<Int_Comparison_Exp>;
  receipt_locations?: Maybe<Paths_Bool_Exp>;
  receipt_locations_aggregate?: Maybe<Paths_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  utility_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint on columns "pipeline_id", "name" */
  IndexLocationsOnNameAndPipelineId = 'index_locations_on_name_and_pipeline_id',
  /** unique or primary key constraint on columns "id" */
  LocationsPkey = 'locations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Locations_Delete_At_Path_Input = {
  loc_descriptors?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Locations_Delete_Elem_Input = {
  loc_descriptors?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Locations_Delete_Key_Input = {
  loc_descriptors?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "locations" */
export type Locations_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  pool_id?: Maybe<Scalars['Int']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deal_details?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  default_location?: Maybe<Scalars['Boolean']>;
  delivery_locations?: Maybe<Paths_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  loc_descriptors?: Maybe<Scalars['jsonb']>;
  loc_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Pipelines_Obj_Rel_Insert_Input>;
  pipeline_id?: Maybe<Scalars['Int']>;
  pool_id?: Maybe<Scalars['Int']>;
  receipt_locations?: Maybe<Paths_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  loc_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  pool_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "locations" */
export type Locations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  loc_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  loc_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  pool_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "locations" */
export type Locations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  loc_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: Maybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  created_at?: Maybe<Order_By>;
  deal_details_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  default_location?: Maybe<Order_By>;
  delivery_locations_aggregate?: Maybe<Paths_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  loc_descriptors?: Maybe<Order_By>;
  loc_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pipeline?: Maybe<Pipelines_Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  receipt_locations_aggregate?: Maybe<Paths_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Locations_Prepend_Input = {
  loc_descriptors?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultLocation = 'default_location',
  /** column name */
  Id = 'id',
  /** column name */
  LocDescriptors = 'loc_descriptors',
  /** column name */
  LocId = 'loc_id',
  /** column name */
  Name = 'name',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  PoolId = 'pool_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityId = 'utility_id'
}

/** select "locations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "locations" */
export enum Locations_Select_Column_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DefaultLocation = 'default_location'
}

/** select "locations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "locations" */
export enum Locations_Select_Column_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DefaultLocation = 'default_location'
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  default_location?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  loc_descriptors?: Maybe<Scalars['jsonb']>;
  loc_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  pool_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Locations_Stddev_Fields = {
  __typename?: 'locations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  pool_id?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "locations" */
export type Locations_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Locations_Stddev_Pop_Fields = {
  __typename?: 'locations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  pool_id?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "locations" */
export type Locations_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Locations_Stddev_Samp_Fields = {
  __typename?: 'locations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  pool_id?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "locations" */
export type Locations_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  default_location?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  loc_descriptors?: Maybe<Scalars['jsonb']>;
  loc_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  pool_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Locations_Sum_Fields = {
  __typename?: 'locations_sum_fields';
  id?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  pool_id?: Maybe<Scalars['Int']>;
  utility_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "locations" */
export type Locations_Sum_Order_By = {
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultLocation = 'default_location',
  /** column name */
  Id = 'id',
  /** column name */
  LocDescriptors = 'loc_descriptors',
  /** column name */
  LocId = 'loc_id',
  /** column name */
  Name = 'name',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  PoolId = 'pool_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityId = 'utility_id'
}

export type Locations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Locations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Locations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Locations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Locations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Locations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Locations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Locations_Var_Pop_Fields = {
  __typename?: 'locations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  pool_id?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "locations" */
export type Locations_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Locations_Var_Samp_Fields = {
  __typename?: 'locations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  pool_id?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "locations" */
export type Locations_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Locations_Variance_Fields = {
  __typename?: 'locations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  pool_id?: Maybe<Scalars['Float']>;
  utility_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "locations" */
export type Locations_Variance_Order_By = {
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  pool_id?: Maybe<Order_By>;
  utility_id?: Maybe<Order_By>;
};

/** columns and relationships of "logs" */
export type Logs = {
  __typename?: 'logs';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  id: Scalars['bigint'];
  /** An object relationship */
  user?: Maybe<Auth_Users>;
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "logs" */
export type LogsDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "logs" */
export type Logs_Aggregate = {
  __typename?: 'logs_aggregate';
  aggregate?: Maybe<Logs_Aggregate_Fields>;
  nodes: Array<Logs>;
};

export type Logs_Aggregate_Bool_Exp = {
  count?: Maybe<Logs_Aggregate_Bool_Exp_Count>;
};

export type Logs_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Logs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Logs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "logs" */
export type Logs_Aggregate_Fields = {
  __typename?: 'logs_aggregate_fields';
  avg?: Maybe<Logs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Logs_Max_Fields>;
  min?: Maybe<Logs_Min_Fields>;
  stddev?: Maybe<Logs_Stddev_Fields>;
  stddev_pop?: Maybe<Logs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Logs_Stddev_Samp_Fields>;
  sum?: Maybe<Logs_Sum_Fields>;
  var_pop?: Maybe<Logs_Var_Pop_Fields>;
  var_samp?: Maybe<Logs_Var_Samp_Fields>;
  variance?: Maybe<Logs_Variance_Fields>;
};


/** aggregate fields of "logs" */
export type Logs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Logs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "logs" */
export type Logs_Aggregate_Order_By = {
  avg?: Maybe<Logs_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Logs_Max_Order_By>;
  min?: Maybe<Logs_Min_Order_By>;
  stddev?: Maybe<Logs_Stddev_Order_By>;
  stddev_pop?: Maybe<Logs_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Logs_Stddev_Samp_Order_By>;
  sum?: Maybe<Logs_Sum_Order_By>;
  var_pop?: Maybe<Logs_Var_Pop_Order_By>;
  var_samp?: Maybe<Logs_Var_Samp_Order_By>;
  variance?: Maybe<Logs_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Logs_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "logs" */
export type Logs_Arr_Rel_Insert_Input = {
  data: Array<Logs_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Logs_On_Conflict>;
};

/** aggregate avg on columns */
export type Logs_Avg_Fields = {
  __typename?: 'logs_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "logs" */
export type Logs_Avg_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "logs". All fields are combined with a logical 'AND'. */
export type Logs_Bool_Exp = {
  _and?: Maybe<Array<Logs_Bool_Exp>>;
  _not?: Maybe<Logs_Bool_Exp>;
  _or?: Maybe<Array<Logs_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  user?: Maybe<Auth_Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "logs" */
export enum Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogsPkey = 'logs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Logs_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Logs_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Logs_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "logs" */
export type Logs_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "logs" */
export type Logs_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  user?: Maybe<Auth_Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Logs_Max_Fields = {
  __typename?: 'logs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "logs" */
export type Logs_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Logs_Min_Fields = {
  __typename?: 'logs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "logs" */
export type Logs_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "logs" */
export type Logs_Mutation_Response = {
  __typename?: 'logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Logs>;
};

/** on_conflict condition type for table "logs" */
export type Logs_On_Conflict = {
  constraint: Logs_Constraint;
  update_columns?: Array<Logs_Update_Column>;
  where?: Maybe<Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "logs". */
export type Logs_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user?: Maybe<Auth_Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: logs */
export type Logs_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Logs_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "logs" */
export enum Logs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "logs" */
export type Logs_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Logs_Stddev_Fields = {
  __typename?: 'logs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "logs" */
export type Logs_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Logs_Stddev_Pop_Fields = {
  __typename?: 'logs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "logs" */
export type Logs_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Logs_Stddev_Samp_Fields = {
  __typename?: 'logs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "logs" */
export type Logs_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "logs" */
export type Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Logs_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Logs_Sum_Fields = {
  __typename?: 'logs_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "logs" */
export type Logs_Sum_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "logs" */
export enum Logs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Logs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Logs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Logs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Logs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Logs_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Logs_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Logs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Logs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Logs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Logs_Var_Pop_Fields = {
  __typename?: 'logs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "logs" */
export type Logs_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Logs_Var_Samp_Fields = {
  __typename?: 'logs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "logs" */
export type Logs_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Logs_Variance_Fields = {
  __typename?: 'logs_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "logs" */
export type Logs_Variance_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createPasswordResetToken?: Maybe<Scalars['String']>;
  /** delete data from the table: "ach_queues" */
  delete_ach_queues?: Maybe<Ach_Queues_Mutation_Response>;
  /** delete single row from the table: "ach_queues" */
  delete_ach_queues_by_pk?: Maybe<Ach_Queues>;
  /** delete data from the table: "active_storage_blobs" */
  delete_active_storage_blobs?: Maybe<Active_Storage_Blobs_Mutation_Response>;
  /** delete single row from the table: "active_storage_blobs" */
  delete_active_storage_blobs_by_pk?: Maybe<Active_Storage_Blobs>;
  /** delete data from the table: "addresses" */
  delete_addresses?: Maybe<Addresses_Mutation_Response>;
  /** delete single row from the table: "addresses" */
  delete_addresses_by_pk?: Maybe<Addresses>;
  /** delete data from the table: "audit.logged_actions" */
  delete_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** delete single row from the table: "audit.logged_actions" */
  delete_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** delete data from the table: "auth.rbac_role_acls" */
  delete_auth_rbac_role_acls?: Maybe<Auth_Rbac_Role_Acls_Mutation_Response>;
  /** delete single row from the table: "auth.rbac_role_acls" */
  delete_auth_rbac_role_acls_by_pk?: Maybe<Auth_Rbac_Role_Acls>;
  /** delete data from the table: "auth.rbac_roles" */
  delete_auth_rbac_roles?: Maybe<Auth_Rbac_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.rbac_roles" */
  delete_auth_rbac_roles_by_pk?: Maybe<Auth_Rbac_Roles>;
  /** delete data from the table: "auth.users" */
  delete_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** delete single row from the table: "auth.users" */
  delete_auth_users_by_pk?: Maybe<Auth_Users>;
  /** delete data from the table: "balance_dues" */
  delete_balance_dues?: Maybe<Balance_Dues_Mutation_Response>;
  /** delete single row from the table: "balance_dues" */
  delete_balance_dues_by_pk?: Maybe<Balance_Dues>;
  /** delete data from the table: "bhe_accounts" */
  delete_bhe_accounts?: Maybe<Bhe_Accounts_Mutation_Response>;
  /** delete single row from the table: "bhe_accounts" */
  delete_bhe_accounts_by_pk?: Maybe<Bhe_Accounts>;
  /** delete data from the table: "bhe_invoices" */
  delete_bhe_invoices?: Maybe<Bhe_Invoices_Mutation_Response>;
  /** delete single row from the table: "bhe_invoices" */
  delete_bhe_invoices_by_pk?: Maybe<Bhe_Invoices>;
  /** delete data from the table: "commissions" */
  delete_commissions?: Maybe<Commissions_Mutation_Response>;
  /** delete single row from the table: "commissions" */
  delete_commissions_by_pk?: Maybe<Commissions>;
  /** delete data from the table: "contact_assignments" */
  delete_contact_assignments?: Maybe<Contact_Assignments_Mutation_Response>;
  /** delete single row from the table: "contact_assignments" */
  delete_contact_assignments_by_pk?: Maybe<Contact_Assignments>;
  /** delete data from the table: "contact_entries" */
  delete_contact_entries?: Maybe<Contact_Entries_Mutation_Response>;
  /** delete single row from the table: "contact_entries" */
  delete_contact_entries_by_pk?: Maybe<Contact_Entries>;
  /** delete data from the table: "contacts" */
  delete_contacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  delete_contacts_by_pk?: Maybe<Contacts>;
  /** delete data from the table: "customer_categories" */
  delete_customer_categories?: Maybe<Customer_Categories_Mutation_Response>;
  /** delete single row from the table: "customer_categories" */
  delete_customer_categories_by_pk?: Maybe<Customer_Categories>;
  /** delete data from the table: "customer_groups" */
  delete_customer_groups?: Maybe<Customer_Groups_Mutation_Response>;
  /** delete single row from the table: "customer_groups" */
  delete_customer_groups_by_pk?: Maybe<Customer_Groups>;
  /** delete data from the table: "customers" */
  delete_customers?: Maybe<Customers_Mutation_Response>;
  /** delete single row from the table: "customers" */
  delete_customers_by_pk?: Maybe<Customers>;
  /** delete data from the table: "customers_users" */
  delete_customers_users?: Maybe<Customers_Users_Mutation_Response>;
  /** delete single row from the table: "customers_users" */
  delete_customers_users_by_pk?: Maybe<Customers_Users>;
  /** delete data from the table: "cyborg_queries" */
  delete_cyborg_queries?: Maybe<Cyborg_Queries_Mutation_Response>;
  /** delete single row from the table: "cyborg_queries" */
  delete_cyborg_queries_by_pk?: Maybe<Cyborg_Queries>;
  /** delete data from the table: "deal_detail_volumes" */
  delete_deal_detail_volumes?: Maybe<Deal_Detail_Volumes_Mutation_Response>;
  /** delete single row from the table: "deal_detail_volumes" */
  delete_deal_detail_volumes_by_pk?: Maybe<Deal_Detail_Volumes>;
  /** delete data from the table: "deal_details" */
  delete_deal_details?: Maybe<Deal_Details_Mutation_Response>;
  /** delete single row from the table: "deal_details" */
  delete_deal_details_by_pk?: Maybe<Deal_Details>;
  /** delete data from the table: "dealmakers" */
  delete_dealmakers?: Maybe<Dealmakers_Mutation_Response>;
  /** delete single row from the table: "dealmakers" */
  delete_dealmakers_by_pk?: Maybe<Dealmakers>;
  /** delete data from the table: "deals" */
  delete_deals?: Maybe<Deals_Mutation_Response>;
  /** delete single row from the table: "deals" */
  delete_deals_by_pk?: Maybe<Deals>;
  /** delete data from the table: "email.email_attachments" */
  delete_email_email_attachments?: Maybe<Email_Email_Attachments_Mutation_Response>;
  /** delete single row from the table: "email.email_attachments" */
  delete_email_email_attachments_by_pk?: Maybe<Email_Email_Attachments>;
  /** delete data from the table: "email.emails" */
  delete_email_emails?: Maybe<Email_Emails_Mutation_Response>;
  /** delete single row from the table: "email.emails" */
  delete_email_emails_by_pk?: Maybe<Email_Emails>;
  /** delete data from the table: "fee_types" */
  delete_fee_types?: Maybe<Fee_Types_Mutation_Response>;
  /** delete single row from the table: "fee_types" */
  delete_fee_types_by_pk?: Maybe<Fee_Types>;
  /** delete data from the table: "fees" */
  delete_fees?: Maybe<Fees_Mutation_Response>;
  /** delete single row from the table: "fees" */
  delete_fees_by_pk?: Maybe<Fees>;
  /** delete data from the table: "files.document_render_results" */
  delete_files_document_render_results?: Maybe<Files_Document_Render_Results_Mutation_Response>;
  /** delete single row from the table: "files.document_render_results" */
  delete_files_document_render_results_by_pk?: Maybe<Files_Document_Render_Results>;
  /** delete data from the table: "files.document_template_executions" */
  delete_files_document_template_executions?: Maybe<Files_Document_Template_Executions_Mutation_Response>;
  /** delete single row from the table: "files.document_template_executions" */
  delete_files_document_template_executions_by_pk?: Maybe<Files_Document_Template_Executions>;
  /** delete data from the table: "files.document_templates" */
  delete_files_document_templates?: Maybe<Files_Document_Templates_Mutation_Response>;
  /** delete single row from the table: "files.document_templates" */
  delete_files_document_templates_by_pk?: Maybe<Files_Document_Templates>;
  /** delete data from the table: "files.pdf_render" */
  delete_files_pdf_render?: Maybe<Files_Pdf_Render_Mutation_Response>;
  /** delete single row from the table: "files.pdf_render" */
  delete_files_pdf_render_by_pk?: Maybe<Files_Pdf_Render>;
  /** delete data from the table: "forecast_types" */
  delete_forecast_types?: Maybe<Forecast_Types_Mutation_Response>;
  /** delete single row from the table: "forecast_types" */
  delete_forecast_types_by_pk?: Maybe<Forecast_Types>;
  /** delete data from the table: "group_types" */
  delete_group_types?: Maybe<Group_Types_Mutation_Response>;
  /** delete single row from the table: "group_types" */
  delete_group_types_by_pk?: Maybe<Group_Types>;
  /** delete data from the table: "groups" */
  delete_groups?: Maybe<Groups_Mutation_Response>;
  /** delete single row from the table: "groups" */
  delete_groups_by_pk?: Maybe<Groups>;
  /** delete data from the table: "ind_types" */
  delete_ind_types?: Maybe<Ind_Types_Mutation_Response>;
  /** delete single row from the table: "ind_types" */
  delete_ind_types_by_pk?: Maybe<Ind_Types>;
  /** delete data from the table: "indices" */
  delete_indices?: Maybe<Indices_Mutation_Response>;
  /** delete single row from the table: "indices" */
  delete_indices_by_pk?: Maybe<Indices>;
  /** delete data from the table: "invitations" */
  delete_invitations?: Maybe<Invitations_Mutation_Response>;
  /** delete single row from the table: "invitations" */
  delete_invitations_by_pk?: Maybe<Invitations>;
  /** delete data from the table: "invitations_customers" */
  delete_invitations_customers?: Maybe<Invitations_Customers_Mutation_Response>;
  /** delete single row from the table: "invitations_customers" */
  delete_invitations_customers_by_pk?: Maybe<Invitations_Customers>;
  /** delete data from the table: "invoice_data" */
  delete_invoice_data?: Maybe<Invoice_Data_Mutation_Response>;
  /** delete single row from the table: "invoice_data" */
  delete_invoice_data_by_pk?: Maybe<Invoice_Data>;
  /** delete data from the table: "invoice_numbers" */
  delete_invoice_numbers?: Maybe<Invoice_Numbers_Mutation_Response>;
  /** delete single row from the table: "invoice_numbers" */
  delete_invoice_numbers_by_pk?: Maybe<Invoice_Numbers>;
  /** delete data from the table: "load_forecasts" */
  delete_load_forecasts?: Maybe<Load_Forecasts_Mutation_Response>;
  /** delete single row from the table: "load_forecasts" */
  delete_load_forecasts_by_pk?: Maybe<Load_Forecasts>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "logs" */
  delete_logs?: Maybe<Logs_Mutation_Response>;
  /** delete single row from the table: "logs" */
  delete_logs_by_pk?: Maybe<Logs>;
  /** delete data from the table: "params" */
  delete_params?: Maybe<Params_Mutation_Response>;
  /** delete single row from the table: "params" */
  delete_params_by_pk?: Maybe<Params>;
  /** delete data from the table: "paths" */
  delete_paths?: Maybe<Paths_Mutation_Response>;
  /** delete single row from the table: "paths" */
  delete_paths_by_pk?: Maybe<Paths>;
  /** delete data from the table: "pipelines" */
  delete_pipelines?: Maybe<Pipelines_Mutation_Response>;
  /** delete single row from the table: "pipelines" */
  delete_pipelines_by_pk?: Maybe<Pipelines>;
  /** delete data from the table: "records" */
  delete_records?: Maybe<Records_Mutation_Response>;
  /** delete single row from the table: "records" */
  delete_records_by_pk?: Maybe<Records>;
  /** delete data from the table: "report_dashboard" */
  delete_report_dashboard?: Maybe<Report_Dashboard_Mutation_Response>;
  /** delete single row from the table: "report_dashboard" */
  delete_report_dashboard_by_pk?: Maybe<Report_Dashboard>;
  /** delete data from the table: "risk_types" */
  delete_risk_types?: Maybe<Risk_Types_Mutation_Response>;
  /** delete single row from the table: "risk_types" */
  delete_risk_types_by_pk?: Maybe<Risk_Types>;
  /** delete data from the table: "users_data" */
  delete_users_data?: Maybe<Users_Data_Mutation_Response>;
  /** delete single row from the table: "users_data" */
  delete_users_data_by_pk?: Maybe<Users_Data>;
  delete_wrec_meeting?: Maybe<Wrec_Meeting_Mutation_Response>;
  /** delete single row from the table: "wrec.meeting" */
  delete_wrec_meeting_by_pk?: Maybe<Wrec_Meeting>;
  /** insert data into the table: "ach_queues" */
  insert_ach_queues?: Maybe<Ach_Queues_Mutation_Response>;
  /** insert a single row into the table: "ach_queues" */
  insert_ach_queues_one?: Maybe<Ach_Queues>;
  /** insert data into the table: "active_storage_blobs" */
  insert_active_storage_blobs?: Maybe<Active_Storage_Blobs_Mutation_Response>;
  /** insert a single row into the table: "active_storage_blobs" */
  insert_active_storage_blobs_one?: Maybe<Active_Storage_Blobs>;
  /** insert data into the table: "addresses" */
  insert_addresses?: Maybe<Addresses_Mutation_Response>;
  /** insert a single row into the table: "addresses" */
  insert_addresses_one?: Maybe<Addresses>;
  /** insert data into the table: "audit.logged_actions" */
  insert_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** insert a single row into the table: "audit.logged_actions" */
  insert_audit_logged_actions_one?: Maybe<Audit_Logged_Actions>;
  /** insert data into the table: "auth.rbac_role_acls" */
  insert_auth_rbac_role_acls?: Maybe<Auth_Rbac_Role_Acls_Mutation_Response>;
  /** insert a single row into the table: "auth.rbac_role_acls" */
  insert_auth_rbac_role_acls_one?: Maybe<Auth_Rbac_Role_Acls>;
  /** insert data into the table: "auth.rbac_roles" */
  insert_auth_rbac_roles?: Maybe<Auth_Rbac_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.rbac_roles" */
  insert_auth_rbac_roles_one?: Maybe<Auth_Rbac_Roles>;
  /** insert data into the table: "auth.users" */
  insert_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** insert a single row into the table: "auth.users" */
  insert_auth_users_one?: Maybe<Auth_Users>;
  /** insert data into the table: "balance_dues" */
  insert_balance_dues?: Maybe<Balance_Dues_Mutation_Response>;
  /** insert a single row into the table: "balance_dues" */
  insert_balance_dues_one?: Maybe<Balance_Dues>;
  /** insert data into the table: "bhe_accounts" */
  insert_bhe_accounts?: Maybe<Bhe_Accounts_Mutation_Response>;
  /** insert a single row into the table: "bhe_accounts" */
  insert_bhe_accounts_one?: Maybe<Bhe_Accounts>;
  /** insert data into the table: "bhe_invoices" */
  insert_bhe_invoices?: Maybe<Bhe_Invoices_Mutation_Response>;
  /** insert a single row into the table: "bhe_invoices" */
  insert_bhe_invoices_one?: Maybe<Bhe_Invoices>;
  /** insert data into the table: "commissions" */
  insert_commissions?: Maybe<Commissions_Mutation_Response>;
  /** insert a single row into the table: "commissions" */
  insert_commissions_one?: Maybe<Commissions>;
  /** insert data into the table: "contact_assignments" */
  insert_contact_assignments?: Maybe<Contact_Assignments_Mutation_Response>;
  /** insert a single row into the table: "contact_assignments" */
  insert_contact_assignments_one?: Maybe<Contact_Assignments>;
  /** insert data into the table: "contact_entries" */
  insert_contact_entries?: Maybe<Contact_Entries_Mutation_Response>;
  /** insert a single row into the table: "contact_entries" */
  insert_contact_entries_one?: Maybe<Contact_Entries>;
  /** insert data into the table: "contacts" */
  insert_contacts?: Maybe<Contacts_Mutation_Response>;
  /** insert a single row into the table: "contacts" */
  insert_contacts_one?: Maybe<Contacts>;
  /** insert data into the table: "customer_categories" */
  insert_customer_categories?: Maybe<Customer_Categories_Mutation_Response>;
  /** insert a single row into the table: "customer_categories" */
  insert_customer_categories_one?: Maybe<Customer_Categories>;
  /** insert data into the table: "customer_groups" */
  insert_customer_groups?: Maybe<Customer_Groups_Mutation_Response>;
  /** insert a single row into the table: "customer_groups" */
  insert_customer_groups_one?: Maybe<Customer_Groups>;
  /** insert data into the table: "customers" */
  insert_customers?: Maybe<Customers_Mutation_Response>;
  /** insert a single row into the table: "customers" */
  insert_customers_one?: Maybe<Customers>;
  /** insert data into the table: "customers_users" */
  insert_customers_users?: Maybe<Customers_Users_Mutation_Response>;
  /** insert a single row into the table: "customers_users" */
  insert_customers_users_one?: Maybe<Customers_Users>;
  /** insert data into the table: "cyborg_queries" */
  insert_cyborg_queries?: Maybe<Cyborg_Queries_Mutation_Response>;
  /** insert a single row into the table: "cyborg_queries" */
  insert_cyborg_queries_one?: Maybe<Cyborg_Queries>;
  /** insert data into the table: "deal_detail_volumes" */
  insert_deal_detail_volumes?: Maybe<Deal_Detail_Volumes_Mutation_Response>;
  /** insert a single row into the table: "deal_detail_volumes" */
  insert_deal_detail_volumes_one?: Maybe<Deal_Detail_Volumes>;
  /** insert data into the table: "deal_details" */
  insert_deal_details?: Maybe<Deal_Details_Mutation_Response>;
  /** insert a single row into the table: "deal_details" */
  insert_deal_details_one?: Maybe<Deal_Details>;
  /** insert data into the table: "dealmakers" */
  insert_dealmakers?: Maybe<Dealmakers_Mutation_Response>;
  /** insert a single row into the table: "dealmakers" */
  insert_dealmakers_one?: Maybe<Dealmakers>;
  /** insert data into the table: "deals" */
  insert_deals?: Maybe<Deals_Mutation_Response>;
  /** insert a single row into the table: "deals" */
  insert_deals_one?: Maybe<Deals>;
  /** insert data into the table: "email.email_attachments" */
  insert_email_email_attachments?: Maybe<Email_Email_Attachments_Mutation_Response>;
  /** insert a single row into the table: "email.email_attachments" */
  insert_email_email_attachments_one?: Maybe<Email_Email_Attachments>;
  /** insert data into the table: "email.emails" */
  insert_email_emails?: Maybe<Email_Emails_Mutation_Response>;
  /** insert a single row into the table: "email.emails" */
  insert_email_emails_one?: Maybe<Email_Emails>;
  /** insert data into the table: "fee_types" */
  insert_fee_types?: Maybe<Fee_Types_Mutation_Response>;
  /** insert a single row into the table: "fee_types" */
  insert_fee_types_one?: Maybe<Fee_Types>;
  /** insert data into the table: "fees" */
  insert_fees?: Maybe<Fees_Mutation_Response>;
  /** insert a single row into the table: "fees" */
  insert_fees_one?: Maybe<Fees>;
  /** insert data into the table: "files.document_render_results" */
  insert_files_document_render_results?: Maybe<Files_Document_Render_Results_Mutation_Response>;
  /** insert a single row into the table: "files.document_render_results" */
  insert_files_document_render_results_one?: Maybe<Files_Document_Render_Results>;
  /** insert data into the table: "files.document_template_executions" */
  insert_files_document_template_executions?: Maybe<Files_Document_Template_Executions_Mutation_Response>;
  /** insert a single row into the table: "files.document_template_executions" */
  insert_files_document_template_executions_one?: Maybe<Files_Document_Template_Executions>;
  /** insert data into the table: "files.document_templates" */
  insert_files_document_templates?: Maybe<Files_Document_Templates_Mutation_Response>;
  /** insert a single row into the table: "files.document_templates" */
  insert_files_document_templates_one?: Maybe<Files_Document_Templates>;
  /** insert data into the table: "files.pdf_render" */
  insert_files_pdf_render?: Maybe<Files_Pdf_Render_Mutation_Response>;
  /** insert a single row into the table: "files.pdf_render" */
  insert_files_pdf_render_one?: Maybe<Files_Pdf_Render>;
  /** insert data into the table: "forecast_types" */
  insert_forecast_types?: Maybe<Forecast_Types_Mutation_Response>;
  /** insert a single row into the table: "forecast_types" */
  insert_forecast_types_one?: Maybe<Forecast_Types>;
  /** insert data into the table: "group_types" */
  insert_group_types?: Maybe<Group_Types_Mutation_Response>;
  /** insert a single row into the table: "group_types" */
  insert_group_types_one?: Maybe<Group_Types>;
  /** insert data into the table: "groups" */
  insert_groups?: Maybe<Groups_Mutation_Response>;
  /** insert a single row into the table: "groups" */
  insert_groups_one?: Maybe<Groups>;
  /** insert data into the table: "ind_types" */
  insert_ind_types?: Maybe<Ind_Types_Mutation_Response>;
  /** insert a single row into the table: "ind_types" */
  insert_ind_types_one?: Maybe<Ind_Types>;
  /** insert data into the table: "indices" */
  insert_indices?: Maybe<Indices_Mutation_Response>;
  /** insert a single row into the table: "indices" */
  insert_indices_one?: Maybe<Indices>;
  /** insert data into the table: "invitations" */
  insert_invitations?: Maybe<Invitations_Mutation_Response>;
  /** insert data into the table: "invitations_customers" */
  insert_invitations_customers?: Maybe<Invitations_Customers_Mutation_Response>;
  /** insert a single row into the table: "invitations_customers" */
  insert_invitations_customers_one?: Maybe<Invitations_Customers>;
  /** insert a single row into the table: "invitations" */
  insert_invitations_one?: Maybe<Invitations>;
  /** insert data into the table: "invoice_data" */
  insert_invoice_data?: Maybe<Invoice_Data_Mutation_Response>;
  /** insert a single row into the table: "invoice_data" */
  insert_invoice_data_one?: Maybe<Invoice_Data>;
  /** insert data into the table: "invoice_numbers" */
  insert_invoice_numbers?: Maybe<Invoice_Numbers_Mutation_Response>;
  /** insert a single row into the table: "invoice_numbers" */
  insert_invoice_numbers_one?: Maybe<Invoice_Numbers>;
  /** insert data into the table: "load_forecasts" */
  insert_load_forecasts?: Maybe<Load_Forecasts_Mutation_Response>;
  /** insert a single row into the table: "load_forecasts" */
  insert_load_forecasts_one?: Maybe<Load_Forecasts>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "logs" */
  insert_logs?: Maybe<Logs_Mutation_Response>;
  /** insert a single row into the table: "logs" */
  insert_logs_one?: Maybe<Logs>;
  /** insert data into the table: "params" */
  insert_params?: Maybe<Params_Mutation_Response>;
  /** insert a single row into the table: "params" */
  insert_params_one?: Maybe<Params>;
  /** insert data into the table: "paths" */
  insert_paths?: Maybe<Paths_Mutation_Response>;
  /** insert a single row into the table: "paths" */
  insert_paths_one?: Maybe<Paths>;
  /** insert data into the table: "pipelines" */
  insert_pipelines?: Maybe<Pipelines_Mutation_Response>;
  /** insert a single row into the table: "pipelines" */
  insert_pipelines_one?: Maybe<Pipelines>;
  /** insert data into the table: "records" */
  insert_records?: Maybe<Records_Mutation_Response>;
  /** insert a single row into the table: "records" */
  insert_records_one?: Maybe<Records>;
  /** insert data into the table: "report_dashboard" */
  insert_report_dashboard?: Maybe<Report_Dashboard_Mutation_Response>;
  /** insert a single row into the table: "report_dashboard" */
  insert_report_dashboard_one?: Maybe<Report_Dashboard>;
  /** insert data into the table: "risk_types" */
  insert_risk_types?: Maybe<Risk_Types_Mutation_Response>;
  /** insert a single row into the table: "risk_types" */
  insert_risk_types_one?: Maybe<Risk_Types>;
  /** insert data into the table: "users_data" */
  insert_users_data?: Maybe<Users_Data_Mutation_Response>;
  /** insert a single row into the table: "users_data" */
  insert_users_data_one?: Maybe<Users_Data>;
  insert_wrec_meeting?: Maybe<Wrec_Meeting_Mutation_Response>;
  /** insert a single row into the table: "wrec.meeting" */
  insert_wrec_meeting_one?: Maybe<Wrec_Meeting>;
  setCombinedUserById?: Maybe<CombinedUser>;
  updateAchInfo?: Maybe<Scalars['String']>;
  /** update data of the table: "ach_queues" */
  update_ach_queues?: Maybe<Ach_Queues_Mutation_Response>;
  /** update single row of the table: "ach_queues" */
  update_ach_queues_by_pk?: Maybe<Ach_Queues>;
  /** update multiples rows of table: "ach_queues" */
  update_ach_queues_many?: Maybe<Array<Maybe<Ach_Queues_Mutation_Response>>>;
  /** update data of the table: "active_storage_blobs" */
  update_active_storage_blobs?: Maybe<Active_Storage_Blobs_Mutation_Response>;
  /** update single row of the table: "active_storage_blobs" */
  update_active_storage_blobs_by_pk?: Maybe<Active_Storage_Blobs>;
  /** update multiples rows of table: "active_storage_blobs" */
  update_active_storage_blobs_many?: Maybe<Array<Maybe<Active_Storage_Blobs_Mutation_Response>>>;
  /** update data of the table: "addresses" */
  update_addresses?: Maybe<Addresses_Mutation_Response>;
  /** update single row of the table: "addresses" */
  update_addresses_by_pk?: Maybe<Addresses>;
  /** update multiples rows of table: "addresses" */
  update_addresses_many?: Maybe<Array<Maybe<Addresses_Mutation_Response>>>;
  /** update data of the table: "audit.logged_actions" */
  update_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** update single row of the table: "audit.logged_actions" */
  update_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** update multiples rows of table: "audit.logged_actions" */
  update_audit_logged_actions_many?: Maybe<Array<Maybe<Audit_Logged_Actions_Mutation_Response>>>;
  /** update data of the table: "auth.rbac_role_acls" */
  update_auth_rbac_role_acls?: Maybe<Auth_Rbac_Role_Acls_Mutation_Response>;
  /** update single row of the table: "auth.rbac_role_acls" */
  update_auth_rbac_role_acls_by_pk?: Maybe<Auth_Rbac_Role_Acls>;
  /** update multiples rows of table: "auth.rbac_role_acls" */
  update_auth_rbac_role_acls_many?: Maybe<Array<Maybe<Auth_Rbac_Role_Acls_Mutation_Response>>>;
  /** update data of the table: "auth.rbac_roles" */
  update_auth_rbac_roles?: Maybe<Auth_Rbac_Roles_Mutation_Response>;
  /** update single row of the table: "auth.rbac_roles" */
  update_auth_rbac_roles_by_pk?: Maybe<Auth_Rbac_Roles>;
  /** update multiples rows of table: "auth.rbac_roles" */
  update_auth_rbac_roles_many?: Maybe<Array<Maybe<Auth_Rbac_Roles_Mutation_Response>>>;
  /** update data of the table: "auth.users" */
  update_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** update single row of the table: "auth.users" */
  update_auth_users_by_pk?: Maybe<Auth_Users>;
  /** update multiples rows of table: "auth.users" */
  update_auth_users_many?: Maybe<Array<Maybe<Auth_Users_Mutation_Response>>>;
  /** update data of the table: "balance_dues" */
  update_balance_dues?: Maybe<Balance_Dues_Mutation_Response>;
  /** update single row of the table: "balance_dues" */
  update_balance_dues_by_pk?: Maybe<Balance_Dues>;
  /** update multiples rows of table: "balance_dues" */
  update_balance_dues_many?: Maybe<Array<Maybe<Balance_Dues_Mutation_Response>>>;
  /** update data of the table: "bhe_accounts" */
  update_bhe_accounts?: Maybe<Bhe_Accounts_Mutation_Response>;
  /** update single row of the table: "bhe_accounts" */
  update_bhe_accounts_by_pk?: Maybe<Bhe_Accounts>;
  /** update multiples rows of table: "bhe_accounts" */
  update_bhe_accounts_many?: Maybe<Array<Maybe<Bhe_Accounts_Mutation_Response>>>;
  /** update data of the table: "bhe_invoices" */
  update_bhe_invoices?: Maybe<Bhe_Invoices_Mutation_Response>;
  /** update single row of the table: "bhe_invoices" */
  update_bhe_invoices_by_pk?: Maybe<Bhe_Invoices>;
  /** update multiples rows of table: "bhe_invoices" */
  update_bhe_invoices_many?: Maybe<Array<Maybe<Bhe_Invoices_Mutation_Response>>>;
  /** update data of the table: "commissions" */
  update_commissions?: Maybe<Commissions_Mutation_Response>;
  /** update single row of the table: "commissions" */
  update_commissions_by_pk?: Maybe<Commissions>;
  /** update multiples rows of table: "commissions" */
  update_commissions_many?: Maybe<Array<Maybe<Commissions_Mutation_Response>>>;
  /** update data of the table: "contact_assignments" */
  update_contact_assignments?: Maybe<Contact_Assignments_Mutation_Response>;
  /** update single row of the table: "contact_assignments" */
  update_contact_assignments_by_pk?: Maybe<Contact_Assignments>;
  /** update multiples rows of table: "contact_assignments" */
  update_contact_assignments_many?: Maybe<Array<Maybe<Contact_Assignments_Mutation_Response>>>;
  /** update data of the table: "contact_entries" */
  update_contact_entries?: Maybe<Contact_Entries_Mutation_Response>;
  /** update single row of the table: "contact_entries" */
  update_contact_entries_by_pk?: Maybe<Contact_Entries>;
  /** update multiples rows of table: "contact_entries" */
  update_contact_entries_many?: Maybe<Array<Maybe<Contact_Entries_Mutation_Response>>>;
  /** update data of the table: "contacts" */
  update_contacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "contacts" */
  update_contacts_by_pk?: Maybe<Contacts>;
  /** update multiples rows of table: "contacts" */
  update_contacts_many?: Maybe<Array<Maybe<Contacts_Mutation_Response>>>;
  /** update data of the table: "customer_categories" */
  update_customer_categories?: Maybe<Customer_Categories_Mutation_Response>;
  /** update single row of the table: "customer_categories" */
  update_customer_categories_by_pk?: Maybe<Customer_Categories>;
  /** update multiples rows of table: "customer_categories" */
  update_customer_categories_many?: Maybe<Array<Maybe<Customer_Categories_Mutation_Response>>>;
  /** update data of the table: "customer_groups" */
  update_customer_groups?: Maybe<Customer_Groups_Mutation_Response>;
  /** update single row of the table: "customer_groups" */
  update_customer_groups_by_pk?: Maybe<Customer_Groups>;
  /** update multiples rows of table: "customer_groups" */
  update_customer_groups_many?: Maybe<Array<Maybe<Customer_Groups_Mutation_Response>>>;
  /** update data of the table: "customers" */
  update_customers?: Maybe<Customers_Mutation_Response>;
  /** update single row of the table: "customers" */
  update_customers_by_pk?: Maybe<Customers>;
  /** update multiples rows of table: "customers" */
  update_customers_many?: Maybe<Array<Maybe<Customers_Mutation_Response>>>;
  /** update data of the table: "customers_users" */
  update_customers_users?: Maybe<Customers_Users_Mutation_Response>;
  /** update single row of the table: "customers_users" */
  update_customers_users_by_pk?: Maybe<Customers_Users>;
  /** update multiples rows of table: "customers_users" */
  update_customers_users_many?: Maybe<Array<Maybe<Customers_Users_Mutation_Response>>>;
  /** update data of the table: "cyborg_queries" */
  update_cyborg_queries?: Maybe<Cyborg_Queries_Mutation_Response>;
  /** update single row of the table: "cyborg_queries" */
  update_cyborg_queries_by_pk?: Maybe<Cyborg_Queries>;
  /** update multiples rows of table: "cyborg_queries" */
  update_cyborg_queries_many?: Maybe<Array<Maybe<Cyborg_Queries_Mutation_Response>>>;
  /** update data of the table: "deal_detail_volumes" */
  update_deal_detail_volumes?: Maybe<Deal_Detail_Volumes_Mutation_Response>;
  /** update single row of the table: "deal_detail_volumes" */
  update_deal_detail_volumes_by_pk?: Maybe<Deal_Detail_Volumes>;
  /** update multiples rows of table: "deal_detail_volumes" */
  update_deal_detail_volumes_many?: Maybe<Array<Maybe<Deal_Detail_Volumes_Mutation_Response>>>;
  /** update data of the table: "deal_details" */
  update_deal_details?: Maybe<Deal_Details_Mutation_Response>;
  /** update single row of the table: "deal_details" */
  update_deal_details_by_pk?: Maybe<Deal_Details>;
  /** update multiples rows of table: "deal_details" */
  update_deal_details_many?: Maybe<Array<Maybe<Deal_Details_Mutation_Response>>>;
  /** update data of the table: "dealmakers" */
  update_dealmakers?: Maybe<Dealmakers_Mutation_Response>;
  /** update single row of the table: "dealmakers" */
  update_dealmakers_by_pk?: Maybe<Dealmakers>;
  /** update multiples rows of table: "dealmakers" */
  update_dealmakers_many?: Maybe<Array<Maybe<Dealmakers_Mutation_Response>>>;
  /** update data of the table: "deals" */
  update_deals?: Maybe<Deals_Mutation_Response>;
  /** update single row of the table: "deals" */
  update_deals_by_pk?: Maybe<Deals>;
  /** update multiples rows of table: "deals" */
  update_deals_many?: Maybe<Array<Maybe<Deals_Mutation_Response>>>;
  /** update data of the table: "email.email_attachments" */
  update_email_email_attachments?: Maybe<Email_Email_Attachments_Mutation_Response>;
  /** update single row of the table: "email.email_attachments" */
  update_email_email_attachments_by_pk?: Maybe<Email_Email_Attachments>;
  /** update multiples rows of table: "email.email_attachments" */
  update_email_email_attachments_many?: Maybe<Array<Maybe<Email_Email_Attachments_Mutation_Response>>>;
  /** update data of the table: "email.emails" */
  update_email_emails?: Maybe<Email_Emails_Mutation_Response>;
  /** update single row of the table: "email.emails" */
  update_email_emails_by_pk?: Maybe<Email_Emails>;
  /** update multiples rows of table: "email.emails" */
  update_email_emails_many?: Maybe<Array<Maybe<Email_Emails_Mutation_Response>>>;
  /** update data of the table: "fee_types" */
  update_fee_types?: Maybe<Fee_Types_Mutation_Response>;
  /** update single row of the table: "fee_types" */
  update_fee_types_by_pk?: Maybe<Fee_Types>;
  /** update multiples rows of table: "fee_types" */
  update_fee_types_many?: Maybe<Array<Maybe<Fee_Types_Mutation_Response>>>;
  /** update data of the table: "fees" */
  update_fees?: Maybe<Fees_Mutation_Response>;
  /** update single row of the table: "fees" */
  update_fees_by_pk?: Maybe<Fees>;
  /** update multiples rows of table: "fees" */
  update_fees_many?: Maybe<Array<Maybe<Fees_Mutation_Response>>>;
  /** update data of the table: "files.document_render_results" */
  update_files_document_render_results?: Maybe<Files_Document_Render_Results_Mutation_Response>;
  /** update single row of the table: "files.document_render_results" */
  update_files_document_render_results_by_pk?: Maybe<Files_Document_Render_Results>;
  /** update multiples rows of table: "files.document_render_results" */
  update_files_document_render_results_many?: Maybe<Array<Maybe<Files_Document_Render_Results_Mutation_Response>>>;
  /** update data of the table: "files.document_template_executions" */
  update_files_document_template_executions?: Maybe<Files_Document_Template_Executions_Mutation_Response>;
  /** update single row of the table: "files.document_template_executions" */
  update_files_document_template_executions_by_pk?: Maybe<Files_Document_Template_Executions>;
  /** update multiples rows of table: "files.document_template_executions" */
  update_files_document_template_executions_many?: Maybe<Array<Maybe<Files_Document_Template_Executions_Mutation_Response>>>;
  /** update data of the table: "files.document_templates" */
  update_files_document_templates?: Maybe<Files_Document_Templates_Mutation_Response>;
  /** update single row of the table: "files.document_templates" */
  update_files_document_templates_by_pk?: Maybe<Files_Document_Templates>;
  /** update multiples rows of table: "files.document_templates" */
  update_files_document_templates_many?: Maybe<Array<Maybe<Files_Document_Templates_Mutation_Response>>>;
  /** update data of the table: "files.pdf_render" */
  update_files_pdf_render?: Maybe<Files_Pdf_Render_Mutation_Response>;
  /** update single row of the table: "files.pdf_render" */
  update_files_pdf_render_by_pk?: Maybe<Files_Pdf_Render>;
  /** update multiples rows of table: "files.pdf_render" */
  update_files_pdf_render_many?: Maybe<Array<Maybe<Files_Pdf_Render_Mutation_Response>>>;
  /** update data of the table: "forecast_types" */
  update_forecast_types?: Maybe<Forecast_Types_Mutation_Response>;
  /** update single row of the table: "forecast_types" */
  update_forecast_types_by_pk?: Maybe<Forecast_Types>;
  /** update multiples rows of table: "forecast_types" */
  update_forecast_types_many?: Maybe<Array<Maybe<Forecast_Types_Mutation_Response>>>;
  /** update data of the table: "group_types" */
  update_group_types?: Maybe<Group_Types_Mutation_Response>;
  /** update single row of the table: "group_types" */
  update_group_types_by_pk?: Maybe<Group_Types>;
  /** update multiples rows of table: "group_types" */
  update_group_types_many?: Maybe<Array<Maybe<Group_Types_Mutation_Response>>>;
  /** update data of the table: "groups" */
  update_groups?: Maybe<Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_groups_by_pk?: Maybe<Groups>;
  /** update multiples rows of table: "groups" */
  update_groups_many?: Maybe<Array<Maybe<Groups_Mutation_Response>>>;
  /** update data of the table: "ind_types" */
  update_ind_types?: Maybe<Ind_Types_Mutation_Response>;
  /** update single row of the table: "ind_types" */
  update_ind_types_by_pk?: Maybe<Ind_Types>;
  /** update multiples rows of table: "ind_types" */
  update_ind_types_many?: Maybe<Array<Maybe<Ind_Types_Mutation_Response>>>;
  /** update data of the table: "indices" */
  update_indices?: Maybe<Indices_Mutation_Response>;
  /** update single row of the table: "indices" */
  update_indices_by_pk?: Maybe<Indices>;
  /** update multiples rows of table: "indices" */
  update_indices_many?: Maybe<Array<Maybe<Indices_Mutation_Response>>>;
  /** update data of the table: "invitations" */
  update_invitations?: Maybe<Invitations_Mutation_Response>;
  /** update single row of the table: "invitations" */
  update_invitations_by_pk?: Maybe<Invitations>;
  /** update data of the table: "invitations_customers" */
  update_invitations_customers?: Maybe<Invitations_Customers_Mutation_Response>;
  /** update single row of the table: "invitations_customers" */
  update_invitations_customers_by_pk?: Maybe<Invitations_Customers>;
  /** update multiples rows of table: "invitations_customers" */
  update_invitations_customers_many?: Maybe<Array<Maybe<Invitations_Customers_Mutation_Response>>>;
  /** update multiples rows of table: "invitations" */
  update_invitations_many?: Maybe<Array<Maybe<Invitations_Mutation_Response>>>;
  /** update data of the table: "invoice_data" */
  update_invoice_data?: Maybe<Invoice_Data_Mutation_Response>;
  /** update single row of the table: "invoice_data" */
  update_invoice_data_by_pk?: Maybe<Invoice_Data>;
  /** update multiples rows of table: "invoice_data" */
  update_invoice_data_many?: Maybe<Array<Maybe<Invoice_Data_Mutation_Response>>>;
  /** update data of the table: "invoice_numbers" */
  update_invoice_numbers?: Maybe<Invoice_Numbers_Mutation_Response>;
  /** update single row of the table: "invoice_numbers" */
  update_invoice_numbers_by_pk?: Maybe<Invoice_Numbers>;
  /** update multiples rows of table: "invoice_numbers" */
  update_invoice_numbers_many?: Maybe<Array<Maybe<Invoice_Numbers_Mutation_Response>>>;
  /** update data of the table: "load_forecasts" */
  update_load_forecasts?: Maybe<Load_Forecasts_Mutation_Response>;
  /** update single row of the table: "load_forecasts" */
  update_load_forecasts_by_pk?: Maybe<Load_Forecasts>;
  /** update multiples rows of table: "load_forecasts" */
  update_load_forecasts_many?: Maybe<Array<Maybe<Load_Forecasts_Mutation_Response>>>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update multiples rows of table: "locations" */
  update_locations_many?: Maybe<Array<Maybe<Locations_Mutation_Response>>>;
  /** update data of the table: "logs" */
  update_logs?: Maybe<Logs_Mutation_Response>;
  /** update single row of the table: "logs" */
  update_logs_by_pk?: Maybe<Logs>;
  /** update multiples rows of table: "logs" */
  update_logs_many?: Maybe<Array<Maybe<Logs_Mutation_Response>>>;
  /** update data of the table: "params" */
  update_params?: Maybe<Params_Mutation_Response>;
  /** update single row of the table: "params" */
  update_params_by_pk?: Maybe<Params>;
  /** update multiples rows of table: "params" */
  update_params_many?: Maybe<Array<Maybe<Params_Mutation_Response>>>;
  /** update data of the table: "paths" */
  update_paths?: Maybe<Paths_Mutation_Response>;
  /** update single row of the table: "paths" */
  update_paths_by_pk?: Maybe<Paths>;
  /** update multiples rows of table: "paths" */
  update_paths_many?: Maybe<Array<Maybe<Paths_Mutation_Response>>>;
  /** update data of the table: "pipelines" */
  update_pipelines?: Maybe<Pipelines_Mutation_Response>;
  /** update single row of the table: "pipelines" */
  update_pipelines_by_pk?: Maybe<Pipelines>;
  /** update multiples rows of table: "pipelines" */
  update_pipelines_many?: Maybe<Array<Maybe<Pipelines_Mutation_Response>>>;
  /** update data of the table: "records" */
  update_records?: Maybe<Records_Mutation_Response>;
  /** update single row of the table: "records" */
  update_records_by_pk?: Maybe<Records>;
  /** update multiples rows of table: "records" */
  update_records_many?: Maybe<Array<Maybe<Records_Mutation_Response>>>;
  /** update data of the table: "report_dashboard" */
  update_report_dashboard?: Maybe<Report_Dashboard_Mutation_Response>;
  /** update single row of the table: "report_dashboard" */
  update_report_dashboard_by_pk?: Maybe<Report_Dashboard>;
  /** update multiples rows of table: "report_dashboard" */
  update_report_dashboard_many?: Maybe<Array<Maybe<Report_Dashboard_Mutation_Response>>>;
  /** update data of the table: "risk_types" */
  update_risk_types?: Maybe<Risk_Types_Mutation_Response>;
  /** update single row of the table: "risk_types" */
  update_risk_types_by_pk?: Maybe<Risk_Types>;
  /** update multiples rows of table: "risk_types" */
  update_risk_types_many?: Maybe<Array<Maybe<Risk_Types_Mutation_Response>>>;
  /** update data of the table: "users_data" */
  update_users_data?: Maybe<Users_Data_Mutation_Response>;
  /** update single row of the table: "users_data" */
  update_users_data_by_pk?: Maybe<Users_Data>;
  /** update multiples rows of table: "users_data" */
  update_users_data_many?: Maybe<Array<Maybe<Users_Data_Mutation_Response>>>;
  update_wrec_meeting?: Maybe<Wrec_Meeting_Mutation_Response>;
  /** update single row of the table: "wrec.meeting" */
  update_wrec_meeting_by_pk?: Maybe<Wrec_Meeting>;
  /** update multiples rows of table: "wrec.meeting" */
  update_wrec_meeting_many?: Maybe<Array<Maybe<Wrec_Meeting_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootCreatePasswordResetTokenArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ach_QueuesArgs = {
  where: Ach_Queues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ach_Queues_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Active_Storage_BlobsArgs = {
  where: Active_Storage_Blobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Active_Storage_Blobs_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_AddressesArgs = {
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Addresses_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Audit_Logged_ActionsArgs = {
  where: Audit_Logged_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Rbac_Role_AclsArgs = {
  where: Auth_Rbac_Role_Acls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Rbac_Role_Acls_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Rbac_RolesArgs = {
  where: Auth_Rbac_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Rbac_Roles_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_UsersArgs = {
  where: Auth_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Balance_DuesArgs = {
  where: Balance_Dues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Balance_Dues_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Bhe_AccountsArgs = {
  where: Bhe_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bhe_Accounts_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Bhe_InvoicesArgs = {
  where: Bhe_Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bhe_Invoices_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_CommissionsArgs = {
  where: Commissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Commissions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_AssignmentsArgs = {
  where: Contact_Assignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Assignments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_EntriesArgs = {
  where: Contact_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Entries_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ContactsArgs = {
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contacts_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_CategoriesArgs = {
  where: Customer_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Categories_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_GroupsArgs = {
  where: Customer_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_CustomersArgs = {
  where: Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Customers_UsersArgs = {
  where: Customers_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customers_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Cyborg_QueriesArgs = {
  where: Cyborg_Queries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cyborg_Queries_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Deal_Detail_VolumesArgs = {
  where: Deal_Detail_Volumes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deal_Detail_Volumes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Deal_DetailsArgs = {
  where: Deal_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deal_Details_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DealmakersArgs = {
  where: Dealmakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dealmakers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DealsArgs = {
  where: Deals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deals_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Email_AttachmentsArgs = {
  where: Email_Email_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Email_Attachments_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Email_EmailsArgs = {
  where: Email_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Emails_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Fee_TypesArgs = {
  where: Fee_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fee_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FeesArgs = {
  where: Fees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fees_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Files_Document_Render_ResultsArgs = {
  where: Files_Document_Render_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_Document_Render_Results_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Files_Document_Template_ExecutionsArgs = {
  where: Files_Document_Template_Executions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_Document_Template_Executions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Files_Document_TemplatesArgs = {
  where: Files_Document_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_Document_Templates_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Files_Pdf_RenderArgs = {
  where: Files_Pdf_Render_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_Pdf_Render_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Forecast_TypesArgs = {
  where: Forecast_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Forecast_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Group_TypesArgs = {
  where: Group_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Group_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Ind_TypesArgs = {
  where: Ind_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ind_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IndicesArgs = {
  where: Indices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Indices_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InvitationsArgs = {
  where: Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invitations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Invitations_CustomersArgs = {
  where: Invitations_Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invitations_Customers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Invoice_DataArgs = {
  where: Invoice_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_Data_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Invoice_NumbersArgs = {
  where: Invoice_Numbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_Numbers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Load_ForecastsArgs = {
  where: Load_Forecasts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Load_Forecasts_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LogsArgs = {
  where: Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Logs_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ParamsArgs = {
  where: Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Params_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_PathsArgs = {
  where: Paths_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Paths_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PipelinesArgs = {
  where: Pipelines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pipelines_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RecordsArgs = {
  where: Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Records_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Report_DashboardArgs = {
  where: Report_Dashboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Report_Dashboard_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Risk_TypesArgs = {
  where: Risk_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Risk_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Users_DataArgs = {
  where: Users_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Data_By_PkArgs = {
  key: Scalars['String'];
  user_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wrec_MeetingArgs = {
  where: Wrec_Meeting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wrec_Meeting_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Ach_QueuesArgs = {
  objects: Array<Ach_Queues_Insert_Input>;
  on_conflict?: Maybe<Ach_Queues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ach_Queues_OneArgs = {
  object: Ach_Queues_Insert_Input;
  on_conflict?: Maybe<Ach_Queues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Active_Storage_BlobsArgs = {
  objects: Array<Active_Storage_Blobs_Insert_Input>;
  on_conflict?: Maybe<Active_Storage_Blobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Active_Storage_Blobs_OneArgs = {
  object: Active_Storage_Blobs_Insert_Input;
  on_conflict?: Maybe<Active_Storage_Blobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AddressesArgs = {
  objects: Array<Addresses_Insert_Input>;
  on_conflict?: Maybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_OneArgs = {
  object: Addresses_Insert_Input;
  on_conflict?: Maybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_Logged_ActionsArgs = {
  objects: Array<Audit_Logged_Actions_Insert_Input>;
  on_conflict?: Maybe<Audit_Logged_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_Logged_Actions_OneArgs = {
  object: Audit_Logged_Actions_Insert_Input;
  on_conflict?: Maybe<Audit_Logged_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Rbac_Role_AclsArgs = {
  objects: Array<Auth_Rbac_Role_Acls_Insert_Input>;
  on_conflict?: Maybe<Auth_Rbac_Role_Acls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Rbac_Role_Acls_OneArgs = {
  object: Auth_Rbac_Role_Acls_Insert_Input;
  on_conflict?: Maybe<Auth_Rbac_Role_Acls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Rbac_RolesArgs = {
  objects: Array<Auth_Rbac_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Rbac_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Rbac_Roles_OneArgs = {
  object: Auth_Rbac_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Rbac_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_UsersArgs = {
  objects: Array<Auth_Users_Insert_Input>;
  on_conflict?: Maybe<Auth_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Users_OneArgs = {
  object: Auth_Users_Insert_Input;
  on_conflict?: Maybe<Auth_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Balance_DuesArgs = {
  objects: Array<Balance_Dues_Insert_Input>;
  on_conflict?: Maybe<Balance_Dues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Balance_Dues_OneArgs = {
  object: Balance_Dues_Insert_Input;
  on_conflict?: Maybe<Balance_Dues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bhe_AccountsArgs = {
  objects: Array<Bhe_Accounts_Insert_Input>;
  on_conflict?: Maybe<Bhe_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bhe_Accounts_OneArgs = {
  object: Bhe_Accounts_Insert_Input;
  on_conflict?: Maybe<Bhe_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bhe_InvoicesArgs = {
  objects: Array<Bhe_Invoices_Insert_Input>;
  on_conflict?: Maybe<Bhe_Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bhe_Invoices_OneArgs = {
  object: Bhe_Invoices_Insert_Input;
  on_conflict?: Maybe<Bhe_Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommissionsArgs = {
  objects: Array<Commissions_Insert_Input>;
  on_conflict?: Maybe<Commissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commissions_OneArgs = {
  object: Commissions_Insert_Input;
  on_conflict?: Maybe<Commissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_AssignmentsArgs = {
  objects: Array<Contact_Assignments_Insert_Input>;
  on_conflict?: Maybe<Contact_Assignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Assignments_OneArgs = {
  object: Contact_Assignments_Insert_Input;
  on_conflict?: Maybe<Contact_Assignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_EntriesArgs = {
  objects: Array<Contact_Entries_Insert_Input>;
  on_conflict?: Maybe<Contact_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Entries_OneArgs = {
  object: Contact_Entries_Insert_Input;
  on_conflict?: Maybe<Contact_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_OneArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_CategoriesArgs = {
  objects: Array<Customer_Categories_Insert_Input>;
  on_conflict?: Maybe<Customer_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Categories_OneArgs = {
  object: Customer_Categories_Insert_Input;
  on_conflict?: Maybe<Customer_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_GroupsArgs = {
  objects: Array<Customer_Groups_Insert_Input>;
  on_conflict?: Maybe<Customer_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Groups_OneArgs = {
  object: Customer_Groups_Insert_Input;
  on_conflict?: Maybe<Customer_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomersArgs = {
  objects: Array<Customers_Insert_Input>;
  on_conflict?: Maybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customers_OneArgs = {
  object: Customers_Insert_Input;
  on_conflict?: Maybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customers_UsersArgs = {
  objects: Array<Customers_Users_Insert_Input>;
  on_conflict?: Maybe<Customers_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customers_Users_OneArgs = {
  object: Customers_Users_Insert_Input;
  on_conflict?: Maybe<Customers_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cyborg_QueriesArgs = {
  objects: Array<Cyborg_Queries_Insert_Input>;
  on_conflict?: Maybe<Cyborg_Queries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cyborg_Queries_OneArgs = {
  object: Cyborg_Queries_Insert_Input;
  on_conflict?: Maybe<Cyborg_Queries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deal_Detail_VolumesArgs = {
  objects: Array<Deal_Detail_Volumes_Insert_Input>;
  on_conflict?: Maybe<Deal_Detail_Volumes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deal_Detail_Volumes_OneArgs = {
  object: Deal_Detail_Volumes_Insert_Input;
  on_conflict?: Maybe<Deal_Detail_Volumes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deal_DetailsArgs = {
  objects: Array<Deal_Details_Insert_Input>;
  on_conflict?: Maybe<Deal_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deal_Details_OneArgs = {
  object: Deal_Details_Insert_Input;
  on_conflict?: Maybe<Deal_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DealmakersArgs = {
  objects: Array<Dealmakers_Insert_Input>;
  on_conflict?: Maybe<Dealmakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dealmakers_OneArgs = {
  object: Dealmakers_Insert_Input;
  on_conflict?: Maybe<Dealmakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DealsArgs = {
  objects: Array<Deals_Insert_Input>;
  on_conflict?: Maybe<Deals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deals_OneArgs = {
  object: Deals_Insert_Input;
  on_conflict?: Maybe<Deals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Email_AttachmentsArgs = {
  objects: Array<Email_Email_Attachments_Insert_Input>;
  on_conflict?: Maybe<Email_Email_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Email_Attachments_OneArgs = {
  object: Email_Email_Attachments_Insert_Input;
  on_conflict?: Maybe<Email_Email_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_EmailsArgs = {
  objects: Array<Email_Emails_Insert_Input>;
  on_conflict?: Maybe<Email_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Emails_OneArgs = {
  object: Email_Emails_Insert_Input;
  on_conflict?: Maybe<Email_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fee_TypesArgs = {
  objects: Array<Fee_Types_Insert_Input>;
  on_conflict?: Maybe<Fee_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fee_Types_OneArgs = {
  object: Fee_Types_Insert_Input;
  on_conflict?: Maybe<Fee_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeesArgs = {
  objects: Array<Fees_Insert_Input>;
  on_conflict?: Maybe<Fees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fees_OneArgs = {
  object: Fees_Insert_Input;
  on_conflict?: Maybe<Fees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_Document_Render_ResultsArgs = {
  objects: Array<Files_Document_Render_Results_Insert_Input>;
  on_conflict?: Maybe<Files_Document_Render_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_Document_Render_Results_OneArgs = {
  object: Files_Document_Render_Results_Insert_Input;
  on_conflict?: Maybe<Files_Document_Render_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_Document_Template_ExecutionsArgs = {
  objects: Array<Files_Document_Template_Executions_Insert_Input>;
  on_conflict?: Maybe<Files_Document_Template_Executions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_Document_Template_Executions_OneArgs = {
  object: Files_Document_Template_Executions_Insert_Input;
  on_conflict?: Maybe<Files_Document_Template_Executions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_Document_TemplatesArgs = {
  objects: Array<Files_Document_Templates_Insert_Input>;
  on_conflict?: Maybe<Files_Document_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_Document_Templates_OneArgs = {
  object: Files_Document_Templates_Insert_Input;
  on_conflict?: Maybe<Files_Document_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_Pdf_RenderArgs = {
  objects: Array<Files_Pdf_Render_Insert_Input>;
  on_conflict?: Maybe<Files_Pdf_Render_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_Pdf_Render_OneArgs = {
  object: Files_Pdf_Render_Insert_Input;
  on_conflict?: Maybe<Files_Pdf_Render_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Forecast_TypesArgs = {
  objects: Array<Forecast_Types_Insert_Input>;
  on_conflict?: Maybe<Forecast_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Forecast_Types_OneArgs = {
  object: Forecast_Types_Insert_Input;
  on_conflict?: Maybe<Forecast_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_TypesArgs = {
  objects: Array<Group_Types_Insert_Input>;
  on_conflict?: Maybe<Group_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_Types_OneArgs = {
  object: Group_Types_Insert_Input;
  on_conflict?: Maybe<Group_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>;
  on_conflict?: Maybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input;
  on_conflict?: Maybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ind_TypesArgs = {
  objects: Array<Ind_Types_Insert_Input>;
  on_conflict?: Maybe<Ind_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ind_Types_OneArgs = {
  object: Ind_Types_Insert_Input;
  on_conflict?: Maybe<Ind_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IndicesArgs = {
  objects: Array<Indices_Insert_Input>;
  on_conflict?: Maybe<Indices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Indices_OneArgs = {
  object: Indices_Insert_Input;
  on_conflict?: Maybe<Indices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvitationsArgs = {
  objects: Array<Invitations_Insert_Input>;
  on_conflict?: Maybe<Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invitations_CustomersArgs = {
  objects: Array<Invitations_Customers_Insert_Input>;
  on_conflict?: Maybe<Invitations_Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invitations_Customers_OneArgs = {
  object: Invitations_Customers_Insert_Input;
  on_conflict?: Maybe<Invitations_Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invitations_OneArgs = {
  object: Invitations_Insert_Input;
  on_conflict?: Maybe<Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_DataArgs = {
  objects: Array<Invoice_Data_Insert_Input>;
  on_conflict?: Maybe<Invoice_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_Data_OneArgs = {
  object: Invoice_Data_Insert_Input;
  on_conflict?: Maybe<Invoice_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_NumbersArgs = {
  objects: Array<Invoice_Numbers_Insert_Input>;
  on_conflict?: Maybe<Invoice_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_Numbers_OneArgs = {
  object: Invoice_Numbers_Insert_Input;
  on_conflict?: Maybe<Invoice_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Load_ForecastsArgs = {
  objects: Array<Load_Forecasts_Insert_Input>;
  on_conflict?: Maybe<Load_Forecasts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Load_Forecasts_OneArgs = {
  object: Load_Forecasts_Insert_Input;
  on_conflict?: Maybe<Load_Forecasts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LogsArgs = {
  objects: Array<Logs_Insert_Input>;
  on_conflict?: Maybe<Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Logs_OneArgs = {
  object: Logs_Insert_Input;
  on_conflict?: Maybe<Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ParamsArgs = {
  objects: Array<Params_Insert_Input>;
  on_conflict?: Maybe<Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Params_OneArgs = {
  object: Params_Insert_Input;
  on_conflict?: Maybe<Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PathsArgs = {
  objects: Array<Paths_Insert_Input>;
  on_conflict?: Maybe<Paths_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Paths_OneArgs = {
  object: Paths_Insert_Input;
  on_conflict?: Maybe<Paths_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PipelinesArgs = {
  objects: Array<Pipelines_Insert_Input>;
  on_conflict?: Maybe<Pipelines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pipelines_OneArgs = {
  object: Pipelines_Insert_Input;
  on_conflict?: Maybe<Pipelines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RecordsArgs = {
  objects: Array<Records_Insert_Input>;
  on_conflict?: Maybe<Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Records_OneArgs = {
  object: Records_Insert_Input;
  on_conflict?: Maybe<Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_DashboardArgs = {
  objects: Array<Report_Dashboard_Insert_Input>;
  on_conflict?: Maybe<Report_Dashboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_Dashboard_OneArgs = {
  object: Report_Dashboard_Insert_Input;
  on_conflict?: Maybe<Report_Dashboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Risk_TypesArgs = {
  objects: Array<Risk_Types_Insert_Input>;
  on_conflict?: Maybe<Risk_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Risk_Types_OneArgs = {
  object: Risk_Types_Insert_Input;
  on_conflict?: Maybe<Risk_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_DataArgs = {
  objects: Array<Users_Data_Insert_Input>;
  on_conflict?: Maybe<Users_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Data_OneArgs = {
  object: Users_Data_Insert_Input;
  on_conflict?: Maybe<Users_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wrec_MeetingArgs = {
  objects: Array<Wrec_Meeting_Insert_Input>;
  on_conflict?: Maybe<Wrec_Meeting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wrec_Meeting_OneArgs = {
  object: Wrec_Meeting_Insert_Input;
  on_conflict?: Maybe<Wrec_Meeting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootSetCombinedUserByIdArgs = {
  email?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUpdateAchInfoArgs = {
  accountNumber: Scalars['String'];
  accountType: Scalars['String'];
  autoPay: Scalars['Boolean'];
  bankName: Scalars['String'];
  customerId: Scalars['Int'];
  nameOnAccount: Scalars['String'];
  routingNumber: Scalars['String'];
  signatureText: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Ach_QueuesArgs = {
  _append?: Maybe<Ach_Queues_Append_Input>;
  _delete_at_path?: Maybe<Ach_Queues_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ach_Queues_Delete_Elem_Input>;
  _delete_key?: Maybe<Ach_Queues_Delete_Key_Input>;
  _inc?: Maybe<Ach_Queues_Inc_Input>;
  _prepend?: Maybe<Ach_Queues_Prepend_Input>;
  _set?: Maybe<Ach_Queues_Set_Input>;
  where: Ach_Queues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ach_Queues_By_PkArgs = {
  _append?: Maybe<Ach_Queues_Append_Input>;
  _delete_at_path?: Maybe<Ach_Queues_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ach_Queues_Delete_Elem_Input>;
  _delete_key?: Maybe<Ach_Queues_Delete_Key_Input>;
  _inc?: Maybe<Ach_Queues_Inc_Input>;
  _prepend?: Maybe<Ach_Queues_Prepend_Input>;
  _set?: Maybe<Ach_Queues_Set_Input>;
  pk_columns: Ach_Queues_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ach_Queues_ManyArgs = {
  updates: Array<Ach_Queues_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Active_Storage_BlobsArgs = {
  _inc?: Maybe<Active_Storage_Blobs_Inc_Input>;
  _set?: Maybe<Active_Storage_Blobs_Set_Input>;
  where: Active_Storage_Blobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Active_Storage_Blobs_By_PkArgs = {
  _inc?: Maybe<Active_Storage_Blobs_Inc_Input>;
  _set?: Maybe<Active_Storage_Blobs_Set_Input>;
  pk_columns: Active_Storage_Blobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Active_Storage_Blobs_ManyArgs = {
  updates: Array<Active_Storage_Blobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressesArgs = {
  _append?: Maybe<Addresses_Append_Input>;
  _delete_at_path?: Maybe<Addresses_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Addresses_Delete_Elem_Input>;
  _delete_key?: Maybe<Addresses_Delete_Key_Input>;
  _inc?: Maybe<Addresses_Inc_Input>;
  _prepend?: Maybe<Addresses_Prepend_Input>;
  _set?: Maybe<Addresses_Set_Input>;
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_By_PkArgs = {
  _append?: Maybe<Addresses_Append_Input>;
  _delete_at_path?: Maybe<Addresses_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Addresses_Delete_Elem_Input>;
  _delete_key?: Maybe<Addresses_Delete_Key_Input>;
  _inc?: Maybe<Addresses_Inc_Input>;
  _prepend?: Maybe<Addresses_Prepend_Input>;
  _set?: Maybe<Addresses_Set_Input>;
  pk_columns: Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_ManyArgs = {
  updates: Array<Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_ActionsArgs = {
  _append?: Maybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: Maybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: Maybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: Maybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: Maybe<Audit_Logged_Actions_Set_Input>;
  where: Audit_Logged_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_By_PkArgs = {
  _append?: Maybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: Maybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: Maybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: Maybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: Maybe<Audit_Logged_Actions_Set_Input>;
  pk_columns: Audit_Logged_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_ManyArgs = {
  updates: Array<Audit_Logged_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Rbac_Role_AclsArgs = {
  _inc?: Maybe<Auth_Rbac_Role_Acls_Inc_Input>;
  _set?: Maybe<Auth_Rbac_Role_Acls_Set_Input>;
  where: Auth_Rbac_Role_Acls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Rbac_Role_Acls_By_PkArgs = {
  _inc?: Maybe<Auth_Rbac_Role_Acls_Inc_Input>;
  _set?: Maybe<Auth_Rbac_Role_Acls_Set_Input>;
  pk_columns: Auth_Rbac_Role_Acls_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Rbac_Role_Acls_ManyArgs = {
  updates: Array<Auth_Rbac_Role_Acls_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Rbac_RolesArgs = {
  _inc?: Maybe<Auth_Rbac_Roles_Inc_Input>;
  _set?: Maybe<Auth_Rbac_Roles_Set_Input>;
  where: Auth_Rbac_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Rbac_Roles_By_PkArgs = {
  _inc?: Maybe<Auth_Rbac_Roles_Inc_Input>;
  _set?: Maybe<Auth_Rbac_Roles_Set_Input>;
  pk_columns: Auth_Rbac_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Rbac_Roles_ManyArgs = {
  updates: Array<Auth_Rbac_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_UsersArgs = {
  _inc?: Maybe<Auth_Users_Inc_Input>;
  _set?: Maybe<Auth_Users_Set_Input>;
  where: Auth_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Users_By_PkArgs = {
  _inc?: Maybe<Auth_Users_Inc_Input>;
  _set?: Maybe<Auth_Users_Set_Input>;
  pk_columns: Auth_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Users_ManyArgs = {
  updates: Array<Auth_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Balance_DuesArgs = {
  _inc?: Maybe<Balance_Dues_Inc_Input>;
  _set?: Maybe<Balance_Dues_Set_Input>;
  where: Balance_Dues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Balance_Dues_By_PkArgs = {
  _inc?: Maybe<Balance_Dues_Inc_Input>;
  _set?: Maybe<Balance_Dues_Set_Input>;
  pk_columns: Balance_Dues_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Balance_Dues_ManyArgs = {
  updates: Array<Balance_Dues_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bhe_AccountsArgs = {
  _append?: Maybe<Bhe_Accounts_Append_Input>;
  _delete_at_path?: Maybe<Bhe_Accounts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Bhe_Accounts_Delete_Elem_Input>;
  _delete_key?: Maybe<Bhe_Accounts_Delete_Key_Input>;
  _inc?: Maybe<Bhe_Accounts_Inc_Input>;
  _prepend?: Maybe<Bhe_Accounts_Prepend_Input>;
  _set?: Maybe<Bhe_Accounts_Set_Input>;
  where: Bhe_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bhe_Accounts_By_PkArgs = {
  _append?: Maybe<Bhe_Accounts_Append_Input>;
  _delete_at_path?: Maybe<Bhe_Accounts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Bhe_Accounts_Delete_Elem_Input>;
  _delete_key?: Maybe<Bhe_Accounts_Delete_Key_Input>;
  _inc?: Maybe<Bhe_Accounts_Inc_Input>;
  _prepend?: Maybe<Bhe_Accounts_Prepend_Input>;
  _set?: Maybe<Bhe_Accounts_Set_Input>;
  pk_columns: Bhe_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bhe_Accounts_ManyArgs = {
  updates: Array<Bhe_Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bhe_InvoicesArgs = {
  _append?: Maybe<Bhe_Invoices_Append_Input>;
  _delete_at_path?: Maybe<Bhe_Invoices_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Bhe_Invoices_Delete_Elem_Input>;
  _delete_key?: Maybe<Bhe_Invoices_Delete_Key_Input>;
  _inc?: Maybe<Bhe_Invoices_Inc_Input>;
  _prepend?: Maybe<Bhe_Invoices_Prepend_Input>;
  _set?: Maybe<Bhe_Invoices_Set_Input>;
  where: Bhe_Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bhe_Invoices_By_PkArgs = {
  _append?: Maybe<Bhe_Invoices_Append_Input>;
  _delete_at_path?: Maybe<Bhe_Invoices_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Bhe_Invoices_Delete_Elem_Input>;
  _delete_key?: Maybe<Bhe_Invoices_Delete_Key_Input>;
  _inc?: Maybe<Bhe_Invoices_Inc_Input>;
  _prepend?: Maybe<Bhe_Invoices_Prepend_Input>;
  _set?: Maybe<Bhe_Invoices_Set_Input>;
  pk_columns: Bhe_Invoices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bhe_Invoices_ManyArgs = {
  updates: Array<Bhe_Invoices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommissionsArgs = {
  _inc?: Maybe<Commissions_Inc_Input>;
  _set?: Maybe<Commissions_Set_Input>;
  where: Commissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Commissions_By_PkArgs = {
  _inc?: Maybe<Commissions_Inc_Input>;
  _set?: Maybe<Commissions_Set_Input>;
  pk_columns: Commissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Commissions_ManyArgs = {
  updates: Array<Commissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_AssignmentsArgs = {
  _inc?: Maybe<Contact_Assignments_Inc_Input>;
  _set?: Maybe<Contact_Assignments_Set_Input>;
  where: Contact_Assignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Assignments_By_PkArgs = {
  _inc?: Maybe<Contact_Assignments_Inc_Input>;
  _set?: Maybe<Contact_Assignments_Set_Input>;
  pk_columns: Contact_Assignments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Assignments_ManyArgs = {
  updates: Array<Contact_Assignments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_EntriesArgs = {
  _inc?: Maybe<Contact_Entries_Inc_Input>;
  _set?: Maybe<Contact_Entries_Set_Input>;
  where: Contact_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Entries_By_PkArgs = {
  _inc?: Maybe<Contact_Entries_Inc_Input>;
  _set?: Maybe<Contact_Entries_Set_Input>;
  pk_columns: Contact_Entries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Entries_ManyArgs = {
  updates: Array<Contact_Entries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContactsArgs = {
  _inc?: Maybe<Contacts_Inc_Input>;
  _set?: Maybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_By_PkArgs = {
  _inc?: Maybe<Contacts_Inc_Input>;
  _set?: Maybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_ManyArgs = {
  updates: Array<Contacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_CategoriesArgs = {
  _inc?: Maybe<Customer_Categories_Inc_Input>;
  _set?: Maybe<Customer_Categories_Set_Input>;
  where: Customer_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Categories_By_PkArgs = {
  _inc?: Maybe<Customer_Categories_Inc_Input>;
  _set?: Maybe<Customer_Categories_Set_Input>;
  pk_columns: Customer_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Categories_ManyArgs = {
  updates: Array<Customer_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_GroupsArgs = {
  _inc?: Maybe<Customer_Groups_Inc_Input>;
  _set?: Maybe<Customer_Groups_Set_Input>;
  where: Customer_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Groups_By_PkArgs = {
  _inc?: Maybe<Customer_Groups_Inc_Input>;
  _set?: Maybe<Customer_Groups_Set_Input>;
  pk_columns: Customer_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Groups_ManyArgs = {
  updates: Array<Customer_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomersArgs = {
  _inc?: Maybe<Customers_Inc_Input>;
  _set?: Maybe<Customers_Set_Input>;
  where: Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_By_PkArgs = {
  _inc?: Maybe<Customers_Inc_Input>;
  _set?: Maybe<Customers_Set_Input>;
  pk_columns: Customers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_ManyArgs = {
  updates: Array<Customers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_UsersArgs = {
  _inc?: Maybe<Customers_Users_Inc_Input>;
  _set?: Maybe<Customers_Users_Set_Input>;
  where: Customers_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_Users_By_PkArgs = {
  _inc?: Maybe<Customers_Users_Inc_Input>;
  _set?: Maybe<Customers_Users_Set_Input>;
  pk_columns: Customers_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_Users_ManyArgs = {
  updates: Array<Customers_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cyborg_QueriesArgs = {
  _inc?: Maybe<Cyborg_Queries_Inc_Input>;
  _set?: Maybe<Cyborg_Queries_Set_Input>;
  where: Cyborg_Queries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cyborg_Queries_By_PkArgs = {
  _inc?: Maybe<Cyborg_Queries_Inc_Input>;
  _set?: Maybe<Cyborg_Queries_Set_Input>;
  pk_columns: Cyborg_Queries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cyborg_Queries_ManyArgs = {
  updates: Array<Cyborg_Queries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_Detail_VolumesArgs = {
  _inc?: Maybe<Deal_Detail_Volumes_Inc_Input>;
  _set?: Maybe<Deal_Detail_Volumes_Set_Input>;
  where: Deal_Detail_Volumes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_Detail_Volumes_By_PkArgs = {
  _inc?: Maybe<Deal_Detail_Volumes_Inc_Input>;
  _set?: Maybe<Deal_Detail_Volumes_Set_Input>;
  pk_columns: Deal_Detail_Volumes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_Detail_Volumes_ManyArgs = {
  updates: Array<Deal_Detail_Volumes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_DetailsArgs = {
  _append?: Maybe<Deal_Details_Append_Input>;
  _delete_at_path?: Maybe<Deal_Details_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Deal_Details_Delete_Elem_Input>;
  _delete_key?: Maybe<Deal_Details_Delete_Key_Input>;
  _inc?: Maybe<Deal_Details_Inc_Input>;
  _prepend?: Maybe<Deal_Details_Prepend_Input>;
  _set?: Maybe<Deal_Details_Set_Input>;
  where: Deal_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_Details_By_PkArgs = {
  _append?: Maybe<Deal_Details_Append_Input>;
  _delete_at_path?: Maybe<Deal_Details_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Deal_Details_Delete_Elem_Input>;
  _delete_key?: Maybe<Deal_Details_Delete_Key_Input>;
  _inc?: Maybe<Deal_Details_Inc_Input>;
  _prepend?: Maybe<Deal_Details_Prepend_Input>;
  _set?: Maybe<Deal_Details_Set_Input>;
  pk_columns: Deal_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_Details_ManyArgs = {
  updates: Array<Deal_Details_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DealmakersArgs = {
  _inc?: Maybe<Dealmakers_Inc_Input>;
  _set?: Maybe<Dealmakers_Set_Input>;
  where: Dealmakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dealmakers_By_PkArgs = {
  _inc?: Maybe<Dealmakers_Inc_Input>;
  _set?: Maybe<Dealmakers_Set_Input>;
  pk_columns: Dealmakers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dealmakers_ManyArgs = {
  updates: Array<Dealmakers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DealsArgs = {
  _inc?: Maybe<Deals_Inc_Input>;
  _set?: Maybe<Deals_Set_Input>;
  where: Deals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deals_By_PkArgs = {
  _inc?: Maybe<Deals_Inc_Input>;
  _set?: Maybe<Deals_Set_Input>;
  pk_columns: Deals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deals_ManyArgs = {
  updates: Array<Deals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Email_AttachmentsArgs = {
  _inc?: Maybe<Email_Email_Attachments_Inc_Input>;
  _set?: Maybe<Email_Email_Attachments_Set_Input>;
  where: Email_Email_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Email_Attachments_By_PkArgs = {
  _inc?: Maybe<Email_Email_Attachments_Inc_Input>;
  _set?: Maybe<Email_Email_Attachments_Set_Input>;
  pk_columns: Email_Email_Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Email_Attachments_ManyArgs = {
  updates: Array<Email_Email_Attachments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Email_EmailsArgs = {
  _append?: Maybe<Email_Emails_Append_Input>;
  _delete_at_path?: Maybe<Email_Emails_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Emails_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Emails_Delete_Key_Input>;
  _inc?: Maybe<Email_Emails_Inc_Input>;
  _prepend?: Maybe<Email_Emails_Prepend_Input>;
  _set?: Maybe<Email_Emails_Set_Input>;
  where: Email_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Emails_By_PkArgs = {
  _append?: Maybe<Email_Emails_Append_Input>;
  _delete_at_path?: Maybe<Email_Emails_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Emails_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Emails_Delete_Key_Input>;
  _inc?: Maybe<Email_Emails_Inc_Input>;
  _prepend?: Maybe<Email_Emails_Prepend_Input>;
  _set?: Maybe<Email_Emails_Set_Input>;
  pk_columns: Email_Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Emails_ManyArgs = {
  updates: Array<Email_Emails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fee_TypesArgs = {
  _inc?: Maybe<Fee_Types_Inc_Input>;
  _set?: Maybe<Fee_Types_Set_Input>;
  where: Fee_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fee_Types_By_PkArgs = {
  _inc?: Maybe<Fee_Types_Inc_Input>;
  _set?: Maybe<Fee_Types_Set_Input>;
  pk_columns: Fee_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fee_Types_ManyArgs = {
  updates: Array<Fee_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FeesArgs = {
  _inc?: Maybe<Fees_Inc_Input>;
  _set?: Maybe<Fees_Set_Input>;
  where: Fees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fees_By_PkArgs = {
  _inc?: Maybe<Fees_Inc_Input>;
  _set?: Maybe<Fees_Set_Input>;
  pk_columns: Fees_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fees_ManyArgs = {
  updates: Array<Fees_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Document_Render_ResultsArgs = {
  _append?: Maybe<Files_Document_Render_Results_Append_Input>;
  _delete_at_path?: Maybe<Files_Document_Render_Results_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Files_Document_Render_Results_Delete_Elem_Input>;
  _delete_key?: Maybe<Files_Document_Render_Results_Delete_Key_Input>;
  _inc?: Maybe<Files_Document_Render_Results_Inc_Input>;
  _prepend?: Maybe<Files_Document_Render_Results_Prepend_Input>;
  _set?: Maybe<Files_Document_Render_Results_Set_Input>;
  where: Files_Document_Render_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Document_Render_Results_By_PkArgs = {
  _append?: Maybe<Files_Document_Render_Results_Append_Input>;
  _delete_at_path?: Maybe<Files_Document_Render_Results_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Files_Document_Render_Results_Delete_Elem_Input>;
  _delete_key?: Maybe<Files_Document_Render_Results_Delete_Key_Input>;
  _inc?: Maybe<Files_Document_Render_Results_Inc_Input>;
  _prepend?: Maybe<Files_Document_Render_Results_Prepend_Input>;
  _set?: Maybe<Files_Document_Render_Results_Set_Input>;
  pk_columns: Files_Document_Render_Results_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Document_Render_Results_ManyArgs = {
  updates: Array<Files_Document_Render_Results_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Document_Template_ExecutionsArgs = {
  _inc?: Maybe<Files_Document_Template_Executions_Inc_Input>;
  _set?: Maybe<Files_Document_Template_Executions_Set_Input>;
  where: Files_Document_Template_Executions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Document_Template_Executions_By_PkArgs = {
  _inc?: Maybe<Files_Document_Template_Executions_Inc_Input>;
  _set?: Maybe<Files_Document_Template_Executions_Set_Input>;
  pk_columns: Files_Document_Template_Executions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Document_Template_Executions_ManyArgs = {
  updates: Array<Files_Document_Template_Executions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Document_TemplatesArgs = {
  _inc?: Maybe<Files_Document_Templates_Inc_Input>;
  _set?: Maybe<Files_Document_Templates_Set_Input>;
  where: Files_Document_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Document_Templates_By_PkArgs = {
  _inc?: Maybe<Files_Document_Templates_Inc_Input>;
  _set?: Maybe<Files_Document_Templates_Set_Input>;
  pk_columns: Files_Document_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Document_Templates_ManyArgs = {
  updates: Array<Files_Document_Templates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Pdf_RenderArgs = {
  _inc?: Maybe<Files_Pdf_Render_Inc_Input>;
  _set?: Maybe<Files_Pdf_Render_Set_Input>;
  where: Files_Pdf_Render_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Pdf_Render_By_PkArgs = {
  _inc?: Maybe<Files_Pdf_Render_Inc_Input>;
  _set?: Maybe<Files_Pdf_Render_Set_Input>;
  pk_columns: Files_Pdf_Render_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_Pdf_Render_ManyArgs = {
  updates: Array<Files_Pdf_Render_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Forecast_TypesArgs = {
  _inc?: Maybe<Forecast_Types_Inc_Input>;
  _set?: Maybe<Forecast_Types_Set_Input>;
  where: Forecast_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Forecast_Types_By_PkArgs = {
  _inc?: Maybe<Forecast_Types_Inc_Input>;
  _set?: Maybe<Forecast_Types_Set_Input>;
  pk_columns: Forecast_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Forecast_Types_ManyArgs = {
  updates: Array<Forecast_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Group_TypesArgs = {
  _inc?: Maybe<Group_Types_Inc_Input>;
  _set?: Maybe<Group_Types_Set_Input>;
  where: Group_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Types_By_PkArgs = {
  _inc?: Maybe<Group_Types_Inc_Input>;
  _set?: Maybe<Group_Types_Set_Input>;
  pk_columns: Group_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Types_ManyArgs = {
  updates: Array<Group_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _inc?: Maybe<Groups_Inc_Input>;
  _set?: Maybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _inc?: Maybe<Groups_Inc_Input>;
  _set?: Maybe<Groups_Set_Input>;
  pk_columns: Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_ManyArgs = {
  updates: Array<Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ind_TypesArgs = {
  _inc?: Maybe<Ind_Types_Inc_Input>;
  _set?: Maybe<Ind_Types_Set_Input>;
  where: Ind_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ind_Types_By_PkArgs = {
  _inc?: Maybe<Ind_Types_Inc_Input>;
  _set?: Maybe<Ind_Types_Set_Input>;
  pk_columns: Ind_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ind_Types_ManyArgs = {
  updates: Array<Ind_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IndicesArgs = {
  _append?: Maybe<Indices_Append_Input>;
  _delete_at_path?: Maybe<Indices_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Indices_Delete_Elem_Input>;
  _delete_key?: Maybe<Indices_Delete_Key_Input>;
  _inc?: Maybe<Indices_Inc_Input>;
  _prepend?: Maybe<Indices_Prepend_Input>;
  _set?: Maybe<Indices_Set_Input>;
  where: Indices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Indices_By_PkArgs = {
  _append?: Maybe<Indices_Append_Input>;
  _delete_at_path?: Maybe<Indices_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Indices_Delete_Elem_Input>;
  _delete_key?: Maybe<Indices_Delete_Key_Input>;
  _inc?: Maybe<Indices_Inc_Input>;
  _prepend?: Maybe<Indices_Prepend_Input>;
  _set?: Maybe<Indices_Set_Input>;
  pk_columns: Indices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Indices_ManyArgs = {
  updates: Array<Indices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvitationsArgs = {
  _append?: Maybe<Invitations_Append_Input>;
  _delete_at_path?: Maybe<Invitations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Invitations_Delete_Elem_Input>;
  _delete_key?: Maybe<Invitations_Delete_Key_Input>;
  _inc?: Maybe<Invitations_Inc_Input>;
  _prepend?: Maybe<Invitations_Prepend_Input>;
  _set?: Maybe<Invitations_Set_Input>;
  where: Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invitations_By_PkArgs = {
  _append?: Maybe<Invitations_Append_Input>;
  _delete_at_path?: Maybe<Invitations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Invitations_Delete_Elem_Input>;
  _delete_key?: Maybe<Invitations_Delete_Key_Input>;
  _inc?: Maybe<Invitations_Inc_Input>;
  _prepend?: Maybe<Invitations_Prepend_Input>;
  _set?: Maybe<Invitations_Set_Input>;
  pk_columns: Invitations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invitations_CustomersArgs = {
  _inc?: Maybe<Invitations_Customers_Inc_Input>;
  _set?: Maybe<Invitations_Customers_Set_Input>;
  where: Invitations_Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invitations_Customers_By_PkArgs = {
  _inc?: Maybe<Invitations_Customers_Inc_Input>;
  _set?: Maybe<Invitations_Customers_Set_Input>;
  pk_columns: Invitations_Customers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invitations_Customers_ManyArgs = {
  updates: Array<Invitations_Customers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invitations_ManyArgs = {
  updates: Array<Invitations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_DataArgs = {
  _inc?: Maybe<Invoice_Data_Inc_Input>;
  _set?: Maybe<Invoice_Data_Set_Input>;
  where: Invoice_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Data_By_PkArgs = {
  _inc?: Maybe<Invoice_Data_Inc_Input>;
  _set?: Maybe<Invoice_Data_Set_Input>;
  pk_columns: Invoice_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Data_ManyArgs = {
  updates: Array<Invoice_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_NumbersArgs = {
  _inc?: Maybe<Invoice_Numbers_Inc_Input>;
  _set?: Maybe<Invoice_Numbers_Set_Input>;
  where: Invoice_Numbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Numbers_By_PkArgs = {
  _inc?: Maybe<Invoice_Numbers_Inc_Input>;
  _set?: Maybe<Invoice_Numbers_Set_Input>;
  pk_columns: Invoice_Numbers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Numbers_ManyArgs = {
  updates: Array<Invoice_Numbers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Load_ForecastsArgs = {
  _inc?: Maybe<Load_Forecasts_Inc_Input>;
  _set?: Maybe<Load_Forecasts_Set_Input>;
  where: Load_Forecasts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Load_Forecasts_By_PkArgs = {
  _inc?: Maybe<Load_Forecasts_Inc_Input>;
  _set?: Maybe<Load_Forecasts_Set_Input>;
  pk_columns: Load_Forecasts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Load_Forecasts_ManyArgs = {
  updates: Array<Load_Forecasts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _append?: Maybe<Locations_Append_Input>;
  _delete_at_path?: Maybe<Locations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Locations_Delete_Elem_Input>;
  _delete_key?: Maybe<Locations_Delete_Key_Input>;
  _inc?: Maybe<Locations_Inc_Input>;
  _prepend?: Maybe<Locations_Prepend_Input>;
  _set?: Maybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _append?: Maybe<Locations_Append_Input>;
  _delete_at_path?: Maybe<Locations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Locations_Delete_Elem_Input>;
  _delete_key?: Maybe<Locations_Delete_Key_Input>;
  _inc?: Maybe<Locations_Inc_Input>;
  _prepend?: Maybe<Locations_Prepend_Input>;
  _set?: Maybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_ManyArgs = {
  updates: Array<Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LogsArgs = {
  _append?: Maybe<Logs_Append_Input>;
  _delete_at_path?: Maybe<Logs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Logs_Delete_Elem_Input>;
  _delete_key?: Maybe<Logs_Delete_Key_Input>;
  _inc?: Maybe<Logs_Inc_Input>;
  _prepend?: Maybe<Logs_Prepend_Input>;
  _set?: Maybe<Logs_Set_Input>;
  where: Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Logs_By_PkArgs = {
  _append?: Maybe<Logs_Append_Input>;
  _delete_at_path?: Maybe<Logs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Logs_Delete_Elem_Input>;
  _delete_key?: Maybe<Logs_Delete_Key_Input>;
  _inc?: Maybe<Logs_Inc_Input>;
  _prepend?: Maybe<Logs_Prepend_Input>;
  _set?: Maybe<Logs_Set_Input>;
  pk_columns: Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Logs_ManyArgs = {
  updates: Array<Logs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ParamsArgs = {
  _append?: Maybe<Params_Append_Input>;
  _delete_at_path?: Maybe<Params_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Params_Delete_Elem_Input>;
  _delete_key?: Maybe<Params_Delete_Key_Input>;
  _inc?: Maybe<Params_Inc_Input>;
  _prepend?: Maybe<Params_Prepend_Input>;
  _set?: Maybe<Params_Set_Input>;
  where: Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Params_By_PkArgs = {
  _append?: Maybe<Params_Append_Input>;
  _delete_at_path?: Maybe<Params_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Params_Delete_Elem_Input>;
  _delete_key?: Maybe<Params_Delete_Key_Input>;
  _inc?: Maybe<Params_Inc_Input>;
  _prepend?: Maybe<Params_Prepend_Input>;
  _set?: Maybe<Params_Set_Input>;
  pk_columns: Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Params_ManyArgs = {
  updates: Array<Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PathsArgs = {
  _inc?: Maybe<Paths_Inc_Input>;
  _set?: Maybe<Paths_Set_Input>;
  where: Paths_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Paths_By_PkArgs = {
  _inc?: Maybe<Paths_Inc_Input>;
  _set?: Maybe<Paths_Set_Input>;
  pk_columns: Paths_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Paths_ManyArgs = {
  updates: Array<Paths_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PipelinesArgs = {
  _inc?: Maybe<Pipelines_Inc_Input>;
  _set?: Maybe<Pipelines_Set_Input>;
  where: Pipelines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pipelines_By_PkArgs = {
  _inc?: Maybe<Pipelines_Inc_Input>;
  _set?: Maybe<Pipelines_Set_Input>;
  pk_columns: Pipelines_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pipelines_ManyArgs = {
  updates: Array<Pipelines_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RecordsArgs = {
  _append?: Maybe<Records_Append_Input>;
  _delete_at_path?: Maybe<Records_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Records_Delete_Elem_Input>;
  _delete_key?: Maybe<Records_Delete_Key_Input>;
  _inc?: Maybe<Records_Inc_Input>;
  _prepend?: Maybe<Records_Prepend_Input>;
  _set?: Maybe<Records_Set_Input>;
  where: Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Records_By_PkArgs = {
  _append?: Maybe<Records_Append_Input>;
  _delete_at_path?: Maybe<Records_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Records_Delete_Elem_Input>;
  _delete_key?: Maybe<Records_Delete_Key_Input>;
  _inc?: Maybe<Records_Inc_Input>;
  _prepend?: Maybe<Records_Prepend_Input>;
  _set?: Maybe<Records_Set_Input>;
  pk_columns: Records_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Records_ManyArgs = {
  updates: Array<Records_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Report_DashboardArgs = {
  _inc?: Maybe<Report_Dashboard_Inc_Input>;
  _set?: Maybe<Report_Dashboard_Set_Input>;
  where: Report_Dashboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Dashboard_By_PkArgs = {
  _inc?: Maybe<Report_Dashboard_Inc_Input>;
  _set?: Maybe<Report_Dashboard_Set_Input>;
  pk_columns: Report_Dashboard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Dashboard_ManyArgs = {
  updates: Array<Report_Dashboard_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Risk_TypesArgs = {
  _inc?: Maybe<Risk_Types_Inc_Input>;
  _set?: Maybe<Risk_Types_Set_Input>;
  where: Risk_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Risk_Types_By_PkArgs = {
  _inc?: Maybe<Risk_Types_Inc_Input>;
  _set?: Maybe<Risk_Types_Set_Input>;
  pk_columns: Risk_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Risk_Types_ManyArgs = {
  updates: Array<Risk_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_DataArgs = {
  _append?: Maybe<Users_Data_Append_Input>;
  _delete_at_path?: Maybe<Users_Data_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Users_Data_Delete_Elem_Input>;
  _delete_key?: Maybe<Users_Data_Delete_Key_Input>;
  _inc?: Maybe<Users_Data_Inc_Input>;
  _prepend?: Maybe<Users_Data_Prepend_Input>;
  _set?: Maybe<Users_Data_Set_Input>;
  where: Users_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Data_By_PkArgs = {
  _append?: Maybe<Users_Data_Append_Input>;
  _delete_at_path?: Maybe<Users_Data_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Users_Data_Delete_Elem_Input>;
  _delete_key?: Maybe<Users_Data_Delete_Key_Input>;
  _inc?: Maybe<Users_Data_Inc_Input>;
  _prepend?: Maybe<Users_Data_Prepend_Input>;
  _set?: Maybe<Users_Data_Set_Input>;
  pk_columns: Users_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Data_ManyArgs = {
  updates: Array<Users_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wrec_MeetingArgs = {
  _append?: Maybe<Wrec_Meeting_Append_Input>;
  _delete_at_path?: Maybe<Wrec_Meeting_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wrec_Meeting_Delete_Elem_Input>;
  _delete_key?: Maybe<Wrec_Meeting_Delete_Key_Input>;
  _inc?: Maybe<Wrec_Meeting_Inc_Input>;
  _prepend?: Maybe<Wrec_Meeting_Prepend_Input>;
  _set?: Maybe<Wrec_Meeting_Set_Input>;
  where: Wrec_Meeting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wrec_Meeting_By_PkArgs = {
  _append?: Maybe<Wrec_Meeting_Append_Input>;
  _delete_at_path?: Maybe<Wrec_Meeting_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wrec_Meeting_Delete_Elem_Input>;
  _delete_key?: Maybe<Wrec_Meeting_Delete_Key_Input>;
  _inc?: Maybe<Wrec_Meeting_Inc_Input>;
  _prepend?: Maybe<Wrec_Meeting_Prepend_Input>;
  _set?: Maybe<Wrec_Meeting_Set_Input>;
  pk_columns: Wrec_Meeting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wrec_Meeting_ManyArgs = {
  updates: Array<Wrec_Meeting_Updates>;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: Maybe<Scalars['name']>;
  _gt?: Maybe<Scalars['name']>;
  _gte?: Maybe<Scalars['name']>;
  _in?: Maybe<Array<Scalars['name']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['name']>;
  _lte?: Maybe<Scalars['name']>;
  _neq?: Maybe<Scalars['name']>;
  _nin?: Maybe<Array<Scalars['name']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?: Maybe<Scalars['oid']>;
  _gt?: Maybe<Scalars['oid']>;
  _gte?: Maybe<Scalars['oid']>;
  _in?: Maybe<Array<Scalars['oid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['oid']>;
  _lte?: Maybe<Scalars['oid']>;
  _neq?: Maybe<Scalars['oid']>;
  _nin?: Maybe<Array<Scalars['oid']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "params" */
export type Params = {
  __typename?: 'params';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  id: Scalars['bigint'];
  key: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "params" */
export type ParamsDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "params" */
export type Params_Aggregate = {
  __typename?: 'params_aggregate';
  aggregate?: Maybe<Params_Aggregate_Fields>;
  nodes: Array<Params>;
};

/** aggregate fields of "params" */
export type Params_Aggregate_Fields = {
  __typename?: 'params_aggregate_fields';
  avg?: Maybe<Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Params_Max_Fields>;
  min?: Maybe<Params_Min_Fields>;
  stddev?: Maybe<Params_Stddev_Fields>;
  stddev_pop?: Maybe<Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Params_Stddev_Samp_Fields>;
  sum?: Maybe<Params_Sum_Fields>;
  var_pop?: Maybe<Params_Var_Pop_Fields>;
  var_samp?: Maybe<Params_Var_Samp_Fields>;
  variance?: Maybe<Params_Variance_Fields>;
};


/** aggregate fields of "params" */
export type Params_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Params_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Params_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Params_Avg_Fields = {
  __typename?: 'params_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "params". All fields are combined with a logical 'AND'. */
export type Params_Bool_Exp = {
  _and?: Maybe<Array<Params_Bool_Exp>>;
  _not?: Maybe<Params_Bool_Exp>;
  _or?: Maybe<Array<Params_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  tags?: Maybe<String_Array_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "params" */
export enum Params_Constraint {
  /** unique or primary key constraint on columns "key" */
  ParamsKeyKey = 'params_key_key',
  /** unique or primary key constraint on columns "id" */
  ParamsPkey = 'params_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Params_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Params_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Params_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "params" */
export type Params_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "params" */
export type Params_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Params_Max_Fields = {
  __typename?: 'params_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Params_Min_Fields = {
  __typename?: 'params_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "params" */
export type Params_Mutation_Response = {
  __typename?: 'params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Params>;
};

/** on_conflict condition type for table "params" */
export type Params_On_Conflict = {
  constraint: Params_Constraint;
  update_columns?: Array<Params_Update_Column>;
  where?: Maybe<Params_Bool_Exp>;
};

/** Ordering options when selecting data from "params". */
export type Params_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: params */
export type Params_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Params_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "params" */
export enum Params_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Notes = 'notes',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "params" */
export type Params_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Params_Stddev_Fields = {
  __typename?: 'params_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Params_Stddev_Pop_Fields = {
  __typename?: 'params_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Params_Stddev_Samp_Fields = {
  __typename?: 'params_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "params" */
export type Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Params_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Params_Sum_Fields = {
  __typename?: 'params_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "params" */
export enum Params_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Notes = 'notes',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Params_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Params_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Params_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Params_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Params_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Params_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Params_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Params_Var_Pop_Fields = {
  __typename?: 'params_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Params_Var_Samp_Fields = {
  __typename?: 'params_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Params_Variance_Fields = {
  __typename?: 'params_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "paths" */
export type Paths = {
  __typename?: 'paths';
  archived?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  company?: Maybe<Indices>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  delivery_location?: Maybe<Locations>;
  delivery_location_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['Int']>;
  fuel_percent?: Maybe<Scalars['float8']>;
  gas_day?: Maybe<Scalars['date']>;
  group_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  pipeline?: Maybe<Pipelines>;
  pipeline_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  receipt_location?: Maybe<Locations>;
  receipt_location_id?: Maybe<Scalars['Int']>;
  transport_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "paths" */
export type Paths_Aggregate = {
  __typename?: 'paths_aggregate';
  aggregate?: Maybe<Paths_Aggregate_Fields>;
  nodes: Array<Paths>;
};

export type Paths_Aggregate_Bool_Exp = {
  avg?: Maybe<Paths_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Paths_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Paths_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Paths_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Paths_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Paths_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Paths_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Paths_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Paths_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Paths_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Paths_Aggregate_Bool_Exp_Var_Samp>;
};

export type Paths_Aggregate_Bool_Exp_Avg = {
  arguments: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Bool_And = {
  arguments: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Corr = {
  arguments: Paths_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Paths_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Paths_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Paths_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Paths_Aggregate_Bool_Exp_Max = {
  arguments: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Min = {
  arguments: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Sum = {
  arguments: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Paths_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Paths_Select_Column_Paths_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Paths_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "paths" */
export type Paths_Aggregate_Fields = {
  __typename?: 'paths_aggregate_fields';
  avg?: Maybe<Paths_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Paths_Max_Fields>;
  min?: Maybe<Paths_Min_Fields>;
  stddev?: Maybe<Paths_Stddev_Fields>;
  stddev_pop?: Maybe<Paths_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Paths_Stddev_Samp_Fields>;
  sum?: Maybe<Paths_Sum_Fields>;
  var_pop?: Maybe<Paths_Var_Pop_Fields>;
  var_samp?: Maybe<Paths_Var_Samp_Fields>;
  variance?: Maybe<Paths_Variance_Fields>;
};


/** aggregate fields of "paths" */
export type Paths_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Paths_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "paths" */
export type Paths_Aggregate_Order_By = {
  avg?: Maybe<Paths_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Paths_Max_Order_By>;
  min?: Maybe<Paths_Min_Order_By>;
  stddev?: Maybe<Paths_Stddev_Order_By>;
  stddev_pop?: Maybe<Paths_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Paths_Stddev_Samp_Order_By>;
  sum?: Maybe<Paths_Sum_Order_By>;
  var_pop?: Maybe<Paths_Var_Pop_Order_By>;
  var_samp?: Maybe<Paths_Var_Samp_Order_By>;
  variance?: Maybe<Paths_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "paths" */
export type Paths_Arr_Rel_Insert_Input = {
  data: Array<Paths_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Paths_On_Conflict>;
};

/** aggregate avg on columns */
export type Paths_Avg_Fields = {
  __typename?: 'paths_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  delivery_location_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  fuel_percent?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  receipt_location_id?: Maybe<Scalars['Float']>;
  transport_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "paths" */
export type Paths_Avg_Order_By = {
  company_id?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "paths". All fields are combined with a logical 'AND'. */
export type Paths_Bool_Exp = {
  _and?: Maybe<Array<Paths_Bool_Exp>>;
  _not?: Maybe<Paths_Bool_Exp>;
  _or?: Maybe<Array<Paths_Bool_Exp>>;
  archived?: Maybe<Boolean_Comparison_Exp>;
  company?: Maybe<Indices_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  delivery_location?: Maybe<Locations_Bool_Exp>;
  delivery_location_id?: Maybe<Int_Comparison_Exp>;
  dth?: Maybe<Int_Comparison_Exp>;
  fuel_percent?: Maybe<Float8_Comparison_Exp>;
  gas_day?: Maybe<Date_Comparison_Exp>;
  group_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  pipeline?: Maybe<Pipelines_Bool_Exp>;
  pipeline_id?: Maybe<Int_Comparison_Exp>;
  receipt_location?: Maybe<Locations_Bool_Exp>;
  receipt_location_id?: Maybe<Int_Comparison_Exp>;
  transport_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "paths" */
export enum Paths_Constraint {
  /** unique or primary key constraint on columns "id" */
  PathsPkey = 'paths_pkey'
}

/** input type for incrementing numeric columns in table "paths" */
export type Paths_Inc_Input = {
  company_id?: Maybe<Scalars['Int']>;
  delivery_location_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['Int']>;
  fuel_percent?: Maybe<Scalars['float8']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  receipt_location_id?: Maybe<Scalars['Int']>;
  transport_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "paths" */
export type Paths_Insert_Input = {
  archived?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Indices_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  delivery_location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  delivery_location_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['Int']>;
  fuel_percent?: Maybe<Scalars['float8']>;
  gas_day?: Maybe<Scalars['date']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pipeline?: Maybe<Pipelines_Obj_Rel_Insert_Input>;
  pipeline_id?: Maybe<Scalars['Int']>;
  receipt_location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  receipt_location_id?: Maybe<Scalars['Int']>;
  transport_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Paths_Max_Fields = {
  __typename?: 'paths_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  delivery_location_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['Int']>;
  fuel_percent?: Maybe<Scalars['float8']>;
  gas_day?: Maybe<Scalars['date']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  receipt_location_id?: Maybe<Scalars['Int']>;
  transport_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "paths" */
export type Paths_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  gas_day?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Paths_Min_Fields = {
  __typename?: 'paths_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  delivery_location_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['Int']>;
  fuel_percent?: Maybe<Scalars['float8']>;
  gas_day?: Maybe<Scalars['date']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  receipt_location_id?: Maybe<Scalars['Int']>;
  transport_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "paths" */
export type Paths_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  gas_day?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "paths" */
export type Paths_Mutation_Response = {
  __typename?: 'paths_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Paths>;
};

/** on_conflict condition type for table "paths" */
export type Paths_On_Conflict = {
  constraint: Paths_Constraint;
  update_columns?: Array<Paths_Update_Column>;
  where?: Maybe<Paths_Bool_Exp>;
};

/** Ordering options when selecting data from "paths". */
export type Paths_Order_By = {
  archived?: Maybe<Order_By>;
  company?: Maybe<Indices_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  delivery_location?: Maybe<Locations_Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  gas_day?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline?: Maybe<Pipelines_Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location?: Maybe<Locations_Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: paths */
export type Paths_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "paths" */
export enum Paths_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryLocationId = 'delivery_location_id',
  /** column name */
  Dth = 'dth',
  /** column name */
  FuelPercent = 'fuel_percent',
  /** column name */
  GasDay = 'gas_day',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  ReceiptLocationId = 'receipt_location_id',
  /** column name */
  TransportId = 'transport_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "paths_aggregate_bool_exp_avg_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  FuelPercent = 'fuel_percent'
}

/** select "paths_aggregate_bool_exp_bool_and_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived'
}

/** select "paths_aggregate_bool_exp_bool_or_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived'
}

/** select "paths_aggregate_bool_exp_corr_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  FuelPercent = 'fuel_percent'
}

/** select "paths_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  FuelPercent = 'fuel_percent'
}

/** select "paths_aggregate_bool_exp_max_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  FuelPercent = 'fuel_percent'
}

/** select "paths_aggregate_bool_exp_min_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  FuelPercent = 'fuel_percent'
}

/** select "paths_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  FuelPercent = 'fuel_percent'
}

/** select "paths_aggregate_bool_exp_sum_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  FuelPercent = 'fuel_percent'
}

/** select "paths_aggregate_bool_exp_var_samp_arguments_columns" columns of table "paths" */
export enum Paths_Select_Column_Paths_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  FuelPercent = 'fuel_percent'
}

/** input type for updating data in table "paths" */
export type Paths_Set_Input = {
  archived?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  delivery_location_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['Int']>;
  fuel_percent?: Maybe<Scalars['float8']>;
  gas_day?: Maybe<Scalars['date']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  receipt_location_id?: Maybe<Scalars['Int']>;
  transport_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Paths_Stddev_Fields = {
  __typename?: 'paths_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  delivery_location_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  fuel_percent?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  receipt_location_id?: Maybe<Scalars['Float']>;
  transport_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "paths" */
export type Paths_Stddev_Order_By = {
  company_id?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Paths_Stddev_Pop_Fields = {
  __typename?: 'paths_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  delivery_location_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  fuel_percent?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  receipt_location_id?: Maybe<Scalars['Float']>;
  transport_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "paths" */
export type Paths_Stddev_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Paths_Stddev_Samp_Fields = {
  __typename?: 'paths_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  delivery_location_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  fuel_percent?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  receipt_location_id?: Maybe<Scalars['Float']>;
  transport_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "paths" */
export type Paths_Stddev_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "paths" */
export type Paths_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Paths_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Paths_Stream_Cursor_Value_Input = {
  archived?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  delivery_location_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['Int']>;
  fuel_percent?: Maybe<Scalars['float8']>;
  gas_day?: Maybe<Scalars['date']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  receipt_location_id?: Maybe<Scalars['Int']>;
  transport_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Paths_Sum_Fields = {
  __typename?: 'paths_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  delivery_location_id?: Maybe<Scalars['Int']>;
  dth?: Maybe<Scalars['Int']>;
  fuel_percent?: Maybe<Scalars['float8']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['Int']>;
  receipt_location_id?: Maybe<Scalars['Int']>;
  transport_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "paths" */
export type Paths_Sum_Order_By = {
  company_id?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
};

/** update columns of table "paths" */
export enum Paths_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryLocationId = 'delivery_location_id',
  /** column name */
  Dth = 'dth',
  /** column name */
  FuelPercent = 'fuel_percent',
  /** column name */
  GasDay = 'gas_day',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  ReceiptLocationId = 'receipt_location_id',
  /** column name */
  TransportId = 'transport_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Paths_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Paths_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Paths_Set_Input>;
  /** filter the rows which have to be updated */
  where: Paths_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Paths_Var_Pop_Fields = {
  __typename?: 'paths_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  delivery_location_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  fuel_percent?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  receipt_location_id?: Maybe<Scalars['Float']>;
  transport_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "paths" */
export type Paths_Var_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Paths_Var_Samp_Fields = {
  __typename?: 'paths_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  delivery_location_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  fuel_percent?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  receipt_location_id?: Maybe<Scalars['Float']>;
  transport_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "paths" */
export type Paths_Var_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Paths_Variance_Fields = {
  __typename?: 'paths_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  delivery_location_id?: Maybe<Scalars['Float']>;
  dth?: Maybe<Scalars['Float']>;
  fuel_percent?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pipeline_id?: Maybe<Scalars['Float']>;
  receipt_location_id?: Maybe<Scalars['Float']>;
  transport_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "paths" */
export type Paths_Variance_Order_By = {
  company_id?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dth?: Maybe<Order_By>;
  fuel_percent?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  receipt_location_id?: Maybe<Order_By>;
  transport_id?: Maybe<Order_By>;
};

/** columns and relationships of "pipelines" */
export type Pipelines = {
  __typename?: 'pipelines';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  deal_details_pipeline: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_pipeline_aggregate: Deal_Details_Aggregate;
  /** An array relationship */
  deal_details_utility: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_utility_aggregate: Deal_Details_Aggregate;
  duns_number?: Maybe<Scalars['String']>;
  end_of_cycle?: Maybe<Scalars['Int']>;
  fuel_rate?: Maybe<Scalars['float8']>;
  geo_zip?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  interstate?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  mdq_factor?: Maybe<Scalars['float8']>;
  mdq_hdd?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  paths: Array<Paths>;
  /** An aggregate relationship */
  paths_aggregate: Paths_Aggregate;
  percent_adder?: Maybe<Scalars['float8']>;
  region_id?: Maybe<Scalars['Int']>;
  shortid?: Maybe<Scalars['bpchar']>;
  unit_conversion?: Maybe<Scalars['float8']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "pipelines" */
export type PipelinesDeal_Details_PipelineArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "pipelines" */
export type PipelinesDeal_Details_Pipeline_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "pipelines" */
export type PipelinesDeal_Details_UtilityArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "pipelines" */
export type PipelinesDeal_Details_Utility_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "pipelines" */
export type PipelinesLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


/** columns and relationships of "pipelines" */
export type PipelinesLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


/** columns and relationships of "pipelines" */
export type PipelinesPathsArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};


/** columns and relationships of "pipelines" */
export type PipelinesPaths_AggregateArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};

/** aggregated selection of "pipelines" */
export type Pipelines_Aggregate = {
  __typename?: 'pipelines_aggregate';
  aggregate?: Maybe<Pipelines_Aggregate_Fields>;
  nodes: Array<Pipelines>;
};

/** aggregate fields of "pipelines" */
export type Pipelines_Aggregate_Fields = {
  __typename?: 'pipelines_aggregate_fields';
  avg?: Maybe<Pipelines_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pipelines_Max_Fields>;
  min?: Maybe<Pipelines_Min_Fields>;
  stddev?: Maybe<Pipelines_Stddev_Fields>;
  stddev_pop?: Maybe<Pipelines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pipelines_Stddev_Samp_Fields>;
  sum?: Maybe<Pipelines_Sum_Fields>;
  var_pop?: Maybe<Pipelines_Var_Pop_Fields>;
  var_samp?: Maybe<Pipelines_Var_Samp_Fields>;
  variance?: Maybe<Pipelines_Variance_Fields>;
};


/** aggregate fields of "pipelines" */
export type Pipelines_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pipelines_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Pipelines_Avg_Fields = {
  __typename?: 'pipelines_avg_fields';
  end_of_cycle?: Maybe<Scalars['Float']>;
  fuel_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq_factor?: Maybe<Scalars['Float']>;
  mdq_hdd?: Maybe<Scalars['Float']>;
  percent_adder?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  unit_conversion?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "pipelines". All fields are combined with a logical 'AND'. */
export type Pipelines_Bool_Exp = {
  _and?: Maybe<Array<Pipelines_Bool_Exp>>;
  _not?: Maybe<Pipelines_Bool_Exp>;
  _or?: Maybe<Array<Pipelines_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deal_details_pipeline?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_pipeline_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  deal_details_utility?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_utility_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  duns_number?: Maybe<String_Comparison_Exp>;
  end_of_cycle?: Maybe<Int_Comparison_Exp>;
  fuel_rate?: Maybe<Float8_Comparison_Exp>;
  geo_zip?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  interstate?: Maybe<Boolean_Comparison_Exp>;
  locations?: Maybe<Locations_Bool_Exp>;
  locations_aggregate?: Maybe<Locations_Aggregate_Bool_Exp>;
  mdq_factor?: Maybe<Float8_Comparison_Exp>;
  mdq_hdd?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  paths?: Maybe<Paths_Bool_Exp>;
  paths_aggregate?: Maybe<Paths_Aggregate_Bool_Exp>;
  percent_adder?: Maybe<Float8_Comparison_Exp>;
  region_id?: Maybe<Int_Comparison_Exp>;
  shortid?: Maybe<Bpchar_Comparison_Exp>;
  unit_conversion?: Maybe<Float8_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "pipelines" */
export enum Pipelines_Constraint {
  /** unique or primary key constraint on columns "id" */
  PipelinesPkey = 'pipelines_pkey'
}

/** input type for incrementing numeric columns in table "pipelines" */
export type Pipelines_Inc_Input = {
  end_of_cycle?: Maybe<Scalars['Int']>;
  fuel_rate?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  mdq_factor?: Maybe<Scalars['float8']>;
  mdq_hdd?: Maybe<Scalars['Int']>;
  percent_adder?: Maybe<Scalars['float8']>;
  region_id?: Maybe<Scalars['Int']>;
  unit_conversion?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "pipelines" */
export type Pipelines_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deal_details_pipeline?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  deal_details_utility?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  duns_number?: Maybe<Scalars['String']>;
  end_of_cycle?: Maybe<Scalars['Int']>;
  fuel_rate?: Maybe<Scalars['float8']>;
  geo_zip?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  interstate?: Maybe<Scalars['Boolean']>;
  locations?: Maybe<Locations_Arr_Rel_Insert_Input>;
  mdq_factor?: Maybe<Scalars['float8']>;
  mdq_hdd?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  paths?: Maybe<Paths_Arr_Rel_Insert_Input>;
  percent_adder?: Maybe<Scalars['float8']>;
  region_id?: Maybe<Scalars['Int']>;
  shortid?: Maybe<Scalars['bpchar']>;
  unit_conversion?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Pipelines_Max_Fields = {
  __typename?: 'pipelines_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  duns_number?: Maybe<Scalars['String']>;
  end_of_cycle?: Maybe<Scalars['Int']>;
  fuel_rate?: Maybe<Scalars['float8']>;
  geo_zip?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mdq_factor?: Maybe<Scalars['float8']>;
  mdq_hdd?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percent_adder?: Maybe<Scalars['float8']>;
  region_id?: Maybe<Scalars['Int']>;
  shortid?: Maybe<Scalars['bpchar']>;
  unit_conversion?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Pipelines_Min_Fields = {
  __typename?: 'pipelines_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  duns_number?: Maybe<Scalars['String']>;
  end_of_cycle?: Maybe<Scalars['Int']>;
  fuel_rate?: Maybe<Scalars['float8']>;
  geo_zip?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mdq_factor?: Maybe<Scalars['float8']>;
  mdq_hdd?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percent_adder?: Maybe<Scalars['float8']>;
  region_id?: Maybe<Scalars['Int']>;
  shortid?: Maybe<Scalars['bpchar']>;
  unit_conversion?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "pipelines" */
export type Pipelines_Mutation_Response = {
  __typename?: 'pipelines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pipelines>;
};

/** input type for inserting object relation for remote table "pipelines" */
export type Pipelines_Obj_Rel_Insert_Input = {
  data: Pipelines_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Pipelines_On_Conflict>;
};

/** on_conflict condition type for table "pipelines" */
export type Pipelines_On_Conflict = {
  constraint: Pipelines_Constraint;
  update_columns?: Array<Pipelines_Update_Column>;
  where?: Maybe<Pipelines_Bool_Exp>;
};

/** Ordering options when selecting data from "pipelines". */
export type Pipelines_Order_By = {
  created_at?: Maybe<Order_By>;
  deal_details_pipeline_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  deal_details_utility_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  duns_number?: Maybe<Order_By>;
  end_of_cycle?: Maybe<Order_By>;
  fuel_rate?: Maybe<Order_By>;
  geo_zip?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interstate?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Locations_Aggregate_Order_By>;
  mdq_factor?: Maybe<Order_By>;
  mdq_hdd?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  paths_aggregate?: Maybe<Paths_Aggregate_Order_By>;
  percent_adder?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  shortid?: Maybe<Order_By>;
  unit_conversion?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: pipelines */
export type Pipelines_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "pipelines" */
export enum Pipelines_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DunsNumber = 'duns_number',
  /** column name */
  EndOfCycle = 'end_of_cycle',
  /** column name */
  FuelRate = 'fuel_rate',
  /** column name */
  GeoZip = 'geo_zip',
  /** column name */
  Id = 'id',
  /** column name */
  Interstate = 'interstate',
  /** column name */
  MdqFactor = 'mdq_factor',
  /** column name */
  MdqHdd = 'mdq_hdd',
  /** column name */
  Name = 'name',
  /** column name */
  PercentAdder = 'percent_adder',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  Shortid = 'shortid',
  /** column name */
  UnitConversion = 'unit_conversion',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pipelines" */
export type Pipelines_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  duns_number?: Maybe<Scalars['String']>;
  end_of_cycle?: Maybe<Scalars['Int']>;
  fuel_rate?: Maybe<Scalars['float8']>;
  geo_zip?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  interstate?: Maybe<Scalars['Boolean']>;
  mdq_factor?: Maybe<Scalars['float8']>;
  mdq_hdd?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percent_adder?: Maybe<Scalars['float8']>;
  region_id?: Maybe<Scalars['Int']>;
  shortid?: Maybe<Scalars['bpchar']>;
  unit_conversion?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Pipelines_Stddev_Fields = {
  __typename?: 'pipelines_stddev_fields';
  end_of_cycle?: Maybe<Scalars['Float']>;
  fuel_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq_factor?: Maybe<Scalars['Float']>;
  mdq_hdd?: Maybe<Scalars['Float']>;
  percent_adder?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  unit_conversion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Pipelines_Stddev_Pop_Fields = {
  __typename?: 'pipelines_stddev_pop_fields';
  end_of_cycle?: Maybe<Scalars['Float']>;
  fuel_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq_factor?: Maybe<Scalars['Float']>;
  mdq_hdd?: Maybe<Scalars['Float']>;
  percent_adder?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  unit_conversion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Pipelines_Stddev_Samp_Fields = {
  __typename?: 'pipelines_stddev_samp_fields';
  end_of_cycle?: Maybe<Scalars['Float']>;
  fuel_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq_factor?: Maybe<Scalars['Float']>;
  mdq_hdd?: Maybe<Scalars['Float']>;
  percent_adder?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  unit_conversion?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "pipelines" */
export type Pipelines_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pipelines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pipelines_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  duns_number?: Maybe<Scalars['String']>;
  end_of_cycle?: Maybe<Scalars['Int']>;
  fuel_rate?: Maybe<Scalars['float8']>;
  geo_zip?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  interstate?: Maybe<Scalars['Boolean']>;
  mdq_factor?: Maybe<Scalars['float8']>;
  mdq_hdd?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percent_adder?: Maybe<Scalars['float8']>;
  region_id?: Maybe<Scalars['Int']>;
  shortid?: Maybe<Scalars['bpchar']>;
  unit_conversion?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Pipelines_Sum_Fields = {
  __typename?: 'pipelines_sum_fields';
  end_of_cycle?: Maybe<Scalars['Int']>;
  fuel_rate?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  mdq_factor?: Maybe<Scalars['float8']>;
  mdq_hdd?: Maybe<Scalars['Int']>;
  percent_adder?: Maybe<Scalars['float8']>;
  region_id?: Maybe<Scalars['Int']>;
  unit_conversion?: Maybe<Scalars['float8']>;
};

/** update columns of table "pipelines" */
export enum Pipelines_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DunsNumber = 'duns_number',
  /** column name */
  EndOfCycle = 'end_of_cycle',
  /** column name */
  FuelRate = 'fuel_rate',
  /** column name */
  GeoZip = 'geo_zip',
  /** column name */
  Id = 'id',
  /** column name */
  Interstate = 'interstate',
  /** column name */
  MdqFactor = 'mdq_factor',
  /** column name */
  MdqHdd = 'mdq_hdd',
  /** column name */
  Name = 'name',
  /** column name */
  PercentAdder = 'percent_adder',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  Shortid = 'shortid',
  /** column name */
  UnitConversion = 'unit_conversion',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Pipelines_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Pipelines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Pipelines_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pipelines_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Pipelines_Var_Pop_Fields = {
  __typename?: 'pipelines_var_pop_fields';
  end_of_cycle?: Maybe<Scalars['Float']>;
  fuel_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq_factor?: Maybe<Scalars['Float']>;
  mdq_hdd?: Maybe<Scalars['Float']>;
  percent_adder?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  unit_conversion?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Pipelines_Var_Samp_Fields = {
  __typename?: 'pipelines_var_samp_fields';
  end_of_cycle?: Maybe<Scalars['Float']>;
  fuel_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq_factor?: Maybe<Scalars['Float']>;
  mdq_hdd?: Maybe<Scalars['Float']>;
  percent_adder?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  unit_conversion?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Pipelines_Variance_Fields = {
  __typename?: 'pipelines_variance_fields';
  end_of_cycle?: Maybe<Scalars['Float']>;
  fuel_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mdq_factor?: Maybe<Scalars['Float']>;
  mdq_hdd?: Maybe<Scalars['Float']>;
  percent_adder?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  unit_conversion?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "point". All fields are combined with logical 'AND'. */
export type Point_Comparison_Exp = {
  _eq?: Maybe<Scalars['point']>;
  _gt?: Maybe<Scalars['point']>;
  _gte?: Maybe<Scalars['point']>;
  _in?: Maybe<Array<Scalars['point']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['point']>;
  _lte?: Maybe<Scalars['point']>;
  _neq?: Maybe<Scalars['point']>;
  _nin?: Maybe<Array<Scalars['point']>>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  ach_queues: Array<Ach_Queues>;
  /** An aggregate relationship */
  ach_queues_aggregate: Ach_Queues_Aggregate;
  /** fetch data from the table: "ach_queues" using primary key columns */
  ach_queues_by_pk?: Maybe<Ach_Queues>;
  /** fetch data from the table: "active_storage_blobs" */
  active_storage_blobs: Array<Active_Storage_Blobs>;
  /** fetch aggregated fields from the table: "active_storage_blobs" */
  active_storage_blobs_aggregate: Active_Storage_Blobs_Aggregate;
  /** fetch data from the table: "active_storage_blobs" using primary key columns */
  active_storage_blobs_by_pk?: Maybe<Active_Storage_Blobs>;
  /** An array relationship */
  addresses: Array<Addresses>;
  /** An aggregate relationship */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** An array relationship */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** An aggregate relationship */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table: "auth.rbac_role_acls" */
  auth_rbac_role_acls: Array<Auth_Rbac_Role_Acls>;
  /** fetch aggregated fields from the table: "auth.rbac_role_acls" */
  auth_rbac_role_acls_aggregate: Auth_Rbac_Role_Acls_Aggregate;
  /** fetch data from the table: "auth.rbac_role_acls" using primary key columns */
  auth_rbac_role_acls_by_pk?: Maybe<Auth_Rbac_Role_Acls>;
  /** fetch data from the table: "auth.rbac_roles" */
  auth_rbac_roles: Array<Auth_Rbac_Roles>;
  /** fetch aggregated fields from the table: "auth.rbac_roles" */
  auth_rbac_roles_aggregate: Auth_Rbac_Roles_Aggregate;
  /** fetch data from the table: "auth.rbac_roles" using primary key columns */
  auth_rbac_roles_by_pk?: Maybe<Auth_Rbac_Roles>;
  /** fetch data from the table: "auth.users" */
  auth_users: Array<Auth_Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  auth_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "auth.users" using primary key columns */
  auth_users_by_pk?: Maybe<Auth_Users>;
  /** An array relationship */
  balance_dues: Array<Balance_Dues>;
  /** An aggregate relationship */
  balance_dues_aggregate: Balance_Dues_Aggregate;
  /** fetch data from the table: "balance_dues" using primary key columns */
  balance_dues_by_pk?: Maybe<Balance_Dues>;
  /** fetch data from the table: "bhe_accounts" */
  bhe_accounts: Array<Bhe_Accounts>;
  /** fetch aggregated fields from the table: "bhe_accounts" */
  bhe_accounts_aggregate: Bhe_Accounts_Aggregate;
  /** fetch data from the table: "bhe_accounts" using primary key columns */
  bhe_accounts_by_pk?: Maybe<Bhe_Accounts>;
  /** An array relationship */
  bhe_invoices: Array<Bhe_Invoices>;
  /** An aggregate relationship */
  bhe_invoices_aggregate: Bhe_Invoices_Aggregate;
  /** fetch data from the table: "bhe_invoices" using primary key columns */
  bhe_invoices_by_pk?: Maybe<Bhe_Invoices>;
  combinedUserById?: Maybe<CombinedUser>;
  /** fetch data from the table: "commissions" */
  commissions: Array<Commissions>;
  /** fetch aggregated fields from the table: "commissions" */
  commissions_aggregate: Commissions_Aggregate;
  /** fetch data from the table: "commissions" using primary key columns */
  commissions_by_pk?: Maybe<Commissions>;
  /** An array relationship */
  contact_assignments: Array<Contact_Assignments>;
  /** An aggregate relationship */
  contact_assignments_aggregate: Contact_Assignments_Aggregate;
  /** fetch data from the table: "contact_assignments" using primary key columns */
  contact_assignments_by_pk?: Maybe<Contact_Assignments>;
  /** fetch data from the table: "contact_entries" */
  contact_entries: Array<Contact_Entries>;
  /** fetch aggregated fields from the table: "contact_entries" */
  contact_entries_aggregate: Contact_Entries_Aggregate;
  /** fetch data from the table: "contact_entries" using primary key columns */
  contact_entries_by_pk?: Maybe<Contact_Entries>;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "customer_categories" */
  customer_categories: Array<Customer_Categories>;
  /** fetch aggregated fields from the table: "customer_categories" */
  customer_categories_aggregate: Customer_Categories_Aggregate;
  /** fetch data from the table: "customer_categories" using primary key columns */
  customer_categories_by_pk?: Maybe<Customer_Categories>;
  /** An array relationship */
  customer_documents_view: Array<Customer_Documents_View>;
  /** An aggregate relationship */
  customer_documents_view_aggregate: Customer_Documents_View_Aggregate;
  /** An array relationship */
  customer_groups: Array<Customer_Groups>;
  /** An aggregate relationship */
  customer_groups_aggregate: Customer_Groups_Aggregate;
  /** fetch data from the table: "customer_groups" using primary key columns */
  customer_groups_by_pk?: Maybe<Customer_Groups>;
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  /** An array relationship */
  customers_users: Array<Customers_Users>;
  /** An aggregate relationship */
  customers_users_aggregate: Customers_Users_Aggregate;
  /** fetch data from the table: "customers_users" using primary key columns */
  customers_users_by_pk?: Maybe<Customers_Users>;
  /** fetch data from the table: "cyborg_queries" */
  cyborg_queries: Array<Cyborg_Queries>;
  /** fetch aggregated fields from the table: "cyborg_queries" */
  cyborg_queries_aggregate: Cyborg_Queries_Aggregate;
  /** fetch data from the table: "cyborg_queries" using primary key columns */
  cyborg_queries_by_pk?: Maybe<Cyborg_Queries>;
  /** An array relationship */
  deal_detail_volumes: Array<Deal_Detail_Volumes>;
  /** An aggregate relationship */
  deal_detail_volumes_aggregate: Deal_Detail_Volumes_Aggregate;
  /** fetch data from the table: "deal_detail_volumes" using primary key columns */
  deal_detail_volumes_by_pk?: Maybe<Deal_Detail_Volumes>;
  /** An array relationship */
  deal_detail_volumes_sum_by_beg_date: Array<Deal_Detail_Volumes_Sum_By_Beg_Date>;
  /** An aggregate relationship */
  deal_detail_volumes_sum_by_beg_date_aggregate: Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate;
  /** An array relationship */
  deal_details: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_aggregate: Deal_Details_Aggregate;
  /** fetch data from the table: "deal_details" using primary key columns */
  deal_details_by_pk?: Maybe<Deal_Details>;
  /** fetch data from the table: "dealmakers" */
  dealmakers: Array<Dealmakers>;
  /** fetch aggregated fields from the table: "dealmakers" */
  dealmakers_aggregate: Dealmakers_Aggregate;
  /** fetch data from the table: "dealmakers" using primary key columns */
  dealmakers_by_pk?: Maybe<Dealmakers>;
  /** An array relationship */
  deals: Array<Deals>;
  /** An aggregate relationship */
  deals_aggregate: Deals_Aggregate;
  /** fetch data from the table: "deals" using primary key columns */
  deals_by_pk?: Maybe<Deals>;
  /** fetch data from the table: "deals_detail_preview" */
  deals_detail_preview: Array<Deals_Detail_Preview>;
  /** fetch aggregated fields from the table: "deals_detail_preview" */
  deals_detail_preview_aggregate: Deals_Detail_Preview_Aggregate;
  /** fetch data from the table: "email.email_attachments" */
  email_email_attachments: Array<Email_Email_Attachments>;
  /** fetch aggregated fields from the table: "email.email_attachments" */
  email_email_attachments_aggregate: Email_Email_Attachments_Aggregate;
  /** fetch data from the table: "email.email_attachments" using primary key columns */
  email_email_attachments_by_pk?: Maybe<Email_Email_Attachments>;
  /** fetch data from the table: "email.emails" */
  email_emails: Array<Email_Emails>;
  /** fetch aggregated fields from the table: "email.emails" */
  email_emails_aggregate: Email_Emails_Aggregate;
  /** fetch data from the table: "email.emails" using primary key columns */
  email_emails_by_pk?: Maybe<Email_Emails>;
  /** fetch data from the table: "enum_types" */
  enum_types: Array<Enum_Types>;
  /** fetch aggregated fields from the table: "enum_types" */
  enum_types_aggregate: Enum_Types_Aggregate;
  /** fetch data from the table: "fee_types" */
  fee_types: Array<Fee_Types>;
  /** fetch aggregated fields from the table: "fee_types" */
  fee_types_aggregate: Fee_Types_Aggregate;
  /** fetch data from the table: "fee_types" using primary key columns */
  fee_types_by_pk?: Maybe<Fee_Types>;
  /** fetch data from the table: "fees" */
  fees: Array<Fees>;
  /** fetch aggregated fields from the table: "fees" */
  fees_aggregate: Fees_Aggregate;
  /** fetch data from the table: "fees" using primary key columns */
  fees_by_pk?: Maybe<Fees>;
  /** fetch data from the table: "files.document_render_results" */
  files_document_render_results: Array<Files_Document_Render_Results>;
  /** fetch aggregated fields from the table: "files.document_render_results" */
  files_document_render_results_aggregate: Files_Document_Render_Results_Aggregate;
  /** fetch data from the table: "files.document_render_results" using primary key columns */
  files_document_render_results_by_pk?: Maybe<Files_Document_Render_Results>;
  /** fetch data from the table: "files.document_template_executions" */
  files_document_template_executions: Array<Files_Document_Template_Executions>;
  /** fetch aggregated fields from the table: "files.document_template_executions" */
  files_document_template_executions_aggregate: Files_Document_Template_Executions_Aggregate;
  /** fetch data from the table: "files.document_template_executions" using primary key columns */
  files_document_template_executions_by_pk?: Maybe<Files_Document_Template_Executions>;
  /** fetch data from the table: "files.document_templates" */
  files_document_templates: Array<Files_Document_Templates>;
  /** fetch aggregated fields from the table: "files.document_templates" */
  files_document_templates_aggregate: Files_Document_Templates_Aggregate;
  /** fetch data from the table: "files.document_templates" using primary key columns */
  files_document_templates_by_pk?: Maybe<Files_Document_Templates>;
  /** fetch data from the table: "files.pdf_render" */
  files_pdf_render: Array<Files_Pdf_Render>;
  /** fetch aggregated fields from the table: "files.pdf_render" */
  files_pdf_render_aggregate: Files_Pdf_Render_Aggregate;
  /** fetch data from the table: "files.pdf_render" using primary key columns */
  files_pdf_render_by_pk?: Maybe<Files_Pdf_Render>;
  /** fetch data from the table: "forecast_types" */
  forecast_types: Array<Forecast_Types>;
  /** fetch aggregated fields from the table: "forecast_types" */
  forecast_types_aggregate: Forecast_Types_Aggregate;
  /** fetch data from the table: "forecast_types" using primary key columns */
  forecast_types_by_pk?: Maybe<Forecast_Types>;
  /** fetch data from the table: "group_types" */
  group_types: Array<Group_Types>;
  /** fetch aggregated fields from the table: "group_types" */
  group_types_aggregate: Group_Types_Aggregate;
  /** fetch data from the table: "group_types" using primary key columns */
  group_types_by_pk?: Maybe<Group_Types>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table: "ind_types" */
  ind_types: Array<Ind_Types>;
  /** fetch aggregated fields from the table: "ind_types" */
  ind_types_aggregate: Ind_Types_Aggregate;
  /** fetch data from the table: "ind_types" using primary key columns */
  ind_types_by_pk?: Maybe<Ind_Types>;
  /** fetch data from the table: "indices" */
  indices: Array<Indices>;
  /** fetch aggregated fields from the table: "indices" */
  indices_aggregate: Indices_Aggregate;
  /** fetch data from the table: "indices" using primary key columns */
  indices_by_pk?: Maybe<Indices>;
  /** fetch data from the table: "invitations" */
  invitations: Array<Invitations>;
  /** fetch aggregated fields from the table: "invitations" */
  invitations_aggregate: Invitations_Aggregate;
  /** fetch data from the table: "invitations" using primary key columns */
  invitations_by_pk?: Maybe<Invitations>;
  /** An array relationship */
  invitations_customers: Array<Invitations_Customers>;
  /** An aggregate relationship */
  invitations_customers_aggregate: Invitations_Customers_Aggregate;
  /** fetch data from the table: "invitations_customers" using primary key columns */
  invitations_customers_by_pk?: Maybe<Invitations_Customers>;
  /** Get a signed invoice PDF URL by invoice number */
  invoiceFileUrl?: Maybe<Scalars['String']>;
  /** fetch data from the table: "invoice_aggregate" */
  invoice_aggregate: Array<Invoice_Aggregate>;
  /** fetch aggregated fields from the table: "invoice_aggregate" */
  invoice_aggregate_aggregate: Invoice_Aggregate_Aggregate;
  /** An array relationship */
  invoice_data: Array<Invoice_Data>;
  /** An aggregate relationship */
  invoice_data_aggregate: Invoice_Data_Aggregate;
  /** fetch data from the table: "invoice_data" using primary key columns */
  invoice_data_by_pk?: Maybe<Invoice_Data>;
  /** An array relationship */
  invoice_data_sum_by_month: Array<Invoice_Data_Sum_By_Month>;
  /** An aggregate relationship */
  invoice_data_sum_by_month_aggregate: Invoice_Data_Sum_By_Month_Aggregate;
  /** An array relationship */
  invoice_numbers: Array<Invoice_Numbers>;
  /** An aggregate relationship */
  invoice_numbers_aggregate: Invoice_Numbers_Aggregate;
  /** fetch data from the table: "invoice_numbers" using primary key columns */
  invoice_numbers_by_pk?: Maybe<Invoice_Numbers>;
  /** An array relationship */
  ldc_accounts_view: Array<Ldc_Accounts_View>;
  /** An aggregate relationship */
  ldc_accounts_view_aggregate: Ldc_Accounts_View_Aggregate;
  /** An array relationship */
  ldc_invoices_view: Array<Ldc_Invoices_View>;
  /** An aggregate relationship */
  ldc_invoices_view_aggregate: Ldc_Invoices_View_Aggregate;
  /** An array relationship */
  ldc_service_charges_view: Array<Ldc_Service_Charges_View>;
  /** An aggregate relationship */
  ldc_service_charges_view_aggregate: Ldc_Service_Charges_View_Aggregate;
  /** fetch data from the table: "load_forecasts" */
  load_forecasts: Array<Load_Forecasts>;
  /** fetch aggregated fields from the table: "load_forecasts" */
  load_forecasts_aggregate: Load_Forecasts_Aggregate;
  /** fetch data from the table: "load_forecasts" using primary key columns */
  load_forecasts_by_pk?: Maybe<Load_Forecasts>;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  logs: Array<Logs>;
  /** An aggregate relationship */
  logs_aggregate: Logs_Aggregate;
  /** fetch data from the table: "logs" using primary key columns */
  logs_by_pk?: Maybe<Logs>;
  /** fetch data from the table: "params" */
  params: Array<Params>;
  /** fetch aggregated fields from the table: "params" */
  params_aggregate: Params_Aggregate;
  /** fetch data from the table: "params" using primary key columns */
  params_by_pk?: Maybe<Params>;
  /** An array relationship */
  paths: Array<Paths>;
  /** An aggregate relationship */
  paths_aggregate: Paths_Aggregate;
  /** fetch data from the table: "paths" using primary key columns */
  paths_by_pk?: Maybe<Paths>;
  /** Health check endpoint */
  ping?: Maybe<Scalars['String']>;
  /** fetch data from the table: "pipelines" */
  pipelines: Array<Pipelines>;
  /** fetch aggregated fields from the table: "pipelines" */
  pipelines_aggregate: Pipelines_Aggregate;
  /** fetch data from the table: "pipelines" using primary key columns */
  pipelines_by_pk?: Maybe<Pipelines>;
  /** An array relationship */
  records: Array<Records>;
  /** An aggregate relationship */
  records_aggregate: Records_Aggregate;
  /** fetch data from the table: "records" using primary key columns */
  records_by_pk?: Maybe<Records>;
  /** fetch data from the table: "report_dashboard" */
  report_dashboard: Array<Report_Dashboard>;
  /** fetch aggregated fields from the table: "report_dashboard" */
  report_dashboard_aggregate: Report_Dashboard_Aggregate;
  /** fetch data from the table: "report_dashboard" using primary key columns */
  report_dashboard_by_pk?: Maybe<Report_Dashboard>;
  /** fetch data from the table: "risk_types" */
  risk_types: Array<Risk_Types>;
  /** fetch aggregated fields from the table: "risk_types" */
  risk_types_aggregate: Risk_Types_Aggregate;
  /** fetch data from the table: "risk_types" using primary key columns */
  risk_types_by_pk?: Maybe<Risk_Types>;
  /** execute function "search_addresses" which returns "addresses" */
  search_addresses: Array<Addresses>;
  /** execute function "search_addresses" and query aggregates on result of table type "addresses" */
  search_addresses_aggregate: Addresses_Aggregate;
  /** execute function "search_contact_entries" which returns "contact_entries" */
  search_contact_entries: Array<Contact_Entries>;
  /** execute function "search_contact_entries" and query aggregates on result of table type "contact_entries" */
  search_contact_entries_aggregate: Contact_Entries_Aggregate;
  /** execute function "search_customers" which returns "customers" */
  search_customers: Array<Customers>;
  /** execute function "search_customers" and query aggregates on result of table type "customers" */
  search_customers_aggregate: Customers_Aggregate;
  /** execute function "search_deals" which returns "deals" */
  search_deals: Array<Deals>;
  /** execute function "search_deals" and query aggregates on result of table type "deals" */
  search_deals_aggregate: Deals_Aggregate;
  /** execute function "search_groups" which returns "groups" */
  search_groups: Array<Groups>;
  /** execute function "search_groups" and query aggregates on result of table type "groups" */
  search_groups_aggregate: Groups_Aggregate;
  /** execute function "search_users" which returns "auth.users" */
  search_users: Array<Auth_Users>;
  /** execute function "search_users" and query aggregates on result of table type "auth.users" */
  search_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "users_data" */
  users_data: Array<Users_Data>;
  /** fetch aggregated fields from the table: "users_data" */
  users_data_aggregate: Users_Data_Aggregate;
  /** fetch data from the table: "users_data" using primary key columns */
  users_data_by_pk?: Maybe<Users_Data>;
  wrec_meeting: Array<Wrec_Meeting>;
  wrec_meeting_aggregate: Wrec_Meeting_Aggregate;
  /** fetch data from the table: "wrec.meeting" using primary key columns */
  wrec_meeting_by_pk?: Maybe<Wrec_Meeting>;
};


export type Query_RootAch_QueuesArgs = {
  distinct_on?: Maybe<Array<Ach_Queues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ach_Queues_Order_By>>;
  where?: Maybe<Ach_Queues_Bool_Exp>;
};


export type Query_RootAch_Queues_AggregateArgs = {
  distinct_on?: Maybe<Array<Ach_Queues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ach_Queues_Order_By>>;
  where?: Maybe<Ach_Queues_Bool_Exp>;
};


export type Query_RootAch_Queues_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootActive_Storage_BlobsArgs = {
  distinct_on?: Maybe<Array<Active_Storage_Blobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Active_Storage_Blobs_Order_By>>;
  where?: Maybe<Active_Storage_Blobs_Bool_Exp>;
};


export type Query_RootActive_Storage_Blobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Active_Storage_Blobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Active_Storage_Blobs_Order_By>>;
  where?: Maybe<Active_Storage_Blobs_Bool_Exp>;
};


export type Query_RootActive_Storage_Blobs_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAddressesArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAudit_Logged_ActionsArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Query_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Query_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint'];
};


export type Query_RootAuth_Rbac_Role_AclsArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Role_Acls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Role_Acls_Order_By>>;
  where?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
};


export type Query_RootAuth_Rbac_Role_Acls_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Role_Acls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Role_Acls_Order_By>>;
  where?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
};


export type Query_RootAuth_Rbac_Role_Acls_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAuth_Rbac_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Roles_Order_By>>;
  where?: Maybe<Auth_Rbac_Roles_Bool_Exp>;
};


export type Query_RootAuth_Rbac_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Roles_Order_By>>;
  where?: Maybe<Auth_Rbac_Roles_Bool_Exp>;
};


export type Query_RootAuth_Rbac_Roles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAuth_UsersArgs = {
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


export type Query_RootAuth_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


export type Query_RootAuth_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBalance_DuesArgs = {
  distinct_on?: Maybe<Array<Balance_Dues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balance_Dues_Order_By>>;
  where?: Maybe<Balance_Dues_Bool_Exp>;
};


export type Query_RootBalance_Dues_AggregateArgs = {
  distinct_on?: Maybe<Array<Balance_Dues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balance_Dues_Order_By>>;
  where?: Maybe<Balance_Dues_Bool_Exp>;
};


export type Query_RootBalance_Dues_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBhe_AccountsArgs = {
  distinct_on?: Maybe<Array<Bhe_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Accounts_Order_By>>;
  where?: Maybe<Bhe_Accounts_Bool_Exp>;
};


export type Query_RootBhe_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Bhe_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Accounts_Order_By>>;
  where?: Maybe<Bhe_Accounts_Bool_Exp>;
};


export type Query_RootBhe_Accounts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBhe_InvoicesArgs = {
  distinct_on?: Maybe<Array<Bhe_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Invoices_Order_By>>;
  where?: Maybe<Bhe_Invoices_Bool_Exp>;
};


export type Query_RootBhe_Invoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Bhe_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Invoices_Order_By>>;
  where?: Maybe<Bhe_Invoices_Bool_Exp>;
};


export type Query_RootBhe_Invoices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCombinedUserByIdArgs = {
  id: Scalars['Float'];
};


export type Query_RootCommissionsArgs = {
  distinct_on?: Maybe<Array<Commissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Commissions_Order_By>>;
  where?: Maybe<Commissions_Bool_Exp>;
};


export type Query_RootCommissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Commissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Commissions_Order_By>>;
  where?: Maybe<Commissions_Bool_Exp>;
};


export type Query_RootCommissions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContact_AssignmentsArgs = {
  distinct_on?: Maybe<Array<Contact_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Assignments_Order_By>>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};


export type Query_RootContact_Assignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Assignments_Order_By>>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};


export type Query_RootContact_Assignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContact_EntriesArgs = {
  distinct_on?: Maybe<Array<Contact_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Entries_Order_By>>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};


export type Query_RootContact_Entries_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Entries_Order_By>>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};


export type Query_RootContact_Entries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_CategoriesArgs = {
  distinct_on?: Maybe<Array<Customer_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Categories_Order_By>>;
  where?: Maybe<Customer_Categories_Bool_Exp>;
};


export type Query_RootCustomer_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Categories_Order_By>>;
  where?: Maybe<Customer_Categories_Bool_Exp>;
};


export type Query_RootCustomer_Categories_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomer_Documents_ViewArgs = {
  distinct_on?: Maybe<Array<Customer_Documents_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Documents_View_Order_By>>;
  where?: Maybe<Customer_Documents_View_Bool_Exp>;
};


export type Query_RootCustomer_Documents_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Documents_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Documents_View_Order_By>>;
  where?: Maybe<Customer_Documents_View_Bool_Exp>;
};


export type Query_RootCustomer_GroupsArgs = {
  distinct_on?: Maybe<Array<Customer_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Groups_Order_By>>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};


export type Query_RootCustomer_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Groups_Order_By>>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};


export type Query_RootCustomer_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Query_RootCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Query_RootCustomers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomers_UsersArgs = {
  distinct_on?: Maybe<Array<Customers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Users_Order_By>>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};


export type Query_RootCustomers_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Users_Order_By>>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};


export type Query_RootCustomers_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCyborg_QueriesArgs = {
  distinct_on?: Maybe<Array<Cyborg_Queries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cyborg_Queries_Order_By>>;
  where?: Maybe<Cyborg_Queries_Bool_Exp>;
};


export type Query_RootCyborg_Queries_AggregateArgs = {
  distinct_on?: Maybe<Array<Cyborg_Queries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cyborg_Queries_Order_By>>;
  where?: Maybe<Cyborg_Queries_Bool_Exp>;
};


export type Query_RootCyborg_Queries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDeal_Detail_VolumesArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
};


export type Query_RootDeal_Detail_Volumes_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
};


export type Query_RootDeal_Detail_Volumes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDeal_Detail_Volumes_Sum_By_Beg_DateArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
};


export type Query_RootDeal_Detail_Volumes_Sum_By_Beg_Date_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
};


export type Query_RootDeal_DetailsArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


export type Query_RootDeal_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


export type Query_RootDeal_Details_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDealmakersArgs = {
  distinct_on?: Maybe<Array<Dealmakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealmakers_Order_By>>;
  where?: Maybe<Dealmakers_Bool_Exp>;
};


export type Query_RootDealmakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Dealmakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealmakers_Order_By>>;
  where?: Maybe<Dealmakers_Bool_Exp>;
};


export type Query_RootDealmakers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDealsArgs = {
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


export type Query_RootDeals_AggregateArgs = {
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


export type Query_RootDeals_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDeals_Detail_PreviewArgs = {
  distinct_on?: Maybe<Array<Deals_Detail_Preview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Detail_Preview_Order_By>>;
  where?: Maybe<Deals_Detail_Preview_Bool_Exp>;
};


export type Query_RootDeals_Detail_Preview_AggregateArgs = {
  distinct_on?: Maybe<Array<Deals_Detail_Preview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Detail_Preview_Order_By>>;
  where?: Maybe<Deals_Detail_Preview_Bool_Exp>;
};


export type Query_RootEmail_Email_AttachmentsArgs = {
  distinct_on?: Maybe<Array<Email_Email_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Email_Attachments_Order_By>>;
  where?: Maybe<Email_Email_Attachments_Bool_Exp>;
};


export type Query_RootEmail_Email_Attachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Email_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Email_Attachments_Order_By>>;
  where?: Maybe<Email_Email_Attachments_Bool_Exp>;
};


export type Query_RootEmail_Email_Attachments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEmail_EmailsArgs = {
  distinct_on?: Maybe<Array<Email_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Emails_Order_By>>;
  where?: Maybe<Email_Emails_Bool_Exp>;
};


export type Query_RootEmail_Emails_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Emails_Order_By>>;
  where?: Maybe<Email_Emails_Bool_Exp>;
};


export type Query_RootEmail_Emails_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEnum_TypesArgs = {
  distinct_on?: Maybe<Array<Enum_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Types_Order_By>>;
  where?: Maybe<Enum_Types_Bool_Exp>;
};


export type Query_RootEnum_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Types_Order_By>>;
  where?: Maybe<Enum_Types_Bool_Exp>;
};


export type Query_RootFee_TypesArgs = {
  distinct_on?: Maybe<Array<Fee_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fee_Types_Order_By>>;
  where?: Maybe<Fee_Types_Bool_Exp>;
};


export type Query_RootFee_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Fee_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fee_Types_Order_By>>;
  where?: Maybe<Fee_Types_Bool_Exp>;
};


export type Query_RootFee_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFeesArgs = {
  distinct_on?: Maybe<Array<Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fees_Order_By>>;
  where?: Maybe<Fees_Bool_Exp>;
};


export type Query_RootFees_AggregateArgs = {
  distinct_on?: Maybe<Array<Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fees_Order_By>>;
  where?: Maybe<Fees_Bool_Exp>;
};


export type Query_RootFees_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFiles_Document_Render_ResultsArgs = {
  distinct_on?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Render_Results_Order_By>>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};


export type Query_RootFiles_Document_Render_Results_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Render_Results_Order_By>>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};


export type Query_RootFiles_Document_Render_Results_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFiles_Document_Template_ExecutionsArgs = {
  distinct_on?: Maybe<Array<Files_Document_Template_Executions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Template_Executions_Order_By>>;
  where?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
};


export type Query_RootFiles_Document_Template_Executions_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Document_Template_Executions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Template_Executions_Order_By>>;
  where?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
};


export type Query_RootFiles_Document_Template_Executions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFiles_Document_TemplatesArgs = {
  distinct_on?: Maybe<Array<Files_Document_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Templates_Order_By>>;
  where?: Maybe<Files_Document_Templates_Bool_Exp>;
};


export type Query_RootFiles_Document_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Document_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Templates_Order_By>>;
  where?: Maybe<Files_Document_Templates_Bool_Exp>;
};


export type Query_RootFiles_Document_Templates_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFiles_Pdf_RenderArgs = {
  distinct_on?: Maybe<Array<Files_Pdf_Render_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Pdf_Render_Order_By>>;
  where?: Maybe<Files_Pdf_Render_Bool_Exp>;
};


export type Query_RootFiles_Pdf_Render_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Pdf_Render_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Pdf_Render_Order_By>>;
  where?: Maybe<Files_Pdf_Render_Bool_Exp>;
};


export type Query_RootFiles_Pdf_Render_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootForecast_TypesArgs = {
  distinct_on?: Maybe<Array<Forecast_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Types_Order_By>>;
  where?: Maybe<Forecast_Types_Bool_Exp>;
};


export type Query_RootForecast_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Types_Order_By>>;
  where?: Maybe<Forecast_Types_Bool_Exp>;
};


export type Query_RootForecast_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGroup_TypesArgs = {
  distinct_on?: Maybe<Array<Group_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Types_Order_By>>;
  where?: Maybe<Group_Types_Bool_Exp>;
};


export type Query_RootGroup_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Types_Order_By>>;
  where?: Maybe<Group_Types_Bool_Exp>;
};


export type Query_RootGroup_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGroupsArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootInd_TypesArgs = {
  distinct_on?: Maybe<Array<Ind_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ind_Types_Order_By>>;
  where?: Maybe<Ind_Types_Bool_Exp>;
};


export type Query_RootInd_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Ind_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ind_Types_Order_By>>;
  where?: Maybe<Ind_Types_Bool_Exp>;
};


export type Query_RootInd_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIndicesArgs = {
  distinct_on?: Maybe<Array<Indices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Indices_Order_By>>;
  where?: Maybe<Indices_Bool_Exp>;
};


export type Query_RootIndices_AggregateArgs = {
  distinct_on?: Maybe<Array<Indices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Indices_Order_By>>;
  where?: Maybe<Indices_Bool_Exp>;
};


export type Query_RootIndices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvitationsArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};


export type Query_RootInvitations_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};


export type Query_RootInvitations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvitations_CustomersArgs = {
  distinct_on?: Maybe<Array<Invitations_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Customers_Order_By>>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};


export type Query_RootInvitations_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitations_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Customers_Order_By>>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};


export type Query_RootInvitations_Customers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvoiceFileUrlArgs = {
  invoiceNumber: Scalars['ID'];
};


export type Query_RootInvoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Aggregate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Aggregate_Order_By>>;
  where?: Maybe<Invoice_Aggregate_Bool_Exp>;
};


export type Query_RootInvoice_Aggregate_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Aggregate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Aggregate_Order_By>>;
  where?: Maybe<Invoice_Aggregate_Bool_Exp>;
};


export type Query_RootInvoice_DataArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


export type Query_RootInvoice_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


export type Query_RootInvoice_Data_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvoice_Data_Sum_By_MonthArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Sum_By_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Sum_By_Month_Order_By>>;
  where?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
};


export type Query_RootInvoice_Data_Sum_By_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Sum_By_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Sum_By_Month_Order_By>>;
  where?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
};


export type Query_RootInvoice_NumbersArgs = {
  distinct_on?: Maybe<Array<Invoice_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Numbers_Order_By>>;
  where?: Maybe<Invoice_Numbers_Bool_Exp>;
};


export type Query_RootInvoice_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Numbers_Order_By>>;
  where?: Maybe<Invoice_Numbers_Bool_Exp>;
};


export type Query_RootInvoice_Numbers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLdc_Accounts_ViewArgs = {
  distinct_on?: Maybe<Array<Ldc_Accounts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Accounts_View_Order_By>>;
  where?: Maybe<Ldc_Accounts_View_Bool_Exp>;
};


export type Query_RootLdc_Accounts_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Ldc_Accounts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Accounts_View_Order_By>>;
  where?: Maybe<Ldc_Accounts_View_Bool_Exp>;
};


export type Query_RootLdc_Invoices_ViewArgs = {
  distinct_on?: Maybe<Array<Ldc_Invoices_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Invoices_View_Order_By>>;
  where?: Maybe<Ldc_Invoices_View_Bool_Exp>;
};


export type Query_RootLdc_Invoices_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Ldc_Invoices_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Invoices_View_Order_By>>;
  where?: Maybe<Ldc_Invoices_View_Bool_Exp>;
};


export type Query_RootLdc_Service_Charges_ViewArgs = {
  distinct_on?: Maybe<Array<Ldc_Service_Charges_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Service_Charges_View_Order_By>>;
  where?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
};


export type Query_RootLdc_Service_Charges_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Ldc_Service_Charges_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Service_Charges_View_Order_By>>;
  where?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
};


export type Query_RootLoad_ForecastsArgs = {
  distinct_on?: Maybe<Array<Load_Forecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_Forecasts_Order_By>>;
  where?: Maybe<Load_Forecasts_Bool_Exp>;
};


export type Query_RootLoad_Forecasts_AggregateArgs = {
  distinct_on?: Maybe<Array<Load_Forecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_Forecasts_Order_By>>;
  where?: Maybe<Load_Forecasts_Bool_Exp>;
};


export type Query_RootLoad_Forecasts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Query_RootLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Query_RootLogs_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootParamsArgs = {
  distinct_on?: Maybe<Array<Params_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Params_Order_By>>;
  where?: Maybe<Params_Bool_Exp>;
};


export type Query_RootParams_AggregateArgs = {
  distinct_on?: Maybe<Array<Params_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Params_Order_By>>;
  where?: Maybe<Params_Bool_Exp>;
};


export type Query_RootParams_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPathsArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};


export type Query_RootPaths_AggregateArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};


export type Query_RootPaths_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPipelinesArgs = {
  distinct_on?: Maybe<Array<Pipelines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pipelines_Order_By>>;
  where?: Maybe<Pipelines_Bool_Exp>;
};


export type Query_RootPipelines_AggregateArgs = {
  distinct_on?: Maybe<Array<Pipelines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pipelines_Order_By>>;
  where?: Maybe<Pipelines_Bool_Exp>;
};


export type Query_RootPipelines_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRecordsArgs = {
  distinct_on?: Maybe<Array<Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Records_Order_By>>;
  where?: Maybe<Records_Bool_Exp>;
};


export type Query_RootRecords_AggregateArgs = {
  distinct_on?: Maybe<Array<Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Records_Order_By>>;
  where?: Maybe<Records_Bool_Exp>;
};


export type Query_RootRecords_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootReport_DashboardArgs = {
  distinct_on?: Maybe<Array<Report_Dashboard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Dashboard_Order_By>>;
  where?: Maybe<Report_Dashboard_Bool_Exp>;
};


export type Query_RootReport_Dashboard_AggregateArgs = {
  distinct_on?: Maybe<Array<Report_Dashboard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Dashboard_Order_By>>;
  where?: Maybe<Report_Dashboard_Bool_Exp>;
};


export type Query_RootReport_Dashboard_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRisk_TypesArgs = {
  distinct_on?: Maybe<Array<Risk_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Risk_Types_Order_By>>;
  where?: Maybe<Risk_Types_Bool_Exp>;
};


export type Query_RootRisk_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Risk_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Risk_Types_Order_By>>;
  where?: Maybe<Risk_Types_Bool_Exp>;
};


export type Query_RootRisk_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSearch_AddressesArgs = {
  args: Search_Addresses_Args;
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Query_RootSearch_Addresses_AggregateArgs = {
  args: Search_Addresses_Args;
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Query_RootSearch_Contact_EntriesArgs = {
  args: Search_Contact_Entries_Args;
  distinct_on?: Maybe<Array<Contact_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Entries_Order_By>>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};


export type Query_RootSearch_Contact_Entries_AggregateArgs = {
  args: Search_Contact_Entries_Args;
  distinct_on?: Maybe<Array<Contact_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Entries_Order_By>>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};


export type Query_RootSearch_CustomersArgs = {
  args: Search_Customers_Args;
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Query_RootSearch_Customers_AggregateArgs = {
  args: Search_Customers_Args;
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Query_RootSearch_DealsArgs = {
  args: Search_Deals_Args;
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


export type Query_RootSearch_Deals_AggregateArgs = {
  args: Search_Deals_Args;
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


export type Query_RootSearch_GroupsArgs = {
  args: Search_Groups_Args;
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Query_RootSearch_Groups_AggregateArgs = {
  args: Search_Groups_Args;
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Query_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


export type Query_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


export type Query_RootUsers_DataArgs = {
  distinct_on?: Maybe<Array<Users_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Data_Order_By>>;
  where?: Maybe<Users_Data_Bool_Exp>;
};


export type Query_RootUsers_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Data_Order_By>>;
  where?: Maybe<Users_Data_Bool_Exp>;
};


export type Query_RootUsers_Data_By_PkArgs = {
  key: Scalars['String'];
  user_id: Scalars['bigint'];
};


export type Query_RootWrec_MeetingArgs = {
  distinct_on?: Maybe<Array<Wrec_Meeting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wrec_Meeting_Order_By>>;
  where?: Maybe<Wrec_Meeting_Bool_Exp>;
};


export type Query_RootWrec_Meeting_AggregateArgs = {
  distinct_on?: Maybe<Array<Wrec_Meeting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wrec_Meeting_Order_By>>;
  where?: Maybe<Wrec_Meeting_Bool_Exp>;
};


export type Query_RootWrec_Meeting_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "records" */
export type Records = {
  __typename?: 'records';
  category: Scalars['String'];
  created_at: Scalars['timestamp'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  pdf_url?: Maybe<Scalars['String']>;
  private_data?: Maybe<Scalars['jsonb']>;
  public_data?: Maybe<Scalars['jsonb']>;
  render_url?: Maybe<Scalars['String']>;
  search_terms?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user?: Maybe<Auth_Users>;
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "records" */
export type RecordsPrivate_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "records" */
export type RecordsPublic_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "records" */
export type Records_Aggregate = {
  __typename?: 'records_aggregate';
  aggregate?: Maybe<Records_Aggregate_Fields>;
  nodes: Array<Records>;
};

export type Records_Aggregate_Bool_Exp = {
  count?: Maybe<Records_Aggregate_Bool_Exp_Count>;
};

export type Records_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Records_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Records_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "records" */
export type Records_Aggregate_Fields = {
  __typename?: 'records_aggregate_fields';
  avg?: Maybe<Records_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Records_Max_Fields>;
  min?: Maybe<Records_Min_Fields>;
  stddev?: Maybe<Records_Stddev_Fields>;
  stddev_pop?: Maybe<Records_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Records_Stddev_Samp_Fields>;
  sum?: Maybe<Records_Sum_Fields>;
  var_pop?: Maybe<Records_Var_Pop_Fields>;
  var_samp?: Maybe<Records_Var_Samp_Fields>;
  variance?: Maybe<Records_Variance_Fields>;
};


/** aggregate fields of "records" */
export type Records_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Records_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "records" */
export type Records_Aggregate_Order_By = {
  avg?: Maybe<Records_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Records_Max_Order_By>;
  min?: Maybe<Records_Min_Order_By>;
  stddev?: Maybe<Records_Stddev_Order_By>;
  stddev_pop?: Maybe<Records_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Records_Stddev_Samp_Order_By>;
  sum?: Maybe<Records_Sum_Order_By>;
  var_pop?: Maybe<Records_Var_Pop_Order_By>;
  var_samp?: Maybe<Records_Var_Samp_Order_By>;
  variance?: Maybe<Records_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Records_Append_Input = {
  private_data?: Maybe<Scalars['jsonb']>;
  public_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "records" */
export type Records_Arr_Rel_Insert_Input = {
  data: Array<Records_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Records_On_Conflict>;
};

/** aggregate avg on columns */
export type Records_Avg_Fields = {
  __typename?: 'records_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "records" */
export type Records_Avg_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "records". All fields are combined with a logical 'AND'. */
export type Records_Bool_Exp = {
  _and?: Maybe<Array<Records_Bool_Exp>>;
  _not?: Maybe<Records_Bool_Exp>;
  _or?: Maybe<Array<Records_Bool_Exp>>;
  category?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customer?: Maybe<Customers_Bool_Exp>;
  customer_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  pdf_url?: Maybe<String_Comparison_Exp>;
  private_data?: Maybe<Jsonb_Comparison_Exp>;
  public_data?: Maybe<Jsonb_Comparison_Exp>;
  render_url?: Maybe<String_Comparison_Exp>;
  search_terms?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  user?: Maybe<Auth_Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "records" */
export enum Records_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecordsPkey = 'records_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Records_Delete_At_Path_Input = {
  private_data?: Maybe<Array<Scalars['String']>>;
  public_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Records_Delete_Elem_Input = {
  private_data?: Maybe<Scalars['Int']>;
  public_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Records_Delete_Key_Input = {
  private_data?: Maybe<Scalars['String']>;
  public_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "records" */
export type Records_Inc_Input = {
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "records" */
export type Records_Insert_Input = {
  category?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer?: Maybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_url?: Maybe<Scalars['String']>;
  private_data?: Maybe<Scalars['jsonb']>;
  public_data?: Maybe<Scalars['jsonb']>;
  render_url?: Maybe<Scalars['String']>;
  search_terms?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user?: Maybe<Auth_Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Records_Max_Fields = {
  __typename?: 'records_max_fields';
  category?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_url?: Maybe<Scalars['String']>;
  render_url?: Maybe<Scalars['String']>;
  search_terms?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "records" */
export type Records_Max_Order_By = {
  category?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_url?: Maybe<Order_By>;
  render_url?: Maybe<Order_By>;
  search_terms?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Records_Min_Fields = {
  __typename?: 'records_min_fields';
  category?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_url?: Maybe<Scalars['String']>;
  render_url?: Maybe<Scalars['String']>;
  search_terms?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "records" */
export type Records_Min_Order_By = {
  category?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_url?: Maybe<Order_By>;
  render_url?: Maybe<Order_By>;
  search_terms?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "records" */
export type Records_Mutation_Response = {
  __typename?: 'records_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Records>;
};

/** on_conflict condition type for table "records" */
export type Records_On_Conflict = {
  constraint: Records_Constraint;
  update_columns?: Array<Records_Update_Column>;
  where?: Maybe<Records_Bool_Exp>;
};

/** Ordering options when selecting data from "records". */
export type Records_Order_By = {
  category?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customers_Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pdf_url?: Maybe<Order_By>;
  private_data?: Maybe<Order_By>;
  public_data?: Maybe<Order_By>;
  render_url?: Maybe<Order_By>;
  search_terms?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Auth_Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: records */
export type Records_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Records_Prepend_Input = {
  private_data?: Maybe<Scalars['jsonb']>;
  public_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "records" */
export enum Records_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  PdfUrl = 'pdf_url',
  /** column name */
  PrivateData = 'private_data',
  /** column name */
  PublicData = 'public_data',
  /** column name */
  RenderUrl = 'render_url',
  /** column name */
  SearchTerms = 'search_terms',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "records" */
export type Records_Set_Input = {
  category?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_url?: Maybe<Scalars['String']>;
  private_data?: Maybe<Scalars['jsonb']>;
  public_data?: Maybe<Scalars['jsonb']>;
  render_url?: Maybe<Scalars['String']>;
  search_terms?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Records_Stddev_Fields = {
  __typename?: 'records_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "records" */
export type Records_Stddev_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Records_Stddev_Pop_Fields = {
  __typename?: 'records_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "records" */
export type Records_Stddev_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Records_Stddev_Samp_Fields = {
  __typename?: 'records_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "records" */
export type Records_Stddev_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "records" */
export type Records_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Records_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Records_Stream_Cursor_Value_Input = {
  category?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  pdf_url?: Maybe<Scalars['String']>;
  private_data?: Maybe<Scalars['jsonb']>;
  public_data?: Maybe<Scalars['jsonb']>;
  render_url?: Maybe<Scalars['String']>;
  search_terms?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Records_Sum_Fields = {
  __typename?: 'records_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "records" */
export type Records_Sum_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "records" */
export enum Records_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  PdfUrl = 'pdf_url',
  /** column name */
  PrivateData = 'private_data',
  /** column name */
  PublicData = 'public_data',
  /** column name */
  RenderUrl = 'render_url',
  /** column name */
  SearchTerms = 'search_terms',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Records_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Records_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Records_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Records_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Records_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Records_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Records_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Records_Set_Input>;
  /** filter the rows which have to be updated */
  where: Records_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Records_Var_Pop_Fields = {
  __typename?: 'records_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "records" */
export type Records_Var_Pop_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Records_Var_Samp_Fields = {
  __typename?: 'records_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "records" */
export type Records_Var_Samp_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Records_Variance_Fields = {
  __typename?: 'records_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "records" */
export type Records_Variance_Order_By = {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "report_dashboard" */
export type Report_Dashboard = {
  __typename?: 'report_dashboard';
  archived: Scalars['Boolean'];
  /** Whether or not to auto-apply filters on a dashboard */
  auto_apply_filters: Scalars['Boolean'];
  cache_ttl?: Maybe<Scalars['Int']>;
  caveats?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['Int']>;
  collection_position?: Maybe<Scalars['smallint']>;
  created_at: Scalars['timestamptz'];
  creator_id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  embedding_params?: Maybe<Scalars['String']>;
  enable_embedding: Scalars['Boolean'];
  entity_id?: Maybe<Scalars['bpchar']>;
  id: Scalars['Int'];
  made_public_by_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  parameters: Scalars['String'];
  points_of_interest?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  public_uuid?: Maybe<Scalars['bpchar']>;
  show_in_getting_started: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "report_dashboard" */
export type Report_Dashboard_Aggregate = {
  __typename?: 'report_dashboard_aggregate';
  aggregate?: Maybe<Report_Dashboard_Aggregate_Fields>;
  nodes: Array<Report_Dashboard>;
};

/** aggregate fields of "report_dashboard" */
export type Report_Dashboard_Aggregate_Fields = {
  __typename?: 'report_dashboard_aggregate_fields';
  avg?: Maybe<Report_Dashboard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Report_Dashboard_Max_Fields>;
  min?: Maybe<Report_Dashboard_Min_Fields>;
  stddev?: Maybe<Report_Dashboard_Stddev_Fields>;
  stddev_pop?: Maybe<Report_Dashboard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Report_Dashboard_Stddev_Samp_Fields>;
  sum?: Maybe<Report_Dashboard_Sum_Fields>;
  var_pop?: Maybe<Report_Dashboard_Var_Pop_Fields>;
  var_samp?: Maybe<Report_Dashboard_Var_Samp_Fields>;
  variance?: Maybe<Report_Dashboard_Variance_Fields>;
};


/** aggregate fields of "report_dashboard" */
export type Report_Dashboard_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Report_Dashboard_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Report_Dashboard_Avg_Fields = {
  __typename?: 'report_dashboard_avg_fields';
  cache_ttl?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  collection_position?: Maybe<Scalars['Float']>;
  creator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  made_public_by_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "report_dashboard". All fields are combined with a logical 'AND'. */
export type Report_Dashboard_Bool_Exp = {
  _and?: Maybe<Array<Report_Dashboard_Bool_Exp>>;
  _not?: Maybe<Report_Dashboard_Bool_Exp>;
  _or?: Maybe<Array<Report_Dashboard_Bool_Exp>>;
  archived?: Maybe<Boolean_Comparison_Exp>;
  auto_apply_filters?: Maybe<Boolean_Comparison_Exp>;
  cache_ttl?: Maybe<Int_Comparison_Exp>;
  caveats?: Maybe<String_Comparison_Exp>;
  collection_id?: Maybe<Int_Comparison_Exp>;
  collection_position?: Maybe<Smallint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  creator_id?: Maybe<Int_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  embedding_params?: Maybe<String_Comparison_Exp>;
  enable_embedding?: Maybe<Boolean_Comparison_Exp>;
  entity_id?: Maybe<Bpchar_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  made_public_by_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  parameters?: Maybe<String_Comparison_Exp>;
  points_of_interest?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Int_Comparison_Exp>;
  public_uuid?: Maybe<Bpchar_Comparison_Exp>;
  show_in_getting_started?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "report_dashboard" */
export enum Report_Dashboard_Constraint {
  /** unique or primary key constraint on columns "entity_id" */
  ReportDashboardEntityIdKey = 'report_dashboard_entity_id_key',
  /** unique or primary key constraint on columns "id" */
  ReportDashboardPkey = 'report_dashboard_pkey',
  /** unique or primary key constraint on columns "public_uuid" */
  ReportDashboardPublicUuidKey = 'report_dashboard_public_uuid_key'
}

/** input type for incrementing numeric columns in table "report_dashboard" */
export type Report_Dashboard_Inc_Input = {
  cache_ttl?: Maybe<Scalars['Int']>;
  collection_id?: Maybe<Scalars['Int']>;
  collection_position?: Maybe<Scalars['smallint']>;
  creator_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  made_public_by_id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_dashboard" */
export type Report_Dashboard_Insert_Input = {
  archived?: Maybe<Scalars['Boolean']>;
  /** Whether or not to auto-apply filters on a dashboard */
  auto_apply_filters?: Maybe<Scalars['Boolean']>;
  cache_ttl?: Maybe<Scalars['Int']>;
  caveats?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['Int']>;
  collection_position?: Maybe<Scalars['smallint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  embedding_params?: Maybe<Scalars['String']>;
  enable_embedding?: Maybe<Scalars['Boolean']>;
  entity_id?: Maybe<Scalars['bpchar']>;
  id?: Maybe<Scalars['Int']>;
  made_public_by_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['String']>;
  points_of_interest?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  public_uuid?: Maybe<Scalars['bpchar']>;
  show_in_getting_started?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Report_Dashboard_Max_Fields = {
  __typename?: 'report_dashboard_max_fields';
  cache_ttl?: Maybe<Scalars['Int']>;
  caveats?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['Int']>;
  collection_position?: Maybe<Scalars['smallint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  embedding_params?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['bpchar']>;
  id?: Maybe<Scalars['Int']>;
  made_public_by_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['String']>;
  points_of_interest?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  public_uuid?: Maybe<Scalars['bpchar']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Report_Dashboard_Min_Fields = {
  __typename?: 'report_dashboard_min_fields';
  cache_ttl?: Maybe<Scalars['Int']>;
  caveats?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['Int']>;
  collection_position?: Maybe<Scalars['smallint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  embedding_params?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['bpchar']>;
  id?: Maybe<Scalars['Int']>;
  made_public_by_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['String']>;
  points_of_interest?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  public_uuid?: Maybe<Scalars['bpchar']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "report_dashboard" */
export type Report_Dashboard_Mutation_Response = {
  __typename?: 'report_dashboard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Report_Dashboard>;
};

/** on_conflict condition type for table "report_dashboard" */
export type Report_Dashboard_On_Conflict = {
  constraint: Report_Dashboard_Constraint;
  update_columns?: Array<Report_Dashboard_Update_Column>;
  where?: Maybe<Report_Dashboard_Bool_Exp>;
};

/** Ordering options when selecting data from "report_dashboard". */
export type Report_Dashboard_Order_By = {
  archived?: Maybe<Order_By>;
  auto_apply_filters?: Maybe<Order_By>;
  cache_ttl?: Maybe<Order_By>;
  caveats?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  collection_position?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creator_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  embedding_params?: Maybe<Order_By>;
  enable_embedding?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  made_public_by_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parameters?: Maybe<Order_By>;
  points_of_interest?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  public_uuid?: Maybe<Order_By>;
  show_in_getting_started?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: report_dashboard */
export type Report_Dashboard_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "report_dashboard" */
export enum Report_Dashboard_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  AutoApplyFilters = 'auto_apply_filters',
  /** column name */
  CacheTtl = 'cache_ttl',
  /** column name */
  Caveats = 'caveats',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CollectionPosition = 'collection_position',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  EmbeddingParams = 'embedding_params',
  /** column name */
  EnableEmbedding = 'enable_embedding',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  MadePublicById = 'made_public_by_id',
  /** column name */
  Name = 'name',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  PointsOfInterest = 'points_of_interest',
  /** column name */
  Position = 'position',
  /** column name */
  PublicUuid = 'public_uuid',
  /** column name */
  ShowInGettingStarted = 'show_in_getting_started',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "report_dashboard" */
export type Report_Dashboard_Set_Input = {
  archived?: Maybe<Scalars['Boolean']>;
  /** Whether or not to auto-apply filters on a dashboard */
  auto_apply_filters?: Maybe<Scalars['Boolean']>;
  cache_ttl?: Maybe<Scalars['Int']>;
  caveats?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['Int']>;
  collection_position?: Maybe<Scalars['smallint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  embedding_params?: Maybe<Scalars['String']>;
  enable_embedding?: Maybe<Scalars['Boolean']>;
  entity_id?: Maybe<Scalars['bpchar']>;
  id?: Maybe<Scalars['Int']>;
  made_public_by_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['String']>;
  points_of_interest?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  public_uuid?: Maybe<Scalars['bpchar']>;
  show_in_getting_started?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Report_Dashboard_Stddev_Fields = {
  __typename?: 'report_dashboard_stddev_fields';
  cache_ttl?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  collection_position?: Maybe<Scalars['Float']>;
  creator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  made_public_by_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Report_Dashboard_Stddev_Pop_Fields = {
  __typename?: 'report_dashboard_stddev_pop_fields';
  cache_ttl?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  collection_position?: Maybe<Scalars['Float']>;
  creator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  made_public_by_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Report_Dashboard_Stddev_Samp_Fields = {
  __typename?: 'report_dashboard_stddev_samp_fields';
  cache_ttl?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  collection_position?: Maybe<Scalars['Float']>;
  creator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  made_public_by_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "report_dashboard" */
export type Report_Dashboard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Report_Dashboard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Report_Dashboard_Stream_Cursor_Value_Input = {
  archived?: Maybe<Scalars['Boolean']>;
  /** Whether or not to auto-apply filters on a dashboard */
  auto_apply_filters?: Maybe<Scalars['Boolean']>;
  cache_ttl?: Maybe<Scalars['Int']>;
  caveats?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['Int']>;
  collection_position?: Maybe<Scalars['smallint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  embedding_params?: Maybe<Scalars['String']>;
  enable_embedding?: Maybe<Scalars['Boolean']>;
  entity_id?: Maybe<Scalars['bpchar']>;
  id?: Maybe<Scalars['Int']>;
  made_public_by_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['String']>;
  points_of_interest?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  public_uuid?: Maybe<Scalars['bpchar']>;
  show_in_getting_started?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Report_Dashboard_Sum_Fields = {
  __typename?: 'report_dashboard_sum_fields';
  cache_ttl?: Maybe<Scalars['Int']>;
  collection_id?: Maybe<Scalars['Int']>;
  collection_position?: Maybe<Scalars['smallint']>;
  creator_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  made_public_by_id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** update columns of table "report_dashboard" */
export enum Report_Dashboard_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  AutoApplyFilters = 'auto_apply_filters',
  /** column name */
  CacheTtl = 'cache_ttl',
  /** column name */
  Caveats = 'caveats',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CollectionPosition = 'collection_position',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  EmbeddingParams = 'embedding_params',
  /** column name */
  EnableEmbedding = 'enable_embedding',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  MadePublicById = 'made_public_by_id',
  /** column name */
  Name = 'name',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  PointsOfInterest = 'points_of_interest',
  /** column name */
  Position = 'position',
  /** column name */
  PublicUuid = 'public_uuid',
  /** column name */
  ShowInGettingStarted = 'show_in_getting_started',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Report_Dashboard_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Report_Dashboard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Report_Dashboard_Set_Input>;
  /** filter the rows which have to be updated */
  where: Report_Dashboard_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Report_Dashboard_Var_Pop_Fields = {
  __typename?: 'report_dashboard_var_pop_fields';
  cache_ttl?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  collection_position?: Maybe<Scalars['Float']>;
  creator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  made_public_by_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Report_Dashboard_Var_Samp_Fields = {
  __typename?: 'report_dashboard_var_samp_fields';
  cache_ttl?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  collection_position?: Maybe<Scalars['Float']>;
  creator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  made_public_by_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Report_Dashboard_Variance_Fields = {
  __typename?: 'report_dashboard_variance_fields';
  cache_ttl?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  collection_position?: Maybe<Scalars['Float']>;
  creator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  made_public_by_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "risk_types" */
export type Risk_Types = {
  __typename?: 'risk_types';
  base_index?: Maybe<Scalars['Int']>;
  call_option?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  deal_details: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_aggregate: Deal_Details_Aggregate;
  fixed?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  put_option?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};


/** columns and relationships of "risk_types" */
export type Risk_TypesDeal_DetailsArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


/** columns and relationships of "risk_types" */
export type Risk_TypesDeal_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};

/** aggregated selection of "risk_types" */
export type Risk_Types_Aggregate = {
  __typename?: 'risk_types_aggregate';
  aggregate?: Maybe<Risk_Types_Aggregate_Fields>;
  nodes: Array<Risk_Types>;
};

/** aggregate fields of "risk_types" */
export type Risk_Types_Aggregate_Fields = {
  __typename?: 'risk_types_aggregate_fields';
  avg?: Maybe<Risk_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Risk_Types_Max_Fields>;
  min?: Maybe<Risk_Types_Min_Fields>;
  stddev?: Maybe<Risk_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Risk_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Risk_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Risk_Types_Sum_Fields>;
  var_pop?: Maybe<Risk_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Risk_Types_Var_Samp_Fields>;
  variance?: Maybe<Risk_Types_Variance_Fields>;
};


/** aggregate fields of "risk_types" */
export type Risk_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Risk_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Risk_Types_Avg_Fields = {
  __typename?: 'risk_types_avg_fields';
  base_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "risk_types". All fields are combined with a logical 'AND'. */
export type Risk_Types_Bool_Exp = {
  _and?: Maybe<Array<Risk_Types_Bool_Exp>>;
  _not?: Maybe<Risk_Types_Bool_Exp>;
  _or?: Maybe<Array<Risk_Types_Bool_Exp>>;
  base_index?: Maybe<Int_Comparison_Exp>;
  call_option?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  deal_details?: Maybe<Deal_Details_Bool_Exp>;
  deal_details_aggregate?: Maybe<Deal_Details_Aggregate_Bool_Exp>;
  fixed?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  put_option?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_types" */
export enum Risk_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  RiskTypesPkey = 'risk_types_pkey'
}

/** input type for incrementing numeric columns in table "risk_types" */
export type Risk_Types_Inc_Input = {
  base_index?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "risk_types" */
export type Risk_Types_Insert_Input = {
  base_index?: Maybe<Scalars['Int']>;
  call_option?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deal_details?: Maybe<Deal_Details_Arr_Rel_Insert_Input>;
  fixed?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  put_option?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Risk_Types_Max_Fields = {
  __typename?: 'risk_types_max_fields';
  base_index?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Risk_Types_Min_Fields = {
  __typename?: 'risk_types_min_fields';
  base_index?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "risk_types" */
export type Risk_Types_Mutation_Response = {
  __typename?: 'risk_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Types>;
};

/** input type for inserting object relation for remote table "risk_types" */
export type Risk_Types_Obj_Rel_Insert_Input = {
  data: Risk_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Risk_Types_On_Conflict>;
};

/** on_conflict condition type for table "risk_types" */
export type Risk_Types_On_Conflict = {
  constraint: Risk_Types_Constraint;
  update_columns?: Array<Risk_Types_Update_Column>;
  where?: Maybe<Risk_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_types". */
export type Risk_Types_Order_By = {
  base_index?: Maybe<Order_By>;
  call_option?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deal_details_aggregate?: Maybe<Deal_Details_Aggregate_Order_By>;
  fixed?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  put_option?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: risk_types */
export type Risk_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "risk_types" */
export enum Risk_Types_Select_Column {
  /** column name */
  BaseIndex = 'base_index',
  /** column name */
  CallOption = 'call_option',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fixed = 'fixed',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PutOption = 'put_option',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "risk_types" */
export type Risk_Types_Set_Input = {
  base_index?: Maybe<Scalars['Int']>;
  call_option?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  fixed?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  put_option?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Risk_Types_Stddev_Fields = {
  __typename?: 'risk_types_stddev_fields';
  base_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Risk_Types_Stddev_Pop_Fields = {
  __typename?: 'risk_types_stddev_pop_fields';
  base_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Risk_Types_Stddev_Samp_Fields = {
  __typename?: 'risk_types_stddev_samp_fields';
  base_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "risk_types" */
export type Risk_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Types_Stream_Cursor_Value_Input = {
  base_index?: Maybe<Scalars['Int']>;
  call_option?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  fixed?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  put_option?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Risk_Types_Sum_Fields = {
  __typename?: 'risk_types_sum_fields';
  base_index?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "risk_types" */
export enum Risk_Types_Update_Column {
  /** column name */
  BaseIndex = 'base_index',
  /** column name */
  CallOption = 'call_option',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fixed = 'fixed',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PutOption = 'put_option',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Risk_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Risk_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Risk_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Risk_Types_Var_Pop_Fields = {
  __typename?: 'risk_types_var_pop_fields';
  base_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Risk_Types_Var_Samp_Fields = {
  __typename?: 'risk_types_var_samp_fields';
  base_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Risk_Types_Variance_Fields = {
  __typename?: 'risk_types_variance_fields';
  base_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Search_Addresses_Args = {
  search?: Maybe<Scalars['String']>;
};

export type Search_Contact_Entries_Args = {
  search?: Maybe<Scalars['String']>;
};

export type Search_Customers_Args = {
  search?: Maybe<Scalars['String']>;
};

export type Search_Deals_Args = {
  search?: Maybe<Scalars['String']>;
};

export type Search_Groups_Args = {
  search?: Maybe<Scalars['String']>;
};

export type Search_Users_Args = {
  search?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

/** Boolean expression to compare columns of type "status". All fields are combined with logical 'AND'. */
export type Status_Comparison_Exp = {
  _eq?: Maybe<Scalars['status']>;
  _gt?: Maybe<Scalars['status']>;
  _gte?: Maybe<Scalars['status']>;
  _in?: Maybe<Array<Scalars['status']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['status']>;
  _lte?: Maybe<Scalars['status']>;
  _neq?: Maybe<Scalars['status']>;
  _nin?: Maybe<Array<Scalars['status']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  ach_queues: Array<Ach_Queues>;
  /** An aggregate relationship */
  ach_queues_aggregate: Ach_Queues_Aggregate;
  /** fetch data from the table: "ach_queues" using primary key columns */
  ach_queues_by_pk?: Maybe<Ach_Queues>;
  /** fetch data from the table in a streaming manner: "ach_queues" */
  ach_queues_stream: Array<Ach_Queues>;
  /** fetch data from the table: "active_storage_blobs" */
  active_storage_blobs: Array<Active_Storage_Blobs>;
  /** fetch aggregated fields from the table: "active_storage_blobs" */
  active_storage_blobs_aggregate: Active_Storage_Blobs_Aggregate;
  /** fetch data from the table: "active_storage_blobs" using primary key columns */
  active_storage_blobs_by_pk?: Maybe<Active_Storage_Blobs>;
  /** fetch data from the table in a streaming manner: "active_storage_blobs" */
  active_storage_blobs_stream: Array<Active_Storage_Blobs>;
  /** An array relationship */
  addresses: Array<Addresses>;
  /** An aggregate relationship */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** fetch data from the table in a streaming manner: "addresses" */
  addresses_stream: Array<Addresses>;
  /** An array relationship */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** An aggregate relationship */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "audit.logged_actions" */
  audit_logged_actions_stream: Array<Audit_Logged_Actions>;
  /** fetch data from the table: "auth.rbac_role_acls" */
  auth_rbac_role_acls: Array<Auth_Rbac_Role_Acls>;
  /** fetch aggregated fields from the table: "auth.rbac_role_acls" */
  auth_rbac_role_acls_aggregate: Auth_Rbac_Role_Acls_Aggregate;
  /** fetch data from the table: "auth.rbac_role_acls" using primary key columns */
  auth_rbac_role_acls_by_pk?: Maybe<Auth_Rbac_Role_Acls>;
  /** fetch data from the table in a streaming manner: "auth.rbac_role_acls" */
  auth_rbac_role_acls_stream: Array<Auth_Rbac_Role_Acls>;
  /** fetch data from the table: "auth.rbac_roles" */
  auth_rbac_roles: Array<Auth_Rbac_Roles>;
  /** fetch aggregated fields from the table: "auth.rbac_roles" */
  auth_rbac_roles_aggregate: Auth_Rbac_Roles_Aggregate;
  /** fetch data from the table: "auth.rbac_roles" using primary key columns */
  auth_rbac_roles_by_pk?: Maybe<Auth_Rbac_Roles>;
  /** fetch data from the table in a streaming manner: "auth.rbac_roles" */
  auth_rbac_roles_stream: Array<Auth_Rbac_Roles>;
  /** fetch data from the table: "auth.users" */
  auth_users: Array<Auth_Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  auth_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "auth.users" using primary key columns */
  auth_users_by_pk?: Maybe<Auth_Users>;
  /** fetch data from the table in a streaming manner: "auth.users" */
  auth_users_stream: Array<Auth_Users>;
  /** An array relationship */
  balance_dues: Array<Balance_Dues>;
  /** An aggregate relationship */
  balance_dues_aggregate: Balance_Dues_Aggregate;
  /** fetch data from the table: "balance_dues" using primary key columns */
  balance_dues_by_pk?: Maybe<Balance_Dues>;
  /** fetch data from the table in a streaming manner: "balance_dues" */
  balance_dues_stream: Array<Balance_Dues>;
  /** fetch data from the table: "bhe_accounts" */
  bhe_accounts: Array<Bhe_Accounts>;
  /** fetch aggregated fields from the table: "bhe_accounts" */
  bhe_accounts_aggregate: Bhe_Accounts_Aggregate;
  /** fetch data from the table: "bhe_accounts" using primary key columns */
  bhe_accounts_by_pk?: Maybe<Bhe_Accounts>;
  /** fetch data from the table in a streaming manner: "bhe_accounts" */
  bhe_accounts_stream: Array<Bhe_Accounts>;
  /** An array relationship */
  bhe_invoices: Array<Bhe_Invoices>;
  /** An aggregate relationship */
  bhe_invoices_aggregate: Bhe_Invoices_Aggregate;
  /** fetch data from the table: "bhe_invoices" using primary key columns */
  bhe_invoices_by_pk?: Maybe<Bhe_Invoices>;
  /** fetch data from the table in a streaming manner: "bhe_invoices" */
  bhe_invoices_stream: Array<Bhe_Invoices>;
  /** fetch data from the table: "commissions" */
  commissions: Array<Commissions>;
  /** fetch aggregated fields from the table: "commissions" */
  commissions_aggregate: Commissions_Aggregate;
  /** fetch data from the table: "commissions" using primary key columns */
  commissions_by_pk?: Maybe<Commissions>;
  /** fetch data from the table in a streaming manner: "commissions" */
  commissions_stream: Array<Commissions>;
  /** An array relationship */
  contact_assignments: Array<Contact_Assignments>;
  /** An aggregate relationship */
  contact_assignments_aggregate: Contact_Assignments_Aggregate;
  /** fetch data from the table: "contact_assignments" using primary key columns */
  contact_assignments_by_pk?: Maybe<Contact_Assignments>;
  /** fetch data from the table in a streaming manner: "contact_assignments" */
  contact_assignments_stream: Array<Contact_Assignments>;
  /** fetch data from the table: "contact_entries" */
  contact_entries: Array<Contact_Entries>;
  /** fetch aggregated fields from the table: "contact_entries" */
  contact_entries_aggregate: Contact_Entries_Aggregate;
  /** fetch data from the table: "contact_entries" using primary key columns */
  contact_entries_by_pk?: Maybe<Contact_Entries>;
  /** fetch data from the table in a streaming manner: "contact_entries" */
  contact_entries_stream: Array<Contact_Entries>;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table in a streaming manner: "contacts" */
  contacts_stream: Array<Contacts>;
  /** fetch data from the table: "customer_categories" */
  customer_categories: Array<Customer_Categories>;
  /** fetch aggregated fields from the table: "customer_categories" */
  customer_categories_aggregate: Customer_Categories_Aggregate;
  /** fetch data from the table: "customer_categories" using primary key columns */
  customer_categories_by_pk?: Maybe<Customer_Categories>;
  /** fetch data from the table in a streaming manner: "customer_categories" */
  customer_categories_stream: Array<Customer_Categories>;
  /** An array relationship */
  customer_documents_view: Array<Customer_Documents_View>;
  /** An aggregate relationship */
  customer_documents_view_aggregate: Customer_Documents_View_Aggregate;
  /** fetch data from the table in a streaming manner: "customer_documents_view" */
  customer_documents_view_stream: Array<Customer_Documents_View>;
  /** An array relationship */
  customer_groups: Array<Customer_Groups>;
  /** An aggregate relationship */
  customer_groups_aggregate: Customer_Groups_Aggregate;
  /** fetch data from the table: "customer_groups" using primary key columns */
  customer_groups_by_pk?: Maybe<Customer_Groups>;
  /** fetch data from the table in a streaming manner: "customer_groups" */
  customer_groups_stream: Array<Customer_Groups>;
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  /** fetch data from the table in a streaming manner: "customers" */
  customers_stream: Array<Customers>;
  /** An array relationship */
  customers_users: Array<Customers_Users>;
  /** An aggregate relationship */
  customers_users_aggregate: Customers_Users_Aggregate;
  /** fetch data from the table: "customers_users" using primary key columns */
  customers_users_by_pk?: Maybe<Customers_Users>;
  /** fetch data from the table in a streaming manner: "customers_users" */
  customers_users_stream: Array<Customers_Users>;
  /** fetch data from the table: "cyborg_queries" */
  cyborg_queries: Array<Cyborg_Queries>;
  /** fetch aggregated fields from the table: "cyborg_queries" */
  cyborg_queries_aggregate: Cyborg_Queries_Aggregate;
  /** fetch data from the table: "cyborg_queries" using primary key columns */
  cyborg_queries_by_pk?: Maybe<Cyborg_Queries>;
  /** fetch data from the table in a streaming manner: "cyborg_queries" */
  cyborg_queries_stream: Array<Cyborg_Queries>;
  /** An array relationship */
  deal_detail_volumes: Array<Deal_Detail_Volumes>;
  /** An aggregate relationship */
  deal_detail_volumes_aggregate: Deal_Detail_Volumes_Aggregate;
  /** fetch data from the table: "deal_detail_volumes" using primary key columns */
  deal_detail_volumes_by_pk?: Maybe<Deal_Detail_Volumes>;
  /** fetch data from the table in a streaming manner: "deal_detail_volumes" */
  deal_detail_volumes_stream: Array<Deal_Detail_Volumes>;
  /** An array relationship */
  deal_detail_volumes_sum_by_beg_date: Array<Deal_Detail_Volumes_Sum_By_Beg_Date>;
  /** An aggregate relationship */
  deal_detail_volumes_sum_by_beg_date_aggregate: Deal_Detail_Volumes_Sum_By_Beg_Date_Aggregate;
  /** fetch data from the table in a streaming manner: "deal_detail_volumes_sum_by_beg_date" */
  deal_detail_volumes_sum_by_beg_date_stream: Array<Deal_Detail_Volumes_Sum_By_Beg_Date>;
  /** An array relationship */
  deal_details: Array<Deal_Details>;
  /** An aggregate relationship */
  deal_details_aggregate: Deal_Details_Aggregate;
  /** fetch data from the table: "deal_details" using primary key columns */
  deal_details_by_pk?: Maybe<Deal_Details>;
  /** fetch data from the table in a streaming manner: "deal_details" */
  deal_details_stream: Array<Deal_Details>;
  /** fetch data from the table: "dealmakers" */
  dealmakers: Array<Dealmakers>;
  /** fetch aggregated fields from the table: "dealmakers" */
  dealmakers_aggregate: Dealmakers_Aggregate;
  /** fetch data from the table: "dealmakers" using primary key columns */
  dealmakers_by_pk?: Maybe<Dealmakers>;
  /** fetch data from the table in a streaming manner: "dealmakers" */
  dealmakers_stream: Array<Dealmakers>;
  /** An array relationship */
  deals: Array<Deals>;
  /** An aggregate relationship */
  deals_aggregate: Deals_Aggregate;
  /** fetch data from the table: "deals" using primary key columns */
  deals_by_pk?: Maybe<Deals>;
  /** fetch data from the table: "deals_detail_preview" */
  deals_detail_preview: Array<Deals_Detail_Preview>;
  /** fetch aggregated fields from the table: "deals_detail_preview" */
  deals_detail_preview_aggregate: Deals_Detail_Preview_Aggregate;
  /** fetch data from the table in a streaming manner: "deals_detail_preview" */
  deals_detail_preview_stream: Array<Deals_Detail_Preview>;
  /** fetch data from the table in a streaming manner: "deals" */
  deals_stream: Array<Deals>;
  /** fetch data from the table: "email.email_attachments" */
  email_email_attachments: Array<Email_Email_Attachments>;
  /** fetch aggregated fields from the table: "email.email_attachments" */
  email_email_attachments_aggregate: Email_Email_Attachments_Aggregate;
  /** fetch data from the table: "email.email_attachments" using primary key columns */
  email_email_attachments_by_pk?: Maybe<Email_Email_Attachments>;
  /** fetch data from the table in a streaming manner: "email.email_attachments" */
  email_email_attachments_stream: Array<Email_Email_Attachments>;
  /** fetch data from the table: "email.emails" */
  email_emails: Array<Email_Emails>;
  /** fetch aggregated fields from the table: "email.emails" */
  email_emails_aggregate: Email_Emails_Aggregate;
  /** fetch data from the table: "email.emails" using primary key columns */
  email_emails_by_pk?: Maybe<Email_Emails>;
  /** fetch data from the table in a streaming manner: "email.emails" */
  email_emails_stream: Array<Email_Emails>;
  /** fetch data from the table: "enum_types" */
  enum_types: Array<Enum_Types>;
  /** fetch aggregated fields from the table: "enum_types" */
  enum_types_aggregate: Enum_Types_Aggregate;
  /** fetch data from the table in a streaming manner: "enum_types" */
  enum_types_stream: Array<Enum_Types>;
  /** fetch data from the table: "fee_types" */
  fee_types: Array<Fee_Types>;
  /** fetch aggregated fields from the table: "fee_types" */
  fee_types_aggregate: Fee_Types_Aggregate;
  /** fetch data from the table: "fee_types" using primary key columns */
  fee_types_by_pk?: Maybe<Fee_Types>;
  /** fetch data from the table in a streaming manner: "fee_types" */
  fee_types_stream: Array<Fee_Types>;
  /** fetch data from the table: "fees" */
  fees: Array<Fees>;
  /** fetch aggregated fields from the table: "fees" */
  fees_aggregate: Fees_Aggregate;
  /** fetch data from the table: "fees" using primary key columns */
  fees_by_pk?: Maybe<Fees>;
  /** fetch data from the table in a streaming manner: "fees" */
  fees_stream: Array<Fees>;
  /** fetch data from the table: "files.document_render_results" */
  files_document_render_results: Array<Files_Document_Render_Results>;
  /** fetch aggregated fields from the table: "files.document_render_results" */
  files_document_render_results_aggregate: Files_Document_Render_Results_Aggregate;
  /** fetch data from the table: "files.document_render_results" using primary key columns */
  files_document_render_results_by_pk?: Maybe<Files_Document_Render_Results>;
  /** fetch data from the table in a streaming manner: "files.document_render_results" */
  files_document_render_results_stream: Array<Files_Document_Render_Results>;
  /** fetch data from the table: "files.document_template_executions" */
  files_document_template_executions: Array<Files_Document_Template_Executions>;
  /** fetch aggregated fields from the table: "files.document_template_executions" */
  files_document_template_executions_aggregate: Files_Document_Template_Executions_Aggregate;
  /** fetch data from the table: "files.document_template_executions" using primary key columns */
  files_document_template_executions_by_pk?: Maybe<Files_Document_Template_Executions>;
  /** fetch data from the table in a streaming manner: "files.document_template_executions" */
  files_document_template_executions_stream: Array<Files_Document_Template_Executions>;
  /** fetch data from the table: "files.document_templates" */
  files_document_templates: Array<Files_Document_Templates>;
  /** fetch aggregated fields from the table: "files.document_templates" */
  files_document_templates_aggregate: Files_Document_Templates_Aggregate;
  /** fetch data from the table: "files.document_templates" using primary key columns */
  files_document_templates_by_pk?: Maybe<Files_Document_Templates>;
  /** fetch data from the table in a streaming manner: "files.document_templates" */
  files_document_templates_stream: Array<Files_Document_Templates>;
  /** fetch data from the table: "files.pdf_render" */
  files_pdf_render: Array<Files_Pdf_Render>;
  /** fetch aggregated fields from the table: "files.pdf_render" */
  files_pdf_render_aggregate: Files_Pdf_Render_Aggregate;
  /** fetch data from the table: "files.pdf_render" using primary key columns */
  files_pdf_render_by_pk?: Maybe<Files_Pdf_Render>;
  /** fetch data from the table in a streaming manner: "files.pdf_render" */
  files_pdf_render_stream: Array<Files_Pdf_Render>;
  /** fetch data from the table: "forecast_types" */
  forecast_types: Array<Forecast_Types>;
  /** fetch aggregated fields from the table: "forecast_types" */
  forecast_types_aggregate: Forecast_Types_Aggregate;
  /** fetch data from the table: "forecast_types" using primary key columns */
  forecast_types_by_pk?: Maybe<Forecast_Types>;
  /** fetch data from the table in a streaming manner: "forecast_types" */
  forecast_types_stream: Array<Forecast_Types>;
  /** fetch data from the table: "group_types" */
  group_types: Array<Group_Types>;
  /** fetch aggregated fields from the table: "group_types" */
  group_types_aggregate: Group_Types_Aggregate;
  /** fetch data from the table: "group_types" using primary key columns */
  group_types_by_pk?: Maybe<Group_Types>;
  /** fetch data from the table in a streaming manner: "group_types" */
  group_types_stream: Array<Group_Types>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table in a streaming manner: "groups" */
  groups_stream: Array<Groups>;
  /** fetch data from the table: "ind_types" */
  ind_types: Array<Ind_Types>;
  /** fetch aggregated fields from the table: "ind_types" */
  ind_types_aggregate: Ind_Types_Aggregate;
  /** fetch data from the table: "ind_types" using primary key columns */
  ind_types_by_pk?: Maybe<Ind_Types>;
  /** fetch data from the table in a streaming manner: "ind_types" */
  ind_types_stream: Array<Ind_Types>;
  /** fetch data from the table: "indices" */
  indices: Array<Indices>;
  /** fetch aggregated fields from the table: "indices" */
  indices_aggregate: Indices_Aggregate;
  /** fetch data from the table: "indices" using primary key columns */
  indices_by_pk?: Maybe<Indices>;
  /** fetch data from the table in a streaming manner: "indices" */
  indices_stream: Array<Indices>;
  /** fetch data from the table: "invitations" */
  invitations: Array<Invitations>;
  /** fetch aggregated fields from the table: "invitations" */
  invitations_aggregate: Invitations_Aggregate;
  /** fetch data from the table: "invitations" using primary key columns */
  invitations_by_pk?: Maybe<Invitations>;
  /** An array relationship */
  invitations_customers: Array<Invitations_Customers>;
  /** An aggregate relationship */
  invitations_customers_aggregate: Invitations_Customers_Aggregate;
  /** fetch data from the table: "invitations_customers" using primary key columns */
  invitations_customers_by_pk?: Maybe<Invitations_Customers>;
  /** fetch data from the table in a streaming manner: "invitations_customers" */
  invitations_customers_stream: Array<Invitations_Customers>;
  /** fetch data from the table in a streaming manner: "invitations" */
  invitations_stream: Array<Invitations>;
  /** fetch data from the table: "invoice_aggregate" */
  invoice_aggregate: Array<Invoice_Aggregate>;
  /** fetch aggregated fields from the table: "invoice_aggregate" */
  invoice_aggregate_aggregate: Invoice_Aggregate_Aggregate;
  /** fetch data from the table in a streaming manner: "invoice_aggregate" */
  invoice_aggregate_stream: Array<Invoice_Aggregate>;
  /** An array relationship */
  invoice_data: Array<Invoice_Data>;
  /** An aggregate relationship */
  invoice_data_aggregate: Invoice_Data_Aggregate;
  /** fetch data from the table: "invoice_data" using primary key columns */
  invoice_data_by_pk?: Maybe<Invoice_Data>;
  /** fetch data from the table in a streaming manner: "invoice_data" */
  invoice_data_stream: Array<Invoice_Data>;
  /** An array relationship */
  invoice_data_sum_by_month: Array<Invoice_Data_Sum_By_Month>;
  /** An aggregate relationship */
  invoice_data_sum_by_month_aggregate: Invoice_Data_Sum_By_Month_Aggregate;
  /** fetch data from the table in a streaming manner: "invoice_data_sum_by_month" */
  invoice_data_sum_by_month_stream: Array<Invoice_Data_Sum_By_Month>;
  /** An array relationship */
  invoice_numbers: Array<Invoice_Numbers>;
  /** An aggregate relationship */
  invoice_numbers_aggregate: Invoice_Numbers_Aggregate;
  /** fetch data from the table: "invoice_numbers" using primary key columns */
  invoice_numbers_by_pk?: Maybe<Invoice_Numbers>;
  /** fetch data from the table in a streaming manner: "invoice_numbers" */
  invoice_numbers_stream: Array<Invoice_Numbers>;
  /** An array relationship */
  ldc_accounts_view: Array<Ldc_Accounts_View>;
  /** An aggregate relationship */
  ldc_accounts_view_aggregate: Ldc_Accounts_View_Aggregate;
  /** fetch data from the table in a streaming manner: "ldc_accounts_view" */
  ldc_accounts_view_stream: Array<Ldc_Accounts_View>;
  /** An array relationship */
  ldc_invoices_view: Array<Ldc_Invoices_View>;
  /** An aggregate relationship */
  ldc_invoices_view_aggregate: Ldc_Invoices_View_Aggregate;
  /** fetch data from the table in a streaming manner: "ldc_invoices_view" */
  ldc_invoices_view_stream: Array<Ldc_Invoices_View>;
  /** An array relationship */
  ldc_service_charges_view: Array<Ldc_Service_Charges_View>;
  /** An aggregate relationship */
  ldc_service_charges_view_aggregate: Ldc_Service_Charges_View_Aggregate;
  /** fetch data from the table in a streaming manner: "ldc_service_charges_view" */
  ldc_service_charges_view_stream: Array<Ldc_Service_Charges_View>;
  /** fetch data from the table: "load_forecasts" */
  load_forecasts: Array<Load_Forecasts>;
  /** fetch aggregated fields from the table: "load_forecasts" */
  load_forecasts_aggregate: Load_Forecasts_Aggregate;
  /** fetch data from the table: "load_forecasts" using primary key columns */
  load_forecasts_by_pk?: Maybe<Load_Forecasts>;
  /** fetch data from the table in a streaming manner: "load_forecasts" */
  load_forecasts_stream: Array<Load_Forecasts>;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** An array relationship */
  logs: Array<Logs>;
  /** An aggregate relationship */
  logs_aggregate: Logs_Aggregate;
  /** fetch data from the table: "logs" using primary key columns */
  logs_by_pk?: Maybe<Logs>;
  /** fetch data from the table in a streaming manner: "logs" */
  logs_stream: Array<Logs>;
  /** fetch data from the table: "params" */
  params: Array<Params>;
  /** fetch aggregated fields from the table: "params" */
  params_aggregate: Params_Aggregate;
  /** fetch data from the table: "params" using primary key columns */
  params_by_pk?: Maybe<Params>;
  /** fetch data from the table in a streaming manner: "params" */
  params_stream: Array<Params>;
  /** An array relationship */
  paths: Array<Paths>;
  /** An aggregate relationship */
  paths_aggregate: Paths_Aggregate;
  /** fetch data from the table: "paths" using primary key columns */
  paths_by_pk?: Maybe<Paths>;
  /** fetch data from the table in a streaming manner: "paths" */
  paths_stream: Array<Paths>;
  /** fetch data from the table: "pipelines" */
  pipelines: Array<Pipelines>;
  /** fetch aggregated fields from the table: "pipelines" */
  pipelines_aggregate: Pipelines_Aggregate;
  /** fetch data from the table: "pipelines" using primary key columns */
  pipelines_by_pk?: Maybe<Pipelines>;
  /** fetch data from the table in a streaming manner: "pipelines" */
  pipelines_stream: Array<Pipelines>;
  /** An array relationship */
  records: Array<Records>;
  /** An aggregate relationship */
  records_aggregate: Records_Aggregate;
  /** fetch data from the table: "records" using primary key columns */
  records_by_pk?: Maybe<Records>;
  /** fetch data from the table in a streaming manner: "records" */
  records_stream: Array<Records>;
  /** fetch data from the table: "report_dashboard" */
  report_dashboard: Array<Report_Dashboard>;
  /** fetch aggregated fields from the table: "report_dashboard" */
  report_dashboard_aggregate: Report_Dashboard_Aggregate;
  /** fetch data from the table: "report_dashboard" using primary key columns */
  report_dashboard_by_pk?: Maybe<Report_Dashboard>;
  /** fetch data from the table in a streaming manner: "report_dashboard" */
  report_dashboard_stream: Array<Report_Dashboard>;
  /** fetch data from the table: "risk_types" */
  risk_types: Array<Risk_Types>;
  /** fetch aggregated fields from the table: "risk_types" */
  risk_types_aggregate: Risk_Types_Aggregate;
  /** fetch data from the table: "risk_types" using primary key columns */
  risk_types_by_pk?: Maybe<Risk_Types>;
  /** fetch data from the table in a streaming manner: "risk_types" */
  risk_types_stream: Array<Risk_Types>;
  /** execute function "search_addresses" which returns "addresses" */
  search_addresses: Array<Addresses>;
  /** execute function "search_addresses" and query aggregates on result of table type "addresses" */
  search_addresses_aggregate: Addresses_Aggregate;
  /** execute function "search_contact_entries" which returns "contact_entries" */
  search_contact_entries: Array<Contact_Entries>;
  /** execute function "search_contact_entries" and query aggregates on result of table type "contact_entries" */
  search_contact_entries_aggregate: Contact_Entries_Aggregate;
  /** execute function "search_customers" which returns "customers" */
  search_customers: Array<Customers>;
  /** execute function "search_customers" and query aggregates on result of table type "customers" */
  search_customers_aggregate: Customers_Aggregate;
  /** execute function "search_deals" which returns "deals" */
  search_deals: Array<Deals>;
  /** execute function "search_deals" and query aggregates on result of table type "deals" */
  search_deals_aggregate: Deals_Aggregate;
  /** execute function "search_groups" which returns "groups" */
  search_groups: Array<Groups>;
  /** execute function "search_groups" and query aggregates on result of table type "groups" */
  search_groups_aggregate: Groups_Aggregate;
  /** execute function "search_users" which returns "auth.users" */
  search_users: Array<Auth_Users>;
  /** execute function "search_users" and query aggregates on result of table type "auth.users" */
  search_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "users_data" */
  users_data: Array<Users_Data>;
  /** fetch aggregated fields from the table: "users_data" */
  users_data_aggregate: Users_Data_Aggregate;
  /** fetch data from the table: "users_data" using primary key columns */
  users_data_by_pk?: Maybe<Users_Data>;
  /** fetch data from the table in a streaming manner: "users_data" */
  users_data_stream: Array<Users_Data>;
  wrec_meeting: Array<Wrec_Meeting>;
  wrec_meeting_aggregate: Wrec_Meeting_Aggregate;
  /** fetch data from the table: "wrec.meeting" using primary key columns */
  wrec_meeting_by_pk?: Maybe<Wrec_Meeting>;
  /** fetch data from the table in a streaming manner: "wrec.meeting" */
  wrec_meeting_stream: Array<Wrec_Meeting>;
};


export type Subscription_RootAch_QueuesArgs = {
  distinct_on?: Maybe<Array<Ach_Queues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ach_Queues_Order_By>>;
  where?: Maybe<Ach_Queues_Bool_Exp>;
};


export type Subscription_RootAch_Queues_AggregateArgs = {
  distinct_on?: Maybe<Array<Ach_Queues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ach_Queues_Order_By>>;
  where?: Maybe<Ach_Queues_Bool_Exp>;
};


export type Subscription_RootAch_Queues_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAch_Queues_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ach_Queues_Stream_Cursor_Input>>;
  where?: Maybe<Ach_Queues_Bool_Exp>;
};


export type Subscription_RootActive_Storage_BlobsArgs = {
  distinct_on?: Maybe<Array<Active_Storage_Blobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Active_Storage_Blobs_Order_By>>;
  where?: Maybe<Active_Storage_Blobs_Bool_Exp>;
};


export type Subscription_RootActive_Storage_Blobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Active_Storage_Blobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Active_Storage_Blobs_Order_By>>;
  where?: Maybe<Active_Storage_Blobs_Bool_Exp>;
};


export type Subscription_RootActive_Storage_Blobs_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootActive_Storage_Blobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Active_Storage_Blobs_Stream_Cursor_Input>>;
  where?: Maybe<Active_Storage_Blobs_Bool_Exp>;
};


export type Subscription_RootAddressesArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAddresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Addresses_Stream_Cursor_Input>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAudit_Logged_ActionsArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint'];
};


export type Subscription_RootAudit_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Audit_Logged_Actions_Stream_Cursor_Input>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootAuth_Rbac_Role_AclsArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Role_Acls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Role_Acls_Order_By>>;
  where?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
};


export type Subscription_RootAuth_Rbac_Role_Acls_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Role_Acls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Role_Acls_Order_By>>;
  where?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
};


export type Subscription_RootAuth_Rbac_Role_Acls_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAuth_Rbac_Role_Acls_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Auth_Rbac_Role_Acls_Stream_Cursor_Input>>;
  where?: Maybe<Auth_Rbac_Role_Acls_Bool_Exp>;
};


export type Subscription_RootAuth_Rbac_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Roles_Order_By>>;
  where?: Maybe<Auth_Rbac_Roles_Bool_Exp>;
};


export type Subscription_RootAuth_Rbac_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Rbac_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Rbac_Roles_Order_By>>;
  where?: Maybe<Auth_Rbac_Roles_Bool_Exp>;
};


export type Subscription_RootAuth_Rbac_Roles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAuth_Rbac_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Auth_Rbac_Roles_Stream_Cursor_Input>>;
  where?: Maybe<Auth_Rbac_Roles_Bool_Exp>;
};


export type Subscription_RootAuth_UsersArgs = {
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAuth_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Auth_Users_Stream_Cursor_Input>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootBalance_DuesArgs = {
  distinct_on?: Maybe<Array<Balance_Dues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balance_Dues_Order_By>>;
  where?: Maybe<Balance_Dues_Bool_Exp>;
};


export type Subscription_RootBalance_Dues_AggregateArgs = {
  distinct_on?: Maybe<Array<Balance_Dues_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balance_Dues_Order_By>>;
  where?: Maybe<Balance_Dues_Bool_Exp>;
};


export type Subscription_RootBalance_Dues_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBalance_Dues_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Balance_Dues_Stream_Cursor_Input>>;
  where?: Maybe<Balance_Dues_Bool_Exp>;
};


export type Subscription_RootBhe_AccountsArgs = {
  distinct_on?: Maybe<Array<Bhe_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Accounts_Order_By>>;
  where?: Maybe<Bhe_Accounts_Bool_Exp>;
};


export type Subscription_RootBhe_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Bhe_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Accounts_Order_By>>;
  where?: Maybe<Bhe_Accounts_Bool_Exp>;
};


export type Subscription_RootBhe_Accounts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBhe_Accounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bhe_Accounts_Stream_Cursor_Input>>;
  where?: Maybe<Bhe_Accounts_Bool_Exp>;
};


export type Subscription_RootBhe_InvoicesArgs = {
  distinct_on?: Maybe<Array<Bhe_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Invoices_Order_By>>;
  where?: Maybe<Bhe_Invoices_Bool_Exp>;
};


export type Subscription_RootBhe_Invoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Bhe_Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bhe_Invoices_Order_By>>;
  where?: Maybe<Bhe_Invoices_Bool_Exp>;
};


export type Subscription_RootBhe_Invoices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBhe_Invoices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bhe_Invoices_Stream_Cursor_Input>>;
  where?: Maybe<Bhe_Invoices_Bool_Exp>;
};


export type Subscription_RootCommissionsArgs = {
  distinct_on?: Maybe<Array<Commissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Commissions_Order_By>>;
  where?: Maybe<Commissions_Bool_Exp>;
};


export type Subscription_RootCommissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Commissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Commissions_Order_By>>;
  where?: Maybe<Commissions_Bool_Exp>;
};


export type Subscription_RootCommissions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCommissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Commissions_Stream_Cursor_Input>>;
  where?: Maybe<Commissions_Bool_Exp>;
};


export type Subscription_RootContact_AssignmentsArgs = {
  distinct_on?: Maybe<Array<Contact_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Assignments_Order_By>>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};


export type Subscription_RootContact_Assignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Assignments_Order_By>>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};


export type Subscription_RootContact_Assignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContact_Assignments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Contact_Assignments_Stream_Cursor_Input>>;
  where?: Maybe<Contact_Assignments_Bool_Exp>;
};


export type Subscription_RootContact_EntriesArgs = {
  distinct_on?: Maybe<Array<Contact_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Entries_Order_By>>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};


export type Subscription_RootContact_Entries_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Entries_Order_By>>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};


export type Subscription_RootContact_Entries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContact_Entries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Contact_Entries_Stream_Cursor_Input>>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};


export type Subscription_RootContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Contacts_Stream_Cursor_Input>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


export type Subscription_RootCustomer_CategoriesArgs = {
  distinct_on?: Maybe<Array<Customer_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Categories_Order_By>>;
  where?: Maybe<Customer_Categories_Bool_Exp>;
};


export type Subscription_RootCustomer_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Categories_Order_By>>;
  where?: Maybe<Customer_Categories_Bool_Exp>;
};


export type Subscription_RootCustomer_Categories_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomer_Categories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Categories_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Categories_Bool_Exp>;
};


export type Subscription_RootCustomer_Documents_ViewArgs = {
  distinct_on?: Maybe<Array<Customer_Documents_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Documents_View_Order_By>>;
  where?: Maybe<Customer_Documents_View_Bool_Exp>;
};


export type Subscription_RootCustomer_Documents_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Documents_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Documents_View_Order_By>>;
  where?: Maybe<Customer_Documents_View_Bool_Exp>;
};


export type Subscription_RootCustomer_Documents_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Documents_View_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Documents_View_Bool_Exp>;
};


export type Subscription_RootCustomer_GroupsArgs = {
  distinct_on?: Maybe<Array<Customer_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Groups_Order_By>>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};


export type Subscription_RootCustomer_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Groups_Order_By>>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};


export type Subscription_RootCustomer_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomer_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Groups_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Groups_Bool_Exp>;
};


export type Subscription_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customers_Stream_Cursor_Input>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_UsersArgs = {
  distinct_on?: Maybe<Array<Customers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Users_Order_By>>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};


export type Subscription_RootCustomers_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Users_Order_By>>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};


export type Subscription_RootCustomers_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomers_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customers_Users_Stream_Cursor_Input>>;
  where?: Maybe<Customers_Users_Bool_Exp>;
};


export type Subscription_RootCyborg_QueriesArgs = {
  distinct_on?: Maybe<Array<Cyborg_Queries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cyborg_Queries_Order_By>>;
  where?: Maybe<Cyborg_Queries_Bool_Exp>;
};


export type Subscription_RootCyborg_Queries_AggregateArgs = {
  distinct_on?: Maybe<Array<Cyborg_Queries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cyborg_Queries_Order_By>>;
  where?: Maybe<Cyborg_Queries_Bool_Exp>;
};


export type Subscription_RootCyborg_Queries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCyborg_Queries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Cyborg_Queries_Stream_Cursor_Input>>;
  where?: Maybe<Cyborg_Queries_Bool_Exp>;
};


export type Subscription_RootDeal_Detail_VolumesArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
};


export type Subscription_RootDeal_Detail_Volumes_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
};


export type Subscription_RootDeal_Detail_Volumes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDeal_Detail_Volumes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deal_Detail_Volumes_Stream_Cursor_Input>>;
  where?: Maybe<Deal_Detail_Volumes_Bool_Exp>;
};


export type Subscription_RootDeal_Detail_Volumes_Sum_By_Beg_DateArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
};


export type Subscription_RootDeal_Detail_Volumes_Sum_By_Beg_Date_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Detail_Volumes_Sum_By_Beg_Date_Order_By>>;
  where?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
};


export type Subscription_RootDeal_Detail_Volumes_Sum_By_Beg_Date_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Stream_Cursor_Input>>;
  where?: Maybe<Deal_Detail_Volumes_Sum_By_Beg_Date_Bool_Exp>;
};


export type Subscription_RootDeal_DetailsArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


export type Subscription_RootDeal_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Details_Order_By>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


export type Subscription_RootDeal_Details_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDeal_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deal_Details_Stream_Cursor_Input>>;
  where?: Maybe<Deal_Details_Bool_Exp>;
};


export type Subscription_RootDealmakersArgs = {
  distinct_on?: Maybe<Array<Dealmakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealmakers_Order_By>>;
  where?: Maybe<Dealmakers_Bool_Exp>;
};


export type Subscription_RootDealmakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Dealmakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealmakers_Order_By>>;
  where?: Maybe<Dealmakers_Bool_Exp>;
};


export type Subscription_RootDealmakers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDealmakers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Dealmakers_Stream_Cursor_Input>>;
  where?: Maybe<Dealmakers_Bool_Exp>;
};


export type Subscription_RootDealsArgs = {
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


export type Subscription_RootDeals_AggregateArgs = {
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


export type Subscription_RootDeals_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDeals_Detail_PreviewArgs = {
  distinct_on?: Maybe<Array<Deals_Detail_Preview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Detail_Preview_Order_By>>;
  where?: Maybe<Deals_Detail_Preview_Bool_Exp>;
};


export type Subscription_RootDeals_Detail_Preview_AggregateArgs = {
  distinct_on?: Maybe<Array<Deals_Detail_Preview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Detail_Preview_Order_By>>;
  where?: Maybe<Deals_Detail_Preview_Bool_Exp>;
};


export type Subscription_RootDeals_Detail_Preview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deals_Detail_Preview_Stream_Cursor_Input>>;
  where?: Maybe<Deals_Detail_Preview_Bool_Exp>;
};


export type Subscription_RootDeals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deals_Stream_Cursor_Input>>;
  where?: Maybe<Deals_Bool_Exp>;
};


export type Subscription_RootEmail_Email_AttachmentsArgs = {
  distinct_on?: Maybe<Array<Email_Email_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Email_Attachments_Order_By>>;
  where?: Maybe<Email_Email_Attachments_Bool_Exp>;
};


export type Subscription_RootEmail_Email_Attachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Email_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Email_Attachments_Order_By>>;
  where?: Maybe<Email_Email_Attachments_Bool_Exp>;
};


export type Subscription_RootEmail_Email_Attachments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmail_Email_Attachments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Email_Email_Attachments_Stream_Cursor_Input>>;
  where?: Maybe<Email_Email_Attachments_Bool_Exp>;
};


export type Subscription_RootEmail_EmailsArgs = {
  distinct_on?: Maybe<Array<Email_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Emails_Order_By>>;
  where?: Maybe<Email_Emails_Bool_Exp>;
};


export type Subscription_RootEmail_Emails_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Emails_Order_By>>;
  where?: Maybe<Email_Emails_Bool_Exp>;
};


export type Subscription_RootEmail_Emails_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmail_Emails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Email_Emails_Stream_Cursor_Input>>;
  where?: Maybe<Email_Emails_Bool_Exp>;
};


export type Subscription_RootEnum_TypesArgs = {
  distinct_on?: Maybe<Array<Enum_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Types_Order_By>>;
  where?: Maybe<Enum_Types_Bool_Exp>;
};


export type Subscription_RootEnum_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Types_Order_By>>;
  where?: Maybe<Enum_Types_Bool_Exp>;
};


export type Subscription_RootEnum_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enum_Types_Stream_Cursor_Input>>;
  where?: Maybe<Enum_Types_Bool_Exp>;
};


export type Subscription_RootFee_TypesArgs = {
  distinct_on?: Maybe<Array<Fee_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fee_Types_Order_By>>;
  where?: Maybe<Fee_Types_Bool_Exp>;
};


export type Subscription_RootFee_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Fee_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fee_Types_Order_By>>;
  where?: Maybe<Fee_Types_Bool_Exp>;
};


export type Subscription_RootFee_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFee_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fee_Types_Stream_Cursor_Input>>;
  where?: Maybe<Fee_Types_Bool_Exp>;
};


export type Subscription_RootFeesArgs = {
  distinct_on?: Maybe<Array<Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fees_Order_By>>;
  where?: Maybe<Fees_Bool_Exp>;
};


export type Subscription_RootFees_AggregateArgs = {
  distinct_on?: Maybe<Array<Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fees_Order_By>>;
  where?: Maybe<Fees_Bool_Exp>;
};


export type Subscription_RootFees_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFees_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fees_Stream_Cursor_Input>>;
  where?: Maybe<Fees_Bool_Exp>;
};


export type Subscription_RootFiles_Document_Render_ResultsArgs = {
  distinct_on?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Render_Results_Order_By>>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};


export type Subscription_RootFiles_Document_Render_Results_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Document_Render_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Render_Results_Order_By>>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};


export type Subscription_RootFiles_Document_Render_Results_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFiles_Document_Render_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Files_Document_Render_Results_Stream_Cursor_Input>>;
  where?: Maybe<Files_Document_Render_Results_Bool_Exp>;
};


export type Subscription_RootFiles_Document_Template_ExecutionsArgs = {
  distinct_on?: Maybe<Array<Files_Document_Template_Executions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Template_Executions_Order_By>>;
  where?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
};


export type Subscription_RootFiles_Document_Template_Executions_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Document_Template_Executions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Template_Executions_Order_By>>;
  where?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
};


export type Subscription_RootFiles_Document_Template_Executions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFiles_Document_Template_Executions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Files_Document_Template_Executions_Stream_Cursor_Input>>;
  where?: Maybe<Files_Document_Template_Executions_Bool_Exp>;
};


export type Subscription_RootFiles_Document_TemplatesArgs = {
  distinct_on?: Maybe<Array<Files_Document_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Templates_Order_By>>;
  where?: Maybe<Files_Document_Templates_Bool_Exp>;
};


export type Subscription_RootFiles_Document_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Document_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Document_Templates_Order_By>>;
  where?: Maybe<Files_Document_Templates_Bool_Exp>;
};


export type Subscription_RootFiles_Document_Templates_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFiles_Document_Templates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Files_Document_Templates_Stream_Cursor_Input>>;
  where?: Maybe<Files_Document_Templates_Bool_Exp>;
};


export type Subscription_RootFiles_Pdf_RenderArgs = {
  distinct_on?: Maybe<Array<Files_Pdf_Render_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Pdf_Render_Order_By>>;
  where?: Maybe<Files_Pdf_Render_Bool_Exp>;
};


export type Subscription_RootFiles_Pdf_Render_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Pdf_Render_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Pdf_Render_Order_By>>;
  where?: Maybe<Files_Pdf_Render_Bool_Exp>;
};


export type Subscription_RootFiles_Pdf_Render_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFiles_Pdf_Render_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Files_Pdf_Render_Stream_Cursor_Input>>;
  where?: Maybe<Files_Pdf_Render_Bool_Exp>;
};


export type Subscription_RootForecast_TypesArgs = {
  distinct_on?: Maybe<Array<Forecast_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Types_Order_By>>;
  where?: Maybe<Forecast_Types_Bool_Exp>;
};


export type Subscription_RootForecast_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Types_Order_By>>;
  where?: Maybe<Forecast_Types_Bool_Exp>;
};


export type Subscription_RootForecast_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootForecast_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Forecast_Types_Stream_Cursor_Input>>;
  where?: Maybe<Forecast_Types_Bool_Exp>;
};


export type Subscription_RootGroup_TypesArgs = {
  distinct_on?: Maybe<Array<Group_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Types_Order_By>>;
  where?: Maybe<Group_Types_Bool_Exp>;
};


export type Subscription_RootGroup_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Types_Order_By>>;
  where?: Maybe<Group_Types_Bool_Exp>;
};


export type Subscription_RootGroup_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGroup_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Group_Types_Stream_Cursor_Input>>;
  where?: Maybe<Group_Types_Bool_Exp>;
};


export type Subscription_RootGroupsArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGroups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Groups_Stream_Cursor_Input>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Subscription_RootInd_TypesArgs = {
  distinct_on?: Maybe<Array<Ind_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ind_Types_Order_By>>;
  where?: Maybe<Ind_Types_Bool_Exp>;
};


export type Subscription_RootInd_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Ind_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ind_Types_Order_By>>;
  where?: Maybe<Ind_Types_Bool_Exp>;
};


export type Subscription_RootInd_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInd_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ind_Types_Stream_Cursor_Input>>;
  where?: Maybe<Ind_Types_Bool_Exp>;
};


export type Subscription_RootIndicesArgs = {
  distinct_on?: Maybe<Array<Indices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Indices_Order_By>>;
  where?: Maybe<Indices_Bool_Exp>;
};


export type Subscription_RootIndices_AggregateArgs = {
  distinct_on?: Maybe<Array<Indices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Indices_Order_By>>;
  where?: Maybe<Indices_Bool_Exp>;
};


export type Subscription_RootIndices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIndices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Indices_Stream_Cursor_Input>>;
  where?: Maybe<Indices_Bool_Exp>;
};


export type Subscription_RootInvitationsArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};


export type Subscription_RootInvitations_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};


export type Subscription_RootInvitations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvitations_CustomersArgs = {
  distinct_on?: Maybe<Array<Invitations_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Customers_Order_By>>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};


export type Subscription_RootInvitations_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitations_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Customers_Order_By>>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};


export type Subscription_RootInvitations_Customers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvitations_Customers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invitations_Customers_Stream_Cursor_Input>>;
  where?: Maybe<Invitations_Customers_Bool_Exp>;
};


export type Subscription_RootInvitations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invitations_Stream_Cursor_Input>>;
  where?: Maybe<Invitations_Bool_Exp>;
};


export type Subscription_RootInvoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Aggregate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Aggregate_Order_By>>;
  where?: Maybe<Invoice_Aggregate_Bool_Exp>;
};


export type Subscription_RootInvoice_Aggregate_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Aggregate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Aggregate_Order_By>>;
  where?: Maybe<Invoice_Aggregate_Bool_Exp>;
};


export type Subscription_RootInvoice_Aggregate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invoice_Aggregate_Stream_Cursor_Input>>;
  where?: Maybe<Invoice_Aggregate_Bool_Exp>;
};


export type Subscription_RootInvoice_DataArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


export type Subscription_RootInvoice_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Order_By>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


export type Subscription_RootInvoice_Data_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvoice_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invoice_Data_Stream_Cursor_Input>>;
  where?: Maybe<Invoice_Data_Bool_Exp>;
};


export type Subscription_RootInvoice_Data_Sum_By_MonthArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Sum_By_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Sum_By_Month_Order_By>>;
  where?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
};


export type Subscription_RootInvoice_Data_Sum_By_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Data_Sum_By_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Data_Sum_By_Month_Order_By>>;
  where?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
};


export type Subscription_RootInvoice_Data_Sum_By_Month_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invoice_Data_Sum_By_Month_Stream_Cursor_Input>>;
  where?: Maybe<Invoice_Data_Sum_By_Month_Bool_Exp>;
};


export type Subscription_RootInvoice_NumbersArgs = {
  distinct_on?: Maybe<Array<Invoice_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Numbers_Order_By>>;
  where?: Maybe<Invoice_Numbers_Bool_Exp>;
};


export type Subscription_RootInvoice_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoice_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoice_Numbers_Order_By>>;
  where?: Maybe<Invoice_Numbers_Bool_Exp>;
};


export type Subscription_RootInvoice_Numbers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvoice_Numbers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Invoice_Numbers_Stream_Cursor_Input>>;
  where?: Maybe<Invoice_Numbers_Bool_Exp>;
};


export type Subscription_RootLdc_Accounts_ViewArgs = {
  distinct_on?: Maybe<Array<Ldc_Accounts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Accounts_View_Order_By>>;
  where?: Maybe<Ldc_Accounts_View_Bool_Exp>;
};


export type Subscription_RootLdc_Accounts_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Ldc_Accounts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Accounts_View_Order_By>>;
  where?: Maybe<Ldc_Accounts_View_Bool_Exp>;
};


export type Subscription_RootLdc_Accounts_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ldc_Accounts_View_Stream_Cursor_Input>>;
  where?: Maybe<Ldc_Accounts_View_Bool_Exp>;
};


export type Subscription_RootLdc_Invoices_ViewArgs = {
  distinct_on?: Maybe<Array<Ldc_Invoices_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Invoices_View_Order_By>>;
  where?: Maybe<Ldc_Invoices_View_Bool_Exp>;
};


export type Subscription_RootLdc_Invoices_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Ldc_Invoices_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Invoices_View_Order_By>>;
  where?: Maybe<Ldc_Invoices_View_Bool_Exp>;
};


export type Subscription_RootLdc_Invoices_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ldc_Invoices_View_Stream_Cursor_Input>>;
  where?: Maybe<Ldc_Invoices_View_Bool_Exp>;
};


export type Subscription_RootLdc_Service_Charges_ViewArgs = {
  distinct_on?: Maybe<Array<Ldc_Service_Charges_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Service_Charges_View_Order_By>>;
  where?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
};


export type Subscription_RootLdc_Service_Charges_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Ldc_Service_Charges_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ldc_Service_Charges_View_Order_By>>;
  where?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
};


export type Subscription_RootLdc_Service_Charges_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ldc_Service_Charges_View_Stream_Cursor_Input>>;
  where?: Maybe<Ldc_Service_Charges_View_Bool_Exp>;
};


export type Subscription_RootLoad_ForecastsArgs = {
  distinct_on?: Maybe<Array<Load_Forecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_Forecasts_Order_By>>;
  where?: Maybe<Load_Forecasts_Bool_Exp>;
};


export type Subscription_RootLoad_Forecasts_AggregateArgs = {
  distinct_on?: Maybe<Array<Load_Forecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_Forecasts_Order_By>>;
  where?: Maybe<Load_Forecasts_Bool_Exp>;
};


export type Subscription_RootLoad_Forecasts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootLoad_Forecasts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Load_Forecasts_Stream_Cursor_Input>>;
  where?: Maybe<Load_Forecasts_Bool_Exp>;
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Locations_Stream_Cursor_Input>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Subscription_RootLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Subscription_RootLogs_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootLogs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Logs_Stream_Cursor_Input>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Subscription_RootParamsArgs = {
  distinct_on?: Maybe<Array<Params_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Params_Order_By>>;
  where?: Maybe<Params_Bool_Exp>;
};


export type Subscription_RootParams_AggregateArgs = {
  distinct_on?: Maybe<Array<Params_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Params_Order_By>>;
  where?: Maybe<Params_Bool_Exp>;
};


export type Subscription_RootParams_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootParams_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Params_Stream_Cursor_Input>>;
  where?: Maybe<Params_Bool_Exp>;
};


export type Subscription_RootPathsArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};


export type Subscription_RootPaths_AggregateArgs = {
  distinct_on?: Maybe<Array<Paths_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Paths_Order_By>>;
  where?: Maybe<Paths_Bool_Exp>;
};


export type Subscription_RootPaths_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPaths_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Paths_Stream_Cursor_Input>>;
  where?: Maybe<Paths_Bool_Exp>;
};


export type Subscription_RootPipelinesArgs = {
  distinct_on?: Maybe<Array<Pipelines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pipelines_Order_By>>;
  where?: Maybe<Pipelines_Bool_Exp>;
};


export type Subscription_RootPipelines_AggregateArgs = {
  distinct_on?: Maybe<Array<Pipelines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pipelines_Order_By>>;
  where?: Maybe<Pipelines_Bool_Exp>;
};


export type Subscription_RootPipelines_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPipelines_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Pipelines_Stream_Cursor_Input>>;
  where?: Maybe<Pipelines_Bool_Exp>;
};


export type Subscription_RootRecordsArgs = {
  distinct_on?: Maybe<Array<Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Records_Order_By>>;
  where?: Maybe<Records_Bool_Exp>;
};


export type Subscription_RootRecords_AggregateArgs = {
  distinct_on?: Maybe<Array<Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Records_Order_By>>;
  where?: Maybe<Records_Bool_Exp>;
};


export type Subscription_RootRecords_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRecords_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Records_Stream_Cursor_Input>>;
  where?: Maybe<Records_Bool_Exp>;
};


export type Subscription_RootReport_DashboardArgs = {
  distinct_on?: Maybe<Array<Report_Dashboard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Dashboard_Order_By>>;
  where?: Maybe<Report_Dashboard_Bool_Exp>;
};


export type Subscription_RootReport_Dashboard_AggregateArgs = {
  distinct_on?: Maybe<Array<Report_Dashboard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Dashboard_Order_By>>;
  where?: Maybe<Report_Dashboard_Bool_Exp>;
};


export type Subscription_RootReport_Dashboard_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootReport_Dashboard_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Report_Dashboard_Stream_Cursor_Input>>;
  where?: Maybe<Report_Dashboard_Bool_Exp>;
};


export type Subscription_RootRisk_TypesArgs = {
  distinct_on?: Maybe<Array<Risk_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Risk_Types_Order_By>>;
  where?: Maybe<Risk_Types_Bool_Exp>;
};


export type Subscription_RootRisk_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Risk_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Risk_Types_Order_By>>;
  where?: Maybe<Risk_Types_Bool_Exp>;
};


export type Subscription_RootRisk_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRisk_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Risk_Types_Stream_Cursor_Input>>;
  where?: Maybe<Risk_Types_Bool_Exp>;
};


export type Subscription_RootSearch_AddressesArgs = {
  args: Search_Addresses_Args;
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Subscription_RootSearch_Addresses_AggregateArgs = {
  args: Search_Addresses_Args;
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Subscription_RootSearch_Contact_EntriesArgs = {
  args: Search_Contact_Entries_Args;
  distinct_on?: Maybe<Array<Contact_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Entries_Order_By>>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};


export type Subscription_RootSearch_Contact_Entries_AggregateArgs = {
  args: Search_Contact_Entries_Args;
  distinct_on?: Maybe<Array<Contact_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Entries_Order_By>>;
  where?: Maybe<Contact_Entries_Bool_Exp>;
};


export type Subscription_RootSearch_CustomersArgs = {
  args: Search_Customers_Args;
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootSearch_Customers_AggregateArgs = {
  args: Search_Customers_Args;
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};


export type Subscription_RootSearch_DealsArgs = {
  args: Search_Deals_Args;
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


export type Subscription_RootSearch_Deals_AggregateArgs = {
  args: Search_Deals_Args;
  distinct_on?: Maybe<Array<Deals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deals_Order_By>>;
  where?: Maybe<Deals_Bool_Exp>;
};


export type Subscription_RootSearch_GroupsArgs = {
  args: Search_Groups_Args;
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Subscription_RootSearch_Groups_AggregateArgs = {
  args: Search_Groups_Args;
  distinct_on?: Maybe<Array<Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Groups_Order_By>>;
  where?: Maybe<Groups_Bool_Exp>;
};


export type Subscription_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: Maybe<Array<Auth_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Users_Order_By>>;
  where?: Maybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootUsers_DataArgs = {
  distinct_on?: Maybe<Array<Users_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Data_Order_By>>;
  where?: Maybe<Users_Data_Bool_Exp>;
};


export type Subscription_RootUsers_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Data_Order_By>>;
  where?: Maybe<Users_Data_Bool_Exp>;
};


export type Subscription_RootUsers_Data_By_PkArgs = {
  key: Scalars['String'];
  user_id: Scalars['bigint'];
};


export type Subscription_RootUsers_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Users_Data_Stream_Cursor_Input>>;
  where?: Maybe<Users_Data_Bool_Exp>;
};


export type Subscription_RootWrec_MeetingArgs = {
  distinct_on?: Maybe<Array<Wrec_Meeting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wrec_Meeting_Order_By>>;
  where?: Maybe<Wrec_Meeting_Bool_Exp>;
};


export type Subscription_RootWrec_Meeting_AggregateArgs = {
  distinct_on?: Maybe<Array<Wrec_Meeting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wrec_Meeting_Order_By>>;
  where?: Maybe<Wrec_Meeting_Bool_Exp>;
};


export type Subscription_RootWrec_Meeting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWrec_Meeting_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Wrec_Meeting_Stream_Cursor_Input>>;
  where?: Maybe<Wrec_Meeting_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users_data" */
export type Users_Data = {
  __typename?: 'users_data';
  created_at: Scalars['timestamptz'];
  generation: Scalars['Int'];
  key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Auth_Users;
  user_id: Scalars['bigint'];
  value?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "users_data" */
export type Users_DataValueArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users_data" */
export type Users_Data_Aggregate = {
  __typename?: 'users_data_aggregate';
  aggregate?: Maybe<Users_Data_Aggregate_Fields>;
  nodes: Array<Users_Data>;
};

export type Users_Data_Aggregate_Bool_Exp = {
  count?: Maybe<Users_Data_Aggregate_Bool_Exp_Count>;
};

export type Users_Data_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Users_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Users_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_data" */
export type Users_Data_Aggregate_Fields = {
  __typename?: 'users_data_aggregate_fields';
  avg?: Maybe<Users_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Data_Max_Fields>;
  min?: Maybe<Users_Data_Min_Fields>;
  stddev?: Maybe<Users_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Data_Sum_Fields>;
  var_pop?: Maybe<Users_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Data_Var_Samp_Fields>;
  variance?: Maybe<Users_Data_Variance_Fields>;
};


/** aggregate fields of "users_data" */
export type Users_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_data" */
export type Users_Data_Aggregate_Order_By = {
  avg?: Maybe<Users_Data_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Data_Max_Order_By>;
  min?: Maybe<Users_Data_Min_Order_By>;
  stddev?: Maybe<Users_Data_Stddev_Order_By>;
  stddev_pop?: Maybe<Users_Data_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Users_Data_Stddev_Samp_Order_By>;
  sum?: Maybe<Users_Data_Sum_Order_By>;
  var_pop?: Maybe<Users_Data_Var_Pop_Order_By>;
  var_samp?: Maybe<Users_Data_Var_Samp_Order_By>;
  variance?: Maybe<Users_Data_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Data_Append_Input = {
  value?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "users_data" */
export type Users_Data_Arr_Rel_Insert_Input = {
  data: Array<Users_Data_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Users_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Data_Avg_Fields = {
  __typename?: 'users_data_avg_fields';
  generation?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users_data" */
export type Users_Data_Avg_Order_By = {
  generation?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_data". All fields are combined with a logical 'AND'. */
export type Users_Data_Bool_Exp = {
  _and?: Maybe<Array<Users_Data_Bool_Exp>>;
  _not?: Maybe<Users_Data_Bool_Exp>;
  _or?: Maybe<Array<Users_Data_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  generation?: Maybe<Int_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Auth_Users_Bool_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
  value?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_data" */
export enum Users_Data_Constraint {
  /** unique or primary key constraint on columns "user_id", "key" */
  UsersDataPkey = 'users_data_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Data_Delete_At_Path_Input = {
  value?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Data_Delete_Elem_Input = {
  value?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Data_Delete_Key_Input = {
  value?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "users_data" */
export type Users_Data_Inc_Input = {
  generation?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users_data" */
export type Users_Data_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Auth_Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Users_Data_Max_Fields = {
  __typename?: 'users_data_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "users_data" */
export type Users_Data_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  generation?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Data_Min_Fields = {
  __typename?: 'users_data_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "users_data" */
export type Users_Data_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  generation?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "users_data" */
export type Users_Data_Mutation_Response = {
  __typename?: 'users_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Data>;
};

/** on_conflict condition type for table "users_data" */
export type Users_Data_On_Conflict = {
  constraint: Users_Data_Constraint;
  update_columns?: Array<Users_Data_Update_Column>;
  where?: Maybe<Users_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "users_data". */
export type Users_Data_Order_By = {
  created_at?: Maybe<Order_By>;
  generation?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Auth_Users_Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: users_data */
export type Users_Data_Pk_Columns_Input = {
  key: Scalars['String'];
  user_id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Data_Prepend_Input = {
  value?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "users_data" */
export enum Users_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Generation = 'generation',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "users_data" */
export type Users_Data_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Users_Data_Stddev_Fields = {
  __typename?: 'users_data_stddev_fields';
  generation?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users_data" */
export type Users_Data_Stddev_Order_By = {
  generation?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Data_Stddev_Pop_Fields = {
  __typename?: 'users_data_stddev_pop_fields';
  generation?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users_data" */
export type Users_Data_Stddev_Pop_Order_By = {
  generation?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Data_Stddev_Samp_Fields = {
  __typename?: 'users_data_stddev_samp_fields';
  generation?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users_data" */
export type Users_Data_Stddev_Samp_Order_By = {
  generation?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "users_data" */
export type Users_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Data_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  generation?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Users_Data_Sum_Fields = {
  __typename?: 'users_data_sum_fields';
  generation?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "users_data" */
export type Users_Data_Sum_Order_By = {
  generation?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "users_data" */
export enum Users_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Generation = 'generation',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Users_Data_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Users_Data_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Users_Data_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Users_Data_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Users_Data_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Users_Data_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Users_Data_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Users_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Data_Var_Pop_Fields = {
  __typename?: 'users_data_var_pop_fields';
  generation?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users_data" */
export type Users_Data_Var_Pop_Order_By = {
  generation?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Data_Var_Samp_Fields = {
  __typename?: 'users_data_var_samp_fields';
  generation?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users_data" */
export type Users_Data_Var_Samp_Order_By = {
  generation?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Data_Variance_Fields = {
  __typename?: 'users_data_variance_fields';
  generation?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users_data" */
export type Users_Data_Variance_Order_By = {
  generation?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "wrec.meeting" */
export type Wrec_Meeting = {
  __typename?: 'wrec_meeting';
  config: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  owner: Auth_Users;
  owner_id: Scalars['Int'];
  question_votes: Scalars['jsonb'];
  sentiment_votes: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "wrec.meeting" */
export type Wrec_MeetingConfigArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "wrec.meeting" */
export type Wrec_MeetingQuestion_VotesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "wrec.meeting" */
export type Wrec_MeetingSentiment_VotesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "wrec.meeting" */
export type Wrec_Meeting_Aggregate = {
  __typename?: 'wrec_meeting_aggregate';
  aggregate?: Maybe<Wrec_Meeting_Aggregate_Fields>;
  nodes: Array<Wrec_Meeting>;
};

/** aggregate fields of "wrec.meeting" */
export type Wrec_Meeting_Aggregate_Fields = {
  __typename?: 'wrec_meeting_aggregate_fields';
  avg?: Maybe<Wrec_Meeting_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wrec_Meeting_Max_Fields>;
  min?: Maybe<Wrec_Meeting_Min_Fields>;
  stddev?: Maybe<Wrec_Meeting_Stddev_Fields>;
  stddev_pop?: Maybe<Wrec_Meeting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wrec_Meeting_Stddev_Samp_Fields>;
  sum?: Maybe<Wrec_Meeting_Sum_Fields>;
  var_pop?: Maybe<Wrec_Meeting_Var_Pop_Fields>;
  var_samp?: Maybe<Wrec_Meeting_Var_Samp_Fields>;
  variance?: Maybe<Wrec_Meeting_Variance_Fields>;
};


/** aggregate fields of "wrec.meeting" */
export type Wrec_Meeting_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wrec_Meeting_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wrec_Meeting_Append_Input = {
  config?: Maybe<Scalars['jsonb']>;
  question_votes?: Maybe<Scalars['jsonb']>;
  sentiment_votes?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Wrec_Meeting_Avg_Fields = {
  __typename?: 'wrec_meeting_avg_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "wrec.meeting". All fields are combined with a logical 'AND'. */
export type Wrec_Meeting_Bool_Exp = {
  _and?: Maybe<Array<Wrec_Meeting_Bool_Exp>>;
  _not?: Maybe<Wrec_Meeting_Bool_Exp>;
  _or?: Maybe<Array<Wrec_Meeting_Bool_Exp>>;
  config?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  owner?: Maybe<Auth_Users_Bool_Exp>;
  owner_id?: Maybe<Int_Comparison_Exp>;
  question_votes?: Maybe<Jsonb_Comparison_Exp>;
  sentiment_votes?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "wrec.meeting" */
export enum Wrec_Meeting_Constraint {
  /** unique or primary key constraint on columns "id" */
  MeetingPkey = 'meeting_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wrec_Meeting_Delete_At_Path_Input = {
  config?: Maybe<Array<Scalars['String']>>;
  question_votes?: Maybe<Array<Scalars['String']>>;
  sentiment_votes?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wrec_Meeting_Delete_Elem_Input = {
  config?: Maybe<Scalars['Int']>;
  question_votes?: Maybe<Scalars['Int']>;
  sentiment_votes?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wrec_Meeting_Delete_Key_Input = {
  config?: Maybe<Scalars['String']>;
  question_votes?: Maybe<Scalars['String']>;
  sentiment_votes?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "wrec.meeting" */
export type Wrec_Meeting_Inc_Input = {
  owner_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "wrec.meeting" */
export type Wrec_Meeting_Insert_Input = {
  config?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner?: Maybe<Auth_Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['Int']>;
  question_votes?: Maybe<Scalars['jsonb']>;
  sentiment_votes?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wrec_Meeting_Max_Fields = {
  __typename?: 'wrec_meeting_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wrec_Meeting_Min_Fields = {
  __typename?: 'wrec_meeting_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "wrec.meeting" */
export type Wrec_Meeting_Mutation_Response = {
  __typename?: 'wrec_meeting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wrec_Meeting>;
};

/** on_conflict condition type for table "wrec.meeting" */
export type Wrec_Meeting_On_Conflict = {
  constraint: Wrec_Meeting_Constraint;
  update_columns?: Array<Wrec_Meeting_Update_Column>;
  where?: Maybe<Wrec_Meeting_Bool_Exp>;
};

/** Ordering options when selecting data from "wrec.meeting". */
export type Wrec_Meeting_Order_By = {
  config?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner?: Maybe<Auth_Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  question_votes?: Maybe<Order_By>;
  sentiment_votes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: wrec.meeting */
export type Wrec_Meeting_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wrec_Meeting_Prepend_Input = {
  config?: Maybe<Scalars['jsonb']>;
  question_votes?: Maybe<Scalars['jsonb']>;
  sentiment_votes?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "wrec.meeting" */
export enum Wrec_Meeting_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  QuestionVotes = 'question_votes',
  /** column name */
  SentimentVotes = 'sentiment_votes',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "wrec.meeting" */
export type Wrec_Meeting_Set_Input = {
  config?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  question_votes?: Maybe<Scalars['jsonb']>;
  sentiment_votes?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wrec_Meeting_Stddev_Fields = {
  __typename?: 'wrec_meeting_stddev_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wrec_Meeting_Stddev_Pop_Fields = {
  __typename?: 'wrec_meeting_stddev_pop_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wrec_Meeting_Stddev_Samp_Fields = {
  __typename?: 'wrec_meeting_stddev_samp_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "wrec_meeting" */
export type Wrec_Meeting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wrec_Meeting_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wrec_Meeting_Stream_Cursor_Value_Input = {
  config?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  question_votes?: Maybe<Scalars['jsonb']>;
  sentiment_votes?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Wrec_Meeting_Sum_Fields = {
  __typename?: 'wrec_meeting_sum_fields';
  owner_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "wrec.meeting" */
export enum Wrec_Meeting_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  QuestionVotes = 'question_votes',
  /** column name */
  SentimentVotes = 'sentiment_votes',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Wrec_Meeting_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Wrec_Meeting_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Wrec_Meeting_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Wrec_Meeting_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Wrec_Meeting_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Wrec_Meeting_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Wrec_Meeting_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Wrec_Meeting_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wrec_Meeting_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wrec_Meeting_Var_Pop_Fields = {
  __typename?: 'wrec_meeting_var_pop_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wrec_Meeting_Var_Samp_Fields = {
  __typename?: 'wrec_meeting_var_samp_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wrec_Meeting_Variance_Fields = {
  __typename?: 'wrec_meeting_variance_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

export type InsertLogEntriesMutationVariables = Exact<{
  log_entries: Array<Logs_Insert_Input> | Logs_Insert_Input;
}>;


export type InsertLogEntriesMutation = { __typename?: 'mutation_root', insert_logs?: { __typename?: 'logs_mutation_response', affected_rows: number } | null | undefined };

export type UpdateAchInfoMutationVariables = Exact<{
  customerId: Scalars['Int'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  accountType: Scalars['String'];
  nameOnAccount: Scalars['String'];
  bankName: Scalars['String'];
  signatureText: Scalars['String'];
  autoPay: Scalars['Boolean'];
}>;


export type UpdateAchInfoMutation = { __typename?: 'mutation_root', updateAchInfo?: string | null | undefined };

export type UpdateInvitationMutationVariables = Exact<{
  token: Scalars['String'];
  client_data: Scalars['jsonb'];
}>;


export type UpdateInvitationMutation = { __typename?: 'mutation_root', update_invitations?: { __typename?: 'invitations_mutation_response', affected_rows: number } | null | undefined };

export type BillingInfoQueryVariables = Exact<{
  customer_id: Scalars['Int'];
}>;


export type BillingInfoQuery = { __typename?: 'query_root', balance_dues: Array<{ __typename?: 'balance_dues', amount_due?: any | null | undefined, created_at: any, due_date?: any | null | undefined, effective_date?: any | null | undefined, invoice_number?: number | null | undefined }>, deals: Array<{ __typename?: 'deals', billto_address?: { __typename?: 'addresses', address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, country?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined } | null | undefined }> };

export type CustomerInvoiceDataQueryVariables = Exact<{
  customer_id: Scalars['Int'];
}>;


export type CustomerInvoiceDataQuery = { __typename?: 'query_root', invoice_numbers: Array<{ __typename?: 'invoice_numbers', invoice_number?: number | null | undefined, accounting_mth?: any | null | undefined, invoice_data: Array<{ __typename?: 'invoice_data', accounting_month?: any | null | undefined, invoice_date?: any | null | undefined, pipeline?: string | null | undefined, ldc?: string | null | undefined, premise_number?: string | null | undefined, dth?: any | null | undefined, ext?: any | null | undefined, service_id?: number | null | undefined, dd_id?: number | null | undefined, service_address?: { __typename?: 'addresses', id: number, address1?: string | null | undefined, city?: string | null | undefined, country?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined } | null | undefined, deal_detail?: { __typename?: 'deal_details', account_number?: string | null | undefined } | null | undefined }>, invoice_data_aggregate: { __typename?: 'invoice_data_aggregate', aggregate?: { __typename?: 'invoice_data_aggregate_fields', count: number, sum?: { __typename?: 'invoice_data_sum_fields', ext?: any | null | undefined, dth?: any | null | undefined } | null | undefined } | null | undefined } }> };

export type CustomerInvoiceDataAggQueryVariables = Exact<{
  customer_id: Scalars['Int'];
}>;


export type CustomerInvoiceDataAggQuery = { __typename?: 'query_root', invoice_numbers: Array<{ __typename?: 'invoice_numbers', invoice_number?: number | null | undefined, accounting_mth?: any | null | undefined, invoice_aggregate?: { __typename?: 'invoice_aggregate', dth?: any | null | undefined, ext?: any | null | undefined, accounting_month?: any | null | undefined, invoice_data?: { __typename?: 'invoice_data', accounting_month?: any | null | undefined, invoice_date?: any | null | undefined, pipeline?: string | null | undefined, ldc?: string | null | undefined, premise_number?: string | null | undefined, dth?: any | null | undefined, ext?: any | null | undefined, service_id?: number | null | undefined, dd_id?: number | null | undefined, service_address?: { __typename?: 'addresses', id: number, address1?: string | null | undefined, city?: string | null | undefined, country?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined } | null | undefined, deal_detail?: { __typename?: 'deal_details', account_number?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined }> };

export type CustomersInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomersInfoQuery = { __typename?: 'query_root', customers: Array<{ __typename?: 'customers', id: number, name?: string | null | undefined, verification_email?: string | null | undefined, uuid?: string | null | undefined, ach_on_file?: boolean | null | undefined, autopay?: boolean | null | undefined, contact_assignments: Array<{ __typename?: 'contact_assignments', contact_entry?: { __typename?: 'contact_entries', id: number, email?: string | null | undefined, fax?: string | null | undefined, given_name?: string | null | undefined, middle_name?: string | null | undefined, mobile_phone?: string | null | undefined, name?: string | null | undefined, roles?: Array<any> | null | undefined, salutation?: string | null | undefined, surname?: string | null | undefined, title?: string | null | undefined, work_phone?: string | null | undefined } | null | undefined }>, company?: { __typename?: 'indices', description?: string | null | undefined } | null | undefined }> };

export type DealmakersInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DealmakersInfoQuery = { __typename?: 'query_root', dealmakers: Array<{ __typename?: 'dealmakers', email?: string | null | undefined, fax?: string | null | undefined, name?: string | null | undefined, phone?: string | null | undefined }> };

export type InvitationQueryVariables = Exact<{ [key: string]: never; }>;


export type InvitationQuery = { __typename?: 'query_root', invitations: Array<{ __typename?: 'invitations', id: number, token: string, client_data?: any | null | undefined, metadata?: any | null | undefined, created_at: any, updated_at: any, invitations_customers: Array<{ __typename?: 'invitations_customers', id: number, customer: { __typename?: 'customers', id: number, name?: string | null | undefined, utility_billing_name?: string | null | undefined, deals: Array<{ __typename?: 'deals', billto_address?: { __typename?: 'addresses', address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, country?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined } | null | undefined }> } }> }> };

export type InvoiceFileUrlQueryVariables = Exact<{
  invoice_number: Scalars['Int'];
}>;


export type InvoiceFileUrlQuery = { __typename?: 'query_root', invoice_numbers: Array<{ __typename?: 'invoice_numbers', invoice_file_url?: string | null | undefined }> };


export const InsertLogEntriesDocument = gql`
    mutation insertLogEntries($log_entries: [logs_insert_input!]!) {
  insert_logs(objects: $log_entries) {
    affected_rows
  }
}
    `;

/**
 * __insertLogEntriesMutation__
 *
 * To run a mutation, you call `insertLogEntriesMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = insertLogEntriesMutation(this, {
 *   variables: {
 *     log_entries: // value for 'log_entries'
 *   },
 * });
 */
export const insertLogEntriesMutation = createMutationFunction<
  InsertLogEntriesMutation,
  InsertLogEntriesMutationVariables,
  ApolloError
>(InsertLogEntriesDocument);

export const UpdateAchInfoDocument = gql`
    mutation updateAchInfo($customerId: Int!, $routingNumber: String!, $accountNumber: String!, $accountType: String!, $nameOnAccount: String!, $bankName: String!, $signatureText: String!, $autoPay: Boolean!) {
  updateAchInfo(
    customerId: $customerId
    routingNumber: $routingNumber
    accountNumber: $accountNumber
    accountType: $accountType
    nameOnAccount: $nameOnAccount
    bankName: $bankName
    signatureText: $signatureText
    autoPay: $autoPay
  )
}
    `;

/**
 * __updateAchInfoMutation__
 *
 * To run a mutation, you call `updateAchInfoMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = updateAchInfoMutation(this, {
 *   variables: {
 *     customerId: // value for 'customerId'
 *     routingNumber: // value for 'routingNumber'
 *     accountNumber: // value for 'accountNumber'
 *     accountType: // value for 'accountType'
 *     nameOnAccount: // value for 'nameOnAccount'
 *     bankName: // value for 'bankName'
 *     signatureText: // value for 'signatureText'
 *     autoPay: // value for 'autoPay'
 *   },
 * });
 */
export const updateAchInfoMutation = createMutationFunction<
  UpdateAchInfoMutation,
  UpdateAchInfoMutationVariables,
  ApolloError
>(UpdateAchInfoDocument);

export const UpdateInvitationDocument = gql`
    mutation updateInvitation($token: String!, $client_data: jsonb!) {
  update_invitations(
    where: {token: {_eq: $token}}
    _set: {client_data: $client_data}
  ) {
    affected_rows
  }
}
    `;

/**
 * __updateInvitationMutation__
 *
 * To run a mutation, you call `updateInvitationMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = updateInvitationMutation(this, {
 *   variables: {
 *     token: // value for 'token'
 *     client_data: // value for 'client_data'
 *   },
 * });
 */
export const updateInvitationMutation = createMutationFunction<
  UpdateInvitationMutation,
  UpdateInvitationMutationVariables,
  ApolloError
>(UpdateInvitationDocument);

export const BillingInfoDocument = gql`
    query BillingInfo($customer_id: Int!) {
  balance_dues(
    where: {customer_id: {_eq: $customer_id}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    amount_due
    created_at
    due_date
    effective_date
    invoice_number
  }
  deals(
    order_by: {created_at: desc}
    where: {customer_id: {_eq: $customer_id}, deleted: {_neq: true}}
    limit: 1
  ) {
    billto_address {
      address1
      address2
      city
      country
      state
      zip
    }
  }
}
    `;

/**
 * __useBillingInfoQuery__
 *
 * To use a Smart Query within a Vue component, call `useBillingInfoQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     billingInfo: useBillingInfoQuery({
 *       variables: {
 *         customer_id: // value for 'customer_id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useBillingInfoQuery = createSmartQueryOptionsFunction<
  BillingInfoQuery,
  BillingInfoQueryVariables,
  ApolloError
>(BillingInfoDocument);

export const CustomerInvoiceDataDocument = gql`
    query CustomerInvoiceData($customer_id: Int!) {
  invoice_numbers(where: {customer_id: {_eq: $customer_id}}) {
    invoice_number
    accounting_mth
    invoice_data {
      accounting_month
      invoice_date
      pipeline
      ldc
      premise_number
      dth
      ext
      service_address {
        id
        address1
        city
        country
        state
        zip
      }
      service_id
      pipeline
      dd_id
      deal_detail {
        account_number
      }
    }
    invoice_data_aggregate {
      aggregate {
        sum {
          ext
          dth
        }
        count
      }
    }
  }
}
    `;

/**
 * __useCustomerInvoiceDataQuery__
 *
 * To use a Smart Query within a Vue component, call `useCustomerInvoiceDataQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     customerInvoiceData: useCustomerInvoiceDataQuery({
 *       variables: {
 *         customer_id: // value for 'customer_id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useCustomerInvoiceDataQuery = createSmartQueryOptionsFunction<
  CustomerInvoiceDataQuery,
  CustomerInvoiceDataQueryVariables,
  ApolloError
>(CustomerInvoiceDataDocument);

export const CustomerInvoiceDataAggDocument = gql`
  query CustomerInvoiceDataAgg($customer_id: Int!) {
    invoice_numbers(
      where: {
        customer_id: { _eq: $customer_id }
        posted: { _eq: true }
        invoice_data: { posted: { _eq: true } }
      }
    ) {
      invoice_number
      accounting_mth
      invoice_aggregate {
        dth
        ext
        accounting_month
        invoice_data {
          accounting_month
          invoice_date
          pipeline
          ldc
          premise_number
          dth
          ext
          service_address {
            id
            address1
            city
            country
            state
            zip
          }
          service_id
          pipeline
          dd_id
          deal_detail {
            account_number
          }
        }
      }
    }
  }
`;

/**
 * __useCustomerInvoiceDataAggQuery__
 *
 * To use a Smart Query within a Vue component, call `useCustomerInvoiceDataAggQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     customerInvoiceDataAgg: useCustomerInvoiceDataAggQuery({
 *       variables: {
 *         customer_id: // value for 'customer_id'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useCustomerInvoiceDataAggQuery = createSmartQueryOptionsFunction<
  CustomerInvoiceDataAggQuery,
  CustomerInvoiceDataAggQueryVariables,
  ApolloError
>(CustomerInvoiceDataAggDocument);

export const CustomersInfoDocument = gql`
    query CustomersInfo {
  customers(order_by: {name: asc}) {
    id
    name
    verification_email
    uuid
    ach_on_file
    autopay
    contact_assignments {
      contact_entry {
        id
        email
        fax
        given_name
        middle_name
        mobile_phone
        name
        roles
        salutation
        surname
        title
        work_phone
      }
    }
    company {
      description
    }
  }
}
    `;

/**
 * __useCustomersInfoQuery__
 *
 * To use a Smart Query within a Vue component, call `useCustomersInfoQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     customersInfo: useCustomersInfoQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useCustomersInfoQuery = createSmartQueryOptionsFunction<
  CustomersInfoQuery,
  CustomersInfoQueryVariables,
  ApolloError
>(CustomersInfoDocument);

export const DealmakersInfoDocument = gql`
    query DealmakersInfo {
  dealmakers {
    email
    fax
    name
    phone
  }
}
    `;

/**
 * __useDealmakersInfoQuery__
 *
 * To use a Smart Query within a Vue component, call `useDealmakersInfoQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     dealmakersInfo: useDealmakersInfoQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useDealmakersInfoQuery = createSmartQueryOptionsFunction<
  DealmakersInfoQuery,
  DealmakersInfoQueryVariables,
  ApolloError
>(DealmakersInfoDocument);

export const InvitationDocument = gql`
    query Invitation {
  invitations {
    id
    token
    client_data
    metadata
    created_at
    updated_at
    invitations_customers {
      id
      customer {
        id
        name
        utility_billing_name
        deals {
          billto_address {
            address1
            address2
            city
            country
            state
            zip
          }
        }
      }
    }
  }
}
    `;

/**
 * __useInvitationQuery__
 *
 * To use a Smart Query within a Vue component, call `useInvitationQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     invitation: useInvitationQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useInvitationQuery = createSmartQueryOptionsFunction<
  InvitationQuery,
  InvitationQueryVariables,
  ApolloError
>(InvitationDocument);

export const InvoiceFileUrlDocument = gql`
    query InvoiceFileUrl($invoice_number: Int!) {
  invoice_numbers(where: {invoice_number: {_eq: $invoice_number}}) {
    invoice_file_url
  }
}
    `;

/**
 * __useInvoiceFileUrlQuery__
 *
 * To use a Smart Query within a Vue component, call `useInvoiceFileUrlQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     invoiceFileUrl: useInvoiceFileUrlQuery({
 *       variables: {
 *         invoice_number: // value for 'invoice_number'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useInvoiceFileUrlQuery = createSmartQueryOptionsFunction<
  InvoiceFileUrlQuery,
  InvoiceFileUrlQueryVariables,
  ApolloError
>(InvoiceFileUrlDocument);
