// @/logger and @/disable_webflow_forms must come first.
import "@/logger";
import "@/disable_webflow_forms";

import { AuthPlugin, authProvider, initAuth } from "@/auth/provider";
import { config, getPageConfig } from "@/config";
import "@/directives";
import "@/filters/money";
import "@/filters/time";
import { logWarn } from "@/logger";
import { BillingInfoPlugin } from "@/plugins/billing_info";
import { CustomersInfoPlugin } from "@/plugins/customers_info";
import { CustomerInvoicesPlugin } from "@/plugins/customer_invoices";
import { DealmakersInfoPlugin } from "@/plugins/dealmakers_info";
import { UpdateAchPlugin } from "@/plugins/update_ach";
import { DOMContentLoaded } from "@/utils/events";
import Vue from "vue";
import { ReportsPlugin } from "@/plugins/reports";
import { populateLiveChatInfo } from "@/live_chat";
import StripeOneTimePayment from "@/components/stripe_one_time_payment.vue";
import { OpenAPI } from "@/generated/blink";
import { ChoicePlugin } from "@/plugins/choice";
import { OneTimeAchPlugin } from "@/plugins/one_time_ach";

// Plugins
const plugins: { [key: string]: any } = {
  $billingInfo: BillingInfoPlugin,
  $choice: ChoicePlugin,
  $customerInvoices: CustomerInvoicesPlugin,
  $customersInfo: CustomersInfoPlugin,
  $dealmakersInfo: DealmakersInfoPlugin,
  $reports: ReportsPlugin,
  $updateAch: UpdateAchPlugin,
  $oneTimeAch: OneTimeAchPlugin,
};

async function main() {
  // Handel app config redirects
  for (const redirect of config.appConfig.redirects) {
    if (redirect.pathMatch.test(window.location.pathname)) {
      const url = new URL(window.location.href);
      if (redirect.hostname) {
        url.hostname = redirect.hostname;
      }
      if (redirect.path) {
        url.pathname = redirect.path;
      }
      window.location.href = url.toString();
    }
  }

  // Init auth first.
  initAuth();

  // OpenAPI config.
  OpenAPI.BASE = window.location.host.startsWith("stage")
    ? "https://blink-stage.dokku.woodriverenergy.com"
    : "https://blink-prod.dokku.woodriverenergy.com";

  // Set the global token. This also needs to be set before each request though.
  OpenAPI.TOKEN = authProvider.token || undefined;

  // Components
  Vue.component("stripe-one-time-payment", StripeOneTimePayment);

  // Use auth, always. Even if we aren't logged in.
  Vue.use(AuthPlugin);

  await DOMContentLoaded;

  // Install plugins before creating.
  const pageConfig = await getPageConfig();
  for (const plugin of pageConfig.plugins) {
    if (!plugins.hasOwnProperty(plugin)) {
      logWarn(`unknown plugin (${plugin})`, {
        availablePlugins: Object.keys(plugins),
      });
      continue;
    }

    Vue.use(plugins[plugin]);
    (window as any)[plugin] = Vue[plugin];
  }

  if (document.getElementById("wre-app")) {
    const app = new Vue({ el: "#wre-app" });
    document.body.insertBefore(app.$el, document.body.firstChild);
  }

  // Populate LiveChat info when it's available. Don't bother to await it,
  // because we don't care. Honey badger doesn't give a shit.
  void populateLiveChatInfo();
}

void main();
