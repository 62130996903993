import { apolloProvider } from "@/apollo_provider";
import { ChoiceService, OpenAPI, QuoteResponse } from "@/generated/blink";
import { logError } from "@/logger";
import { isEmailValid } from "@/utils/email";
import { formatNumberWithCommas, isInt } from "@/utils/string";
import { isEmpty } from "lodash";
import Vue from "vue";

export interface Choice {
  error: string | null;
  enrollError: string | null;
  emailError: boolean;
  emailSent: boolean;
  premiseOrAccountNum: string;

  // Fields are all extracted out into short names because bindings on the
  // Webflow page are so ugly.
  enrollPending: boolean;
  hasQuote: boolean;
  notFound: boolean;
  multipleFoundError: number;
  multipleFoundWarn: number;
  pubIndex: string;
  accountNum: number;
  premiseNum: number;
  lastName: string;
  balloted: boolean;
  serviceAddress: string;
  serviceState: string;
  billClass: string;
  isCom: boolean;
  hideFM: boolean;
  in1: string;
  in2: string;
  in3: string;
  ft1: string;
  ft2: string;
  ft3: string;
  fm1: string;
  fm2: string;
  fm3: string;
  qid: number;
  vol: number;

  // Form inputs for signup
  selection:
    | null
    | "in1"
    | "in2"
    | "in3"
    | "ft1"
    | "ft2"
    | "ft3"
    | "fm1"
    | "fm2"
    | "fm3";
  bheControlNumber: string;
  bheEmail: string;
  agreesToTerms: boolean;
}

export const ChoicePlugin = {
  install(vue: any) {
    vue.prototype.$choice = new Vue<Choice>({
      created() {
        // TypeScript types are missing for this :( The TS support for Vue is
        // really super disappointing.
        // @ts-ignore
        this.$nextTick(this.createQuote);
      },
      data() {
        return {
          error: null,
          enrollError: null,
          emailError: false,
          emailSent: false,
          premiseOrAccountNum: "",

          enrollPending: false,
          hasQuote: false,
          notFound: false,
          multipleFoundError: 0,
          multipleFoundWarn: 0,
          pubIndex: "",
          accountNum: 0,
          premiseNum: 0,
          lastName: "",
          balloted: false,
          serviceAddress: "",
          serviceState: "",
          billClass: "",
          isCom: false,
          hideFM: false,
          in1: "",
          in2: "",
          in3: "",
          ft1: "",
          ft2: "",
          ft3: "",
          fm1: "",
          fm2: "",
          fm3: "",
          qid: 0,
          vol: 0,

          selection: null,
          bheControlNumber: "",
          bheEmail: "",
          agreesToTerms: false,
        } as Choice;
      },
      apolloProvider,
      computed: {
        isValidEntry() {
          return (
            isInt(this.premiseOrAccountNum) &&
            Number(this.premiseOrAccountNum) > 0
          );
        },
        canEnroll() {
          return (
            !this.enrollPending &&
            this.hasQuote &&
            this.selection &&
            isInt(this.bheControlNumber) &&
            (isEmpty(this.bheEmail) || isEmailValid(this.bheEmail)) &&
            this.agreesToTerms
          );
        },
        canEmail() {
          return this.hasQuote && this.pubIndex && isEmailValid(this.bheEmail);
        },
      },
      methods: {
        async emailQuote(e: Event) {
          e.preventDefault();
          e.stopPropagation();

          if (
            !this.hasQuote ||
            !this.pubIndex ||
            !isEmailValid(this.bheEmail)
          ) {
            return;
          }

          this.emailError = false;
          this.emailSent = false;

          try {
            await ChoiceService.emailQuote({
              email: this.bheEmail,
              quote_pub_index: this.pubIndex,
            });
            this.emailSent = true;
          } catch (e) {
            this.emailError = true;
            console.error(e);
          }
        },
        async enroll(e: Event) {
          e.preventDefault();
          e.stopPropagation();

          if (
            this.enrollPending ||
            !this.hasQuote ||
            !this.selection ||
            !isInt(this.bheControlNumber) ||
            (!isEmpty(this.bheEmail) && !isEmailValid(this.bheEmail)) ||
            !this.agreesToTerms
          ) {
            return;
          }

          this.enrollPending = true;
          this.error = null;
          this.enrollError = null;
          this.multipleFoundError = 0;

          try {
            const resp = await ChoiceService.createSelection({
              quote_id: this.qid,
              bhe_control_number: this.bheControlNumber,
              bhe_email: isEmpty(this.bheEmail)
                ? "choice@woodriverenergy.com"
                : this.bheEmail,
              selection: this.selection,
            });

            console.log("Resp:", resp);

            if (resp.error) {
              this.enrollError = resp.error;
              this.enrollPending = false;
            } else {
              location.href = `choice/success?verification_id=${resp.verification_id}`;
            }
          } catch (_) {
            this.enrollPending = false;
            this.enrollError =
              "Failed to enroll. Please check your email and control number.";
          }
        },
        async createQuote(e?: Event) {
          e?.preventDefault();
          e?.stopPropagation();

          // Get the optionally set quote ID from the URL search. It wil be used
          // to retrieve the quote if it's specified.
          const search = new URLSearchParams(location.search);
          const pubIndex = search.get("quote");

          if (!pubIndex && !isInt(this.premiseOrAccountNum)) {
            return;
          }

          this.error = null;
          this.notFound = false;
          this.multipleFoundError = 0;
          this.multipleFoundWarn = 0;
          this.hasQuote = false;
          this.selection = null;
          this.balloted = false;

          try {
            OpenAPI.TOKEN = undefined;
            let r: QuoteResponse;

            if (pubIndex) {
              r = await ChoiceService.findQuote({ pub_index: pubIndex });
            } else {
              r = await ChoiceService.createQuote({
                premise_or_account_num: Number(this.premiseOrAccountNum),
              });
            }

            this.hasQuote = !!r.quote;

            if (r.premise_count < 1) {
              this.notFound = true;
              return;
            }

            if (r.premise_count > 1 && !r.quote) {
              this.multipleFoundError = r.premise_count;
              return;
            }

            this.balloted = r.balloted;
            this.multipleFoundWarn = r.premise_count;
            this.notFound = r.premise_count === 0;

            if (r.quote) {
              const q = r.quote;
              this.qid = q.id;
              this.pubIndex = q.pub_index;
              this.accountNum = q.account_num;
              this.premiseNum = q.premise_num;
              this.lastName = q.last_name;
              this.serviceAddress = q.service_address;
              this.serviceState = q.service_state;
              this.billClass = q.bill_class;
              this.isCom = q.bill_class === "COM";
              this.hideFM = q.bill_class === "COM" && q.volume > 1500;
              this.in1 = formatNumberWithCommas(q.index[0], 3);
              this.in2 = formatNumberWithCommas(q.index[1], 3);
              this.in3 = formatNumberWithCommas(q.index[2], 3);
              this.ft1 = formatNumberWithCommas(q.fixed_per_therm[0], 3);
              this.ft2 = formatNumberWithCommas(q.fixed_per_therm[1], 3);
              this.ft3 = formatNumberWithCommas(q.fixed_per_therm[2], 3);
              this.fm1 = formatNumberWithCommas(q.fixed_per_month[0], 2);
              this.fm2 = formatNumberWithCommas(q.fixed_per_month[1], 2);
              this.fm3 = formatNumberWithCommas(q.fixed_per_month[2], 2);
              this.vol = q.volume * 10;
              this.bheEmail = q.email || this.bheEmail || "";
            }
          } catch (e) {
            logError(
              "Failed to find Choice account by account or premise num",
              {
                error: String(e),
                premise_or_account_num: this.premiseOrAccountNum,
              }
            );
            this.error = `Woops, something went wrong. Please give us a call!`;
          }
        },
      },
    });
  },
};
